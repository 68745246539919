import { api } from "../../../config/api";
import { IContractsHistoric, CreateContractsHistoric, UpdateContractsHistoric } from "../../interfaces/reg/contractsHistoric";

export class ContractsHistoric{

    constructor(private token?: string){}

    async readAll(): Promise<IContractsHistoric[]> {
        const response = await api.get(`/api/v1/reg/contractsHistoric`, {
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        })
        return response?.data?.data
    }

    async readById(id: number): Promise<IContractsHistoric> {
        const response = await api.get(`/api/v1/reg/contractsHistoric/${id}`, {
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        })
        return response.data.data
    }

    async removeById(id: number): Promise<void> {
        await api.delete(`/api/v1/reg/contractsHistoric/${id}`, {
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        })
    }

    async create(data: CreateContractsHistoric): Promise<IContractsHistoric> {
        const response = await api.post(`/api/v1/reg/contractsHistoric`, data, {
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        })
        return response.data.data
    }

    async updateById(id: number, data: UpdateContractsHistoric): Promise<IContractsHistoric> {
        console.log(data)
        const response = await api.put(`/api/v1/reg/contractsHistoric/${id}`, data, {
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        })
        return response.data.data
    }

}
