import { createContext, useContext, useState } from "react";
import { ChildrenProps } from "./alertContext";
import { IProjectsMembers } from "../services/interfaces/pro/projectsMembers";
import { IProjectsTags } from "../services/interfaces/pro/projectsTags";
import { ITasks } from "../services/interfaces/pro/tasks";
import { IProjectsSteps } from "../services/interfaces/pro/projectsSteps";

export const ScreenModalContext = createContext
<{
    detectModal: string
    setDetectModal: React.Dispatch<React.SetStateAction<string>>
    taskModal: Task | null
    setTaskModal: React.Dispatch<React.SetStateAction<Task | null>>
} | undefined>(undefined)

interface Task{
    pro_tasks_id: string
    pro_projects_id: number
    pro_projectsSteps_id: number
    pro_projectsSteps?: IProjectsSteps[]
    pro_projectsMembers?: IProjectsMembers[]
    pro_projectsTags?: IProjectsTags[]
    inProjectView?: boolean
    taskData?: ITasks
    openTab?: 'task' | 'times' | 'comentaries' | 'files'
    externalCreateCallback?: any
    externalUpdateCallback?: any
}

export function ScreenModalProvider({ children }: ChildrenProps) {
    const [detectModal, setDetectModal] = useState<string>('')
    const [taskModal, setTaskModal] = useState<Task | null>(null)
    return (
        <ScreenModalContext.Provider value={{ detectModal, setDetectModal, taskModal, setTaskModal }}>
            { children }
        </ScreenModalContext.Provider>
    )
}

export function useScreenModalContext(){
    const context = useContext(ScreenModalContext);
    if (!context) {
        throw new Error("useScreenModalContext must be used within an ScreenModalProvider");
    }
    return context;
}