export const priorityList: IPriority[] = [
    {
        id: "low",
        description: "Baixa",
        bgColor: 'bg-zinc-300',
        textColor: 'text-zinc-500'
    },
    {
        id: "medium",
        description: "Média",
        bgColor: 'bg-yellow-200',
        textColor: 'text-yellow-500'
    },
    {
        id: "high",
        description: "Urgente",
        bgColor: 'bg-red-200',
        textColor: 'text-red-500'
    }
]

interface IPriority {
    id: string
    description: string
    bgColor: string
    textColor: string
}