export function secondsToHHMMSS(seconds: number, withSeconds?: boolean): string {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const secondsLeft = Math.floor(seconds % 60)
  
    const formattedHours = hours.toString()
    const formattedMinutes = minutes.toString().padStart(2, "0")
    const formattedSeconds = secondsLeft.toString().padStart(2, "0")

  
    return withSeconds === true 
        ? `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
        : `${formattedHours}:${formattedMinutes}`
}