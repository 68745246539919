export const statusList: IStatus[] = [
    {
        id: 0,
        description: 'Em Andamento',
        bgColor: 'bg-blue-500',
        defaultStyle: 'border border-blue-500 bg-blue-500 text-white',
        basicStyle: 'border border-blue-500 bg-white text-blue-500',
        hoverStyle: 'border border-blue-500 hover:bg-blue-500 hover:text-white'
    },
    {
        id: 1,
        description: 'Finalizado',
        bgColor: 'bg-green-600',
        defaultStyle: 'border border-green-600 bg-green-600 text-white',
        basicStyle: 'border border-green-600 bg-white text-green-600 bg',
        hoverStyle: 'border border-green-600 hover:bg-green-600 hover:text-white',
    },
    {
        id: 2,
        description: 'Cancelado',
        bgColor: 'bg-red-500',
        defaultStyle: 'border border-red-500 bg-red-500 text-white',
        basicStyle: 'border border-red-500 bg-white text-red-500',
        hoverStyle: 'border border-red-500 hover:bg-red-500 hover:text-white',
    }
]

interface IStatus {
    id: number
    description: string,
    bgColor: string
    defaultStyle: string
    basicStyle:  string
    hoverStyle: string
}