import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from "react";
import { ChildrenProps } from "./alertContext";

export const ToolbarContext = createContext<{
    show: boolean | undefined
    setShow: Dispatch<SetStateAction<boolean | undefined>>
    childrenToolBar?: ReactNode | undefined
    setChildrenToolbar: Dispatch<SetStateAction<ReactNode | undefined>>

} | undefined>(undefined)

export function ToolbarContextProvider({ children }: ChildrenProps) {
    const [show, setShow] = useState<boolean>()
    const [childrenToolBar, setChildrenToolbar] = useState<ReactNode>()

    return (
        <ToolbarContext.Provider value={{
            show, setShow,
            childrenToolBar, setChildrenToolbar
        }}>
            {children}
        </ToolbarContext.Provider>
    )
}

export function useToolbarContext() {
    const context = useContext(ToolbarContext);

    if (!context) {
        throw new Error("useToolbarContext must be used within an ToolbarContextProvider");
    }

    return context;
}