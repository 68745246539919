import { api } from "../../../config/api";
import { IClientsContacts, CreateClientContacts, UpdateClientContacts } from "../../interfaces/reg/clientsContacts";

export class ClientsContacts{

    constructor(private token?: string){}

    async readAll(): Promise<IClientsContacts[]> {
        const response = await api.get(`/api/v1/reg/clientsContacts`, {
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        })
        return response?.data?.data
    }

    async readById(id: number): Promise<IClientsContacts> {
        console.log('aquiiii', id)
        const response = await api.get(`/api/v1/reg/clientsContacts/${id}`, {
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        })
        return response.data.data
    }

    async removeById(id: number): Promise<void> {
        await api.delete(`/api/v1/reg/clientsContacts/${id}`, {
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        })
    }

    async create(data: CreateClientContacts): Promise<IClientsContacts> {
        const response = await api.post(`/api/v1/reg/clientsContacts`, data, {
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        })
        return response.data.data
    }

    async updateById(id: number, data: UpdateClientContacts): Promise<IClientsContacts> {
        const response = await api.put(`/api/v1/reg/clientsContacts/${id}`, data, {
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        })
        return response.data.data
    }

}
