import { Box, FormControl, FormLabel, Text } from "@chakra-ui/react";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonDefault } from "../../../components/button/buttonDefault";
import { Container } from "../../../components/container/container";
import { InputDefault } from "../../../components/input/inputDefault";
import { Loader } from '../../../components/loader/loader';
import { useAlertContext } from '../../../contexts/alertContext';
import { useAuthContext } from '../../../contexts/authContext';
import { api } from '../../../config/api';
import { IUpdateCustomers } from "../../../services/interfaces/auth/customers";
import AutoCompleteInput from "../../../components/input/autocomplete";

export function CustomerAccount() {

    const navigate = useNavigate()
    const { userData } = useAuthContext()
    const { setAlert } = useAlertContext()
    const [loading, setLoading] = useState(false)
    const [userDontHasPermissions, setUserDontHasPermissions] = useState<boolean>(true)
    const [customer, setCustomer] = useState<IUpdateCustomers>({
        name: '',
        email: '',
        phone: undefined,
        dddPhone: undefined,
        addressCep: undefined,
        addressCity: undefined,
        mfaExternalUsers: false,
        mfaInternalUsers: false,
        addressState: '',
        addressRoad: '',
        addressComplement: '',
        addressDistrict: '',
        addressNumber: undefined,
    })
    const optionMFA = [
        { id: false, description: 'Opcional' },
        { id: true, description: 'Obrigatório' },
    ]

    useEffect(() => {

        setUserDontHasPermissions(userData?.auth_typeAccess_id === 1  ? false : true)
        async function getData() {
            try {
                setLoading(true)

                const customerApi = await api.get('/api/v1/auth/customers', {
                    headers: {
                        Authorization: `Bearer ${userData?.token}`
                    }
                })

                setCustomer(customerApi?.data?.data)
                setLoading(false)
            } catch (error) {
                console.log(error)
                setLoading(false)
                setAlert({
                    status: "error",
                    title: "Erro",
                    description: "Erro ao atualizar dados da conta"
                })
            }
        }
        getData()
    }, [userData?.token])

    async function handleSubmit() {
        try {

            setLoading(true)
            await api.put(`/api/v1/auth/customers`, {
                name: customer.name ?? undefined,
                email: customer.email ?? undefined,
                phone: customer.phone ?? undefined,
                dddPhone: customer.dddPhone ?? undefined,
                mfaExternalUsers: customer.mfaExternalUsers ?? undefined,
                mfaInternalUsers: customer.mfaInternalUsers ?? undefined,
                addressComplement: customer.addressComplement ?? undefined,
                addressDistrict: customer.addressDistrict ?? undefined,
                addressNumber: customer.addressNumber ?? undefined,
                addressRoad: customer.addressRoad ?? undefined,
                addressState: customer.addressState ?? undefined,
                addressCep: customer.addressCep ?? undefined,
                addressCity: customer.addressCity ?? undefined,
            }, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            setLoading(false)
            setAlert({
                status: "success",
                title: "Sucesso",
                description: "Sucesso ao cadastrar usuário"
            })
            navigate(-1)
        } catch (error) {
            console.log(error)
            setLoading(false)
            setAlert({
                status: "error",
                title: "Erro",
                description: "Erro ao cadastrar usuário"
            })
        }
    }

    async function getAddressCep(cep: string) {
        if (cep?.length >= 8) {
            setLoading(true)
            cep = cep.replace(/^0+/, '')

            const addressCepData = await fetch(`https://brasilapi.com.br/api/cep/v1/${cep}`)

            if (!addressCepData.ok) {
                setLoading(false)
                return console.log('Erro ao buscar cep')
            }

            const response = await addressCepData.json()
            if (response) {
                response?.street?.split(' ')
                setCustomer(prev => ({
                    ...prev,
                    addressCep: response?.cep,
                    addressCity: response?.city,
                    addressDistrict: response?.neighborhood,
                    addressState: response?.state,
                    addressRoad: response.street,
                }))
                setLoading(false)
            }
        }
    }

    return (
        <>
            {
                loading
                    ?
                    <Loader />
                    :
                    <Container noBackground>
                        <Box className="w-full flex flex-col justify-start gap-6">
                            <Box className="bg-white shadow-md p-10 rounded-md flex flex-col gap-6" >
                                <Text className='text-lg text-primaryColor font-bold' fontWeight={'500'}>Configurações da Conta</Text>
                                <FormControl className="flex flex-row flex-wrap gap-6">
                                    <Box className="w-full flex flex-col gap-4">
                                        <hr className="bg-primaryColor w-full" />
                                        <p>Cadastro</p>
                                    </Box>
                                    <Box className="flex flex-row gap-6 flex-wrap w-full">
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>Nome da conta: *</FormLabel>
                                            <InputDefault
                                                isDisabled={userDontHasPermissions}
                                                width={['100%', '376px']}
                                                value={customer?.name}
                                                onChange={(e) => setCustomer(prev => ({ ...prev, fullname: e.target.value }))}
                                                type="text"
                                                id="name"
                                            />
                                        </Box>
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>Email: *</FormLabel>
                                            <InputDefault
                                                isDisabled={userDontHasPermissions}
                                                width={['100%', '376px']}
                                                value={customer?.email}
                                                onChange={(e) => setCustomer(prev => ({ ...prev, email: e.target.value }))}
                                                type="text"
                                                id="email"
                                            />
                                        </Box>
                                        <Box className="flex flex-row gap-3">
                                            <Box className="flex flex-col w-full md:w-auto">
                                                <FormLabel fontSize={12}>DDD:</FormLabel>
                                                <InputDefault
                                                    isDisabled={userDontHasPermissions}
                                                    width={20}
                                                    value={customer?.dddPhone ? customer?.dddPhone : ''}
                                                    maxLength={2}
                                                    onChange={(e) => setCustomer(prev => ({ ...prev, dddPhone: Number(e.target.value) }))}
                                                    type="text"
                                                />
                                            </Box>
                                            <Box className="flex flex-col w-full md:w-auto">
                                                <FormLabel fontSize={12}>Telefone:</FormLabel>
                                                <InputDefault
                                                    isDisabled={userDontHasPermissions}
                                                    width={[36, 72]}
                                                    value={customer?.phone ? customer?.phone : ''}
                                                    maxLength={9}
                                                    onChange={(e) => setCustomer(prev => ({ ...prev, phone: Number(e.target.value) }))}
                                                    type="tel"
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box className="flex flex-row gap-6 flex-wrap w-full">
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>CEP:</FormLabel>
                                            <InputDefault
                                                isDisabled={userDontHasPermissions}
                                                width={['100%', '376px']}
                                                value={customer?.addressCep}
                                                onBlur={(e) => getAddressCep(e.target.value)}
                                                onChange={(e) => setCustomer(prev => ({ ...prev, addressCep: e.target.value }))}
                                                type="text"
                                                id="addressCep"
                                            />
                                        </Box>
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>Estado:</FormLabel>
                                            <InputDefault
                                                isDisabled={userDontHasPermissions}
                                                width={['100%', '376px']}
                                                value={customer?.addressState}
                                                onChange={(e) => setCustomer(prev => ({ ...prev, addressState: e.target.value }))}
                                                type="text"
                                                id="addressState"
                                            />
                                        </Box>
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>Cidade:</FormLabel>
                                            <InputDefault
                                                isDisabled={userDontHasPermissions}
                                                width={['100%', '376px']}
                                                value={customer?.addressCity}
                                                onChange={(e) => setCustomer(prev => ({ ...prev, addressCity: e.target.value }))}
                                                type="text"
                                                id="addressCity"
                                            />
                                        </Box>
                                    </Box>
                                    <Box className="flex flex-row gap-6 flex-wrap w-full">
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>Bairro:</FormLabel>
                                            <InputDefault
                                                isDisabled={userDontHasPermissions}
                                                width={['100%', '376px']}
                                                value={customer?.addressDistrict}
                                                onChange={(e) => setCustomer(prev => ({ ...prev, addressDistrict: e.target.value }))}
                                                type="text"
                                                id="addressDistrict"
                                            />
                                        </Box>
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>Numero:</FormLabel>
                                            <InputDefault
                                                isDisabled={userDontHasPermissions}
                                                width={['100%', '376px']}
                                                value={customer?.addressNumber}
                                                onChange={(e) => setCustomer(prev => ({ ...prev, addressNumber: Number(e.target.value) }))}
                                                type="number"
                                                id="addressNumber"
                                            />
                                        </Box>
                                    </Box>
                                    <Box className="w-full flex flex-col gap-4">
                                        <hr className="bg-primaryColor w-full" />
                                        <p>Segurança</p>
                                    </Box>
                                    <Box className="flex flex-row gap-6 flex-wrap w-full">
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>MFA Usuários internos:</FormLabel>
                                            <AutoCompleteInput
                                                disabled={userDontHasPermissions}
                                                defaultValue={optionMFA.find(item => item?.id === customer?.mfaInternalUsers)?.description}
                                                id="auth_typeAccess_id"
                                                data={optionMFA}
                                                height="10"
                                                placeholder={"Selecione o tipo"}
                                                preSelectedValue={["id", "description"]}
                                                onChange={(e: any) => setCustomer(prev => ({ ...prev, mfaInternalUsers: e }))}
                                            />
                                        </Box>
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>MFA Usuários externos:</FormLabel>
                                            <AutoCompleteInput
                                                disabled={userDontHasPermissions}
                                                id="auth_typeAccess_id"
                                                defaultValue={optionMFA.find(item => item?.id === customer?.mfaExternalUsers)?.description}
                                                data={optionMFA}
                                                height="10"
                                                placeholder={"Selecione o tipo"}
                                                preSelectedValue={["id", "description"]}
                                                onChange={(e: any) => setCustomer(prev => ({ ...prev, mfaExternalUsers: e }))}
                                            />
                                        </Box>
                                    </Box>
                                    <br />
                                </FormControl>
                                {
                                    userData?.auth_typeAccess_id === 1 &&
                                    <Box className="w-full flex  justify-between items-center gap-4">
                                        <ButtonDefault onClick={() => navigate('/users')} success={false}>Cancelar</ButtonDefault>
                                        <ButtonDefault success onClick={() => handleSubmit()} >Confirmar</ButtonDefault>
                                    </Box>
                                }
                            </Box>
                        </Box>
                    </Container>
            }
        </>
    )
}