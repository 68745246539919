import { useEffect, useRef, useState } from "react";
import { useAuthContext } from "../../../contexts/authContext";
import { api } from '../../../config/api'
import { useParams } from "react-router-dom";
import { Container } from "../../../components/container/container";
import { Loader } from "../../../components/loader/loader";
import { InputDefault } from "../../../components/input/inputDefault";
import {
    Table as TableChackra, TableContainer, Tbody, Td, Thead, Tr,
    InputGroup,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverArrow,
    Avatar,
    FormLabel,
    Text,
    FormControl,
} from "@chakra-ui/react";
import { ButtonDefault } from "../../../components/button/buttonDefault";
import { LabelInput } from "../../../components/label/label.input";
import { formValidator } from "../../../utils/form.validator";
import { useAlertContext } from "../../../contexts/alertContext";
import { IProjectsSteps } from "../../../services/interfaces/pro/projectsSteps";
import { CheckFat, Gear, Plus, Tag, User, UserList } from "@phosphor-icons/react";
import { InputHTMLDefault } from "../../../components/input/inputHtmlDefault";
import { TextAreaDefault } from "../../../components/textArea/textAreaHtml";
import { Trash } from "@phosphor-icons/react/dist/ssr";
import { useScreenModalContext } from "../../../contexts/screenModalContext";
import { IUsers } from "../../../services/interfaces/auth/users";
import { IProjectsMembers } from "../../../services/interfaces/pro/projectsMembers";
import { ScreenModal } from "../../../components/modals/screenModal";
import { IProjectsTags } from "../../../services/interfaces/pro/projectsTags";
import { useAllUsersContext } from "../../../contexts/allUsersContext";
import { ProjectTableView } from "./project.table.view";
import { ProjectKanbanView } from "./project.kanban.view";
import { ProjectTimelineView } from "./project.timeline.view";

export function ProjectsView() {

    const { userData } = useAuthContext()
    const { allUsers } = useAllUsersContext()
    const { detectModal, setDetectModal } = useScreenModalContext()
    const [action, setAction] = useState<string>('')
    const { setAlert } = useAlertContext()
    const [loading, setLoading] = useState(true)
    const [viewOption, setViewOption] = useState('table')
    const { id } = useParams()

    const [projectData, setProjectData] = useState<any>()
    const [projectsSteps, setProjectsSteps] = useState<IProjectsSteps[]>([])
    const [membersData, setMembersData] = useState<IProjectsMembers[]>([])
    const [projectsTags, setProjectsTags] = useState<IProjectsTags[]>([])

    const [removeMember, setRemoveMember] = useState<IProjectsMembers | null>(null)
    const [newProjectTag, setNewProjectTag] = useState<any>('')

    //taskSteps
    const taskStepTitleRef = useRef<HTMLInputElement>(null)
    const taskStepDescriptionRef = useRef<HTMLTextAreaElement>(null)
    const [taskStepColor, setTaskStepColor] = useState<string>('')
    const colorsStep = ['#3498db', '#27ae60', '#f39c12', '#9b59b6', '#e74c3c', '#2c3e50', '#1abc9c', '#e67e22', '#95a5a6', '#1e1e1e']
    const [editTaskStep, setEditTaskStep] = useState<any>(null)

    const [selectedUser, setSelectedUser] = useState<IUsers>({
        auth_customers_id: 0,
        email: '',
        fullname: '',
        id: 0,
        password: '',
        auth_typeAccess_id: 0
    })

    const [popoverUserOpen, setPopoverUserOpen] = useState<boolean>(false)
    const [usersDataToManipulate, setUsersListManipulate] = useState<IUsers[]>([])

    const [selectedProjectTag, setSelectedProjectTag] = useState('')

    const [selectedPermission, setSelectedPermission] = useState<IMembersPermissions>({
        id: 0,
        description: ''
    })

    useEffect(() => {
        getData()
    }, [])

    async function getData() {
        try {
            setLoading(true)
            const projectDataApi = await api.get(`/api/v1/pro/projects/${id}`, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            setProjectData(projectDataApi?.data?.data)
            setProjectsSteps(projectDataApi?.data?.data.pro_projectsSteps)
            setMembersData(projectDataApi?.data?.data?.pro_projectsMembers)
            setProjectsTags(projectDataApi?.data?.data?.pro_projectsTags)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(true)
            setAlert({
                status: 'error',
                title: 'Erro',
                description: (error as any)?.response?.data?.message || 'Erro ao buscar projeto'
            })
        }
    }

    async function handleNewStep() {

        setLoading(true)
        try {

            await api.post(`/api/v1/pro/projectsSteps/project/${id}`, {
                "name": taskStepTitleRef.current?.value ? taskStepTitleRef.current?.value : undefined,
                "description": taskStepDescriptionRef.current?.value ? taskStepDescriptionRef.current?.value : undefined,
                "color": taskStepColor,
                "projects_id": id
            }, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })
            setAction('')
            setAlert({
                status: 'success',
                title: 'Sucesso',
                description: 'Sucesso ao criar nova etapa no projeto'
            })
            getData()

        } catch (error) {
            setAlert({
                status: 'error',
                title: 'Erro',
                description: (error as any)?.response?.data?.message || 'Erro ao criar nova etapa no projeto'
            })
        }
    }

    async function handleSubmitEditProjectStep(stepId: any) {
        try {
            const requiredFields = formValidator([
                { "name": 'editStepName', "value": taskStepTitleRef?.current?.value, "required": true, "type": 'string' }
            ])

            if (requiredFields.length > 0) {
                return setAlert({
                    status: "error",
                    title: "Campos obrigatórios",
                    description: "Campos obrigatórios não preenchidos"
                })
            }

            setLoading(true)
            await api.put(`/api/v1/pro/projectsSteps/${stepId}/project/${id}`, {
                name: taskStepTitleRef?.current?.value ? taskStepTitleRef.current.value : undefined,
                description: taskStepDescriptionRef?.current?.value ? taskStepDescriptionRef.current.value : undefined,
                color: taskStepColor ? taskStepColor : '#000',
            }, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            await getData()
            setLoading(false)
            setAlert({
                status: "success",
                title: "Sucesso",
                description: "Sucesso ao editar etapa"
            })
            setAction('')
        } catch (error) {
            setAlert({
                status: "error",
                title: "Erro",
                description: (error as any)?.response?.data?.message || "Erro ao editar etapa"
            })
        } finally {
            setLoading(false)
        }
    }

    async function handleSubmitRemoveProjectStep(stepId: any) {
        try {
            setLoading(true)
            await api.delete(`/api/v1/pro/projectsSteps/${stepId}/project/${id}`, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            await getData()
            setLoading(false)
            setAlert({
                status: "success",
                title: "Sucesso",
                description: "Sucesso ao excluir etapa"
            })
            setAction('')
        } catch (error) {
            setAlert({
                status: "error",
                title: "Erro",
                description: (error as any)?.response?.data?.message || "Erro ao excluir etapa"
            })
        }
    }

    function handleSearchUser(e: React.ChangeEvent<HTMLInputElement>) {
        const searchUser = e.target.value.toLowerCase()
        setSelectedUser(prev => ({ ...prev, fullname: searchUser }))
        const filteredUser = allUsers.filter(member => member?.fullname?.toLowerCase().includes(searchUser))
        setUsersListManipulate(filteredUser)
    }

    function cleanStatesMember() {
        setSelectedUser(prev => ({
            ...prev,
            id: 0,
            fullname: ''
        }))
        setSelectedPermission(prev => ({
            ...prev,
            id: 0,
            description: ''
        }))
        setUsersListManipulate([...allUsers])
    }

    async function handleCreateMember() {
        try {

            const data = await api.post(`/api/v1/pro/projectsMembers/project/${id}`, {
                pro_projects_id: Number(id),
                permission: selectedPermission?.id,
                auth_users_id: selectedUser?.id
            }, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })
            setAction('')
            setMembersData([...membersData, data?.data?.data])
            cleanStatesMember()
            return setAlert({

                status: "success",
                title: "Sucesso",
                description: "Sucesso ao criar membro"
            })
        } catch (error) {
            console.log(error)
            setLoading(false)
            setAction('')
            return setAlert({
                status: "error",
                title: "Erro",
                description: (error as any)?.response?.data?.message || "Erro ao criar membro"
            })
        }
    }

    async function handleRemoveMember(item: IProjectsMembers) {
        try {
            await api.delete(`/api/v1/pro/projectsMembers/user/${item.auth_users_id}/project/${item.pro_projects_id}`, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            }
            )
            const indexToRemove = membersData.findIndex(i => {
                return i.pro_projects_id === item.pro_projects_id
                    && i.auth_users_id === item.auth_users_id
            })
            membersData.splice(indexToRemove, 1)
            setMembersData([...membersData])
            setUsersListManipulate([...allUsers])
            setDetectModal('')
        } catch (error) {
            console.log('Error on delete member:', error)
            setAlert({
                status: 'error',
                title: 'Error',
                description: (error as any)?.response?.data?.message || 'Erro ao realizar remoção de membro'
            })
        }
    }

    async function handleAddNewProjectTag() {

        try {
            setDetectModal('')
            setLoading(true)
            const data = await api.post(`/api/v1/pro/projectsTags/project/${projectData.id}`, {
                description: newProjectTag?.description,
                color: newProjectTag?.color
            }, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            projectData.pro_projectsTags.push(data.data.data)

            setProjectData(projectData)
            setNewProjectTag('')
            setLoading(false)

        } catch (error) {
            setLoading(false)
            setAction('')
            return setAlert({
                status: "error",
                title: "Erro",
                description: (error as any)?.response?.data?.message || "Erro ao criar nova tag"
            })
        }
    }

    async function handleRemoveProjectTag() {

        try {
            setDetectModal('')
            setLoading(true)

            const removedData = await api.delete(`/api/v1/pro/projectsTags/${selectedProjectTag}/project/${projectData.id}`, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            projectData.pro_projectsTags = projectData.pro_projectsTags.filter((tag: any) => tag.id !== selectedProjectTag)
            setProjectData({ ...projectData })
            setLoading(false)

        } catch (error) {
            setLoading(false)
            setAction('')
            return setAlert({
                status: "error",
                title: "Erro",
                description: (error as any)?.response?.data?.message || "Erro ao remover tag"
            })
        }
    }

    return (
        <Container>
            {
                detectModal === 'removeTaskMember' ?
                    <ScreenModal height={250}>
                        <div className="flex flex-col gap-6 items-center justify-center h-full">
                            <a>Deseja realmente remover o membro?</a>
                            <div className="flex flex-col md:flex-row gap-2">
                                <ButtonDefault onClick={() => setDetectModal('')}>Não</ButtonDefault>
                                <ButtonDefault success onClick={() => handleRemoveMember(removeMember as IProjectsMembers)}>Sim</ButtonDefault>
                            </div>
                        </div>
                    </ScreenModal>
                    :
                    detectModal === 'addProjectTag' ?
                        <ScreenModal height={250}>
                            <div className="flex flex-col gap-6 items-center justify-center h-full">
                                <a>Nova tag</a>
                                <div className="flex flex-col gap-2">
                                    <InputGroup className="flex flex-col">
                                        <LabelInput>Descrição</LabelInput>
                                        <InputDefault type="text" onChange={(e: any) => setNewProjectTag((prev: any) => ({ ...prev, description: e.target.value }))}></InputDefault>
                                    </InputGroup>
                                    <InputGroup className="flex flex-col">
                                        <LabelInput>Cor</LabelInput>
                                        <input className="w-12 h-8" onBlur={(e) => setNewProjectTag((prev: any) => ({ ...prev, color: e.target?.value }))} type="color"></input>
                                    </InputGroup>
                                </div>
                                <div className="flex flex-col md:flex-row gap-2">
                                    <ButtonDefault onClick={() => setDetectModal('')}>Cancelar</ButtonDefault>
                                    <ButtonDefault success onClick={() => handleAddNewProjectTag()}>Cadastrar</ButtonDefault>
                                </div>
                            </div>
                        </ScreenModal>
                        :
                        detectModal === 'removeProjectTag' ?
                            <ScreenModal height={250}>
                                <div className="flex flex-col gap-6 items-center justify-center h-full">
                                    <a>Deseja realmente remover o membro?</a>
                                    <div className="flex flex-col md:flex-row gap-2">
                                        <ButtonDefault onClick={() => setDetectModal('')}>Não</ButtonDefault>
                                        <ButtonDefault success onClick={() => handleRemoveProjectTag()}>Sim</ButtonDefault>
                                    </div>
                                </div>
                            </ScreenModal>
                            :
                            <></>
            }

            <div className="flex flex-col gap-10 w-full">
                <div className="flex flex-col gap-4 justify-start">
                    <div className="flex justify-between items-center">
                        <div>
                            <p className="text-xs font-semibold text-zinc-500">{projectData?.reg_clients?.name}</p>
                            <h1 className="text-2xl font-semibold text-zinc-700">{projectData?.name}</h1>
                            <p className="text-sm font-semibold text-zinc-500">{projectData?.description}</p>
                        </div>
                        <div>
                            <Popover variant={'responsive'} size={'sm'} direction="ltr" >
                                <PopoverTrigger>
                                    <div className="border border-zinc-500  text-zinc-500 hover:opacity-60 p-1 rounded-md cursor-pointer shadow-md">
                                        <Gear size={22} />
                                    </div>
                                </PopoverTrigger>
                                <PopoverContent className="outline-none">
                                    <PopoverArrow />
                                    <PopoverBody className="outline-none">
                                        <div onClick={() => setAction('newTaskStep')} className="text-zinc-500 hover:opacity-60 flex items-center gap-2 cursor-pointer">
                                            <Plus />
                                            <p>Nova etapa</p>
                                        </div>
                                        <div onClick={() => setAction('addProjectTags')} className="text-zinc-500 hover:opacity-60 flex items-center gap-2 cursor-pointer">
                                            <Tag />
                                            <p>Cadastrar tags</p>
                                        </div>
                                        <div onClick={() => setAction('addTaskMembers')} className="text-zinc-500 hover:opacity-60 flex items-center gap-2 cursor-pointer">
                                            <User />
                                            <p>Cadastrar membros</p>
                                        </div>
                                        <div onClick={() => setAction('showTaskMembers')} className="text-zinc-500 hover:opacity-60 flex items-center gap-2 cursor-pointer">
                                            <UserList />
                                            <p>Ver membros</p>
                                        </div>
                                    </PopoverBody>
                                </PopoverContent>
                            </Popover>
                        </div>
                    </div>
                    <div className="w-full border-y border-gray-300 flex items-center gap-2 text-gray-500">
                        <div
                            onClick={() => {
                                setViewOption('table')
                                setAction('')
                            }}
                            className={`${viewOption === 'table' && `underline bg-zinc-200`} py-1 px-2 cursor-pointer`}
                        >
                            Tabela
                        </div>
                        <div
                            onClick={() => { 
                                setViewOption('kanban')
                                setAction('')
                            }}
                            className={`${viewOption === 'kanban' && `underline bg-zinc-200`} py-1 px-2 cursor-pointer`}
                        >
                            Kanban
                        </div>
                        <div
                            onClick={() => { 
                                setViewOption('timeline') 
                                setAction('')
                            }} 
                            className={`${viewOption === 'timeline' && `underline bg-zinc-200`} py-1 px-2 cursor-pointer`}
                        >
                            Timeline
                        </div>
                    </div>
                </div>
                {
                    loading ?
                        <Loader />
                        :
                        action === 'newTaskStep' ?
                            <div className="h-full flex flex-col justify-center items-center gap-4 ">
                                <h2 className='text-lg' color={'#1E1E1E'}>Criação de etapa</h2>
                                <div className="max-w-96 md:w-96 w-auto">
                                    <InputGroup className="flex flex-col items-start">
                                        <LabelInput>* Nome</LabelInput>
                                        <InputHTMLDefault ref={taskStepTitleRef}></InputHTMLDefault>
                                    </InputGroup>
                                    <InputGroup className="flex flex-col items-start">
                                        <LabelInput>Descrição</LabelInput>
                                        <TextAreaDefault ref={taskStepDescriptionRef} ></TextAreaDefault>
                                    </InputGroup>
                                </div>
                                <div className=" w-full relative items-center justify-center flex flex-col gap-5">
                                    <p>Selecione uma cor para esta etapa</p>
                                    <div className="flex flex-col md:flex-row gap-2">
                                        {
                                            colorsStep.map(color => {
                                                return <div onClick={() => setTaskStepColor(color)} style={{ background: color }} className={`cursor-pointer bg-[${color}] text-white flex items-center justify-center h-8 w-8 rounded-full`} >
                                                    {taskStepColor === color && <CheckFat size={16} />}
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="mt-4 w-full flex flex-col md:flex-row items-center justify-center gap-4">
                                    <ButtonDefault onClick={() => setAction('')}>Cancelar</ButtonDefault>
                                    <ButtonDefault onClick={() => handleNewStep()} success>Cadastrar</ButtonDefault>
                                </div>
                            </div>
                            :
                            action === 'editTaskStep' ?
                                <div className="h-full flex flex-col justify-center items-center gap-4 ">
                                    <p className='text-lg' color={'#1E1E1E'}>Edição de etapa</p>
                                    <div className="max-w-96 md:w-96 w-auto">
                                        <InputGroup className="flex flex-col items-start">
                                            <LabelInput>* Nome</LabelInput>
                                            <InputHTMLDefault id="editStepName" defaultValue={editTaskStep?.name} ref={taskStepTitleRef}></InputHTMLDefault>
                                        </InputGroup>
                                        <InputGroup className="flex flex-col items-start">
                                            <LabelInput>Descrição</LabelInput>
                                            <TextAreaDefault defaultValue={editTaskStep?.description} ref={taskStepDescriptionRef} ></TextAreaDefault>
                                        </InputGroup>
                                    </div>
                                    <div className="w-full relative items-center justify-center flex flex-col gap-5">
                                        <p>Selecione uma cor para esta etapa</p>
                                        <div className="flex flex-col md:flex-row gap-2">
                                            {
                                                colorsStep.map(color => {
                                                    return <div onClick={() => setTaskStepColor(color)} style={{ background: color }} className={`cursor-pointer bg-[${color}] text-white flex items-center justify-center h-8 w-8 rounded-full`} >
                                                        {taskStepColor === color && <CheckFat size={16} />}
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="w-full relative items-center justify-center flex flex-col gap-5 mt-4">
                                        <Trash size={20} className="text-red-600 cursor-pointer" onClick={() => handleSubmitRemoveProjectStep(editTaskStep.id)} />
                                    </div>
                                    <div className=" w-full flex flex-col md:flex-row items-center justify-center gap-4 mt-4">
                                        <ButtonDefault onClick={() => setAction('')}>Cancelar</ButtonDefault>
                                        <ButtonDefault onClick={() => handleSubmitEditProjectStep(editTaskStep.id)} success>Salvar</ButtonDefault>
                                    </div>
                                </div>
                                :
                                action === 'addTaskMembers' ?
                                    <div className="h-full flex flex-col gap-4 justify-between items-center py-5">
                                        <div className="flex flex-col items-center">
                                            <Text className="text-lg" color={'#1E1E1E'}>Cadastre um membro</Text>
                                            <div className="flex flex-col justify-center items-center w-full mt-4">
                                                <FormControl className="flex flex-col justify-center gap-6">
                                                    <div className="flex flex-col">
                                                        <FormLabel fontSize={12}>Usuário</FormLabel>
                                                        <Popover variant={'responsive'} size={'sm'} isOpen={popoverUserOpen} onClose={() => setPopoverUserOpen(false)}>
                                                            <PopoverTrigger>
                                                                <div
                                                                    onClick={() => setPopoverUserOpen(true)}>
                                                                    <InputDefault
                                                                        className="flex"
                                                                        w={350}
                                                                        type="text"
                                                                        id="user"
                                                                        value={selectedUser?.fullname}
                                                                        onChange={(e) => handleSearchUser(e)}
                                                                    />
                                                                </div>
                                                            </PopoverTrigger>
                                                            <PopoverContent>
                                                                <PopoverArrow />
                                                                <PopoverBody>
                                                                    <div className="flex flex-col gap-1 ">
                                                                        <div className="flex flex-col p-2">
                                                                            <FormControl mb={4}>
                                                                                <FormLabel>Busque por um membro</FormLabel>
                                                                                <InputDefault
                                                                                    type="text"
                                                                                    id="member"
                                                                                    value={selectedUser?.fullname}
                                                                                    onChange={(e) => handleSearchUser(e)}
                                                                                />
                                                                            </FormControl>
                                                                            {
                                                                                <div>
                                                                                    {
                                                                                        usersDataToManipulate.length > 0 ?
                                                                                            usersDataToManipulate!?.filter((usd) => !membersData.find((mb) => usd.id === mb.auth_users_id))
                                                                                                .map((ass) => {
                                                                                                    return (
                                                                                                        <div className="flex items-center gap-4 p-2 w-full h-12 mt-2 rounded-md hover:bg-gray-300/50 transition-all ease-in-out duration-300"
                                                                                                            onClick={() => {
                                                                                                                setSelectedUser(prev => ({ ...prev, id: ass?.id, fullname: ass?.fullname }));
                                                                                                                setPopoverUserOpen(false)
                                                                                                            }}
                                                                                                        >
                                                                                                            <Avatar size='sm' bg={"#c4c4c4"} name={ass?.fullname}></Avatar>
                                                                                                            <div className="w-full flex items-center justify-between" >
                                                                                                                <Text>{ass?.fullname}</Text>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )
                                                                                                })
                                                                                            : <></>
                                                                                    }
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </PopoverBody>
                                                            </PopoverContent>
                                                        </Popover>
                                                    </div>
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className="flex gap-2 items-center" >
                                            <ButtonDefault onClick={() => setAction('')} success={false}>Cancelar</ButtonDefault>
                                            <ButtonDefault success onClick={() => handleCreateMember()}>Confirmar</ButtonDefault>
                                        </div>
                                    </div>
                                    :
                                    action === 'showTaskMembers' ?
                                        <div className="h-full w-full flex flex-col gap-4 justify-between items-center py-5">
                                            <div className="flex flex-col w-full max-w-[600px] items-center gap-6">
                                                <Text className="text-lg font-semibold" color={'#1E1E1E'}>Membros do projeto</Text>
                                                <TableContainer className="w-full">
                                                    <TableChackra variant='simple' size="sm">
                                                        <Thead>
                                                            <Tr>
                                                                <Td className="px-2 md:px-12">Usuário</Td>
                                                                {
                                                                    Number(userData?.auth_typeAccess_id) < 4 ?
                                                                        <Td>Ação</Td>
                                                                        :
                                                                        <></>
                                                                }

                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            {
                                                                membersData?.map((member: IProjectsMembers) => {
                                                                    let user = allUsers?.filter(user => user?.id === member?.auth_users_id)[0]
                                                                    return <Tr>
                                                                        <Td>{user?.fullname}</Td>
                                                                        {
                                                                            Number(userData?.auth_typeAccess_id) < 4 ?
                                                                                <Td className="text-red-600 cursor-pointer hover:brightness-90" onClick={() => { setRemoveMember(member); setDetectModal('removeTaskMember') }}><Trash /></Td>
                                                                                :
                                                                                <></>
                                                                        }
                                                                    </Tr>
                                                                })
                                                            }

                                                        </Tbody>
                                                    </TableChackra>
                                                </TableContainer>
                                            </div>
                                            <div className="flex gap-2 items-center" >
                                                <ButtonDefault onClick={() => setAction('')} success={false}>Voltar</ButtonDefault>
                                            </div>
                                        </div>
                                        :
                                        action === 'addProjectTags' ?
                                            <div className="h-full w-full flex flex-col gap-4 justify-between items-center py-5">
                                                <div className="flex flex-col w-full max-w-[600px] items-center gap-6">
                                                    <Text className="text-lg font-semibold" color={'#1E1E1E'}>Tags do projeto</Text>
                                                    <TableContainer className="w-full">
                                                        <TableChackra variant='simple' size="sm">
                                                            <Thead>
                                                                <Tr>
                                                                    <Td className="px-2 md:px-12">Tag</Td>
                                                                    {
                                                                        Number(userData?.auth_typeAccess_id) < 4 ?
                                                                            <Td>Ação</Td>
                                                                            :
                                                                            <></>
                                                                    }

                                                                </Tr>
                                                            </Thead>
                                                            <Tbody>
                                                                {
                                                                    projectData.pro_projectsTags?.length > 0 &&
                                                                    projectData.pro_projectsTags.map((tag: any) => {
                                                                        return <Tr>
                                                                            <Td><div style={{ background: `${tag.color}` }} className="px-3 w-fit py-1 rounded-md flex items-center justify-center ">
                                                                                <p className="font-bold text-white">{tag.description}</p>
                                                                            </div>
                                                                            </Td>
                                                                            {
                                                                                Number(userData?.auth_typeAccess_id) < 4 ?
                                                                                    <Td className="text-red-600 cursor-pointer hover:brightness-90" onClick={() => { setSelectedProjectTag(tag?.id); setDetectModal('removeProjectTag') }}><Trash /></Td>
                                                                                    :
                                                                                    <></>
                                                                            }
                                                                        </Tr>
                                                                    })
                                                                }

                                                            </Tbody>
                                                        </TableChackra>
                                                    </TableContainer>
                                                </div>
                                                <div className="flex gap-2 items-center" >
                                                    <ButtonDefault onClick={() => setAction('')} >Voltar</ButtonDefault>
                                                    <ButtonDefault onClick={() => setDetectModal('addProjectTag')} success>+ Nova</ButtonDefault>
                                                </div>
                                            </div>
                                            :
                                            <>
                                                {
                                                    viewOption === 'table' && 
                                                    <ProjectTableView
                                                        projectSteps={projectsSteps}
                                                        projectsTags={projectsTags}
                                                        projectsMembers={membersData}
                                                        onEditStep={(e) => {
                                                            setAction('editTaskStep')
                                                            setEditTaskStep(e)
                                                            setTaskStepColor(e?.color as string)
                                                        }}      
                                                    />
                                                }
                                                {
                                                    viewOption === 'kanban' &&
                                                    <ProjectKanbanView
                                                        projectSteps={projectsSteps}
                                                        projectsTags={projectsTags}
                                                        projectsMembers={membersData}
                                                        onEditStep={(e) => {
                                                            setAction('editTaskStep')
                                                            setEditTaskStep(e)
                                                            setTaskStepColor(e?.color as string)
                                                        }} 
                                                    />
                                                }
                                                {
                                                    viewOption === 'timeline' &&
                                                    <ProjectTimelineView
                                                        projectSteps={projectsSteps}
                                                        projectsTags={projectsTags}
                                                        projectsMembers={membersData}
                                                        onEditStep={(e) => {
                                                            setAction('editTaskStep')
                                                            setEditTaskStep(e)
                                                            setTaskStepColor(e?.color as string)
                                                        }} 
                                                    />
                                                }
                                                <div className="w-full flex gap-3">
                                                    <div className="flex gap-2 items-center text-sm">
                                                        <Plus />
                                                        <p onClick={() => setAction('newTaskStep')} className="underline cursor-pointer">Nova etapa</p>
                                                    </div>
                                                    {
                                                        Number(userData?.auth_typeAccess_id) < 4 ?
                                                            <div className="flex gap-2 items-center text-sm">
                                                                <User />
                                                                <p onClick={() => setAction('addTaskMembers')} className="underline cursor-pointer">Cadastrar membros</p>
                                                            </div>
                                                            :
                                                            <></>
                                                    }
                                                    {
                                                        Number(userData?.auth_typeAccess_id) < 4 ?
                                                            <div className="flex gap-2 items-center text-sm">
                                                                <Tag />
                                                                <p onClick={() => setAction('addProjectTags')} className="underline cursor-pointer">Cadastrar tags</p>
                                                            </div>
                                                            :
                                                            <></>
                                                    }
                                                    <div className="flex gap-2 items-center text-sm">
                                                        <UserList />
                                                        <p onClick={() => setAction('showTaskMembers')} className="underline cursor-pointer">Ver membros</p>
                                                    </div>
                                                </div>
                                            </>

                }
            </div>
        </Container >
    )

}


export interface ISelectOptions {
    key: string
    label: string
}

export interface INewAttachment {
    pro_tasks_id: string
    description?: string
    file?: File
}

export interface IChecklist {
    pro_tasks_id: string
    description?: string
}

interface IMembersPermissions {
    id: number
    description: string
}