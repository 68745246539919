import { Box } from "@chakra-ui/react";
import { HashRouter } from "react-router-dom";
import { Loader } from "../components/loader/loader";

export function PreLoaderRoutes() {

    return (
        <>
            <HashRouter>
                <Box className="w-full h-[100vh] items-center justify-center flex">
                    <Loader></Loader>
                </Box>
            </HashRouter>
        </>
    )
}