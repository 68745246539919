import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, FormControl, FormLabel, Stack, Text } from "@chakra-ui/react";
import { Header } from '../../../components/header/header';
import { api } from "../../../config/api";
import { useAlertContext } from "../../../contexts/alertContext";
import { InputDefault } from "../../../components/input/inputDefault";
import { formValidator } from "../../../utils/form.validator";

export function LostPassword() {
    const navigate = useNavigate()
    const { setAlert } = useAlertContext()
    const [dataEmail, setDataEmail] = useState('')

    const [resetedPassword, setResetedPassword] = useState(false)
    const [customers_code, setCustomers_code] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        
        let dotPosition = window.location.hostname.indexOf('.')
        const thisCustomers = window.location.hostname.slice(0, dotPosition)
        setCustomers_code(thisCustomers)
        getClientByCode(thisCustomers)

    }, [])

    async function getClientByCode(thisCustomers: string){

        try {
            const clientExists = await api.get(`/api/auth/customer/${thisCustomers}`)

            if(clientExists?.data.status === 200){
                setLoading(false)
            }

            
        } catch (error) {
            console.log(error)
            navigate('/clientNotFound')
        }
    }


    async function handleLostPassword() {

        try {
            const requiredFields = formValidator([
                { "name": 'email', "value": dataEmail, "required": true, "type": 'string' },
            ])

            if (requiredFields.length > 0) {
                return setAlert({
                    status: "error",
                    title: "Campos obrigatórios",
                    description: "Campos obrigatórios não preenchidos"
                })
            }

            await api.patch(`/api/auth/lostPassword`, {
                email: dataEmail,
                customers_code: customers_code
            })
            setDataEmail('')
            setResetedPassword(true)
        } catch (error) {
            console.log(error)
            setAlert({
                status: "error",
                title: "Erro",
                description: "Erro ao solicitar alteração de senha"
            })
        }
    }


    return (
        <>
            <Box>
                <Header />
                <Box className="z-50 flex flex-col justify-center items-center">
                    {
                        !resetedPassword ?
                            <Box className="flex flex-col justify-center items-center gap-6">
                                <Text className="text-textPrimaryColor text-5xl text-center tracking-wide font-light">Esqueceu sua senha?</Text>
                                <Text className="text-center">Enviaremos um e-mail com instruções para redefinir sua senha</Text>
                                <Stack spacing={4}>
                                    <FormControl>
                                        <FormLabel fontSize={14} className="tracking-wider">Seu e-mail</FormLabel>

                                        <InputDefault
                                            id='email'
                                            width={['100%', '20rem']}
                                            value={dataEmail}
                                            onChange={(e) => setDataEmail(e.target.value)}
                                            type={dataEmail ? 'text' : 'email'}
                                        />
                                    </FormControl>

                                    <Button
                                        bg={'#279D73'}
                                        textColor={"#FFF"}
                                        _hover={{
                                            bg: '#2aa87c'
                                        }}
                                        className="bg-primaryColor text-white"
                                        onClick={() => handleLostPassword()}>Redefinir senha</Button>
                                </Stack>
                            </Box>
                            :
                            <Box className="flex flex-col justify-center items-center gap-6">
                                <Text className="text-textPrimaryColor text-5xl tracking-wide font-light">Sua solicitação de redefinição de senha foi realizada com sucesso </Text>
                                <Text>Acesse seu e-mail para seguir os passos para redefinir sua senha</Text>
                            </Box>
                        }

                </Box>
            </Box>
            {/* <Box className='hidden 2xl:flex z-10 w-full fixed bottom-0'>
                <Image src={Background_Login} style={{ width: '100%', height: '200px' }} />
            </Box> */}
        </>
    )
}