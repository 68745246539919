export function createRandomPassword(charNumber?: number){

    let generic
    const maxNumber = charNumber ? charNumber : 15

    const caracteres = '!@#$ABCD@FGHIJKL!#*.MN0294OPQRSTUVWXYZabcde%&fghijklmnopq192rstuvwxyz0123456!89'
    for (var i = 0; i < maxNumber; i++) {
        if (!generic) {
            generic = caracteres.charAt(Math.floor(Math.random() * caracteres.length))
        } else {
            generic += caracteres.charAt(Math.floor(Math.random() * caracteres.length))
        }
    }

    return generic

}