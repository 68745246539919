
import Logo from '../assets/wedo.png'

export function ClientNotFound() {

    return (
        <div className="w-screen h-screen flex items-center flex-col justify-center gap-3">
            <img src={Logo} width={300} />
            <div className='flex flex-col items-center justify-center'>
                <a className="font-semibold text-zinc-600">Cliente não encontrado</a>
                <a className='text-sm text-zinc-600'>Contate o suporte</a>
            </div>
        </div>
    )
}