import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "../../../components/container/container";
import { useAuthContext } from "../../../contexts/authContext";
import { Check, Alarm, Kanban, Flag, ClockCountdown, Cake, Trophy, Medal } from "@phosphor-icons/react";
import { api } from "../../../config/api";
import { PieChart } from "../../../components/charts/pie";
import { NewTableDefault } from "../../../components/tables/newTableDefault";
import AutoCompleteInput from "../../../components/input/autocomplete";
import moment from "moment";
import { ColumnChart } from "../../../components/charts/column";
import PieChartMobile from "../../../components/charts/pieMobile";

export function Dashboards() {
    
    const navigate = useNavigate()
    const { userData } = useAuthContext()
    const [ quantities, setQuantities ] = useState(null as null | IQuantities)
    const [ birthdaysOfMonth, setBirthdaysOfMonth ] = useState(null as null | IBirthdays[])
    const [ tasksPerUsers, setTasksPerUsers ] = useState(null as null | ICharts[])
    const [ hoursPerUsers, setHoursPerUsers ] = useState(null as null | ICharts[])
    const [ finishedTasksPerUserInMonth, setFinishedTasksPerUserInMonth ] = useState(null as null | IFinishedTasksPerUserInMonth[])

    const [ monthHoursPerUser, setMonthHoursPerUser ] = useState(moment().month() + 1)
    const [ yearHoursPerUser, setYearHoursPerUser ] = useState(moment().year())
    const [listYearsProjects, setListYearsProjects] = useState<IYear[]>([])

    useEffect(() => {
        yearsProjects()
        getQuantities()
        getTasksPerUsers()
        getHoursPerUsers()
        getBirthdaysOfMonth()
        getFinishedTasksPerUsersInMonth()
    }, [])

    async function yearsProjects() {
        try {
            const data = await api.get('/api/v1/dashboards/yearsProjects', {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })
            setListYearsProjects(data?.data?.data)
        } catch (error) {
            console.log(error)
        }
    }

    async function getQuantities(){
        try {
            const data = await api.get('/api/v1/dashboards/projectAndTasksQuantities', {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })
            console.log(data)
            setQuantities(data?.data?.data)
        } catch (error) {
            console.log(error)
        }
    }
    
    async function getTasksPerUsers(){
        try {
            const data = await api.get('/api/v1/dashboards/tasksPerUsers', {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })
            const formatData = data?.data?.data?.map((i: any) => {
                let nameSplited = i?.fullname.split(' ')
                let name = i?.fullname
                if(nameSplited.length > 2){
                    name = `${nameSplited[0]} ${nameSplited[1]}...`
                }   
                return {
                    name,
                    value: i?.quantity,
                }
            })
            setTasksPerUsers(formatData)
        } catch (error) {
            console.log(error)   
        }
    }

    async function getHoursPerUsers(id?:number){
        try {
            const data = await api.get(`/api/v1/dashboards/hoursPerUsers/${id ? id : monthHoursPerUser}/${yearHoursPerUser}`, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })
            const formatData = data?.data?.data?.map((i: any) => {
                const name = i?.fullname.length > 0 ? i.fullname.substring(0,12) + '...' : i?.fullname
                return {
                    name,
                    value: (i?.quantity / 3600).toFixed(2),
                }
            })
            setHoursPerUsers(formatData)
        } catch (error) {
            console.log(error)   
        }
    }

    async function getBirthdaysOfMonth(){
        try {
            const data = await api.get('/api/v1/dashboards/birthdaysOfMonth', {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })
            setBirthdaysOfMonth(data?.data?.data ?? [])
        } catch (error) {
            console.log(error)   
        }
    }

    async function getFinishedTasksPerUsersInMonth(){
        try{
            const data = await api.get('/api/v1/dashboards/finishedTasksPerUsers', {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })
            setFinishedTasksPerUserInMonth(data?.data?.data ?? [])
        }catch(error){
            console.log(error)
        }
    }

    const monthOptions = [
        { id: 1, value: 'Janeiro' },
        { id: 2, value: 'Fevereiro' },
        { id: 3, value: 'Março' },
        { id: 4, value: 'Abril' },
        { id: 5, value: 'Maio' },
        { id: 6, value: 'Junho' },
        { id: 7, value: 'Julho' },
        { id: 8, value: 'Agosto' },
        { id: 9, value: 'Setembro' },
        { id: 10, value: 'Outubro' },
        { id: 11, value: 'Novembro' },
        { id: 12, value: 'Dezembro' },
    ]

    return (
        <Container noBackground={true}>
            <div className="flex flex-col w-full md:gap-5">
                <section className={`md:grid grid-cols-5 gap-5 hidden sm:block `}> {/* QUANTIDADES/TOTALIZADORES */}
                    <div className="border border-gray-200 bg-white shadow-md h-24 flex flex-col gap-2 py-4 px-4 rounded-md">
                        <h2 className="font-semibold text-gray-500 flex items-center gap-2">
                            <Kanban size={18} weight="bold"/>Total de projetos
                        </h2>
                        <p className={`text-center text-gray-500 text-xl`}>
                            {quantities?.totalProjects ?? '-'}
                        </p>
                    </div>
                    <div onClick={()=> navigate('/viewTasks/priority/high')} className="cursor-pointer hover:brightness-95 duration-200 transition-all border border-gray-200 bg-white shadow-md h-24 flex flex-col gap-2 py-4 px-4 rounded-md">
                        <h2 className="font-semibold text-red-600 flex items-center gap-2 text-center">
                            <Flag size={18} weight="bold"/> Tarefas urgentes
                        </h2>
                        <p className={`text-center text-gray-500 text-xl`}>
                            {quantities?.urgentTasks ?? '-'}
                        </p>
                    </div>
                    <div onClick={()=> navigate('/viewTasks/0,2,3,4')} className="cursor-pointer hover:brightness-95 duration-200 transition-all border border-gray-200 bg-white shadow-md h-24 flex flex-col gap-2 py-4 px-4 rounded-md">
                        <h2 className="font-semibold text-yellow-500 flex items-center gap-2">
                            <ClockCountdown size={18} weight="bold"/> Tarefas pendentes
                        </h2>
                        <p className={`text-center text-gray-500 text-xl`}>
                            {quantities?.pendingTasks ?? '-'}
                        </p>
                    </div>
                    <div onClick={()=> navigate('/viewTasks/2')} className="cursor-pointer hover:brightness-95 duration-200 transition-all border border-gray-200 bg-white shadow-md h-24 flex flex-col gap-2 py-4 px-4 rounded-md">
                        <h2 className="font-semibold text-secondaryColor flex items-center gap-2">
                            <Alarm size={18} weight="bold"/>Tarefas em andamento
                        </h2>
                        <p className={`text-center text-gray-500 text-xl`}>
                            {quantities?.tasksInProgress ?? '-'}
                        </p>
                    </div>
                    <div onClick={()=> navigate('/viewTasks/lateTasks')} className="cursor-pointer hover:brightness-95 duration-200 transition-all border border-gray-200 bg-white shadow-md h-24 flex flex-col gap-2 py-4 px-4 rounded-md">
                        <h2 className="font-semibold text-red-600 flex items-center gap-2">
                            <Alarm size={18} weight="bold"/> Tarefas atrasadas
                        </h2>
                        <p className={`text-center text-gray-500 text-xl`}>
                            {quantities?.lateTasks ?? '-'}
                        </p>
                    </div>                   
                  
                </section>

                {/*  MOBILE   */}
                <section className={`md:hidden `}> {/* QUANTIDADES/TOTALIZADORES */}
                    <div className="border border-gray-200 bg-white shadow-md h-24 flex flex-col gap-2 py-4 px-4 mt-2 mb-2 mx-1 rounded-md">
                        <div className="flex justify-between gap-2">
                            <p className={`text-left text-gray-500 text-2xl`}>
                                {quantities?.totalProjects ?? '-'}
                            </p>
                            <Kanban size={18} weight="bold"/>
                        </div>
                        <h2 className="font-semibold text-gray-500 flex items-center justify-between gap-2">
                            Total de projetos
                        </h2>
                    </div>
                    <div onClick={()=> navigate('/viewTasks/priority/high')} className="cursor-pointer hover:brightness-95 duration-200 transition-all border border-gray-200 bg-white shadow-md h-24 flex flex-col gap-2 py-4 px-4 mb-2 mx-1 rounded-md">
                        <div className="flex justify-between gap-2">
                            <p className={`text-left text-gray-500 text-2xl`}>
                                {quantities?.urgentTasks ?? '-'}
                            </p>
                            <Flag className="text-red-600 " size={18} weight="bold"/> 
                        </div>
                        <h2 className="font-semibold text-red-600 flex items-center text-center">
                            Tarefas urgentes 
                        </h2>
                    </div>
                    <div onClick={()=> navigate('/viewTasks/0,2,3,4')} className="cursor-pointer hover:brightness-95 duration-200 transition-all border border-gray-200 bg-white shadow-md h-24 flex flex-col gap-2 py-4 px-4 mb-2 mx-1 rounded-md">
                        <div className="flex justify-between gap-2">
                            <p className={`text-left text-gray-500 text-2xl`}>
                                {quantities?.pendingTasks ?? '-'}
                            </p>
                            <ClockCountdown className="text-yellow-500" size={18} weight="bold"/>
                        </div>
                        <h2 className="font-semibold text-yellow-500 flex items-center justify-between gap-2">
                             Tarefas pendentes
                        </h2>
                    </div>
                    <div onClick={()=> navigate('/viewTasks/2')} className="cursor-pointer hover:brightness-95 duration-200 transition-all border border-gray-200 bg-white shadow-md h-24 flex flex-col gap-2 py-4 px-4 mb-2 mx-1 rounded-md">
                        <div className="flex justify-between gap-2">
                            <p className={`text-left text-gray-500 text-2xl`}>
                                {quantities?.tasksInProgress ?? '-'}
                            </p>
                            <Alarm className="text-secondaryColor" size={18} weight="bold"/>
                        </div>
                        <h2 className="font-semibold text-secondaryColor flex items-center justify-between gap-2">
                            Tarefas em andamento
                        </h2>
                    </div>
                    <div onClick={()=> navigate('/viewTasks/lateTasks')} className="cursor-pointer hover:brightness-95 duration-200 transition-all border border-gray-200 bg-white shadow-md h-24 flex flex-col gap-2 py-4 px-4 mb-2 mx-1 rounded-md">
                        <div className="flex justify-between gap-2">
                            <p className={`text-left text-gray-500 text-2xl`}>
                                {quantities?.lateTasks ?? '-'}
                            </p>
                            <Alarm className="text-red-600" size={18} weight="bold"/> 
                        </div>
                        <h2 className="font-semibold text-red-600 flex items-center justify-between gap-2">
                            Tarefas atrasadas
                        </h2>
                    </div>                   
                </section>
                <section className="md:grid md:grid-cols-2 md:gap-5 sm:gap-5 hidden sm:block">{/* TAREFAS EM ABERTO POR PROJETO / TAREFAS EM ABERTO POR PESSOA*/}
                    <div className={` border border-gray-200 bg-white shadow-md h-80 flex flex-col gap-2 py-4 px-4 rounded-md`}>
                        <h2 className="font-semibold text-gray-500">Tarefas em aberto por projeto</h2>
                        <div className="w-full h-full flex justify-center overflow-auto">
                            <NewTableDefault 
                                columns={[
                                    {
                                        description: 'Nome',
                                        type: 'string',
                                        field: 'name'
                                    },
                                    {
                                        description: 'Quantidade',
                                        type: 'number',
                                        field: 'quantity'
                                    }
                                ]}
                                endpoint="/api/v1/dashboards/tasksPerProjects"
                                token={userData?.token ?? ''}
                                usePagination={false} 
                                filters={[]}
                                onClickInTableLine={(e) => navigate(`/viewProject/${e.projects_id}`)}
                            />
                        </div>
                    </div>
                    <div className={`${!tasksPerUsers ? 'animate-pulse' : ''} border border-gray-200 bg-white shadow-md h-80 flex flex-col gap-2 py-4 px-4 rounded-md`}>
                        <h2 className="font-semibold text-gray-500">Tarefas em aberto por pessoa</h2>
                        <div className="w-full h-full flex items-center justify-center">
                            {
                                !tasksPerUsers ?
                                    <div className="w-full h-full m-10 flex items-center justify-center bg-slate-100 text-gray-500">
                                        Carregando...
                                    </div> 
                                : tasksPerUsers?.length === 0
                                    ? <p>Nenhuma tarefa foi encontrada</p>
                                    : <PieChart data={tasksPerUsers ?? []} /> 
                            }
                        </div>
                    </div>
                </section>

                {/* MOBILE */}
                <section className={`mb-2 md:hidden `}>{/* TAREFAS EM ABERTO POR PROJETO / TAREFAS EM ABERTO POR PESSOA*/}
                    <div className={` border border-gray-200 bg-white shadow-md h-80 flex flex-col py-4 px-4 mb-2 mx-1 rounded-md`}>
                        <h2 className="font-semibold text-gray-500">Tarefas em aberto por projeto</h2>
                        <div className="w-full h-full flex justify-center overflow-auto">
                            <NewTableDefault 
                                columns={[
                                    {
                                        description: 'Nome',
                                        type: 'string',
                                        field: 'name'
                                    },
                                    {
                                        description: 'Quantidade',
                                        type: 'number',
                                        field: 'quantity'
                                    }
                                ]}
                                endpoint="/api/v1/dashboards/tasksPerProjects"
                                token={userData?.token ?? ''}
                                usePagination={false} 
                                filters={[]}
                                onClickInTableLine={(e) => navigate(`/viewProject/${e.projects_id}`)}
                            />
                        </div>
                    </div>
                    <div className={`${!tasksPerUsers ? 'animate-pulse' : ''} border border-gray-200 bg-white shadow-md h-80 flex flex-col gap-2 py-4 px-4 mx-1 rounded-md`}>
                        <h2 className="font-semibold text-gray-500">Tarefas em aberto por pessoa</h2>
                        <div className="w-full h-full flex items-center justify-center">
                            {
                                !tasksPerUsers ?
                                    <div className="w-full h-full m-10 flex items-center justify-center bg-slate-100 text-gray-500">
                                        Carregando...
                                    </div> 
                                : tasksPerUsers?.length === 0
                                    ? <p>Nenhuma tarefa foi encontrada</p>
                                    : <PieChartMobile data={tasksPerUsers ?? []} /> 
                            }
                        </div>
                    </div>
                </section>
                <section className="md:grid md:grid-cols-2 md:gap-5 hidden sm:block"> {/* HORAS POR PESSOA / ANIVERSARIANTES / RANK DE TAREFAS*/}
                    <div className={`${!hoursPerUsers ? 'animate-pulse' : '' } border border-gray-200 bg-white shadow-md h-80 flex flex-col gap-2 py-4 px-4 rounded-md`}>
                        <div className="flex justify-between items-center">
                            <h2 className="font-semibold text-gray-500">Horas apontadas por pessoa</h2>
                            {
                                listYearsProjects.length > 0 ?
                            <AutoCompleteInput 
                                defaultValue={listYearsProjects?.find((item: any) => item?.value === yearHoursPerUser.toString())?.value} 
                                id="selectYearHoursPerProject" 
                                data={listYearsProjects}
                                placeholder={"Selecione o ano"} 
                                preSelectedValue={["value"]} 
                                onChange={(e: any) => setYearHoursPerUser(e)}
                            />
                            :<></>
                            }
                            <AutoCompleteInput 
                                defaultValue={monthOptions.find((items: any) => items?.id === monthHoursPerUser)?.value} 
                                id="selectMonthHoursPerProject" 
                                data={monthOptions}
                                placeholder={"Selecione o mês"} 
                                preSelectedValue={["id","value"]} 
                                onChange={(e: any) => { setMonthHoursPerUser(e); getHoursPerUsers(e)}} 
                            />
                        </div>
                        <div className="w-full h-full flex items-center justify-center ">
                            {
                                !hoursPerUsers ?
                                    <div className="w-full h-full m-10 flex items-center justify-center bg-slate-100 text-gray-500">
                                        Carregando...
                                    </div> 
                                : hoursPerUsers?.length === 0
                                    ? <p>Nenhuma apontamento foi encontrada</p>
                                    : <ColumnChart data={hoursPerUsers}/>
                            }
                        </div>
                    </div>
                    <div className={`md:grid md:grid-cols-2 md:h-80 sm:flex gap-5`}>
                        <div className="border border-gray-200 bg-white flex flex-col gap-6 shadow-md h-80 w-full rounded-md py-4 px-4">
                            <h2 className="font-semibold text-gray-500">Mais tarefas concluídas no mês</h2>
                            <div className="flex flex-col h-full gap-3 relative overflow-y-auto">
                                {
                                    !finishedTasksPerUserInMonth ?
                                        <div className="w-full h-full flex items-center justify-center bg-slate-100 text-gray-500">
                                            Carregando...
                                        </div> 
                                    : finishedTasksPerUserInMonth.length === 0 ? 
                                            <div className="text-gray-500 h-full flex flex-col items-center justify-center">
                                                Não há tarefas concluídas
                                            </div>
                                        : finishedTasksPerUserInMonth.map((user, index) => {
                                            let nameSplited = user?.fullname.split(' ')
                                            let name = user?.fullname
                                            if(nameSplited.length > 2){
                                                name = `${nameSplited[0]} ${nameSplited[1]}...`
                                            }   
                                            return (
                                                <div key={user.users_id} className="text-gray-500 text-sm p-2 border border-gray-200 rounded-md flex gap-2 items-center">
                                                    {
                                                        index < 3 ? 
                                                        <Trophy 
                                                            size={index === 0 ? 30 : index === 1 ? 30 : 30} 
                                                            weight="fill"
                                                            color={index === 0 ? '#d1af04' : index === 1 ? '#9c9b9a' : '#a68342'}
                                                        />
                                                        :
                                                        <Medal size={25} className="text-gray-500"/>
                                                    }
                                                    <div className="flex flex-col items-center w-full">
                                                        <p className="">{name}</p>
                                                        <p className="text-xs">Tarefas concluídas: {user?.quantity}</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                }
                            </div>
                        </div>
                        <div className="border border-gray-200 bg-white flex flex-col gap-6 shadow-md h-80 w-full rounded-md py-4 px-4">
                            <h2 className="font-semibold text-gray-500">Aniversariantes do mês</h2>
                            <div className="flex flex-col h-full gap-3 relative overflow-y-auto">
                                {
                                    !birthdaysOfMonth ?
                                        <div className="w-full h-full flex items-center justify-center bg-slate-100 text-gray-500">
                                            Carregando...
                                        </div> 
                                    : birthdaysOfMonth.length === 0 
                                        ? <div className="text-gray-500 h-full flex flex-col items-center justify-center">
                                            Não há aniversariantes
                                          </div>
                                        : birthdaysOfMonth.map(birthday => {
                                            const bday = moment(birthday.birthday).utc(false).format('DD/MM/YYYY')
                                            let nameSplited = birthday?.fullname.split(' ')
                                            let name = birthday?.fullname
                                            if(nameSplited.length > 2){
                                                name = `${nameSplited[0]} ${nameSplited[1]}...`
                                            }   
                                            return (
                                                <div key={`${birthday.users_id}-${birthday?.birthday}`} className="text-gray-500 text-sm p-2 border border-gray-200 rounded-md flex items-center">
                                                    <Cake size={25}/>
                                                    <div className="flex flex-col items-center gap-1 w-full">
                                                        <p>{name}</p>
                                                        <p className="text-xs">{bday}</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                }
                            </div>
                        </div>
                    </div>
                </section>

                {/* MOBILE */}
                <section className="mb-2 md:hidden"> {/* HORAS POR PESSOA / ANIVERSARIANTES / RANK DE TAREFAS*/}
                    <div className={`${!hoursPerUsers ? 'animate-pulse' : '' } border border-gray-200 bg-white shadow-md h-80 flex flex-col gap-2 py-4 px-4 mb-2 rounded-md mx-1`}>
                        <div className="flex justify-between items-center">
                            <h2 className="font-semibold text-gray-500">Horas apontadas por pessoa</h2>
                            {
                                listYearsProjects.length > 0 ?
                            <AutoCompleteInput 
                                defaultValue={listYearsProjects?.find((item: any) => item?.value === yearHoursPerUser.toString())?.value} 
                                id="selectYearHoursPerProject" 
                                data={listYearsProjects}
                                placeholder={"Selecione o ano"} 
                                preSelectedValue={["value"]} 
                                onChange={(e: any) => setYearHoursPerUser(e)}
                            />
                            :<></>
                            }
                            <AutoCompleteInput 
                                defaultValue={monthOptions.find((items: any) => items?.id === monthHoursPerUser)?.value} 
                                id="selectMonthHoursPerProject" 
                                data={monthOptions}
                                placeholder={"Selecione o mês"} 
                                preSelectedValue={["id","value"]} 
                                onChange={(e: any) => { setMonthHoursPerUser(e); getHoursPerUsers(e)}} 
                            />
                        </div>
                        <div className="w-full h-full flex items-center justify-center ">
                            {
                                !hoursPerUsers ?
                                    <div className="w-full h-full m-10 flex items-center justify-center bg-slate-100 text-gray-500">
                                        Carregando...
                                    </div> 
                                : hoursPerUsers?.length === 0
                                    ? <p>Nenhuma apontamento foi encontrada</p>
                                    : <ColumnChart data={hoursPerUsers}/>
                            }
                        </div>
                    </div>
                    <div className={`gap-5 mx-1`}>
                        <div className="border border-gray-200 bg-white flex flex-col gap-6 shadow-md h-80 w-full rounded-md py-4 px-4 mb-2">
                            <h2 className="font-semibold text-gray-500">Mais tarefas concluídas no mês</h2>
                            <div className="flex flex-col h-full gap-3 relative overflow-y-auto">
                                {
                                    !finishedTasksPerUserInMonth ?
                                        <div className="w-full h-full flex items-center justify-center bg-slate-100 text-gray-500">
                                            Carregando...
                                        </div> 
                                    : finishedTasksPerUserInMonth.length === 0 ? 
                                            <div className="text-gray-500 h-full flex flex-col items-center justify-center">
                                                Não há tarefas concluídas
                                            </div>
                                        : finishedTasksPerUserInMonth.map((user, index) => {
                                            let nameSplited = user?.fullname.split(' ')
                                            let name = user?.fullname
                                            if(nameSplited.length > 2){
                                                name = `${nameSplited[0]} ${nameSplited[1]}...`
                                            }   
                                            return (
                                                <div key={user.users_id} className="text-gray-500 text-sm p-2 border border-gray-200 rounded-md flex gap-2 items-center">
                                                    {
                                                        index < 3 ? 
                                                        <Trophy 
                                                            size={index === 0 ? 30 : index === 1 ? 30 : 30} 
                                                            weight="fill"
                                                            color={index === 0 ? '#d1af04' : index === 1 ? '#9c9b9a' : '#a68342'}
                                                        />
                                                        :
                                                        <Medal size={25} className="text-gray-500"/>
                                                    }
                                                    <div className="flex flex-col items-center w-full">
                                                        <p className="">{name}</p>
                                                        <p className="text-xs">Tarefas concluídas: {user?.quantity}</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                }
                            </div>
                        </div>
                        <div className="border border-gray-200 bg-white flex flex-col gap-6 shadow-md h-80 w-full rounded-md py-4 px-4 mb-2">
                            <h2 className="font-semibold text-gray-500">Aniversariantes do mês</h2>
                            <div className="flex flex-col h-full gap-3 relative overflow-y-auto">
                                {
                                    !birthdaysOfMonth ?
                                        <div className="w-full h-full flex items-center justify-center bg-slate-100 text-gray-500">
                                            Carregando...
                                        </div> 
                                    : birthdaysOfMonth.length === 0 
                                        ? <div className="text-gray-500 h-full flex flex-col items-center justify-center">
                                            Não há aniversariantes
                                          </div>
                                        : birthdaysOfMonth.map(birthday => {
                                            const bday = moment(birthday.birthday).utc(false).format('DD/MM/YYYY')
                                            let nameSplited = birthday?.fullname.split(' ')
                                            let name = birthday?.fullname
                                            if(nameSplited.length > 2){
                                                name = `${nameSplited[0]} ${nameSplited[1]}...`
                                            }   
                                            return (
                                                <div key={`${birthday.users_id}-${birthday?.birthday}`} className="text-gray-500 text-sm p-2 border border-gray-200 rounded-md flex items-center">
                                                    <Cake size={25}/>
                                                    <div className="flex flex-col items-center gap-1 w-full">
                                                        <p>{name}</p>
                                                        <p className="text-xs">{bday}</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Container>
    )
}

interface ICharts{
    name: string,
    value: number
}

interface IQuantities{
    totalProjects: number
    tasksInProgress: number
    lateTasks: number
    pendingTasks: number
    urgentTasks: number
}

interface IBirthdays{
    users_id: number
    fullname: string
    birthday: Date
}

interface IFinishedTasksPerUserInMonth{
    users_id: number
    fullname: string
    quantity: number
}

interface IYear {
    id: number
    value: string
}