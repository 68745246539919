import { useLocation, useNavigate } from "react-router-dom";
import { Container } from "../../../components/container/container";
import { NewTableDefault } from "../../../components/tables/newTableDefault";
import { useAuthContext } from '../../../contexts/authContext';

export function Users() {

    const { userData } = useAuthContext()
    const navigate = useNavigate()
    const location = useLocation();

    const dataTypeAccess = [
        { id: 1, value: 'Administrador' },
        { id: 2, value: 'Gestor' },
        { id: 3, value: 'Líder' },
        { id: 4, value: 'Colaborador' },
        { id: 5, value: 'Externo - Operação' },
        { id: 6, value: 'Externo - Apenas Leitura' },
    ]

    return (
        <Container title={{
            name: 'Usuários',
            hasButtonAction: true,
            buttonName: 'Novo usuário',
            onClickAction: () => navigate(
                location?.pathname?.includes('external') 
                    ? '/users/external/create'
                    : '/users/create'
            )
        }}>
            <NewTableDefault
                endpoint='/api/v1/auth/users'
                columns={[ 
                    {
                        description: "Código",
                        field: "id",
                        type: "number",
                        orderBy: true
                    },
                    {
                        description: "Usuário",
                        field: "fullname",
                        type: "string",
                        orderBy: true
                    },
                    {
                        description: "Email",
                        field: "email",
                        type: "string",
                        orderBy: true
                    },
                    {
                        description: "Cargo",
                        field: "position",
                        type: "string",
                        orderBy: true
                    },
                    {
                        description: "Tipo de acesso",
                        field: "auth_typeAccess_id",
                        type: "string",
                        orderBy: true,
                        newFieldValue: ((e) => { 
                            const access = dataTypeAccess.find(d => d.id === e?.auth_typeAccess_id)
                            return <div className="w-full flex items-center justify-center">
                                        <p >
                                            {access?.value}
                                        </p>
                                    </div>
                        })
                    }
                ]}
                token={userData?.token as string}
                usePagination={false}
                filters={[
                    {
                        field: 'auth_typeAccess_id',
                        value: location?.pathname?.includes('external') ? '5,6' : '1,2,3,4' 
                    }
                ]}
                onClickInTableLine={(e) => navigate(
                    location?.pathname?.includes('external') 
                        ? `/users/external/edit/${e.id}`
                        : `/users/edit/${e.id}`
                )}
            />
        </Container>
    )
}