import ApexCharts from "react-apexcharts"

interface PieChartProps {
  data: { 
    name: string
    value: number 
  }[]
}

export function PieChartMobile({ data }: PieChartProps) {
  const pieChartData = data // Use data prop
  const pieChartOptions = {
    labels: pieChartData.map((dataSlice) => dataSlice.name),
    chart: {
      type: "pie",
    },
    legend: {
      position: "bottom",
    },
  } as ApexCharts.ApexOptions

  return (
    <ApexCharts
      options={pieChartOptions as ApexCharts.ApexOptions}
      series={pieChartData.map((dataSlice) => dataSlice.value)}
      type="pie"
      height={250} 
    />
  )
}

export default PieChartMobile
