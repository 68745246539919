import { TableContainer, Table as TableChakra, Thead, Th, Tr, Tbody, Td } from "@chakra-ui/react";
import { Container } from "../../../components/container/container";
import { NewTableDefault } from "../../../components/tables/newTableDefault";
import { useAuthContext } from "../../../contexts/authContext";
import { useNavigate } from "react-router-dom";

export function PermissionGroups() {

    const { userData } = useAuthContext();
    const navigate = useNavigate()

    return (
        <Container title={
            {
                name: "Grupos de permissão",
                buttonName: "Novo grupo",
                hasButtonAction: true,
                onClickAction: () => navigate('/permissionGroups/create')
            }            
        }>
            <div className="flex w-full">
                <NewTableDefault
                    endpoint='/api/v1/auth/permissionGroups'
                    columns={[
                        {
                            description: "Código",
                            field: "id",
                            type: "number"
                        },
                        {
                            description: "Nome",
                            field: "title",
                            type: "string"
                        },
                        
                        {
                            description: "Criado em",
                            field: "created_at",
                            type: "date"
                        }
                    ]}
                    token={userData?.token as string}
                    usePagination={false}
                    filters={[]}
                    onClickInTableLine={(e) => navigate(`/permissionGroups/edit/${e.id}`)}
                />
            </div>
        </Container>
    )
}