import { HashRouter, Route, Routes } from 'react-router-dom'
import { NotFound } from '../pages/404'
import { Clients } from '../pages/reg/clients/clients'
import { ClientsCreate } from '../pages/reg/clients/clients.create'
import { ClientsEdit } from '../pages/reg/clients/clients.edit'
import { ProjectsOverview } from '../pages/pro/projectsOverview/ProjectsOverview'
import { Projects } from '../pages/pro/projects/projects'
import { CreateProjects } from '../pages/pro/projects/projects.create'
import { EditProject } from '../pages/pro/projects/projects.edit'
import { Users } from '../pages/auth/users/users'
import { UsersCreate } from '../pages/auth/users/users.create'
import { UsersEdit } from '../pages/auth/users/users.edit'
import { ProjectsView } from '../pages/pro/projectsViews/projectsViews'
import { MyProfile } from '../pages/auth/myProfile/myProfile'
import { Contracts } from '../pages/reg/contracts/contracts'
import { ContractsCreate } from '../pages/reg/contracts/contracts.create'
import { ContractsEdit } from '../pages/reg/contracts/contracts.edit'
import { Settings } from '../pages/_general/settings/settings'
import { Notifications } from '../pages/_general/notifications/notifications'
import { Dashboards } from '../pages/_general/dashboards/dashboards'
import { MyTasks } from '../pages/pro/myTasks/myTasks'
import { ScreenSizeProvider } from '../contexts/screenSizeContext'
import { ViewTasks } from '../pages/pro/tasks/viewTasks'
import { ReportsHome } from '../pages/_general/reports/reports.home'
import { ReportsProjectsTimes } from '../pages/_general/reports/reports.projectsTimes'
import { useScreenModalContext } from '../contexts/screenModalContext'
import { TaskModal } from '../components/projects/modal.task'
import { ClientsVault } from '../pages/doc/clientsVault/clientsVault'
import { PermissionGroups } from '../pages/auth/permissionGroups/permissionGroups'
import { PermissionGroupsEdit } from '../pages/auth/permissionGroups/permissionGrups.edit'
import { PermissionGroupsCreate } from '../pages/auth/permissionGroups/permissionGrups.create'
import { CustomerAccount } from '../pages/auth/customers/account'
import { useAuthContext } from '../contexts/authContext'

export function AppRoutes() {
    
    const { taskModal } = useScreenModalContext()
    const { needCofig2FA, userData } = useAuthContext()

    return (
        <ScreenSizeProvider>
            <HashRouter>
            {
                taskModal &&
                <TaskModal task={{
                    inProjectView: taskModal?.inProjectView ? taskModal?.inProjectView : false,
                    pro_projects_id: taskModal?.pro_projects_id,
                    pro_projectsSteps_id: taskModal?.pro_projectsSteps_id,
                    pro_tasks_id: taskModal?.pro_tasks_id,
                    pro_projectsSteps: taskModal?.pro_projectsSteps,
                    pro_projectsMembers: taskModal?.pro_projectsMembers,
                    pro_projectsTags: taskModal?.pro_projectsTags,
                    taskData: taskModal?.taskData,
                    openTab: taskModal?.openTab,
                    externalCreateCallback: taskModal?.externalCreateCallback,
                    externalUpdateCallback: taskModal?.externalUpdateCallback,
                }}/>
            }
                <Routes>
                    {
                        needCofig2FA ? <> {/* CASO SEJA NECESSÁRIO CONFIGURAR O MFA */}
                            <Route path="/myProfile" element={<MyProfile />} />
                            <Route path="/*" element={<MyProfile />}/>
                        </> 
                        : [5,6].includes(Number(userData?.auth_typeAccess_id)) ? <> {/* CASO CLIENTE SEJA CLIENTE EXTERNO */}
                            <Route path="/" element={<ProjectsOverview />} />
                            <Route path="/projectOverview" element={<ProjectsOverview />} />
                            <Route path="/myProfile" element={<MyProfile />} />
                            <Route path="/*" element={<NotFound />} />
                            <Route path="/viewProject/:id" element={<ProjectsView />} />

                        </>
                        : <> {/* ROTAS PADRÕES DA APLICAÇÃO */}
                            <Route path="/" element={<Dashboards />} />
                            <Route path="/projectOverview" element={<ProjectsOverview />} />
        
                            <Route path="/users" element={<Users />} />
                            <Route path="/users/create" element={<UsersCreate />} />
                            <Route path="/users/edit/:id" element={<UsersEdit />} />
                            <Route path="/users/external" element={<Users />} />
                            <Route path="/users/external/create" element={<UsersCreate />} />
                            <Route path="/users/external/edit/:id" element={<UsersEdit />} />
                            
                            <Route path="/clients" element={<Clients />} />
                            <Route path="/clients/create" element={<ClientsCreate />} />
                            <Route path="/clients/edit/:id" element={<ClientsEdit />} />
                            
                            <Route path="/docs/clientsVault" element={<ClientsVault />} />
        
                            <Route path="/projects" element={<Projects />} />
                            <Route path="/projects/create" element={<CreateProjects />} />
                            <Route path="/projects/edit/:id" element={<EditProject />} />
        
                            <Route path='/contracts' element={<Contracts />} />
                            <Route path="/contracts/create" element={<ContractsCreate />} />
                            <Route path="/contracts/edit/:id" element={<ContractsEdit />} />
        
                            <Route path="/viewProject/:id" element={<ProjectsView />} />
        
                            <Route path="/myProfile" element={<MyProfile />} />
        
                            <Route path="/permissionGroups" element={<PermissionGroups />} />
                            <Route path="/permissionGroups/create" element={<PermissionGroupsCreate />} />
                            <Route path="/permissionGroups/edit/:id" element={<PermissionGroupsEdit />} />
                            <Route path="/reports" element={<ReportsHome />} />
                            <Route path="/reports/projects/projectsTimes" element={<ReportsProjectsTimes />} />
        
                            <Route path="/settings" element={<Settings />} />
        
                            <Route path="/notifications" element={<Notifications />} />
        
                            <Route path="/myTasks" element={<MyTasks />} />
        
                            <Route path="/viewTasks/:status" element={<ViewTasks/>} />
                            <Route path="/viewTasks/priority/:priority" element={<ViewTasks/>} />
                            <Route path='/customerAccount' element={<CustomerAccount/>}/>
        
                            <Route path="/*" element={<NotFound />} />
                        </>
                    }
                </Routes>
            </HashRouter>
        </ScreenSizeProvider>
    )
}