import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, FormControl, FormLabel, Input, Link, Stack, Text } from "@chakra-ui/react";
import { Header } from '../../../components/header/header';
import { api } from "../../../config/api";
import { useAlertContext } from "../../../contexts/alertContext";
import { useAuthContext } from "../../../contexts/authContext";
import { Loader } from "../../../components/loader/loader";

export function Login() {

    const navigate = useNavigate()
    const { setAlert } = useAlertContext()
    const { setUserData, setSigned } = useAuthContext()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [customers_code, setCustomers_code] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(true)
    const [with2FA, setWith2FA] = useState(false)
    const [mfa_code, setMfaCode] = useState('')
    const [token, setToken] = useState<string>()

    useEffect(() => {
        let dotPosition = window.location.hostname.indexOf('.')
        const thisCustomers = window.location.hostname.slice(0, dotPosition)
        setCustomers_code(thisCustomers)
        getClientByCode(thisCustomers)
    }, [])

    async function getClientByCode(thisCustomers: string){
        try {
            const clientExists = await api.get(`/api/auth/customer/${thisCustomers}`)

            if(clientExists?.data.status === 200){
                setLoading(false)
            }
        } catch (error) {
            console.log(error)
            navigate('/clientNotFound')
        }
    }

    async function handleSubmitLogon(code?: string) {
        try {
            setLoading(true)
            let newSession 
            
            if(code){
                newSession = await api.post(`/api/auth/logon/confirm2FA`, {
                    mfa_code,
                    token
                })
            } else {
                newSession = await api.post(`/api/auth/logon`, {
                    email: email,
                    password: password,
                    customers_code: customers_code
                })
            }

            const sessionData = newSession?.data

            if(token && with2FA && !code){
                setAlert({
                    status: 'error',
                    title: "Informe o código",
                    description: "Autenticação 2FA pendente."
                })
                setLoading(false)
                return
            }
            if(sessionData?.mfa_use && !sessionData?.userData && !code){
                setToken(sessionData?.token)
                setWith2FA(true)
                setLoading(false)
                return
            }

            setUserData({
                ...sessionData.userData,
                token: sessionData.token,
            })
            await new Promise(resolve => setTimeout(resolve, 1000))
            localStorage.setItem('@authToken', sessionData.token)
            navigate('/')
            setSigned(true)
            return setAlert({
                status: 'success',
                title: "Sucesso ao logar",
                description: ""
            })
        } catch (error) {
            setLoading(false)
            return setAlert({
                status: 'error',
                title: "Verifique os dados e tente novamente",
                description: (error as any)?.response?.data?.message || ""
            })
        }
    }

    function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            handleSubmitLogon(mfa_code ?? null)
        }
    }

    function handleRedirectToLostPassword () {
        navigate('/lostPassword')
    }

    return (
        <>
            {
                loading ?
                <Loader />
                : 
                <Box> 
                    <Header />
                    <Box className="z-50 flex flex-col justify-center items-center">
                        <Box className="flex flex-col justify-center items-center gap-6">
                            {
                                with2FA &&
                                <>
                                    <Text className="text-textPrimaryColor text-center text-4xl tracking-wide font-light mt-2">
                                        Confirme seu acesso!
                                    </Text>
                                    <Text className="text-textPrimaryColor text-center text-xl tracking-wide font-light">
                                        Informe o código gerado em sem aplicativo 2FA
                                    </Text>
                                    <Stack spacing={4} className="mt-2">
                                        <FormControl>
                                            <FormLabel fontSize={14} className="tracking-wider">Código</FormLabel>
                                            <Input 
                                                borderRadius={2} 
                                                bgColor={"#FFF"} 
                                                onKeyDown={handleKeyPress} 
                                                width={300} borderColor={'#E6E6E6'} 
                                                placeholder='Digite o código' 
                                                type='mfa_code' 
                                                value={mfa_code} 
                                                onChange={(e) => setMfaCode(e.target.value)} 
                                            />
                                        </FormControl>
                                    </Stack>
                                    <Button 
                                        bg={'#279D73'}
                                        textColor={"#FFF"}
                                        _hover={{bg: '#2aa87c'}}
                                        className="bg-primaryColor text-white mt-4"
                                        onClick={() => handleSubmitLogon(mfa_code)}
                                    >Enviar</Button>
                                </>
                            }
                            {
                                !with2FA &&
                                <>
                                    <Text className="text-textPrimaryColor text-center text-5xl tracking-wide font-light">
                                        Bem-vindo de volta!
                                    </Text>
                                    <Stack spacing={4}>
                                        <FormControl>
                                            <FormLabel fontSize={14} className="tracking-wider">Seu e-mail</FormLabel>
                                            <Input 
                                                borderRadius={2} 
                                                bgColor={"#FFF"} 
                                                onKeyDown={handleKeyPress}
                                                width={300}
                                                borderColor={'#E6E6E6'}
                                                placeholder='Digite seu email' 
                                                type='email' 
                                                value={email} 
                                                onChange={(e) => setEmail(e.target.value)} 
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel fontSize={14} className="tracking-wider">Senha</FormLabel>
                                            <Input 
                                                borderRadius={2} 
                                                bgColor={"#FFF"} 
                                                onKeyDown={handleKeyPress} 
                                                width={300}
                                                borderColor={'#E6E6E6'}
                                                placeholder='Sua senha'
                                                type="password"
                                                value={password} 
                                                onChange={(e) => setPassword(e.target.value)} 
                                            />    
                                        </FormControl>
                                        <Button 
                                            bg={'#279D73'}
                                            textColor={"#FFF"}
                                            _hover={{bg: '#2aa87c'}}
                                            className="bg-primaryColor text-white"
                                            onClick={() => handleSubmitLogon()}
                                        >Enviar</Button>
                                    </Stack>
                                    <Link className="text-sm text-center" onClick={() => handleRedirectToLostPassword()}>
                                        Esqueceu a senha?
                                    </Link>
                                </>
                            }
                        </Box>
                    </Box>
                </Box>
            }
        </>
    )
}