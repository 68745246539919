import { Avatar, Box, Flex, Text } from "@chakra-ui/react";
import { SignOut } from "@phosphor-icons/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAlertContext } from "../../contexts/alertContext";
import { useAuthContext } from "../../contexts/authContext";
import { useScreenModalContext } from "../../contexts/screenModalContext";
import { api } from "../../config/api";
import { ButtonDefault } from "../button/buttonDefault";
import { Loader } from "../loader/loader";
import { ScreenModal } from "../modals/screenModal";
import { menuItensListMobile } from "./menuItensList";

export function MobileBar() {
    const navigate = useNavigate()
    const { userData, setSigned } = useAuthContext()
    const { setAlert } = useAlertContext()
    const { detectModal, setDetectModal } = useScreenModalContext()
    const [loading, setLoading] = useState(false)

    async function handleLogout() {
        try {
            setLoading(true)
            await api.delete(`/api/auth/logout`, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            setDetectModal('')
            navigate('/')
            setSigned(false)
            setLoading(false)
            return setAlert({
                title: "Sucesso",
                description: "Sucesso ao deslogar do sistema",
                status: "success"
            })
        } catch (error) {
            setLoading(false)
            setDetectModal('')
            return setAlert({
                title: "Erro",
                description: "Ocorreu algum erro ao deslogar do sistema",
                status: "error"
            })
        }
    }

    return (
        <>
            {
                detectModal === 'logout' ?
                    <ScreenModal height={150} width={260}>
                        <Box className="h-full mt-6 flex flex-col justify-center items-center gap-4">
                            <Text className="text-sm" color={'#1E1E1E'}>Deseja sair do sistema?</Text>
                            <Flex gap={2} alignItems={'center'}>
                                <ButtonDefault onClick={() => setDetectModal('')}>Cancelar</ButtonDefault>
                                <ButtonDefault onClick={() => handleLogout()} success >Confirmar</ButtonDefault>
                            </Flex>
                        </Box>
                    </ScreenModal>
                    : <></>
            }
            {
                detectModal === 'change' ?
                    <ScreenModal>
                        <Box gap={2} zIndex={50} className="flex flex-col w-full h-full outline-none justify-center items-center">
                            <Flex alignItems={'center'} gap={2} onClick={() => { navigate('/myprofile'); setDetectModal('')}}>
                                <Avatar size='md' bg={"#c4c4c4"} name={userData?.fullname} src={userData?.picture}></Avatar>
                                <Flex className="w-full" flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Text className="text-xl">Editar perfil</Text>
                                    <Text textColor={"#FF0000"} className="hover:cursor-pointer "></Text>
                                </Flex>
                            </Flex>
                            <Flex className="mt-2" alignItems={'center'} gap={2} onClick={() => setDetectModal('logout')} >
                                <SignOut size={34} color="#FF0000" />
                                <Text color={'#FF0000'} fontWeight={'600'} className="text-xl">Sair</Text>
                            </Flex>
                        </Box>
                    </ScreenModal>
                    : <></>
            }
            {
                loading
                    ?
                    <Loader />
                    :
                    <aside className={`fixed bottom-0 left-0 w-full bg-white border-t-2 border-green-300 shadow-lg z-50`}>
                        <Box className={`flex justify-around items-center py-1 px-1 overflow-x-scroll hide-scrollbar`}>
                            <Box className={`flex flex-row justify-start items-start w-full text-textPrimaryColor mt-1  overflow-x-scroll hide-scrollbar`}>
                                <Avatar onClick={() => setDetectModal('change')} className="mr-2" size='sm' bg={"#c4c4c4"} name={userData?.fullname} src={userData?.picture}></Avatar>
                                {
                                    menuItensListMobile.map(menuItem => {
                                        return menuItem.permissions.includes(Number(userData?.auth_typeAccess_id)) ? 
                                            <Box onClick={() => navigate(menuItem.path)} className="flex flex-col w-full justify-center items-center self-center mr-2 px-4" key={menuItem.name}>
                                                <span className=" justify-center items-center self-center">
                                                    {menuItem.icon}
                                                </span>
                                                <p className="text-xs">
                                                    {menuItem.name}
                                                </p>
                                            </Box>
                                        :<></>
                                    })
                                }
                            </Box>
                        </Box>
                    </aside>
            }
        </>
    )
}