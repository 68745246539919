import { Box } from "@chakra-ui/react";
import Logo from '../../assets/wedo.png'
import '../../App.css';

export function Header() {
    return (
        <Box className="h-40 flex flex-row items-start justify-start">
            <Box className='w-full flex items-center justify-center sm:justify-start px-6'>
                <a href='/'><img src={Logo} width={150} alt='Logo da Mailou DEV'></img></a>
                {/* <Box className='flex items-center gap-4 text-sm'>
                    <span className='flex items-center gap-2 p-2 rounded-md'>Não tem uma conta?</span>
                    <Box className='flex flex-col items-end'>
                        <button className='text-white bg-primaryColor p-1 md:p-2 rounded-md hover:cursor-pointer'>
                            Criar conta
                        </button>
                    </Box>
                </Box> */}
            </Box>
        </Box>
    )
}