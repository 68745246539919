import { Container } from "../../../components/container/container";
import { useAuthContext } from '../../../contexts/authContext';
import { api } from "../../../config/api";
import { useEffect, useState } from "react";
import { IClients } from "../../../services/interfaces/reg/clients";
import { Loader } from "../../../components/loader/loader";
import { AutocompletePrime } from "../../../components/input/autocompletePrime";
import { IClientsVault } from "../../../services/interfaces/doc/clientsVault";
import { IClientsPermissionGroups } from '../../../services/interfaces/doc/ClientsPermissionGroups'
import {
    TableContainer, Table as TableChakra, Tr, Td, Tbody, PopoverContent, PopoverArrow,
    PopoverBody, PopoverTrigger, Popover, InputGroup, Textarea, Thead
} from "@chakra-ui/react";
import { Plus, Lock, Eye, Trash, Pencil, EyeSlash, PencilSimple, DotsThreeVertical, Info } from "@phosphor-icons/react";
import { ScreenModal } from "../../../components/modals/screenModal";
import { useScreenModalContext } from "../../../contexts/screenModalContext";
import { LabelInput } from "../../../components/label/label.input";
import { InputDefault } from "../../../components/input/inputDefault";
import { IClientsVaultItems } from "../../../services/interfaces/doc/clientsVaultItems";
import { ButtonDefault } from "../../../components/button/buttonDefault";
import { Button, Select } from "antd";
import { MultiSelectChangeEvent, MultiSelect } from "primereact/multiselect";
import { IUsers } from "../../../services/interfaces/auth/users";
import { useAlertContext } from "../../../contexts/alertContext";
import moment from "moment";
import Tippy from "@tippyjs/react";
import { createRandomPassword } from "../../../utils/security/randomPassword";

export function ClientsVault() {

    const { userData } = useAuthContext()
    const { setAlert } = useAlertContext()
    const { detectModal, setDetectModal } = useScreenModalContext()
    const [allPermissionGroups, setAllPermissionGroups] = useState<IClientsPermissionGroups[]>([])
    const [allUsers, setAllUsers] = useState<IUsers[]>([])

    const [clients, setClients] = useState<IClients[]>()
    const [clientsVault, setClientsVault] = useState<IClientsVault[]>([])
    const [clientsVaultItems, setClientsVaultItems] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [loadingPassword, setLoadingPassword] = useState<boolean>(false)

    const [selectedClient, setSelectedClient] = useState<any>('')
    const [selectedClientVault, setSelectedClientVault] = useState<any>('')

    const [newClientVault, setNewClientVault] = useState<IClientsVault | null>(null)
    const [newClientVaultItem, setNewClientVaultItem] = useState<IClientsVaultItems | null>(null)

    const [selectedNewPermissionGroups, setSelectedNewPermissionGroups] = useState<IClientsPermissionGroups[]>([])
    const [selectedVaultItem, setSelectedVaultItem] = useState<IClientsVaultItems | null>(null)
    const [selectedPasswordOrMFA, setSelectedPasswordOrMFA] = useState<string>('');
    const [selectedAuthTypeAccess_id, setSelectedAuthTypeAccess_id] = useState<number>(Number(userData?.auth_typeAccess_id));
    const [displayValue, setDisplayValue] = useState('');

    const [showThisPassword, setShowThisPassword] = useState<string[]>([])
    const [typeToValid, setTypeToValid] = useState<string>('')

    const [thisVaultItemHistory, setThisVaultItemHistory] = useState<any[]>([])

    async function getData() {

        try {

            const data = await api.get('/api/v1/reg/clients', {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            setClients(data.data.data)

            const permissionGroupsData = await api.get(`/api/v1/auth/permissionGroups`, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })
            setAllPermissionGroups(permissionGroupsData.data.data)

            const usersData = await api.get(`/api/v1/auth/users`, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            setAllUsers(usersData?.data?.data)

            setLoading(false)
        } catch (error) {
            console.log(error)
            setAlert({
                status: 'error',
                title: 'Erro',
                description: (error as any)?.response?.data?.message || 'Erro ao buscar dados'
            })
        }
    }

    useEffect(() => {
        getData()
    }, [])

    async function updateClientsVault(id: number) {

        setLoading(true)

        try {
            const data = await api.get(`/api/v1/doc/clientsVault/reg_clients/${id}`, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })
            setSelectedClientVault(null)

            let permittedVaults = data.data.data.filter((vault: IClientsVault) => {
                let hasPermission = Number(userData?.auth_typeAccess_id) === 1 ? true : false
                vault.permissionGroups_id?.map(group => {
                    const thisGroup = allPermissionGroups.filter(groupToValid => groupToValid.id === group && groupToValid.auth_users_id.includes(Number(userData?.id)))
                    if (thisGroup.length > 0) {
                        hasPermission = true
                    }
                })

                if (hasPermission) {
                    return vault
                }
            })
            setClientsVault(permittedVaults)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setAlert({
                status: 'error',
                title: 'Erro',
                description: (error as any)?.response?.data?.message || 'Erro ao buscar dados'
            })
        }
    }

    async function getAllVaultItems(id: string) {

        setLoading(true)

        try {
            const data = await api.get(`/api/v1/doc/clientsVaultItems/doc_clientsVault_id/${id}`, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            setClientsVaultItems(data.data.data)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setAlert({
                status: 'error',
                title: 'Erro',
                description: (error as any)?.response?.data?.message || 'Erro ao buscar dados'
            })
        }
    }

    async function handleNewClientsVault() {

        setLoading(true)

        try {
            const data = await api.post(`/api/v1/doc/clientsVault`, {
                "title": newClientVault?.title ? newClientVault.title : undefined,
                "reg_clients_id": selectedClient?.value,
                "permissionGroups_id": selectedNewPermissionGroups.map(group => { return group.id }),
                "auth_typeAccess_id": selectedAuthTypeAccess_id
            }, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            clientsVault.push(data?.data?.data)
            setClientsVault([...clientsVault])
            setDetectModal('')
            setLoading(false)
            setAlert({
                status: 'success',
                title: "Sucesso!",
                description: 'Novo cofre criado!'
            })
        } catch (error) {
            setLoading(false)
            setAlert({
                status: 'error',
                title: 'Erro',
                description: (error as any)?.response?.data?.message || 'Erro ao criar novo cofre'
            })
        }
    }

    async function handleUpdateClientsVault() {

        setLoading(true)

        try {
            const data = await api.put(`/api/v1/doc/clientsVault/${newClientVault?.id}`, {
                "title": newClientVault?.title ? newClientVault.title : undefined,
                "reg_clients_id": selectedClient?.value,
                "permissionGroups_id": selectedNewPermissionGroups.map(group => { return group.id }),
                "auth_typeAccess_id": selectedAuthTypeAccess_id
            }, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            let newClientsVault: any[] = clientsVault.map(vault => {

                if (vault.id === newClientVault?.id) {
                    return {
                        ...newClientVault,
                        auth_typeAccess_id: selectedAuthTypeAccess_id,
                        permissionGroups_id: selectedNewPermissionGroups.map(group => { return group.id }),
                        id: vault.id
                    }
                } else {
                    return { ...vault }
                }
            })
            setClientsVault([...newClientsVault])
            setSelectedClientVault(null);
            setSelectedNewPermissionGroups([])
            setDetectModal('')
            setLoading(false)
            setAlert({
                status: 'success',
                title: "Sucesso!",
                description: 'Cofre alterado!'
            })
        } catch (error) {
            console.log(error)
            setLoading(false)
            setAlert({
                status: 'error',
                title: 'Erro',
                description: (error as any)?.response?.data?.message || 'Erro ao criar novo cofre'
            })
        }
    }

    async function handleNewClientsVaultItem() {

        setLoading(true)
        try {
            const data = await api.post(`/api/v1/doc/clientsVaultItems/doc_clientsVault_id/${selectedClientVault?.value}`, {
                "title": newClientVaultItem?.title ? newClientVaultItem.title : undefined,
                "username": newClientVaultItem?.username ? newClientVaultItem?.username : undefined,
                "password": newClientVaultItem?.password ? newClientVaultItem?.password : undefined,
                "notes": newClientVaultItem?.notes ? newClientVaultItem?.notes : undefined
            }, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            clientsVaultItems.push(data?.data?.data)
            setClientsVaultItems([...clientsVaultItems])
            setDetectModal('')
            setLoading(false)
            setAlert({
                status: 'success',
                title: "Sucesso!",
                description: 'Nova senha criada!'
            })

        } catch (error) {
            setLoading(false)
            console.log(error)
            setAlert({
                status: 'error',
                title: 'Erro',
                description: (error as any)?.response?.data?.message || 'Erro ao criar nova senha'
            })
        }
    }

    async function handleUpdateClientsVaultItem() {

        setLoading(true)

        try {
            const data = await api.put(`/api/v1/doc/clientsVaultItems/doc_clientsVault_id/${selectedClientVault?.value}/doc_clientsVaultItem_id/${selectedVaultItem?.id}`, {
                "clientsVaultItemsOriginal_id": newClientVaultItem?.clientsVaultItemsOriginal_id,
                "title": newClientVaultItem?.title ? newClientVaultItem.title : undefined,
                "username": newClientVaultItem?.username ? newClientVaultItem?.username : undefined,
                "password": newClientVaultItem?.password ? newClientVaultItem?.password : undefined,
                "notes": newClientVaultItem?.notes ? newClientVaultItem?.notes : undefined
            }, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            setSelectedVaultItem(data.data.data)
            let newClientsVaultItems = clientsVaultItems.map(vaultItem => {

                if (vaultItem.id === selectedVaultItem?.id) {
                    return {
                        ...data.data.data,
                    }
                } else {
                    return { ...vaultItem }
                }
            })

            setClientsVaultItems([...newClientsVaultItems])
            setDetectModal('')
            setLoading(false)
            setAlert({
                status: 'success',
                title: "Sucesso!",
                description: 'Senha Editada!'
            })

        } catch (error) {
            console.log(error)
            setLoading(false)
            setAlert({
                status: 'error',
                title: 'Erro',
                description: (error as any)?.response?.data?.message || 'Erro ao editar senha'
            })
        }
    }

    async function handleRemoveVaultItem() {
        setLoading(true)
        try {
            const data = await api.delete(`/api/v1/doc/clientsVaultItems/doc_clientsVault_id/${selectedClientVault?.value}/doc_clientsVaultItem_id/${selectedVaultItem?.id}`, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            setClientsVaultItems([...clientsVaultItems?.filter(vaultItem => vaultItem?.id !== selectedVaultItem?.id)])
            setDetectModal('')
            setLoading(false)
            setAlert({
                status: 'success',
                title: "Sucesso!",
                description: 'Senha removida!'
            })

        } catch (error) {
            console.log(error)
            setLoading(false)
            setAlert({
                status: 'error',
                title: 'Erro',
                description: (error as any)?.response?.data?.message || 'Erro ao remover senha'
            })
        }
    }

    async function getPasswordDecrypted() {

        try {
            const dataPassword = await api.get(`/api/v1/doc/clientsVaultItems/doc_clientsVault_id/${selectedClientVault.value}/doc_clientsVaultItem_id/${selectedVaultItem?.id}/password`, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            let formattedClientVaultItems = clientsVaultItems.map(vaultItem => {

                if (vaultItem.id === selectedVaultItem?.id) {
                    return {
                        ...dataPassword?.data?.data
                    }
                } else {
                    return { ...vaultItem }
                }
            })
            setClientsVaultItems([...formattedClientVaultItems])

            return dataPassword?.data.data

        } catch (error) {

            setLoading(false)
            setLoadingPassword(false)
            console.log(error)
            setAlert({
                status: 'error',
                title: 'Erro',
                description: (error as any)?.response?.data?.message || 'Erro ao descriptografar a senha'
            })
        }

    }

    async function getPasswordHistoryDecrypted() {

        try {
            const dataPassword = await api.get(`/api/v1/doc/clientsVaultItems/doc_clientsVault_id/${selectedClientVault.value}/doc_clientsVaultItem_id/${selectedVaultItem?.id}/password`, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            let formattedClientVaultItems = thisVaultItemHistory.map(vaultItem => {
                if (vaultItem.id === selectedVaultItem?.id) {
                    return {
                        ...dataPassword?.data?.data
                    }
                } else {
                    return { ...vaultItem }
                }
            })
            setThisVaultItemHistory([...formattedClientVaultItems])

        } catch (error) {

            setLoading(false)
            setLoadingPassword(false)
            console.log(error)
            setAlert({
                status: 'error',
                title: 'Erro',
                description: (error as any)?.response?.data?.message || 'Erro ao descriptografar a senha'
            })
        }

    }

    async function handleValidPassword() {

        try {

            setLoadingPassword(true)
            setSelectedPasswordOrMFA('')
            setDisplayValue('')
            await api.post(`/api/v1/auth/users/${userData?.id}/validPassOrMfA`, {
                password: !userData?.mfa_use ? selectedPasswordOrMFA : undefined,
                mfa_code: userData?.mfa_use ? selectedPasswordOrMFA : undefined
            }, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            if (typeToValid === 'showThisPassword') {
                await getPasswordDecrypted()
                setShowThisPassword([...showThisPassword, selectedVaultItem?.id as string])
                setDetectModal('')
            } else if (typeToValid === 'showThisPasswordHistory') {
                await getPasswordHistoryDecrypted()
                setShowThisPassword([...showThisPassword, selectedVaultItem?.id as string])
                setDetectModal('showVaultItemHistory')
            } else if (typeToValid === 'deleteThisPassword') {
                setDetectModal('deleteThisPassword')
            } else if (typeToValid === 'editThisPassword') {
                const passData = await getPasswordDecrypted()
                setNewClientVaultItem({ ...selectedVaultItem, clientsVaultItemsOriginal_id: newClientVaultItem?.clientsVaultItemsOriginal_id as string, password: passData?.password })
                setDetectModal('editVaultItem')
            }

            setLoadingPassword(false)

        } catch (error) {
            setLoading(false)
            setLoadingPassword(false)
            console.log(error)
            setAlert({
                status: 'error',
                title: 'Erro',
                description: (error as any)?.response?.data?.message || 'Senha inválida'
            })
        }
    }

    async function getHistoryVaultItem(vaultItem: IClientsVaultItems) {

        setLoading(true)
        try {
            const data = await api.get(`/api/v1/doc/clientsVaultItems/doc_clientsVault_id/${selectedClientVault?.value}/clientsVaultItemsOriginal_id/${vaultItem.clientsVaultItemsOriginal_id}/history`, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            setThisVaultItemHistory(data?.data?.data)
            setDetectModal('showVaultItemHistory')
            setLoading(false)

        } catch (error) {
            setLoading(false)
            setLoadingPassword(false)
            console.log(error)
            setAlert({
                status: 'error',
                title: 'Erro',
                description: (error as any)?.response?.data?.message || 'Senha inválida'
            })
        }
    }


    return (
        <Container>
            {
                loading ?
                    <Loader />
                    :
                    <div className="flex gap-2 w-full flex-col">
                        {
                            detectModal === "newClientsVaultItem" ?
                                <ScreenModal autoHeight size="6xl">
                                    <div className="w-full  h-full flex flex-col gap-2 items-start justify-start">
                                        <a className="mb-6">Nova senha para cliente</a>
                                        <InputGroup className="flex flex-col justify-start gap-2">
                                            <LabelInput>Titulo</LabelInput>
                                            <InputDefault onChange={(e) => setNewClientVaultItem({ ...newClientVaultItem, title: e.target.value, clientsVaultItemsOriginal_id: selectedClientVault.clientsVaultItemsOriginal_id })}></InputDefault>
                                        </InputGroup>
                                        <InputGroup className="flex flex-col justify-start gap-2">
                                            <LabelInput>Usuário</LabelInput>
                                            <InputDefault onChange={(e) => setNewClientVaultItem({ ...newClientVaultItem, username: e.target.value, clientsVaultItemsOriginal_id: selectedClientVault.clientsVaultItemsOriginal_id })}></InputDefault>
                                        </InputGroup>
                                        <InputGroup className="flex flex-col justify-start gap-2">
                                            <LabelInput>Senha</LabelInput>
                                            <div className="flex flex-col md:flex-row gap-2 items-center">
                                                <InputDefault value={newClientVaultItem?.password} type="text" onChange={(e) => setNewClientVaultItem({ ...newClientVaultItem, password: e.target.value, clientsVaultItemsOriginal_id: selectedClientVault.clientsVaultItemsOriginal_id })}></InputDefault>
                                                <Button onClick={() =>
                                                    setNewClientVaultItem({ ...newClientVaultItem, password: createRandomPassword(), clientsVaultItemsOriginal_id: selectedClientVault.clientsVaultItemsOriginal_id })}>Gerar Senha</Button>
                                            </div>
                                        </InputGroup>
                                        <InputGroup className="flex flex-col justify-start gap-2">
                                            <LabelInput>Anotações</LabelInput>
                                            <Textarea onChange={(e) => setNewClientVaultItem({ ...newClientVaultItem, notes: e.target.value, clientsVaultItemsOriginal_id: selectedClientVault.clientsVaultItemsOriginal_id })} height={250}></Textarea>
                                        </InputGroup>
                                        <div className="mt-2 flex w-full justify-between ">
                                            <ButtonDefault onClick={() => setDetectModal('')}>Cancelar</ButtonDefault>
                                            <ButtonDefault success onClick={() => handleNewClientsVaultItem()}>Salvar</ButtonDefault>
                                        </div>
                                    </div>
                                </ScreenModal>
                                :
                                detectModal === "newClientsVault" ?
                                    <ScreenModal autoHeight size="lg">
                                        <div className="w-full h-full flex flex-col gap-2 items-start justify-start">

                                            <a className="mb-6">Novo cofre para o cliente</a>
                                            <InputGroup className="flex flex-col justify-start gap-2">
                                                <LabelInput>Titulo</LabelInput>
                                                <InputDefault onChange={(e) => setNewClientVault({
                                                    ...newClientVault,
                                                    auth_typeAccess_id: selectedAuthTypeAccess_id,
                                                    reg_clients_id: selectedClient?.value as number,
                                                    permissionGroups_id: [],
                                                    title: e.target.value
                                                })} />
                                            </InputGroup>
                                            <InputGroup className="flex flex-col justify-start gap-2">
                                                <div className="flex items-center justify-start flex-row gap-2">
                                                    <LabelInput>Hierarquia</LabelInput>
                                                    <Tippy
                                                        content={<span>Este nível poderá editar o cofre adicionando mais grupos de permissão se necessário, todo nível acima dele também terá estas permissões,
                                                            os níveis abaixo dele somente poderão acessar o cofre, caso esteja no grupo de permissões
                                                        </span>}
                                                        arrow={true}
                                                        animation='perspective'
                                                        placement='top-start'
                                                        delay={80}>
                                                        <div>
                                                            <Info />
                                                        </div>
                                                    </Tippy>

                                                </div>
                                                <Select
                                                    filterOption={(input, option) =>
                                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                    }
                                                    showSearch
                                                    defaultValue={userData?.auth_typeAccess_id ? userData?.auth_typeAccess_id?.toString() : '1'}
                                                    options={[
                                                        { value: '1', label: 'Administrador' },
                                                        { value: '2', label: 'Gestor' },
                                                        { value: '3', label: 'Líder' }
                                                    ]}
                                                    onChange={e => setSelectedAuthTypeAccess_id(Number(e))}

                                                >
                                                </Select>
                                            </InputGroup>
                                            <InputGroup className="flex flex-col justify-start gap-2">
                                                <LabelInput>Nível permissão</LabelInput>
                                                <MultiSelect
                                                    value={selectedNewPermissionGroups}
                                                    onChange={(e: MultiSelectChangeEvent) => setSelectedNewPermissionGroups(e.value)}
                                                    options={allPermissionGroups}
                                                    display="chip"
                                                    filter
                                                    width="300px"
                                                    optionLabel="title"
                                                    id="id"
                                                    className="w-full md:w-20rem"
                                                />
                                            </InputGroup>
                                            <div className="mt-2 flex w-full justify-between ">
                                                <ButtonDefault onClick={() => setDetectModal('')}>Cancelar</ButtonDefault>
                                                <ButtonDefault success onClick={() => handleNewClientsVault()}>Salvar</ButtonDefault>
                                            </div>
                                        </div>
                                    </ScreenModal>
                                    :
                                    detectModal === "editClientsVault" ?
                                        <ScreenModal autoHeight size="lg">
                                            <div className="w-full h-full flex flex-col gap-2 items-start justify-start">

                                                <a className="mb-6">Editar cofre do cliente</a>
                                                <InputGroup className="flex flex-col justify-start gap-2">
                                                    <LabelInput>Titulo</LabelInput>
                                                    <InputDefault value={newClientVault?.title} onChange={(e) => setNewClientVault({
                                                        ...newClientVault,
                                                        reg_clients_id: selectedClient?.value as number,
                                                        permissionGroups_id: [],
                                                        auth_typeAccess_id: selectedAuthTypeAccess_id,
                                                        title: e.target.value
                                                    })} />
                                                </InputGroup>
                                                <InputGroup className="flex flex-col justify-start gap-2">
                                                    <div className="flex items-center justify-start flex-row gap-2">
                                                        <LabelInput>Hierarquia</LabelInput>
                                                        <Tippy
                                                            content={<span>Este nível poderá editar o cofre adicionando mais grupos de permissão se necessário, todo nível acima dele também tera estas permissões,
                                                                os níveis abaixo dele somente poderão acessar o cofre, caso estja no grupo de permissões
                                                            </span>}
                                                            arrow={true}
                                                            animation='perspective'
                                                            placement='top-start'
                                                            delay={80}>
                                                            <div>
                                                                <Info />
                                                            </div>
                                                        </Tippy>

                                                    </div>
                                                    <Select
                                                        filterOption={(input, option) =>
                                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                        }
                                                        showSearch
                                                        defaultValue={newClientVault?.auth_typeAccess_id?.toString()}
                                                        options={[
                                                            { value: '1', label: 'Administrador' },
                                                            { value: '2', label: 'Gestor' },
                                                            { value: '3', label: 'Líder' }
                                                        ]}
                                                        onChange={e => setSelectedAuthTypeAccess_id(Number(e))}
                                                    >
                                                    </Select>
                                                </InputGroup>
                                                <InputGroup className="flex flex-col justify-start gap-2">
                                                    <LabelInput>Nível permissão</LabelInput>
                                                    <MultiSelect
                                                        value={selectedNewPermissionGroups}
                                                        onChange={(e: MultiSelectChangeEvent) => setSelectedNewPermissionGroups(e.value)}
                                                        options={allPermissionGroups}
                                                        display="chip"
                                                        filter
                                                        width="300px"
                                                        optionLabel="title"
                                                        id="id"
                                                        className="w-full md:w-20rem"
                                                    />
                                                </InputGroup>
                                                <div className="mt-2 flex w-full justify-between ">
                                                    <ButtonDefault onClick={() => setDetectModal('')}>Cancelar</ButtonDefault>
                                                    <ButtonDefault success onClick={() => handleUpdateClientsVault()}>Salvar</ButtonDefault>
                                                </div>
                                            </div>
                                        </ScreenModal>
                                        :
                                        detectModal === "validPassword" ?
                                            <ScreenModal autoHeight size="xs">
                                                <div className="w-full  h-full flex flex-col gap-2 items-start justify-start">
                                                    <span className="mb-6">Validação</span>
                                                    {
                                                        loadingPassword ?
                                                            <div className="h-48  w-full flex flex-col justify-center items-center gap-4">
                                                                <h1 className="font-medium text-darkBgPrimaryBackground text-xl" >Carregando...</h1>
                                                                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                                            </div>
                                                            :
                                                            <>
                                                                <div className="w-full flex flex-col gap-2 mb-6">
                                                                    <InputGroup className="flex flex-col items-start justify-start gap-2">
                                                                        <LabelInput>{userData?.mfa_use ? 'Código 2FA' : 'Sua senha'}</LabelInput>
                                                                        <InputDefault 
                                                                            autoComplete="off" 
                                                                            type={userData?.mfa_use ? 'text' : 'password'} 
                                                                            width={270}
                                                                            value={selectedPasswordOrMFA}
                                                                            onChange={e => setSelectedPasswordOrMFA(e.target.value)}
                                                                        />
                                                                    </InputGroup>
                                                                </div>
                                                                <div className="mt-2 flex w-full justify-between ">
                                                                    <ButtonDefault onClick={() => setDetectModal('')}>Cancelar</ButtonDefault>
                                                                    <ButtonDefault success onClick={() => handleValidPassword()}>Avançar</ButtonDefault>
                                                                </div>
                                                            </>
                                                    }
                                                </div>
                                            </ScreenModal>
                                            :
                                            detectModal === "editVaultItem" ?
                                                <ScreenModal autoHeight size="6xl">
                                                    <div className="w-full  h-full flex flex-col gap-2 items-start justify-start">
                                                        <a className="mb-6">Editar senha do cliente</a>
                                                        <InputGroup className="flex flex-col justify-start gap-2">
                                                            <LabelInput>Titulo</LabelInput>
                                                            <InputDefault value={newClientVaultItem?.title} onChange={(e) => setNewClientVaultItem({ ...newClientVaultItem, title: e.target.value, clientsVaultItemsOriginal_id: selectedClientVault.clientsVaultItemsOriginal_id })}></InputDefault>
                                                        </InputGroup>
                                                        <InputGroup className="flex flex-col justify-start gap-2">
                                                            <LabelInput>Usuário</LabelInput>
                                                            <InputDefault value={newClientVaultItem?.username} onChange={(e) => setNewClientVaultItem({ ...newClientVaultItem, username: e.target.value, clientsVaultItemsOriginal_id: selectedClientVault.clientsVaultItemsOriginal_id })}></InputDefault>
                                                        </InputGroup>
                                                        <InputGroup className="flex flex-col justify-start gap-2">
                                                            <LabelInput>Senha</LabelInput>
                                                            <div className="flex flex-col md:flex-row gap-2 items-center">
                                                                <InputDefault value={newClientVaultItem?.password} type="text" onChange={(e) => setNewClientVaultItem({ ...newClientVaultItem, password: e.target.value, clientsVaultItemsOriginal_id: selectedClientVault.clientsVaultItemsOriginal_id })}></InputDefault>
                                                                <Button onClick={() =>
                                                                    setNewClientVaultItem({ ...newClientVaultItem, password: createRandomPassword(), clientsVaultItemsOriginal_id: selectedClientVault.clientsVaultItemsOriginal_id })}>Gerar Senha</Button>
                                                            </div>
                                                        </InputGroup>
                                                        <InputGroup className="flex flex-col justify-start gap-2">
                                                            <LabelInput>Anotações</LabelInput>
                                                            <Textarea wrap="hard" value={newClientVaultItem?.notes} onChange={(e) => setNewClientVaultItem({ ...newClientVaultItem, notes: e.target.value, clientsVaultItemsOriginal_id: selectedClientVault.clientsVaultItemsOriginal_id })} height={250}></Textarea>
                                                        </InputGroup>
                                                        <div className="mt-2 flex w-full justify-between ">
                                                            <ButtonDefault onClick={() => setDetectModal('')}>Cancelar</ButtonDefault>
                                                            <ButtonDefault success onClick={() => handleUpdateClientsVaultItem()}>Salvar</ButtonDefault>
                                                        </div>
                                                    </div>
                                                </ScreenModal>
                                                :
                                                detectModal === "deleteThisPassword" ?
                                                    <ScreenModal autoHeight size="sm">
                                                        <div className="w-full  h-full flex flex-col gap-2 items-center justify-center">
                                                            <a className="mt-10">Deseja realmente remover esta senha?</a>
                                                            <div className="mt-2 flex w-full justify-center gap-4 ">
                                                                <ButtonDefault onClick={() => setDetectModal('')}>Não</ButtonDefault>
                                                                <ButtonDefault success onClick={() => handleRemoveVaultItem()}>Sim</ButtonDefault>
                                                            </div>
                                                        </div>
                                                    </ScreenModal>
                                                    :
                                                    detectModal === "viewThisPassword" ?
                                                        <ScreenModal autoHeight size="6xl">
                                                            <div className="w-full  h-full flex flex-col gap-2 items-start justify-start">
                                                                <a className="mb-6">Editar senha do cliente</a>
                                                                <InputGroup className="flex flex-col justify-start gap-2">
                                                                    <LabelInput>Titulo</LabelInput>
                                                                    <InputDefault isDisabled value={newClientVaultItem?.title} />
                                                                </InputGroup>
                                                                <InputGroup className="flex flex-col justify-start gap-2">
                                                                    <LabelInput>Usuário</LabelInput>
                                                                    <InputDefault isDisabled value={newClientVaultItem?.username} />
                                                                </InputGroup>
                                                                <InputGroup className="flex flex-col justify-start gap-2">
                                                                    <LabelInput>Senha</LabelInput>
                                                                    <InputDefault isDisabled type="password" value={newClientVaultItem?.password} />
                                                                </InputGroup>
                                                                <InputGroup className="flex flex-col justify-start gap-2">
                                                                    <LabelInput>Anotações</LabelInput>
                                                                    <Textarea wrap="hard" isDisabled value={newClientVaultItem?.notes} height={250} />
                                                                </InputGroup>
                                                            </div>
                                                        </ScreenModal>
                                                        :
                                                        detectModal === "showVaultItemHistory" ?
                                                            <ScreenModal autoHeight size="6xl">
                                                                <div className="w-full  h-full flex flex-col gap-2 items-start justify-start">
                                                                    <a className="mb-6">Histórico de versões</a>
                                                                    <TableContainer>
                                                                        <TableChakra>
                                                                            <Thead>
                                                                                <Tr>
                                                                                    <Td>Titulo</Td>
                                                                                    <Td>Usuário</Td>
                                                                                    <Td>Senha</Td>
                                                                                    <Td>Notas</Td>
                                                                                    <Td>Criado em</Td>
                                                                                    <Td>Versao</Td>
                                                                                    <Td>Ultima alteração</Td>
                                                                                    <Td>Acões</Td>
                                                                                </Tr>
                                                                            </Thead>
                                                                            <Tbody>
                                                                                {
                                                                                    thisVaultItemHistory?.map(vaultItem => {
                                                                                        return <Tr>
                                                                                            <Td>{vaultItem.title}</Td>
                                                                                            <Td>{vaultItem.username}</Td>
                                                                                            <Td onClick={() => {
                                                                                                setSelectedVaultItem(vaultItem);
                                                                                                setTypeToValid('showThisPasswordHistory');
                                                                                                showThisPassword?.includes(vaultItem?.id) ? setShowThisPassword(showThisPassword.filter(id => id !== vaultItem.id)) : setDetectModal('validPassword')
                                                                                            }}
                                                                                                className="cursor-pointer">{
                                                                                                    showThisPassword.includes(vaultItem?.id) ?
                                                                                                        vaultItem.password
                                                                                                        :
                                                                                                        <>
                                                                                                            <EyeSlash size={20} />

                                                                                                        </>
                                                                                                }</Td>
                                                                                            <Td>{vaultItem.notes?.slice(0, 15).concat('...')}</Td>
                                                                                            <Td>{moment(vaultItem.created_at).utc(true).format("DD/MM/YYYY HH:mm:ss")}</Td>
                                                                                            <Td>
                                                                                                <div className="flex gap-2">
                                                                                                    {vaultItem?.version}
                                                                                                </div>
                                                                                            </Td>
                                                                                            <Td>{allUsers.filter(user => user.id === vaultItem.auth_users_id)[0]?.fullname}</Td>
                                                                                            <Td>
                                                                                                <div className="flex flex-col md:flex-row gap-2">
                                                                                                    <Eye size={20} className="cursor-pointer text-blue-600" onClick={() => {
                                                                                                        setNewClientVaultItem(vaultItem)
                                                                                                        setDetectModal('viewThisPassword')
                                                                                                    }} />
                                                                                                </div>
                                                                                            </Td>
                                                                                        </Tr>
                                                                                    })
                                                                                }
                                                                            </Tbody>
                                                                        </TableChakra>
                                                                    </TableContainer>
                                                                </div>
                                                            </ScreenModal>
                                                            :
                                                            <></>
                        }
                        <div className="flex w-full items-center justify-between">
                            <h1 className="text-2xl font-semibold text-zinc-700">Cofre de senhas</h1>
                        </div>
                        <div className="borber-b bg-zinc-200 p-2 border-zinc-200 flex w-full flex-col md:flex-row gap-3 items-center justify-between flex-wrap">
                            <div className="flex items-center justify-start">
                                <div className="items-center flex flex-col md:flex-row gap-2 min-w-96 w-96">
                                    <a className="text-sm">Cliente:</a>
                                    <AutocompletePrime
                                        items={clients as IClients[]}
                                        label="name"
                                        value="id"
                                        size={350}
                                        className="mb-1"
                                        preselectedItem={selectedClient?.label}
                                        onChange={e => {
                                            setSelectedClient(e?.value)
                                            if (e?.value?.value) {
                                                updateClientsVault(e?.value.value)
                                            }
                                        }}
                                    />
                                </div>
                                {
                                    clientsVault?.length > 0 &&
                                    <div className="items-center  flex flex-col md:flex-row gap-2 w-80">
                                        <a className="text-sm">Cofre:</a>
                                        <AutocompletePrime
                                            items={clientsVault as IClientsVault[]}
                                            label="title"
                                            value="id"
                                            size={200}
                                            className="mb-1"
                                            preselectedItem={selectedClientVault?.label}
                                            onChange={e => {
                                                if (e?.value?.value) {
                                                    setSelectedClientVault(e.value)
                                                    getAllVaultItems(e.value.value)
                                                }
                                            }}
                                        />
                                    </div>
                                }

                            </div>
                            {
                                selectedClient &&
                                <div>
                                    <Popover variant={'responsive'} size={'sm'} direction="ltr" >
                                        <PopoverTrigger>
                                            <div className="border border-zinc-400  text-zinc-500 hover:opacity-60 p-1 rounded-md cursor-pointer shadow-md">
                                                Gerenciar
                                            </div>
                                        </PopoverTrigger>
                                        <PopoverContent className="outline-none">
                                            <PopoverArrow />
                                            <PopoverBody className="outline-none">
                                                {
                                                    selectedClientVault &&
                                                    <div onClick={() => setDetectModal('newClientsVaultItem')} className="text-zinc-500 hover:opacity-60 flex items-center gap-2 cursor-pointer">
                                                        <Plus />
                                                        <p>Nova senha</p>
                                                    </div>
                                                }
                                                {
                                                    Number(userData?.auth_typeAccess_id) <= 3 &&
                                                    <div onClick={() => setDetectModal('newClientsVault')} className="text-zinc-500 hover:opacity-60 flex items-center gap-2 cursor-pointer">
                                                        <Lock />
                                                        <p>Novo cofre</p>
                                                    </div>
                                                }

                                                {
                                                    selectedClientVault?.label?.length > 0 &&
                                                    Number(userData?.auth_typeAccess_id) <= clientsVault?.filter(vault => vault.id === selectedClientVault.value)[0].auth_typeAccess_id &&
                                                    Number(userData?.auth_typeAccess_id) <= 3 &&
                                                    <div onClick={() => {
                                                        let thisVault = clientsVault?.filter(vault => vault.id === selectedClientVault?.value)[0]
                                                        setNewClientVault(thisVault)
                                                        setSelectedNewPermissionGroups(allPermissionGroups.filter(group => thisVault.permissionGroups_id?.includes(group?.id as number)))
                                                        setDetectModal('editClientsVault')
                                                    }
                                                    }
                                                        className="text-zinc-500 hover:opacity-60 flex items-center gap-2 cursor-pointer">
                                                        <PencilSimple />
                                                        <p>Editar cofre</p>
                                                    </div>
                                                }
                                            </PopoverBody>
                                        </PopoverContent>
                                    </Popover>
                                </div>
                            }
                        </div>

                        {
                            clientsVaultItems?.length > 0 ?

                                <TableContainer >
                                    <TableChakra size={'sm'}>
                                        <Tr>
                                            <Td>Titulo</Td>
                                            <Td>Usuário</Td>
                                            <Td>Senha</Td>
                                            <Td>Notas</Td>
                                            <Td>Criado em</Td>
                                            <Td>Versao</Td>
                                            <Td>Ultima alteração</Td>
                                            <Td>Acões</Td>
                                        </Tr>
                                        <Tbody>
                                            {
                                                clientsVaultItems?.map(vaultItem => {
                                                    return <Tr>
                                                        <Td>{vaultItem.title}</Td>
                                                        <Td>{vaultItem.username}</Td>
                                                        <Td onClick={() => {
                                                            setSelectedVaultItem(vaultItem);
                                                            setTypeToValid('showThisPassword');
                                                            showThisPassword?.includes(vaultItem?.id) ? setShowThisPassword(showThisPassword.filter(id => id !== vaultItem.id)) : setDetectModal('validPassword')
                                                        }}
                                                            className="cursor-pointer">{
                                                                showThisPassword.includes(vaultItem?.id) ?
                                                                    vaultItem.password
                                                                    :
                                                                    <>
                                                                        <EyeSlash size={20} />

                                                                    </>
                                                            }</Td>
                                                        <Td>{vaultItem.notes?.slice(0, 15).concat('...')}</Td>
                                                        <Td>{moment(vaultItem.created_at).utc(true).format("DD/MM/YYYY HH:mm:ss")}</Td>
                                                        <Td>
                                                            <div className="flex gap-2">
                                                                {vaultItem?.version}
                                                                {
                                                                    userData?.auth_typeAccess_id && userData?.auth_typeAccess_id < 3 &&

                                                                    <DotsThreeVertical onClick={() => {
                                                                        getHistoryVaultItem(vaultItem)
                                                                    }} className="cursor-pointer" />
                                                                }
                                                            </div>
                                                        </Td>
                                                        <Td>{allUsers.filter(user => user.id === vaultItem.auth_users_id)[0]?.fullname}</Td>
                                                        <Td>
                                                            <div className="flex flex-col md:flex-row gap-2">
                                                                <Eye size={20} className="cursor-pointer text-blue-600" onClick={() => {
                                                                    setNewClientVaultItem(vaultItem)
                                                                    setDetectModal('viewThisPassword')
                                                                }} />
                                                                <Pencil onClick={() => {
                                                                    setSelectedVaultItem(vaultItem)
                                                                    setTypeToValid('editThisPassword')
                                                                    setDetectModal('validPassword')
                                                                }} size={20} className="cursor-pointer text-primaryColor"
                                                                />
                                                                {
                                                                    userData?.auth_typeAccess_id && userData?.auth_typeAccess_id < 3 &&
                                                                    <Trash onClick={() => {
                                                                        setSelectedVaultItem(vaultItem)
                                                                        setDetectModal('validPassword')
                                                                        setTypeToValid('deleteThisPassword')
                                                                    }}
                                                                        size={20} className="cursor-pointer text-red-600"
                                                                    />
                                                                }
                                                            </div>
                                                        </Td>
                                                    </Tr>
                                                })
                                            }
                                        </Tbody>
                                    </TableChakra>
                                </TableContainer>
                                :
                                <></>
                        }
                    </div>
            }
        </Container>
    )
}