import { api } from "../../../config/api";
import { CreateProject, IProjects, UpdateProject } from "../../interfaces/pro/projects";

export class Projects{

    constructor(private token?: string){}

    async readAll(): Promise<IProjects[]> {
        const response = await api.get(`/api/v1/pro/projects`, {
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        })
        return response?.data?.data
    }

    async readByClient(reg_clients_id: number): Promise<IProjects[]> {
        const response = await api.get(`/api/v1/pro/projects/client/${reg_clients_id}`, {
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        })
        return response?.data?.data
    }

    async readById(id: number): Promise<IProjects> {
        const response = await api.get(`/api/v1/pro/projects/${id}`, {
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        })
        return response.data.data
    }

    async removeById(id: number): Promise<void> {
        await api.delete(`/api/v1/pro/projects/${id}`, {
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        })
    }

    async create(data: CreateProject): Promise<IProjects> {
        const response = await api.post(`/api/v1/pro/projects`, data, {
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        })
        return response.data.data
    }

    async updateById(id: number, data: UpdateProject): Promise<IProjects> {
        const response = await api.put(`/api/v1/pro/projects/${id}`, data, {
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        })
        return response.data.data
    }

}
