import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { useScreenModalContext } from '../../contexts/screenModalContext'

interface IModalProps {
    children: ReactNode
    height?: number
    autoHeight?: boolean
    width?: number
    size?: string
    overlayBg?: string
}

export function ScreenModal({ children, width, autoHeight, height, size, overlayBg }: IModalProps) {
    const { setDetectModal } = useScreenModalContext()

    return (
        <Modal trapFocus={true} closeOnOverlayClick={true} size={size} isOpen={true} onClose={() => setDetectModal('')} isCentered>
            <ModalOverlay backgroundColor={`${overlayBg}`}/>
            <ModalContent height={autoHeight ? '' : height ? height : 500} width={width} >
                <ModalCloseButton />
                <ModalBody>
                    { children }
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}