import { Container } from "../../../components/container/container";
import { useAuthContext } from '../../../contexts/authContext';
import { useNavigate } from 'react-router-dom';
import { NewTableDefault } from '../../../components/tables/newTableDefault';
import { maskCnpj } from "../../../utils/mask/cnpj";
import { maskCpf } from "../../../utils/mask/cpf";

export function Clients() {

    const { userData } = useAuthContext()
    const navigate = useNavigate()

    return (
        <Container title={{
            name: 'Clientes',
            hasButtonAction: true,
            buttonName: 'Novo cliente',
            onClickAction: () => navigate('/clients/create')
        }}>
            <NewTableDefault
                endpoint='/api/v1/reg/clients'
                columns={[ 
                    {
                        description: "Código",
                        field: "id",
                        type: "number",
                        orderBy: true,
                    },
                    {
                        description: "Nome",
                        field: "name",
                        type: "string",
                        orderBy: true,
                    },
                    {
                        description: "Documento",
                        type: "number",
                        orderBy: true,
                        newFieldValue: (e) => {
                            return e.personType === 'PF' 
                                ? maskCpf(e.cpf)
                                : maskCnpj(e.cnpj)
                        }
                    },
                    {
                        description: "E-mail",
                        field: "email",
                        type: "string",
                        orderBy: true,
                    }
                ]}
                token={userData?.token as string}
                usePagination={false}
                filters={[]}
                onClickInTableLine={(e: any) => navigate(`/clients/edit/${e.id}`)}
            />
        </Container>
    )
}