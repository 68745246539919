import { useEffect, useState, memo, useMemo } from "react";
import { useAuthContext } from "../../../contexts/authContext";
import { api } from '../../../config/api'
import moment from "moment";
import {
    Table as TableChackra, TableContainer, Tbody, Td, Th, Thead, Tr,
    useDisclosure,
    InputGroup,
    Select,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverArrow,
    CircularProgress,
    CircularProgressLabel,
    Avatar,
    AvatarGroup,
    ModalOverlay,
    Modal,
    ModalContent,
    ModalCloseButton,
    ModalFooter,
    ModalBody,
    RadioGroup,
    Radio,
    Text,
} from "@chakra-ui/react";
import { ButtonDefault } from "../../../components/button/buttonDefault";
import { LabelInput } from "../../../components/label/label.input";
import { useAlertContext } from "../../../contexts/alertContext";
import { IProjectsSteps } from "../../../services/interfaces/pro/projectsSteps";
//icons
import { ArrowBendUpRight, CaretDown, CaretUp, ChatDots, Files, Pencil, Play, Repeat, UserCirclePlus, X } from "@phosphor-icons/react";
import { InputHTMLDefault } from "../../../components/input/inputHtmlDefault";
import { ITasks, ITasksChecklist } from "../../../services/interfaces/pro/tasks";
import { Trash } from "@phosphor-icons/react/dist/ssr";
import { useScreenModalContext } from "../../../contexts/screenModalContext";
import Tippy from "@tippyjs/react";
import { IProjectsMembers } from "../../../services/interfaces/pro/projectsMembers";
import { tasksStatusList } from "../../../utils/list/tasksStatusList";
import { priorityList } from "../../../utils/list/priorityList";
import { IProjectsTags } from "../../../services/interfaces/pro/projectsTags";
import { useAllUsersContext } from "../../../contexts/allUsersContext";

export function ProjectTableView({ projectSteps, projectsMembers, projectsTags, onEditStep }: ITableView) {
    return (
        <>
            {
                projectSteps.map((step: any) => {
                    return <StepTable
                        stepId={step.id}
                        projectId={step.pro_projects_id}
                        projectSteps={projectSteps}
                        projectsTags={projectsTags}
                        projectsMembers={projectsMembers}
                        color={step.color}
                        onEditStep={(e: any) => onEditStep && onEditStep(e)}
                    />
                })
            }
        </>
    )
}

const StepTable: React.FC<IStep> = memo(({ projectId, stepId, color, projectSteps, projectsTags, projectsMembers, onEditStep }) => {

    const { userData } = useAuthContext()
    const { setAlert } = useAlertContext()
    const { setTaskModal } = useScreenModalContext()
    const [thisStep, setThisStep] = useState<IProjectsSteps>({} as IProjectsSteps)
    const [userIsExternal, setuserIsExternal] = useState<boolean>(userData?.auth_typeAccess_id && userData?.auth_typeAccess_id > 4 ? true : false)

    const [tasksSelecteds, setTasksSelecteds] = useState<string[]>([])

    const [internalModal, setInternalModal] = useState<string>('')
    const [showInternalModal, setShowInternalModal] = useState(false)

    const [toStepSelected, setToStepSelected] = useState<number>()
    const [limit, setLimit] = useState(20)

    const [startDateFilter, setStartDateFilter] = useState<'asc' | 'desc'>('asc');
    const [startDateFilterOld, setStartDateFilterOld] = useState<'asc' | 'desc'>('asc');
    const [dueDateFilter, setDueDateFilter] = useState<'asc' | 'desc'>('asc');
    const [dueDateFilterOld, setDueDateFilterOld] = useState<'asc' | 'desc'>('asc');
    const [finishDateFilter, setFinishDateFilter] = useState<'asc' | 'desc'>('asc');
    const [finishDateFilterOld, setFinishDateFilterOld] = useState<'asc' | 'desc'>('asc');
    const [priorityFilterOld, setPriorityFilterOld] = useState<'asc' | 'desc'>('asc');
    const [priorityFilter, setPriorityFilter] = useState<'asc' | 'desc'>('asc');
    const [statusFilterOld, setStatusFilterOld] = useState<'asc' | 'desc'>('asc');
    const [statusFilter, setStatusFilter] = useState<'asc' | 'desc'>('asc');
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        const pro_tasks = thisStep?.pro_tasks?.sort((a: any, b: any) => {
            const applySorting = (field: any, order: 'asc' | 'desc'): number => {
                // Handle null values for all fields
                if (a[field] == null && b[field] == null) return 0;
                if (a[field] == null) return 1;
                if (b[field] == null) return -1;

                // Valida quando é status prioridade para pegar valores corretos
                if (field === 'priority') {
                    a.priorityDetails = priorityList.find(p => p.id === a?.priority);
                    b.priorityDetails = priorityList.find(p => p.id === b?.priority);
                } else if (field === 'status') {
                    a.statusDetails = tasksStatusList.find(t => t.id === a.status);
                    b.statusDetails = tasksStatusList.find(t => t.id === b.status);
                }

                // Pega valores para comparar e ordenar tasks
                const aValue =
                    field === 'priority' ? a.priorityDetails.description
                        : field === 'status' ? a.statusDetails.description.split()
                            : field === 'auth_typeAccess_id' ? a.accessDetails.value
                                : typeof a[field] === 'string' ? a[field].toLowerCase()
                                    : a[field];

                const bValue =
                    field === 'priority' ? b.priorityDetails.description
                        : field === 'status' ? b.statusDetails.description.split()
                            : field === 'auth_typeAccess_id' ? b.accessDetails.value
                                : typeof b[field] === 'string' ? b[field].toLowerCase()
                                    : b[field];

                if (aValue < bValue) return order === 'asc' ? -1 : 1;
                if (aValue > bValue) return order === 'asc' ? 1 : -1;
                return 0;
            };

            if (startDateFilter !== startDateFilterOld) {
                return applySorting('startDate', startDateFilter);
            }

            if (dueDateFilter !== dueDateFilterOld) {
                return applySorting('dueDate', dueDateFilter);
            }

            if (finishDateFilter !== finishDateFilterOld) {
                return applySorting('finishDate', finishDateFilter);
            }

            if (priorityFilter !== priorityFilterOld) {
                return applySorting('priority', priorityFilter);
            }

            if (statusFilter !== statusFilterOld) {
                return applySorting('status', statusFilter);
            }

            return 0;
        });

        // Atualiza os valores antigos dos filtros
        setStartDateFilterOld(startDateFilter);
        setDueDateFilterOld(dueDateFilter);
        setFinishDateFilterOld(finishDateFilter);
        setPriorityFilterOld(priorityFilter);
        setStatusFilterOld(statusFilter);

        setThisStep({ ...thisStep, pro_tasks })
    }, [startDateFilter, dueDateFilter, finishDateFilter, priorityFilter, statusFilter]);

    async function getData() {
        try {
            setLoading(true)
            const stepData = await api.get(`/api/v1/pro/projectsSteps/${stepId}/project/${projectId}`, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            setThisStep(stepData?.data?.data)
        } catch (error) {
            console.log('Error on try to read Step:', error)
            setAlert({
                status: "error",
                title: "Erro",
                description: (error as any)?.response?.data?.message || "Erro ao criar tarefa"
            })
        } finally {
            setLoading(false)
        }
    }

    async function handleMoveTasks() {
        setLoading(true)
        try {
            await api.put(`/api/v1/pro/tasks/moveInBulk/project/${projectId}`, {
                pro_tasks: tasksSelecteds,
                pro_projectsSteps_id: toStepSelected
            }, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            setAlert({

                status: "success",
                title: "Sucesso",
                description: "Tarefa Movida"
            })
        } catch (error) {
            console.log(error)
            return setAlert({
                status: "error",
                title: "Erro",
                description: (error as any)?.response?.data?.message || "Erro ao mover tasks"
            })
        } finally {
            setTasksSelecteds([])
            getData()
        }
    }

    async function handleDeleteTasks() {
        const pro_tasks = tasksSelecteds

        try {
            await api.post(`/api/v1/pro/tasks/removeInBulk/project/${projectId}`, {
                pro_tasks: pro_tasks
            }, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            setAlert({

                status: "success",
                title: "Sucesso",
                description: "Tarefa excluida com sucesso"
            })
        } catch (error) {
            console.log(error)
            return setAlert({
                status: "error",
                title: "Erro",
                description: (error as any)?.response?.data?.message || "Erro ao remover tasks"
            })
        } finally {

            setTasksSelecteds([])
            getData()
        }
    }

    function externalCreateCallback(e: ITask) {
        thisStep.pro_tasks.push({
            ...e,
            pro_tasksFiles: [],
            pro_tasksTimes: [],
            pro_tasksComments: [],
            pro_tasksAssigned: [],
            pro_tasksChecklist: [],
            pro_tasksTags: []
        })
        setThisStep({ ...thisStep })
    }

    return <div className="w-full text-sm">
        <Modal trapFocus={false} closeOnOverlayClick={false} size={'sm'} isOpen={showInternalModal} onClose={() => setShowInternalModal(false)} isCentered>
            <ModalOverlay />
            {
                internalModal === 'moveTask' ?
                    <>
                        <ModalContent height={300} width={500} >
                            <ModalCloseButton />
                            <ModalBody>
                                <div className=" flex items-start flex-col gap-1 justify-center w-full h-full">
                                    <div className="w-full itens-center flex mb-6">
                                        <p className="font-semibold text-lg">Mover etapa</p>
                                    </div>
                                    {
                                        projectSteps.map(stepProject => {
                                            if (stepProject.id != stepId) {
                                                return <div className="flex gap-3 items-center">
                                                    <div style={{ background: stepProject.color }} className="w-4 h-4 border-t border-zinc-200"></div>
                                                    <RadioGroup>
                                                        <Radio isChecked={toStepSelected === stepProject.id} onClick={() => setToStepSelected(stepProject.id)} />
                                                    </RadioGroup>
                                                    <p>{stepProject.name}</p>
                                                </div>
                                            }
                                        })
                                    }
                                </div>
                            </ModalBody>
                            <ModalFooter className="flex gap-3">
                                <ButtonDefault onClick={() => setShowInternalModal(false)}>Cancelar</ButtonDefault>
                                <ButtonDefault onClick={() => handleMoveTasks()} success>Cadastrar</ButtonDefault>
                            </ModalFooter>
                        </ModalContent>
                    </>
                    :
                    internalModal === 'deleteTask' ?
                        <ModalContent className="flex flex-col" height={200} width={300} >
                            <ModalCloseButton />
                            <ModalBody className="flex flex-col">

                                <div className="flex items-start flex-col gap-1 justify-center w-full">
                                    <p className="font-semibold text-sm">Excluir tarefa</p>
                                </div>

                                <div className="flex items-center flex-col gap-1 justify-center h-full w-full">
                                    <p className="font-semibold text-base">Deseja realmente excluir tarefa?</p>
                                </div>
                            </ModalBody>
                            <ModalFooter className="flex gap-3 w-full items-center justify-center border">
                                <div className="w-full flex items-center justfy-between gap-2">
                                    <div className="w-full justify-center flex items-center">
                                        <ButtonDefault onClick={() => setShowInternalModal(false)}>Não</ButtonDefault>
                                    </div>
                                    <div className="w-full justify-center flex items-center">
                                        <ButtonDefault onClick={() => handleDeleteTasks()} success>Sim</ButtonDefault>
                                    </div>
                                </div>
                            </ModalFooter>
                        </ModalContent>
                        :
                        <></>
            }
        </Modal>
        <div className="flex gap-2 justify-between" >
            <div style={{ background: `${thisStep?.color ?? color}` }} className="px-2 py-1 flex justify-between text-white w-48 rounded-t-lg">
                {
                    loading ? <p className="text-sm text-white animate-pulse">Carregando...</p>
                        : <p className="text-white font-semibold">{thisStep?.name?.length > 30 ? thisStep?.name?.slice(0, 30).concat('...') : thisStep?.name}</p>
                }
                <Tippy
                    content={<span>Editar esta etapa</span>}
                    arrow={true}
                    animation='perspective'
                    placement='top-start'
                    delay={80}>
                    <div>
                        <Pencil size={16} className="cursor-pointer" onClick={() => onEditStep ? onEditStep(thisStep) : {}} />
                    </div>
                </Tippy>
            </div>
            {
                userIsExternal &&
                <p style={{ color: `${thisStep?.color ?? color}` }} className="text-xs cursor-pointer" onClick={(e) => setTaskModal({
                    pro_projects_id: projectId,
                    pro_tasks_id: '',
                    pro_projectsSteps_id: stepId,
                    inProjectView: true,
                    externalCreateCallback: externalCreateCallback
                })}>+ Adicionar tarefa</p>
            }
        </div>
        <div className={`shadow-lg w-full`} style={{ borderLeft: `4px solid ${thisStep?.color ?? color}` }}>
            {
                loading ?
                    <div className='w-full flex flex-col border items-center gap-2 justify-center py-5'>
                        <p className={`text-sm text-[${thisStep?.color ?? color}] animate-pulse`}>Carregando...</p>
                    </div>
                    :
                    <div>
                        <TableContainer>
                            <TableChackra variant='simple' size='sm'>
                                <Thead>
                                    <Tr className="border" height={8}>
                                        <Th fontSize={10} paddingLeft={9}>Nome</Th>
                                        <Th fontSize={10}>Tags</Th>
                                        <Th fontSize={10} onClick={() => setStartDateFilter(startDateFilter === 'asc' ? 'desc' : 'asc')}>
                                            <div className="flex cursor-pointer">
                                                Inicio {startDateFilter === 'asc' ? <CaretDown size={10} className="mt-1 ml-1 font-semibold" /> : <CaretUp className="mt-1 ml-1" />}
                                            </div>
                                        </Th>
                                        <Th fontSize={10} onClick={() => setDueDateFilter(dueDateFilter === 'asc' ? 'desc' : 'asc')}>
                                            <div className="flex cursor-pointer">
                                                Vencimento {dueDateFilter === 'asc' ? <CaretDown className="mt-1 ml-1 font-semibold" /> : <CaretUp className="mt-1 ml-1" />}
                                            </div>
                                        </Th>
                                        <Th fontSize={10} onClick={() => setFinishDateFilter(finishDateFilter === 'asc' ? 'desc' : 'asc')}>
                                            <div className="flex cursor-pointer">
                                                Conclusão {finishDateFilter === 'asc' ? <CaretDown className="mt-1 ml-1" /> : <CaretUp className="mt-1 ml-1" />}
                                            </div>
                                        </Th>
                                        <Th fontSize={10}>Checklist</Th>
                                        <Th fontSize={10} onClick={() => setPriorityFilter(priorityFilter === 'asc' ? 'desc' : 'asc')}>
                                            <div className="flex cursor-pointer">
                                                Prioridade {priorityFilter === 'asc' ? <CaretDown className="mt-1 ml-1" /> : <CaretUp className="mt-1 ml-1" />}
                                            </div>
                                        </Th>
                                        <Th fontSize={10} onClick={() => setStatusFilter(statusFilter === 'asc' ? 'desc' : 'asc')}>
                                            <div className="flex cursor-pointer">
                                                Status {statusFilter === 'asc' ? <CaretDown className="mt-1 ml-1" /> : <CaretUp className="mt-1 ml-1" />}
                                            </div>
                                        </Th>
                                        <Th fontSize={10}>Atribuído</Th>
                                        <Th fontSize={10}>Ações</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {
                                        thisStep?.pro_tasks?.map((task: any, index: number) => {
                                            if (index <= limit) {
                                                return (
                                                    <>
                                                        <TaskLine
                                                            step={thisStep}
                                                            task={task}
                                                            index={index}
                                                            onClickCheckTask={(e) => {
                                                                if (tasksSelecteds.find(t => t === e)) {
                                                                    return setTasksSelecteds([...tasksSelecteds.filter(t => t !== e)])
                                                                }
                                                                setTasksSelecteds([...tasksSelecteds, e])
                                                            }}
                                                            projectSteps={projectSteps}
                                                            projectsTags={projectsTags}
                                                            projectsMembers={projectsMembers}
                                                        />
                                                        {thisStep.pro_tasks.length === (index + 1) &&
                                                            <Tr>
                                                                <Td>
                                                                    <p style={{ color: `${thisStep?.color ?? color}` }} className="text-xs cursor-pointer" onClick={() => setTaskModal({
                                                                        pro_projects_id: projectId,
                                                                        pro_tasks_id: '',
                                                                        pro_projectsSteps_id: stepId,
                                                                        inProjectView: true,
                                                                        externalCreateCallback: externalCreateCallback
                                                                    })}>+ Adicionar tarefa</p>
                                                                </Td>
                                                            </Tr>
                                                        }
                                                    </>
                                                )
                                            }
                                        })
                                    }
                                    {
                                        thisStep?.pro_tasks?.length > limit &&
                                        <Tr>
                                            <Td>
                                                <p style={{ color: `${thisStep?.color ?? color}` }} className="font-semibold cursor-pointer" onClick={() => setLimit(prev => (prev + 20))}>Mostrar mais</p>
                                            </Td>
                                        </Tr>
                                    }
                                </Tbody>
                            </TableChackra>
                        </TableContainer>
                    </div>

            }
        </div>
        {
            tasksSelecteds.length > 0 &&
            <div className="w-full fixed z-10 left-6 bottom-4 flex justify-center items-center md:px-12">
                <div style={{ border: `1px solid ${thisStep?.color ?? color}` }} className={`p-2 md:p-0 animate-[fromUp.5s_ease-in-out] items-start md:items-center bg-white z-[99999] max-w-[1750px] justify-between flex shadow-xl shadow-zinc-300 rounded-lg h-16 flex-col md:flex-row bottom-4 md:w-full `}>
                    <div className="flex items-center justify-between md:justify-start h-12 md:h-full gap-3">
                        <div style={{ background: `${thisStep?.color ?? color}` }} className="hidden md:flex items-center justify-center rounded-r-md h-full px-8">
                            <p className="font-semibold text-white md:text-xl">{tasksSelecteds.length}</p>
                        </div>
                        <div style={{ border: `2px solid ${thisStep?.color ?? color}` }} className="flex md:hidden items-center justify-center w-6 h-6 rounded-full p-1">
                            <p style={{ color: `${thisStep?.color ?? color}` }} className="font-semibold text-white md:text-xl">{tasksSelecteds.length}</p>
                        </div>
                        <p>Selecionado (s)</p>
                    </div>
                    <div className="flex gap-3 items-center justify center px-8">
                        <div onClick={() => { setInternalModal('moveTask'); setShowInternalModal(true) }} className="cursor-pointer flex gap-2 items-center justify-center">
                            <ArrowBendUpRight size={30} />
                            <p>Mover</p>
                        </div>
                        <div onClick={() => { setInternalModal('deleteTask'); setShowInternalModal(true) }} className="cursor-pointer flex gap-2 items-center justify-center">
                            <Trash size={30} />
                            <p>Excluir</p>
                        </div>
                    </div>
                </div>
            </div>
        }
    </div>

})

const TaskLine: React.FC<ITask> = memo(({ step, task, projectsTags, projectSteps, projectsMembers, onClickCheckTask }) => {

    const { setTaskModal } = useScreenModalContext()
    const { allUsers } = useAllUsersContext()
    const { setAlert } = useAlertContext()
    const { userData } = useAuthContext()
    const [userIsExternal, setuserIsExternal] = useState<boolean>(userData?.auth_typeAccess_id && userData?.auth_typeAccess_id > 4 ? true : false)
    const [thisTask, setThisTask] = useState<ITasks>(task)
    const [thisProjectTags, setThisProjectTags] = useState<IProjectsTags[]>(projectsTags)
    const [checked, setChecked] = useState(false)
    const { onClose } = useDisclosure()
    const statusPopover = useDisclosure()
    const priorityPopover = useDisclosure()

    const priorityData = priorityList.find(p => p.id === thisTask.priority)
    const statusData = tasksStatusList.find(s => s.id === thisTask.status)

    useEffect(() => {
        setThisTask(task)
    }, [task])

    useEffect(() => {
        setThisProjectTags(projectsTags)
    }, [projectsTags])

    async function handleTaskEdit() {
        setThisTask({ ...thisTask })
        try {
            await api.put(`/api/v1/pro/tasks/${thisTask.id}/project/${step.pro_projects_id}`, {
                title: thisTask?.title,
                description: thisTask?.description ? thisTask.description : undefined,
                priority: thisTask?.priority ? thisTask?.priority : undefined,
                dueDate: thisTask?.dueDate ? thisTask.dueDate : undefined,
                startDate: thisTask?.startDate ? thisTask.startDate : undefined,
                finishDate: thisTask?.finishDate ? thisTask.finishDate : undefined,
                isRecurrency: thisTask?.isRecurrency ? thisTask?.isRecurrency : false,
                isRecurrencyType: thisTask?.isRecurrency && thisTask?.isRecurrencyType ? thisTask?.isRecurrencyType : "0",
                removed: thisTask?.removed,
                status: (thisTask?.status || thisTask?.status === 0) ? thisTask?.status : undefined,
            }, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })
        } catch (error) {
            console.log('Error on try to edit task:', error)
            setAlert({
                status: 'error',
                title: 'Erro',
                description: (error as any)?.response?.data?.message || 'Erro ao editar tarefa'
            })
        }
    }

    function calculatePercentageOfChecklist(checklist: ITasksChecklist[] = []) {
        const checkedItems = checklist.filter(i => i.check === true).length
        const calc = (checkedItems * 100) / checklist.length
        return calc
    }

    function formatUncheckedAndCheckedItems(checklist: ITasksChecklist[] = []) {
        const checkedItems = checklist.filter(i => i.check === true).length
        return checklist.length + '/' + checkedItems
    }

    function alterTaskPriority(thisPriority: string) {
        thisTask.priority = thisPriority
        setThisTask({ ...thisTask })
        handleTaskEdit()
        onClose()
    }

    function alterTaskStatus(status: number) {
        let setStatus = status
        let setDueDate = thisTask?.dueDate
        if (thisTask?.pro_tasksTimes?.length === 0 && status === 1) {
            return setAlert({
                status: 'error',
                title: 'Erro',
                description: 'Não é possível concluir uma tarefa que não possui apontamento de tempo.'
            })
        }
        if (status === 1) {
            thisTask.finishDate = moment().utc(false).toDate()
        }

        if (thisTask?.isRecurrency) {

            if (thisTask?.finishDate && status == 1 && thisTask?.isRecurrency && thisTask?.isRecurrencyType === "M") {
                setThisTask({ ...thisTask, finishDate: null })
                setDueDate = moment(thisTask?.dueDate, 'YYYY-MM-DD').utc(false).add(1, 'month').toDate()
                setStatus = 0
            } else if (thisTask?.finishDate && status == 1 && thisTask?.isRecurrency && thisTask?.isRecurrencyType === "W") {
                setDueDate = moment(thisTask?.dueDate, 'YYYY-MM-DD').utc(false).add(1, 'week').toDate()
                setStatus = 0
            } else if (thisTask?.finishDate && status == 1 && thisTask?.isRecurrency && thisTask?.isRecurrencyType === "D") {
                setDueDate = moment(thisTask?.dueDate, 'YYYY-MM-DD').utc(false).add(1, 'days').toDate()
                setStatus = 0
            }
            if (status == 1) {
                setAlert({
                    status: 'success',
                    title: 'Sucesso',
                    description: 'Tarefa marcada com concluída, verifique os novos prazos!'
                })
            }
        }
        thisTask.status = setStatus
        thisTask.dueDate = setDueDate
        thisTask.finishDate = null
        thisTask.pro_tasksChecklist.map(checklist => { (checklist.check = false) })

        setThisTask({ ...thisTask })
        handleTaskEdit()
        onClose()
    }

    async function handleRemoveAssigned(auth_users_id: number) {
        try {

            const assignedId = thisTask.pro_tasksAssigned.filter((assigned: any) => assigned.auth_users_id === auth_users_id)[0]
            thisTask.pro_tasksAssigned = thisTask.pro_tasksAssigned.filter((assigned: any) => assigned.auth_users_id !== auth_users_id)
            setThisTask({ ...thisTask })

            await api.delete(`/api/v1/pro/tasksAssigned/${assignedId.id}/project/${step?.pro_projects_id}`, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

        } catch (error) {
            console.log('Error on try to create new item on checklist:', error)
        }
    }

    async function handleNewTaskAssigned(auth_users_id: number, fullname: string) {
        let originalTask = structuredClone(thisTask)
        try {
            thisTask.pro_tasksAssigned.push({
                auth_users_id,
                auth_users: { fullname },
                pro_tasks_id: thisTask.id,
                id: ''
            })
            setThisTask({ ...thisTask })

            const data = await api.post(`/api/v1/pro/tasksAssigned/project/${step?.pro_projects_id}`, {
                pro_tasks_id: thisTask.id,
                auth_users_id
            }, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })
            originalTask.pro_tasksAssigned.push({ ...data.data.data, auth_users: { fullname: allUsers.find(user => user.id === data.data.data.auth_users_id)?.fullname } })
            setThisTask({ ...originalTask })
        } catch (error) {
            setThisTask({ ...originalTask })
            console.log('Error on try to create new item on checklist:', error)
        }
    }

    function handleTotalCount(item: any) {
        if (item) {

            const sec = item.reduce((acumulator: any, current: any) => Number(acumulator) + Number(current.ammountSeconds), 0)
            return `${Math.round(sec / 3600) < 10 ? '0' + Math.floor(sec / 3600) : Math.round(sec / 3600)}:${Math.round(sec % 3600 / 60) < 10 ? '0' + Math.round(sec % 3600 / 60) : Math.round(sec % 3600 / 60)}`
        }
    }

    async function handleUpdateDates(type: string, stepId: number, taskId: string, date: string) {
        if (type === 'startDate') {

            thisTask.startDate = moment(date).utc(false).toDate()
        }
        if (type === 'dueDate') {

            thisTask.dueDate = moment(date).utc(false).toDate()
        }
        if (type === 'finishDate') {

            thisTask.finishDate = moment(date).utc(false).toDate()
        }

        setThisTask({ ...thisTask })
    }

    async function handleAddTaskTag(tag: any) {
        const originalTask = structuredClone(thisTask)
        try {
            const tagData = await api.post(`/api/v1/pro/tasksTags/project/${step.pro_projects_id}`, {
                pro_projectsTags_id: tag.id,
                pro_tasks_id: thisTask.id
            }, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            thisTask.pro_tasksTags.push(tagData?.data?.data)
            setThisTask({ ...thisTask })

        } catch (error) {
            setThisTask({ ...originalTask })
            console.log('Error on try to delete an attached file:', error)
            setAlert({
                status: 'error',
                title: 'Erro',
                description: (error as any)?.response?.data?.message || 'Erro ao adicionar tag'
            })
        }
    }

    async function handleRemoveTaskTag(tag: any) {
        let historicTags = structuredClone(thisTask.pro_tasksTags)
        try {
            thisTask.pro_tasksTags = thisTask.pro_tasksTags.filter((projectsTag: any) => projectsTag.pro_projectsTags_id !== tag.id)
            setThisTask({ ...thisTask })

            await api.delete(`/api/v1/pro/tasksTags/task/${thisTask.id}/projectTag/${tag.id}/project/${step.pro_projects_id}`, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

        } catch (error) {
            thisTask.pro_tasksTags = historicTags
            setThisTask({ ...thisTask })
            setAlert({
                status: 'error',
                title: 'Erro',
                description: (error as any)?.response?.data?.message || 'Erro ao remover tag'
            })
        }
    }

    return <>
        <Tr height={9}>
            {/* checklist e nome da tarefa */}
            <Td fontSize={12}>
                <div className="flex items-center justify-start gap-2">
                    <input type="checkbox" checked={checked} className="task-checkboxes" onChange={(e) => {
                        onClickCheckTask(task.id)
                        setChecked(!checked)
                    }}></input>
                    <Tippy
                        content={<span>{thisTask.title}</span>}
                        arrow={true}
                        animation='perspective'
                        placement='top-start'
                        delay={80}>
                        <p onClick={() => {
                            setTaskModal({
                                pro_projects_id: step?.pro_projects_id,
                                pro_tasks_id: task.id,
                                pro_projectsSteps_id: task?.pro_projectsSteps_id,
                                pro_projectsMembers: projectsMembers,
                                pro_projectsTags: projectsTags,
                                pro_projectsSteps: projectSteps,
                                taskData: task,
                                inProjectView: true,
                                openTab: 'task',
                                externalUpdateCallback: (e: ITasks) => setThisTask({ ...e })
                            })
                        }}
                            className="cursor-pointer">{thisTask?.title?.length > 45 ? thisTask?.title.slice(0, 45).concat('....') : thisTask.title}
                        </p>
                    </Tippy>
                </div>
            </Td>
            {/* tags da tarefa */}
            <Td fontSize={12}>
                <Popover onClose={onClose} variant={'responsive'} size={'sm'} >
                    <PopoverTrigger>
                        {
                            thisTask?.pro_tasksTags?.length > 0 ?

                                <div className="flex flex-row max-w-[100px] overflow-hidden">
                                    {
                                        thisTask?.pro_tasksTags?.map((tag: any, index: number) => {
                                            let thisTag = projectsTags?.filter((projectTag: any) => tag.pro_projectsTags_id === projectTag.id)[0]
                                            return <Tippy
                                                content={<span>{thisTag?.description}</span>}
                                                arrow={true}
                                                animation='perspective'
                                                placement='top-start'
                                                delay={80}>
                                                <div className="px-2 py-1 rounded-md" style={{ background: `${thisTag.color}`, translate: `-${5 * index}px 0px` }} >
                                                    <p className="text-white font-semibold w-full h-full">{thisTag?.description?.slice(0, 1)}</p>
                                                </div>
                                            </Tippy>

                                        })
                                    }

                                </div>
                                :
                                <p className="cursor-pointer w-12">-</p>
                        }

                    </PopoverTrigger>
                    <PopoverContent>
                        <PopoverArrow />
                        <PopoverBody>
                            <div className="flex flex-col gap-2 items-center justify-center">
                                {
                                    projectsTags?.length > 0 ?
                                        projectsTags?.map((tag: any) => {
                                            let hasThisTag = thisTask?.pro_tasksTags?.filter(taskTag => taskTag.pro_projectsTags_id === tag?.id)[0]
                                            return hasThisTag ?
                                                <div style={{ background: `${tag.color}` }} className="flex gap-2 items-center">
                                                    <p onClick={() => handleAddTaskTag(tag)} className="px-4 py-1 rounded-md hover:cursor-pointer hover:brightness-90 text-white font-semibold">{tag?.description}</p>
                                                    <div className="text-white font-semibold cursor-pointer border-l px-1">
                                                        <X onClick={() => !userIsExternal && handleRemoveTaskTag(tag)} size={12} />
                                                    </div>
                                                </div>
                                                :
                                                <p onClick={() => !userIsExternal && handleAddTaskTag(tag)} style={{ background: `${tag.color}` }} className="px-4 py-1 rounded-md hover:cursor-pointer hover:brightness-90 text-white font-semibold">{tag?.description}</p>
                                        })
                                        :
                                        <p className="text-sm">Sem tags cadastradas</p>
                                }
                            </div>
                        </PopoverBody>
                    </PopoverContent>
                </Popover>

            </Td>
            {/* data de Inicio */}
            <Td fontSize={12}>
                <Popover onClose={onClose} variant={'responsive'} size={'sm'} >
                    <PopoverTrigger>
                        <p className="cursor-pointer">{thisTask?.startDate ? moment(thisTask.startDate).utc(false).format("DD/MM/YYYY") : '-'}</p>
                    </PopoverTrigger>
                    <PopoverContent>
                        <PopoverArrow />
                        <PopoverBody>
                            <div className="flex flex-col gap-2 items-center justify-center">
                                <InputHTMLDefault
                                    disabled={userIsExternal}
                                    onChange={(e) => handleUpdateDates('startDate', step.id, thisTask.id, e.target.value)}
                                    id={`${task.id}`}
                                    value={thisTask.startDate ? moment(thisTask.startDate).utc(false).format("YYYY-MM-DD") : ''}
                                    type="date"
                                    onBlur={() => handleTaskEdit()}
                                />
                            </div>
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
            </Td>
            {/* data de vencimento */}
            <Td fontSize={12}>
                <Popover onClose={() => {
                    handleTaskEdit();
                    onClose()
                }} variant={'responsive'} size={'sm'} >
                    <PopoverTrigger>
                        <div className="flex items-center gap-1 justify-start">
                            <p className="cursor-pointer">{thisTask?.dueDate ? moment(thisTask?.dueDate).utc(false).format("DD/MM/YYYY") : '-'}</p>
                            {
                                thisTask?.isRecurrency &&
                                <Tippy
                                    content={<span>Tarefa recorrente</span>}
                                    arrow={true}
                                    animation='perspective'
                                    placement='top-start'
                                    delay={80}>
                                    <div className="p-1 bg-zinc-200 rounded-full">
                                        <a className="py-1 bg-zinc-200 rounded-full"><Repeat /></a>
                                    </div>
                                </Tippy>
                            }
                        </div>
                    </PopoverTrigger>
                    <PopoverContent>
                        <PopoverArrow />
                        <PopoverBody>
                            <div className="flex gap-4 items-center justify-center">
                                <InputHTMLDefault
                                    disabled={userIsExternal}
                                    onChange={(e) => handleUpdateDates('dueDate', step.id, task.id, e.target.value)}
                                    id={`${task.id}`}
                                    value={thisTask.dueDate ? moment(thisTask.dueDate).utc(false).format("YYYY-MM-DD") : ''}
                                    type="date"
                                />
                                <InputGroup className="flex gap-2">
                                    <LabelInput>Recorrente</LabelInput>
                                    <Select onChange={e => setThisTask({ ...thisTask, isRecurrency: (e.target.value === "M" || e.target.value === "W" || e.target.value === "D") ? true : false, isRecurrencyType: e.target.value as "M" | "W" | "D" | undefined })} height={8} width={44} >
                                        <option value={undefined} selected={thisTask?.isRecurrency ? false : true} className="text-sm">Selecione</option>
                                        <option value="D" selected={thisTask?.isRecurrencyType == 'D' ?? false} className="text-sm">Diário</option>
                                        <option value="M" selected={thisTask?.isRecurrencyType == 'M' ?? false} className="text-sm">Mensal</option>
                                        <option value="W" selected={thisTask?.isRecurrencyType == "W" ?? false} className="text-sm">Semanal</option>
                                    </Select>
                                </InputGroup>
                            </div>
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
            </Td>
            {/* data conclusao */}
            <Td fontSize={12}>
                {
                    thisTask?.isRecurrency ?
                        <p className="cursor-not-allowed">{thisTask?.finishDate ? moment(thisTask?.finishDate).utc(false).format("DD/MM/YYYY") : '-'}</p>
                        :
                        <Popover onClose={onClose} variant={'responsive'} size={'sm'} >
                            <PopoverTrigger>
                                <p className="cursor-pointer">{thisTask?.finishDate ? moment(thisTask?.finishDate).utc(false).format("DD/MM/YYYY") : '-'}</p>
                            </PopoverTrigger>
                            <PopoverContent>
                                <PopoverArrow />
                                <PopoverBody>
                                    <div className="flex flex-col gap-2 items-center justify-center">
                                        <InputHTMLDefault
                                            disabled={userIsExternal}
                                            onChange={(e) => handleUpdateDates('finishDate', step.id, task.id, e.target.value)}
                                            id={`${task.id}`}
                                            value={thisTask.finishDate ? moment(thisTask.finishDate).utc(false).format("YYYY-MM-DD") : ''}
                                            type="date"
                                            onBlur={() => {
                                                if (thisTask.pro_tasksTimes.length === 0) {
                                                    setAlert({
                                                        status: 'error',
                                                        title: 'Erro',
                                                        description: 'Você precisa apontar ao menos 1 lançamento de tempo'
                                                    })
                                                    return
                                                } else if (thisTask.status !== 1) {

                                                    setAlert({
                                                        status: 'error',
                                                        title: 'Erro',
                                                        description: 'Você deve concluir a tarefa para ter a data de conclusão'
                                                    })
                                                    return
                                                }
                                                else {
                                                    handleTaskEdit()
                                                }
                                            }}
                                        />
                                    </div>
                                </PopoverBody>
                            </PopoverContent>
                        </Popover>
                }
            </Td>
            {/* checklist */}
            <Td className="cursor-pointer" onClick={() => {
                setTaskModal({
                    pro_projects_id: step?.pro_projects_id,
                    pro_tasks_id: task.id,
                    pro_projectsSteps_id: task?.pro_projectsSteps_id,
                    pro_projectsMembers: projectsMembers,
                    pro_projectsTags: projectsTags,
                    pro_projectsSteps: projectSteps,
                    taskData: task,
                    inProjectView: true,
                    openTab: 'task',
                    externalUpdateCallback: (e: ITasks) => setThisTask({ ...e })
                })
            }} fontSize={12}>
                {
                    thisTask?.pro_tasksChecklist?.length > 0 ?
                        <CircularProgress value={calculatePercentageOfChecklist(thisTask?.pro_tasksChecklist)} color='green.400' size={'30px'}>
                            <CircularProgressLabel>
                                {formatUncheckedAndCheckedItems(thisTask?.pro_tasksChecklist)}
                            </CircularProgressLabel>
                        </CircularProgress>
                        :
                        <>-</>

                }
            </Td>
            {/* prioridade */}
            <Td textAlign={'center'} fontSize={12}>
                <Popover isOpen={priorityPopover.isOpen} onClose={priorityPopover.onClose} variant={'responsive'} size={'sm'} >
                    <PopoverTrigger>
                        <div className="cursor-pointer hover:brightness-90 duration-200 transition-all">
                            <div onClick={priorityPopover.onOpen} className={`px-1 py-2 text-center font-semibold w-fit text-xs flex items-center justify-center rounded-lg ${priorityData?.bgColor} ${priorityData?.textColor}`}>{priorityData?.description}</div>
                        </div>
                    </PopoverTrigger>
                    <PopoverContent>
                        <PopoverArrow />
                        <PopoverBody>
                            <div className="flex flex-col gap-1 items-center justify-center">
                                {
                                    priorityList.map((p) => {
                                        return <div onClick={() => { !userIsExternal && alterTaskPriority(p.id); priorityPopover.onClose() }} className={`w-20 flex items-center justify-center rounded-lg ${p.bgColor} ${p.textColor} p-2 cursor-pointer`}>{p.description}</div>
                                    })
                                }
                            </div>
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
            </Td>
            {/* status */}
            <Td textAlign={'center'} fontSize={12}>
                <Popover isOpen={statusPopover.isOpen} onClose={statusPopover.onClose} variant={'responsive'} size={'sm'} >
                    <PopoverTrigger>
                        <div onClick={statusPopover.onOpen}>

                            <Tippy
                                content={<span>{statusData?.description}</span>}
                                arrow={true}
                                animation='perspective'
                                placement='top-start'
                                delay={80}>
                                <div className={`px-2 py-2 w-10 cursor-pointer  text-center text-xs font-semibold flex items-center justify-center rounded-lg ${statusData?.bgColor} text-white`}>{statusData?.description.slice(0, 1)}</div>
                            </Tippy>
                        </div>
                    </PopoverTrigger>
                    <PopoverContent>
                        <PopoverArrow />
                        <PopoverBody>
                            <div className="flex flex-col gap-1 items-center justify-center">
                                {
                                    tasksStatusList.map(status => {
                                        return <div onClick={() => { !userIsExternal && alterTaskStatus(status.id); statusPopover.onClose() }} key={status.id} className={`px-1 cursor-pointer py-2 text-center w-32 text-xs font-semibold flex items-center justify-center rounded-lg ${status.bgColor} text-white`}>{status.description}</div>
                                    })
                                }
                            </div>
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
            </Td>
            {/* atribuido */}
            <Td fontSize={12}>
                <Popover onClose={onClose} variant={'responsive'} size={'sm'} >
                    <PopoverTrigger>
                        <div>
                            {
                                thisTask?.pro_tasksAssigned?.length > 0 ?

                                    <AvatarGroup size='sm' max={3}>
                                        {
                                            thisTask?.pro_tasksAssigned?.map((taskAssigned: any) => {
                                                let user = allUsers?.find(u => u?.id === taskAssigned?.auth_users_id)
                                                return <Avatar className="cursor-pointer" name={taskAssigned?.auth_users?.fullname} src={`${user?.picture}`} />
                                            })
                                        }
                                    </AvatarGroup>
                                    :
                                    <Tippy
                                        content={<span>Vazio - Atribuir</span>}
                                        arrow={true}
                                        animation='perspective'
                                        placement='top-start'
                                        delay={80}>
                                        <div>
                                            <UserCirclePlus size={28} className="cursor-pointer text-primaryColor" />
                                        </div>
                                    </Tippy>
                            }
                        </div>
                    </PopoverTrigger>
                    <PopoverContent>
                        <PopoverArrow />
                        <PopoverBody>
                            <div className="flex flex-col w-56 gap-3">
                                <p className="text-base font-semibold">Atribuído</p>
                                {

                                    thisTask?.pro_tasksAssigned.length > 0 ?
                                        thisTask?.pro_tasksAssigned?.map((taskAssigned: any) => {
                                            let user = allUsers?.find(u => u?.id === taskAssigned?.auth_users_id)
                                            return <div className="flex gap-2 w-full items-center justify-between">
                                                <div className="flex gap-2 items-center justify-start">
                                                    <Avatar className="" size={'sm'} name={taskAssigned?.auth_users?.fullname} src={`${user?.picture}`} />
                                                    <p className="font-semibold">
                                                        {taskAssigned?.auth_users?.fullname?.length > 25 ? taskAssigned?.auth_users?.fullname.slice(0, 25) + '...' : taskAssigned?.auth_users?.fullname}
                                                    </p>
                                                </div>
                                                <X onClick={() => !userIsExternal && handleRemoveAssigned(Number(taskAssigned.auth_users_id))} className="color-red-600 cursor-pointer" />
                                            </div>
                                        })
                                        :
                                        <></>
                                }
                                <div className="border-t flex flex-col gap-2 pt-3 border-zinc-200">
                                    <p className="text-base font-semibold">Não atribuídos</p>
                                    {
                                        projectsMembers?.length > 0 ?
                                            projectsMembers.map((member: any) => {
                                                if (!thisTask?.pro_tasksAssigned.map((userAssigned: any) => { return userAssigned.auth_users_id }).includes(member.auth_users_id)) {
                                                    let user = allUsers?.find(u => u?.id === member?.auth_users_id)
                                                    return <p
                                                        onClick={() => !userIsExternal && handleNewTaskAssigned(Number(user?.id), user?.fullname as string)}
                                                        className="hover:font-semibold cursor-pointer"
                                                    >
                                                        {user?.fullname}
                                                    </p>
                                                }
                                            })
                                            :
                                            <></>

                                    }
                                </div>
                            </div>
                        </PopoverBody>
                    </PopoverContent>
                </Popover>

            </Td>
            {/* acoes */}
            <Td fontSize={12}>
                <div className="flex gap-1">
                    {/* apontamentos */}
                    <Tippy
                        content={<span>Apontamentos de tempo</span>}
                        arrow={true}
                        animation='perspective'
                        placement='top-start'
                        delay={80}>
                        <div
                            className="cursor-pointer gap-1 flex items-center"
                            onClick={() => setTaskModal({
                                pro_projects_id: step.pro_projects_id,
                                pro_projectsSteps_id: step.id,
                                pro_projectsSteps: projectSteps,
                                pro_projectsMembers: projectsMembers,
                                pro_projectsTags: projectsTags,
                                pro_tasks_id: task.id,
                                inProjectView: true,
                                openTab: 'times',
                                taskData: task,
                                externalUpdateCallback: (e: ITasks) => setThisTask({ ...e })
                            })}
                        >
                            <Play size={20} />
                            <div className="px-1 bg-zinc-200 rounded-full ">
                                <Text fontSize={10} className="">
                                    {
                                        handleTotalCount(thisTask.pro_tasksTimes)
                                    }
                                </Text>
                            </div>
                        </div>
                    </Tippy>
                    {/* comentários */}
                    <Tippy
                        content={<span>Comentários</span>}
                        arrow={true}
                        animation='perspective'
                        placement='top-start'
                        delay={80}>
                        <div
                            className="cursor-pointer gap-1 flex items-center"
                            onClick={() => setTaskModal({
                                pro_projects_id: step.pro_projects_id,
                                pro_projectsSteps_id: step.id,
                                pro_projectsSteps: projectSteps,
                                pro_projectsMembers: projectsMembers,
                                pro_projectsTags: projectsTags,
                                pro_tasks_id: task.id,
                                inProjectView: true,
                                openTab: 'comentaries',
                                taskData: task,
                                externalUpdateCallback: (e: ITasks) => setThisTask({ ...e })
                            })}
                        >
                            <ChatDots size={20} />
                            <div className="px-1 bg-zinc-200 rounded-full ">
                                <Text fontSize={10}>
                                    {
                                        thisTask.pro_tasksComments?.length
                                    }
                                </Text>
                            </div>
                        </div>
                    </Tippy>
                    {/* arquivos */}
                    <Tippy
                        content={<span>Arquivos</span>}
                        arrow={true}
                        animation='perspective'
                        placement='top-start'
                        delay={80}>
                        <div
                            className="cursor-pointer gap-1 flex items-center"
                            onClick={() => setTaskModal({
                                pro_projects_id: step.pro_projects_id,
                                pro_projectsSteps_id: step.id,
                                pro_projectsSteps: projectSteps,
                                pro_projectsMembers: projectsMembers,
                                pro_projectsTags: projectsTags,
                                pro_tasks_id: task.id,
                                inProjectView: true,
                                openTab: 'files',
                                taskData: task,
                                externalUpdateCallback: (e: ITasks) => setThisTask({ ...e })
                            })}
                        >
                            <Files size={20} />
                            <div className="px-1 bg-zinc-200 rounded-full ">
                                <Text fontSize={10} className="">
                                    {
                                        thisTask?.pro_tasksFiles?.length
                                    }
                                </Text>
                            </div>
                        </div>
                    </Tippy>
                </div>
            </Td>
        </Tr>
    </>

})

interface ITableView {
    projectSteps: IProjectsSteps[]
    projectsTags: IProjectsTags[]
    projectsMembers: IProjectsMembers[]
    onEditStep?: (e: any) => any
}

interface IStep {
    projectId: number,
    projectSteps: IProjectsSteps[]
    projectsTags: IProjectsTags[]
    projectsMembers: IProjectsMembers[]
    stepId: number,
    color?: string
    onEditStep?: (e: any) => any
}

interface ITask {
    step: any,
    task: ITasks,
    index: number
    projectSteps: IProjectsSteps[]
    projectsTags: IProjectsTags[]
    projectsMembers: IProjectsMembers[]
    onClickCheckTask: (id: string) => void
}
