import { useParams } from "react-router-dom";
import { Container } from "../../../components/container/container";
import { NewTableDefault } from "../../../components/tables/newTableDefault";
import { useAuthContext } from "../../../contexts/authContext";
import { Avatar, AvatarGroup } from "@chakra-ui/react";
import Tippy, { tippy } from "@tippyjs/react";
import 'tippy.js/animations/perspective.css';
import 'tippy.js/themes/light.css';
import 'tippy.js/dist/tippy.css';
import { useEffect, useState } from "react";
import { api } from "../../../config/api";
import { Loader } from "../../../components/loader/loader";
import { tasksStatusList } from "../../../utils/list/tasksStatusList";
import { useScreenModalContext } from "../../../contexts/screenModalContext"; 

export function ViewTasks() {
    const { userData } = useAuthContext()
    const { setTaskModal } = useScreenModalContext()
    const { status, priority } = useParams()
    const [allUsers, setAllUsers] = useState<any[]>([])
    const [loading, setLoading] = useState(true)

    async function getData() {

        try {
            const users = await api.get(`/api/v1/auth/users`, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            setAllUsers(users.data.data)
            setLoading(false)

        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    useEffect(() => {
        getData()
    }, [])

    function handleHoverAvatar(item: any) {
        tippy(`#tippy${item.id}`,
            {
                content: `<span>${item.fullname}</span>`,
                arrow: true,
                animation: 'perspective',
                placement: 'left-end',
                delay: 80,
                allowHTML: true,
            })
    }

    return (
        <Container>
            {
                loading ?
                    <Loader />
                    :
                    <>
                        <h1 className="text-lg font-semibold mb-10">Listagem de tarefas</h1>
                        <NewTableDefault
                            endpoint={priority ? `/api/v1/pro/tasks/viewTasks/priority/${priority}` : status === 'lateTasks' ? `/api/v1/pro/tasks/viewTasks/lateTasks` : `/api/v1/pro/tasks/viewTasks/${status}` }
                            token={`${userData?.token}`}
                            onClickInTableLine={(e) => setTaskModal({
                                pro_projects_id: e.pro_projectsSteps.pro_projects_id,
                                pro_tasks_id: e.id,
                                pro_projectsSteps_id: e.pro_projectsSteps.id,
                            })}
                            columns={
                                [
                                    {
                                        description: 'Projeto',
                                        type: "string",
                                        field: 'projectName',
                                        newFieldValue: (e) => e.pro_projectsSteps.pro_projects.name,
                                        orderBy: true,
                                    },
                                    {
                                        description: 'Titulo',
                                        type: "string",
                                        field: 'title',
                                        orderBy: true,
                                    },
                                    {
                                        description: 'Vencimento',
                                        type: "date",
                                        field: 'dueDate',
                                        orderBy: true,
                                    },
                                    {
                                        description: 'Responsável',
                                        type: "string",
                                        field: 'pro_user_id',
                                        newFieldValue: (e) =>

                                            allUsers?.length > 0 ?
                                                <AvatarGroup size='sm' max={2}>
                                                    {
                                                        allUsers.map(users => {
                                                            return e.pro_projectsSteps.pro_tasks.filter((task: any) => task.id === e.id)[0]?.pro_tasksAssigned?.filter((assigned: any) => assigned.auth_users_id === users?.id)[0]
                                                                &&
                                                                <Avatar
                                                                    id={`tippy${users.id}`}
                                                                    onMouseEnter={() => { handleHoverAvatar(users) }}
                                                                    style={{ maxHeight: '2.9rem' }}
                                                                    size='sm'
                                                                    src={users.picture ? users.picture : undefined}
                                                                    name={users?.fullname}
                                                                ></Avatar>
                                                        })
                                                    }
                                                </AvatarGroup>
                                                : <Avatar size='sm' />
                                    },
                                    {
                                        description: 'Prioridade',
                                        type: "string",
                                        field: 'priority',
                                        orderBy: true,
                                        newFieldValue: (e) => e.priority == 'high' ?
                                            <a className="p-1 rounded-md text-xs font-semibold bg-red-200 text-red-500">Urgente</a> :
                                            e.priority == 'medium' ?
                                                <a className="p-1 rounded-md text-xs font-semibold bg-yellow-300 text-yellow-500">Moderado</a> :
                                                e.priority == 'low' ?
                                                    <a className="p-1 rounded-md text-xs font-semibold bg-zinc-300 text-white">Baixo</a> :
                                                    '-'
                                    },
                                    {
                                        description: 'Status',
                                        type: "number",
                                        field: 'status',
                                        orderBy: true,
                                        newFieldValue: (e) => {
                                            const status = tasksStatusList.find(s => s.id === e?.status ?? 0)
                                            return <div className="w-full flex items-center justify-center">
                                                <p className={`${status?.bgColor} p-1 rounded-md ${status?.textColor} text-xs font-semibold`}>
                                                    {status?.description}
                                                </p>
                                            </div>
                                        }
                                    },

                                ]
                            }
                            filters={[]}
                            usePagination={false}
                        />
                    </>
            }
        </Container>
    )
}