import { ReactElement } from "react";
import { Container } from "../../../components/container/container";
import { ListChecks } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";

export function ReportsHome() {

    interface IReportCard {
        icon: ReactElement
        title: String
        description: String
        link: String
    }

    const navigate = useNavigate()

    const reportProjectItems: any[] = [
        {
            description: 'Relatório dos projetos com detalhamento de horas lançadas por projeto e cliente, podendo filtrar por status',
            icon: <ListChecks className="text-lg text-primaryColor" />,
            link: 'projects/projectsTimes',
            title: 'Apontamento de horas'
        },
        {
            description: 'Relatório com detalhamento das tarefas do projeto detalhado por status / data de conclusão',
            icon: <ListChecks className="text-lg text-primaryColor" />,
            link: 'projects/projectsStatus',
            title: 'Projetos vs status'
        },
    ]

    const reportSupportItems: any[] = [
        {
            description: 'Em desenvolvimento',
            icon: <ListChecks className="text-lg text-primaryColor" />,
            link: 'support',
            title: 'Chamados geral'
        },

    ]

    function ReportCard({ icon, title, description, link }: IReportCard) {

        return (
            <div onClick={() => navigate(`${link}`)} className="cursor-pointer hover:brightness-95 transition-all duration-150 translate-x-1 w-full h-48 rounded-lg bg-white shadow-lg">
                <div className="w-full py-1 bg-zinc-100 flex gap-2 pl-2 items-center justify-start">
                    {icon}
                    <h2 className="p-1 bg-zinc-100 text-base text-primaryColor text-base font-semibold">{title}</h2>
                </div>
                <div className="w-full p-2 text-sm">
                    <a className="text-zinc-700">{description}</a>
                </div>
            </div>
        )
    }

    return (
        <Container noBackground title={{ name: 'Relatórios' }}>
            <div className="w-full flex gap-8 flex-wrap">
                <div className="flex flex-col items-center bg-white min-h-[400px] gap-4 min-w-72 w-72 border-l-4 border-primaryColor shadow-lg rounded-lg overflow-scroll p-2">
                    <div className="w-full flex items-start justify-start">
                        <h2 className="p-1 text-base text-primaryColor font-semibold">Projetos</h2>
                    </div>
                    {
                        reportProjectItems.map(({ description, icon, link, title }: IReportCard) => {
                            return <ReportCard
                                icon={icon}
                                description={description}
                                link={link}
                                title={title}
                                key={1} />

                        })
                    }
                </div>
                <div className="flex flex-col items-center bg-white min-h-[400px] gap-4 min-w-72 w-72 border-l-4 border-primaryColor shadow-lg rounded-lg overflow-scroll p-2">
                    <div className="w-full flex items-start justify-start">
                        <h2 className="p-1 text-base text-primaryColor font-semibold">Chamados</h2>
                    </div>
                    {
                        reportSupportItems.map(({ description, icon, link, title }: IReportCard) => {
                            return <ReportCard
                                icon={icon}
                                description={description}
                                link={link}
                                title={title}
                                key={1} />

                        })
                    }
                </div>
            </div>
        </Container>
    )
}