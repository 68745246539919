import { useEffect, useState } from "react";
import { Container } from "../../../components/container/container";
import { useAuthContext } from "../../../contexts/authContext";
import { ClientsContracts } from "../../../services/api/reg/ClientsContracts";
import { IAmendments, IClientsContracts, UpdateClientContracts } from "../../../services/interfaces/reg/clientsContracts";
import { useNavigate, useParams } from "react-router-dom";
import { Flex, FormLabel, Select } from "@chakra-ui/react";
import { InputDefault } from "../../../components/input/inputDefault";
import moment from "moment";
import { DatePicker } from "antd";
import { ButtonDefault } from "../../../components/button/buttonDefault";
import { Trash, Pencil } from "@phosphor-icons/react";
import dayjs from "dayjs";
import { Loader } from "../../../components/loader/loader";
import { NewTableDefaultWithoutAPI } from "../../../components/tables/newTableDefaultWithoutAPI";
import { useScreenModalContext } from "../../../contexts/screenModalContext";
import { ScreenModal } from "../../../components/modals/screenModal";
import { useAlertContext } from "../../../contexts/alertContext";
import { IProjects } from "../../../services/interfaces/pro/projects";
import { Projects } from "../../../services/api/pro/Projects";
import { CreateContractsHistoric, UpdateContractsHistoric } from "../../../services/interfaces/reg/contractsHistoric";
import { ContractsHistoric } from "../../../services/api/reg/ContractHistoric";

export function ContractsEdit() {

    const { id } = useParams()
    const navigate = useNavigate()
    const { setAlert } = useAlertContext()
    const { userData } = useAuthContext()
    const { detectModal, setDetectModal } = useScreenModalContext()
    const [ loading, setLoading ] = useState<boolean>(true)
    const [ contract, setContract ] = useState<IClientsContracts>({} as any)
    const [ amendment, setAmendment ] = useState<IAmendments>({} as any)
    const [ projects, setProjects ] = useState<IProjects[]>([])
    const [ linkCreate, setLinkCreate ] = useState<CreateContractsHistoric>({} as any)
    const [ linkUpdate, setLinkUpdate ] = useState<UpdateContractsHistoric>({} as any)
    const [ linkContractToRemove, setLinkContractToRemove ] = useState<number | null>()
    const clientsContracts = new ClientsContracts(userData?.token)
    const projectsService = new Projects(userData?.token)
    const contractsHistoric = new ContractsHistoric(userData?.token)
    
    useEffect(() => {
        getContract()
    }, [])

    async function getContract(){
        setLoading(true)
        try {
            const contractData = await clientsContracts.readById(Number(id))
            console.log(contractData)
            setContract({
                ...contractData,
                amountSeconds: contractData.amountSeconds / 60 / 60
            })
        } catch (error) {
            setAlert({
                description: 'Erro ao buscar contrato',
                status: 'error',
                title: 'Erro'
            })
            console.log('> Erro on try to get contract data:', error)
        }finally{
            setLoading(false)
        }
    }

    async function handleSubmit(){
        setLoading(true)
        try {
            await clientsContracts.updateById(Number(id), {
                amendments: contract.amendments ? contract.amendments : undefined, 
                amountSeconds: contract.amountSeconds ? contract.amountSeconds * 60 * 60 : undefined,
                description: contract.description ? contract.description : undefined,
                finishDate: contract.finishDate ? contract.finishDate : undefined,
                reg_clients_id: contract.reg_clients_id ? contract.reg_clients_id : undefined,
                startDate: contract.startDate ? contract.startDate : undefined,
                value: contract.value ? contract.value : undefined
            })
            setAlert({
                description: 'Sucesso ao alterar contrato',
                status: 'success',
                title: 'Sucesso'
            })
            navigate(-1)
        } catch (error) {
            setAlert({
                description: 'Erro ao tentar alterar contrato',
                status: 'error',
                title: 'Erro'
            })
            console.log('Error on try to update contract:', error)
        }finally{
            setLoading(false)
        }
    }

    async function addAmendment(){
        setLoading(true)
        try {
            const updated = await clientsContracts.updateById(Number(id), {
                amendments: [
                    ...contract.amendments as any,
                    {
                        amountSeconds: amendment.amountSeconds * 60 * 60,
                        description: amendment.description,
                        referenceDate: moment(amendment.referenceDate).utc(false).format('YYYY-MM-DD'),
                        value: amendment.value ? Number(amendment.value) : undefined,
                    }
                ]
            })
            setContract({ 
                ...updated,
                reg_clients: contract.reg_clients ? {
                    ...contract.reg_clients
                } : undefined
            })
            setAmendment({} as any)
            setDetectModal('')
            setAlert({
                description: 'Sucesso ao adicionar aditivo ao contrato',
                status: 'success',
                title: 'Sucesso'
            })
        } catch (error) {
            setAlert({
                description: 'Erro ao tentar adicionar aditivo ao contrato',
                status: 'error',
                title: 'Erro'
            })
            console.log('Erro on try to add new amendment:', error)
        } finally {
            setLoading(false)
        }

    }

    async function handleRemoveAmendment(){
        setLoading(true)
        try {
            const indexToRemove = contract.amendments?.findIndex(a => {
                const date1 = moment(a.referenceDate).utc(false).format('YYYY-MM-DD')
                const date2 = moment(amendment.referenceDate).utc(false).format('YYYY-MM-DD')
                return date1 === date2
            })
            if(indexToRemove !== undefined && indexToRemove >= 0){
                contract.amendments?.splice(indexToRemove, 1)
        
                const updated = await clientsContracts.updateById(Number(id), {
                    amendments: contract.amendments
                })
        
                setContract({ 
                    ...updated,
                    reg_clients: contract.reg_clients ? {
                        ...contract.reg_clients
                    } : undefined
                })
                setAmendment({} as any)
                setDetectModal('')
                setAlert({
                    description: 'Sucesso ao remover aditivo do contrato',
                    status: 'success',
                    title: 'Sucesso'
                })
            }
        } catch (error) {
            setAlert({
                description: 'Erro ao remover aditivo do contrato',
                status: 'error',
                title: 'Erro'
            })
            console.log('> Error on try to remove admendment:', error)
        } finally {
            setLoading(false)
        }
    }

    async function getProjects(){
        try {
            if(projects.length === 0){
                setLoading(true)
                const projectList = await projectsService.readByClient(contract.reg_clients_id)
                setProjects(projectList)
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            setAlert({
                status: 'error',
                title: 'Erro',
                description: 'Erro ao buscar projetos'
            })
        }
    }

    async function handleCreateContractLink(){
        try {
            setLoading(true)
            
            const created = await contractsHistoric.create({
                active: true,
                isFor: linkCreate.isFor,
                reg_clientsContracts_id: Number(id),
                startDate: moment(linkCreate.startDate).utc(false).format('YYYY-MM-DD') as any,
                closingDate: linkCreate.closingDate ? moment(linkCreate.closingDate).utc(false).format('YYYY-MM-DD') as any : undefined,
                pro_projects_id: linkCreate.pro_projects_id ? Number(linkCreate.pro_projects_id) : undefined
            })

            contract?.reg_contractsHistoric?.push({
                ...created,
                pro_projects: {
                    name: projects.find(p => p.id === Number(linkCreate.pro_projects_id))?.name
                } as any
            })

            setAlert({
                status: 'success',
                title: 'Sucesso',
                description: 'Sucesso ao criar vinculo.',
            })
            setContract({ ...contract })
            setDetectModal('')
        } catch (error) {
            console.log('> Error on try to create new contract link', error)
            setAlert({
                status: 'error',
                title: 'Erro',
                description: 'Erro ao tentar criar vinculo.',
            })
        } finally {
            setLoading(false)
        }
    }

    async function handleRemoveLink(){
        setLoading(true)
        try {
            await contractsHistoric.removeById(Number(linkContractToRemove))

            const indexToRemove = contract.reg_contractsHistoric?.findIndex(c => {
                return c.id === linkContractToRemove
            })
            if(Number(indexToRemove) >= 0){
                contract?.reg_contractsHistoric?.splice(Number(indexToRemove), 1)

                setContract({ ...contract })
                setDetectModal('')
                setAlert({
                    description: 'Sucesso ao remover vinculo',
                    status: 'success',
                    title: 'Sucesso'
                })
                setLinkContractToRemove(null)
            }
        } catch (error) {
            setAlert({
                description: 'Erro ao remover vinculo do contrato',
                status: 'error',
                title: 'Erro'
            })
            console.log('> Error on try to remove admendment:', error)
        } finally {
            setLoading(false)
        }
    }

    async function handleUpdateContractLink(){
        try {
            setLoading(true)
            
            const updated = await contractsHistoric.updateById(Number(linkUpdate.id), {
                startDate: linkUpdate.startDate ? moment(linkUpdate.startDate).utc(false).format('YYYY-MM-DD') as any : undefined,
                closingDate: linkUpdate.closingDate ? moment(linkUpdate.closingDate).utc(false).format('YYYY-MM-DD') as any : undefined,
            })

            const formatLinkHistoric = contract?.reg_contractsHistoric?.map((i) => {
                if(i.id === linkUpdate.id){
                    return {
                        ...updated,
                        pro_projects: {
                            name: projects.find(p => p.id === Number(linkUpdate.pro_projects_id))?.name
                        } as any
                    }
                }
                return i
            })

            contract.reg_contractsHistoric = formatLinkHistoric

            setContract({ ...contract })
            setDetectModal('')
            setAlert({
                description: 'Sucesso ao alterar vinculo',
                status: 'success',
                title: 'Sucesso'
            })
            setLinkContractToRemove(null)
        } catch (error) {
            console.log('> Error on try to update new contract link', error)
            setAlert({
                status: 'error',
                title: 'Erro',
                description: 'Erro ao tentar criar vinculo.',
            })
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            {
                loading 
                    ? <Loader />
                    : <Container noBackground={true}>
                        {
                            detectModal === 'addAmendment' ?
                                <ScreenModal height={470} width={500}>
                                    <div className="h-full flex flex-col justify-center items-center gap-4 mt-6">
                                        <h2 className='text-md' color={'#1E1E1E'}>Cadastro de aditivo</h2>
                                        <div className="flex flex-col py-4 gap-1">
                                            <div className="flex flex-col w-full md:w-auto gap-0">
                                                <FormLabel fontSize={12}>Data: *</FormLabel>
                                                <DatePicker
                                                    id="referenceDate"
                                                    format={['DD/MM/YYYY']}
                                                    placeholder="dd/mm/aaaa"
                                                    value={ amendment.referenceDate ? dayjs(moment.utc(amendment.referenceDate).format('DD/MM/YYYY'), ['DD/MM/YYYY']) : null } 
                                                    onChange={(dateString) => {
                                                        const dateFormatted: any = moment(String(dateString?.toISOString()))
                                                        setAmendment((prev: any) => ({
                                                            ...prev, 
                                                            referenceDate: dateString ? dateFormatted : amendment.referenceDate
                                                        }))
                                                    }}
                                                    popupStyle={{ zIndex: 10000}}
                                                    style={{ height: 40, width: '240px'}}
                                                />
                                            </div>
                                            <div className="flex flex-col w-full md:w-auto gap-0">
                                                <FormLabel fontSize={12}>Descrição: *</FormLabel>
                                                <InputDefault onChange={(e) => setAmendment((prev) => ({ ...prev, description: e.target.value }))} width={60} value={amendment.description} type="text" id="amendment-description"/>
                                            </div>
                                            <div className="flex flex-col w-full md:w-auto gap-0">
                                                <FormLabel fontSize={12}>Horas: *</FormLabel>
                                                <InputDefault onChange={(e) => setAmendment((prev) => ({ ...prev, amountSeconds: Number(e.target.value) }))} width={60} value={amendment.amountSeconds} type="number" id="amendment-amountSeconds"/>
                                            </div>
                                            <div className="flex flex-col w-full md:w-auto gap-0">
                                                <FormLabel fontSize={12}>Valor: *</FormLabel>
                                                <InputDefault onChange={(e) => setAmendment((prev) => ({ ...prev, value: Number(e.target.value) }))} width={60} value={amendment.value} type="number" id="amendment-value"/>
                                            </div>
                                        </div>
                                        <Flex gap={2} alignItems={'center'}>
                                            <ButtonDefault onClick={() => {setDetectModal(''); setAmendment({} as any)}} anotherColor="gray">Cancelar</ButtonDefault>
                                            <ButtonDefault onClick={() => addAmendment()} success={true}>Confirmar</ButtonDefault>
                                        </Flex>
                                    </div>
                                </ScreenModal>
                                : detectModal === 'removeAmendment' ? 
                                    <ScreenModal height={200} width={400}>
                                        <div className="h-full flex flex-col justify-center items-center gap-4 mt-6">
                                            <p className='text-md' color={'#1E1E1E'}>Deseja realmente remover o aditivo?</p>
                                            <Flex gap={2} alignItems={'center'}>
                                                <ButtonDefault onClick={() => {setDetectModal(''); setAmendment({} as any)}} anotherColor="gray">Cancelar</ButtonDefault>
                                                <ButtonDefault onClick={() => handleRemoveAmendment()} success={false}>Confirmar</ButtonDefault>
                                            </Flex>
                                        </div>
                                    </ScreenModal>
                                    : detectModal === 'addLink' ?
                                        <ScreenModal height={470} width={500}>
                                            <div className="h-full flex flex-col justify-center items-center gap-4 mt-6">
                                                <h2 className='text-md' color={'#1E1E1E'}>Cadastro de vinculo</h2>
                                                <div className="flex flex-col py-4 gap-1">
                                                    <div>
                                                        <FormLabel fontSize={12}>Vincular em: *</FormLabel>
                                                        <Select 
                                                            placeholder='Selecione uma opção' 
                                                            width={240} 
                                                            value={linkCreate.isFor}
                                                            onChange={(e) => {
                                                                if(e?.target?.value === 'project'){
                                                                    getProjects()
                                                                }
                                                                setLinkCreate((prev: any) => ({
                                                                    ...prev, 
                                                                    isFor: e?.target?.value
                                                                }))
                                                            }}
                                                        >
                                                            <option value="support">Suporte</option>
                                                            <option value="project">Projeto</option>
                                                        </Select>
                                                    </div>
                                                    {
                                                        linkCreate.isFor === 'project' &&
                                                        <div>
                                                            <FormLabel fontSize={12}>Projeto: *</FormLabel>
                                                            <Select 
                                                                placeholder='Selecione um projeto' 
                                                                width={240} 
                                                                value={linkCreate.pro_projects_id}
                                                                onChange={(e) => {
                                                                    setLinkCreate((prev: any) => ({
                                                                        ...prev, 
                                                                        pro_projects_id: e?.target?.value
                                                                    }))
                                                                }}
                                                            >
                                                                {
                                                                    projects.map(pro => {
                                                                        return <option value={pro.id}>{pro.name}</option>
                                                                    })
                                                                }
                                                            </Select>
                                                        </div>
                                                    }
                                                    <div className="flex flex-col w-full md:w-auto gap-0">
                                                        <FormLabel fontSize={12}>Data de inicio: *</FormLabel>
                                                        <DatePicker
                                                            id="startDate"
                                                            format={['DD/MM/YYYY']}
                                                            placeholder="dd/mm/aaaa"
                                                            value={ linkCreate.startDate ? dayjs(moment.utc(linkCreate.startDate).format('DD/MM/YYYY'), ['DD/MM/YYYY']) : null } 
                                                            onChange={(dateString) => {
                                                                const dateFormatted: any = moment(String(dateString?.toISOString())).format('YYYY-MM-DD')
                                                                setLinkCreate((prev: any) => ({
                                                                    ...prev, 
                                                                    startDate: dateString ? dateFormatted : linkCreate.startDate
                                                                }))
                                                            }}
                                                            popupStyle={{ zIndex: 10000}}
                                                            style={{ height: 40, width: '240px'}}
                                                        />
                                                    </div>
                                                    <div className="flex flex-col w-full md:w-auto gap-0">
                                                        <FormLabel fontSize={12}>Data Fim:</FormLabel>
                                                        <DatePicker
                                                            id="closingDate"
                                                            format={['DD/MM/YYYY']}
                                                            placeholder="dd/mm/aaaa"
                                                            value={ linkCreate.closingDate ? dayjs(moment.utc(linkCreate.closingDate).format('DD/MM/YYYY'), ['DD/MM/YYYY']) : null } 
                                                            onChange={(dateString) => {
                                                                const dateFormatted: any = moment(String(dateString?.toISOString())).format('YYYY-MM-DD')
                                                                setLinkCreate((prev: any) => ({
                                                                    ...prev, 
                                                                    closingDate: dateString ? dateFormatted : linkCreate.closingDate
                                                                }))
                                                            }}
                                                            popupStyle={{ zIndex: 10000}}
                                                            style={{ height: 40, width: '240px'}}
                                                        />
                                                    </div>
                                                </div>
                                                <Flex gap={2} alignItems={'center'}>
                                                    <ButtonDefault onClick={() => {setDetectModal(''); setLinkCreate({} as any)}} anotherColor="gray">Cancelar</ButtonDefault>
                                                    <ButtonDefault onClick={() => handleCreateContractLink()} success={true}>Confirmar</ButtonDefault>
                                                </Flex>
                                            </div>
                                        </ScreenModal>
                                        : detectModal === 'removeLink' ? 
                                            <ScreenModal height={200} width={400}>
                                                <div className="h-full flex flex-col justify-center items-center gap-4 mt-6">
                                                    <p className='text-md' color={'#1E1E1E'}>Deseja realmente remover o vinculo?</p>
                                                    <Flex gap={2} alignItems={'center'}>
                                                        <ButtonDefault onClick={() => {setDetectModal(''); setLinkContractToRemove(null)}} anotherColor="gray">Cancelar</ButtonDefault>
                                                        <ButtonDefault onClick={() => handleRemoveLink()} success={false}>Confirmar</ButtonDefault>
                                                    </Flex>
                                                </div>
                                            </ScreenModal>
                                            : detectModal === 'updateLink' ?
                                                <ScreenModal height={470} width={500}>
                                                    <div className="h-full flex flex-col justify-center items-center gap-4 mt-6">
                                                        <h2 className='text-md' color={'#1E1E1E'}>Cadastro de vinculo</h2>
                                                        <div className="flex flex-col py-4 gap-1">
                                                            <div>
                                                                <FormLabel fontSize={12}>Vincular em: *</FormLabel>
                                                                <Select 
                                                                    placeholder='Selecione uma opção' 
                                                                    width={240} 
                                                                    value={linkUpdate.isFor}
                                                                    disabled={true}
                                                                    onChange={(e) => {
                                                                        if(e?.target?.value === 'project'){
                                                                            getProjects()
                                                                        }
                                                                        setLinkCreate((prev: any) => ({
                                                                            ...prev, 
                                                                            isFor: e?.target?.value
                                                                        }))
                                                                    }}
                                                                >
                                                                    <option value="support">Suporte</option>
                                                                    <option value="project">Projeto</option>
                                                                </Select>
                                                            </div>
                                                            {
                                                                linkUpdate.isFor === 'project' &&
                                                                <div>
                                                                    <FormLabel fontSize={12}>Projeto: *</FormLabel>
                                                                    <Select 
                                                                        placeholder='Selecione um projeto' 
                                                                        width={240} 
                                                                        disabled={true}
                                                                        value={linkUpdate.pro_projects_id}
                                                                        onChange={(e) => {
                                                                            setLinkUpdate((prev: any) => ({
                                                                                ...prev, 
                                                                                pro_projects_id: e?.target?.value
                                                                            }))
                                                                        }}
                                                                    >
                                                                        {
                                                                            projects.map(pro => {
                                                                                return <option value={pro.id}>{pro.name}</option>
                                                                            })
                                                                        }
                                                                    </Select>
                                                                </div>
                                                            }
                                                            <div className="flex flex-col w-full md:w-auto gap-0">
                                                                <FormLabel fontSize={12}>Data de inicio: *</FormLabel>
                                                                <DatePicker
                                                                    id="startDate"
                                                                    format={['DD/MM/YYYY']}
                                                                    placeholder="dd/mm/aaaa"
                                                                    value={ linkUpdate.startDate ? dayjs(moment.utc(linkUpdate.startDate).format('DD/MM/YYYY'), ['DD/MM/YYYY']) : null } 
                                                                    onChange={(dateString) => {
                                                                        const dateFormatted: any = moment(String(dateString?.toISOString())).format('YYYY-MM-DD')
                                                                        setLinkUpdate((prev: any) => ({
                                                                            ...prev, 
                                                                            startDate: dateString ? dateFormatted : linkUpdate.startDate
                                                                        }))
                                                                    }}
                                                                    popupStyle={{ zIndex: 10000}}
                                                                    style={{ height: 40, width: '240px'}}
                                                                />
                                                            </div>
                                                            <div className="flex flex-col w-full md:w-auto gap-0">
                                                                <FormLabel fontSize={12}>Data Fim:</FormLabel>
                                                                <DatePicker
                                                                    id="closingDate"
                                                                    format={['DD/MM/YYYY']}
                                                                    placeholder="dd/mm/aaaa"
                                                                    value={ linkUpdate.closingDate ? dayjs(moment.utc(linkUpdate.closingDate).format('DD/MM/YYYY'), ['DD/MM/YYYY']) : null } 
                                                                    onChange={(dateString) => {
                                                                        const dateFormatted: any = moment(String(dateString?.toISOString())).format('YYYY-MM-DD')
                                                                        setLinkUpdate((prev: any) => ({
                                                                            ...prev, 
                                                                            closingDate: dateString ? dateFormatted : linkUpdate.closingDate
                                                                        }))
                                                                    }}
                                                                    popupStyle={{ zIndex: 10000}}
                                                                    style={{ height: 40, width: '240px'}}
                                                                />
                                                            </div>
                                                        </div>
                                                        <Flex gap={2} alignItems={'center'}>
                                                            <ButtonDefault onClick={() => {setDetectModal(''); setLinkUpdate({} as any)}} anotherColor="gray">Cancelar</ButtonDefault>
                                                            <ButtonDefault onClick={() => handleUpdateContractLink()} success={true}>Confirmar</ButtonDefault>
                                                        </Flex>
                                                    </div>
                                                </ScreenModal>
                                                : <></>

                        }
                        <div className="bg-white w-full sm:p-10 p-3 flex flex-col">
                            <div className="w-full"> {/* Contrato */}
                                <h2 className='text-lg text-primaryColor'>Cadastro de Contratos</h2>
                                <div className="flex flex-row flex-wrap mt-6 mb-2 gap-5 w-[1000px]">
                                    <div>
                                        <FormLabel fontSize={12}>Cliente: *</FormLabel>
                                        <InputDefault 
                                            isDisabled={true}
                                            style={{width: '400px'}} value={contract?.reg_clients?.name || '-'} 
                                            type="text" id="description"
                                        />
                                    </div>
                                    <div>
                                        <FormLabel fontSize={12}>Descrição: *</FormLabel>
                                        <InputDefault 
                                            onChange={(e) => setContract((prev) => ({ ...prev, description: e.target.value }))} 
                                            width={96} value={contract?.description} 
                                            type="text" id="description"
                                        />
                                    </div>
                                    <div className="flex gap-5">
                                        <div>
                                            <FormLabel fontSize={12}>Início: *</FormLabel>
                                            <DatePicker
                                                id="startDate"
                                                format={['DD/MM/YYYY']}
                                                placeholder="dd/mm/aaaa"
                                                value={ contract.startDate ? dayjs(moment.utc(contract.startDate).format('DD/MM/YYYY'), ['DD/MM/YYYY']) : null } 
                                                onChange={(dateString) => {
                                                    const dateFormatted: any = moment(String(dateString?.toISOString()))
                                                    setContract((prev: any) => ({
                                                        ...prev, 
                                                        startDate: dateString ? dateFormatted : contract.startDate
                                                    }))
                                                }}
                                                popupStyle={{ zIndex: 10000}}
                                                style={{ height: 40, width: '190px'}}
                                            />
                                        </div>
                                        <div>
                                            <FormLabel fontSize={12}>Fim:</FormLabel>
                                            <DatePicker
                                                id="finishDate"
                                                format={['DD/MM/YYYY']}
                                                placeholder="dd/mm/aaaa"
                                                value={ contract.finishDate ? dayjs(moment.utc(contract.finishDate).format('DD/MM/YYYY'), ['DD/MM/YYYY']) : null } 
                                                onChange={(dateString) => {
                                                    const dateFormatted: any = moment(String(dateString?.toISOString()))
                                                    setContract((prev: any) => ({
                                                        ...prev, 
                                                        finishDate: dateString ? dateFormatted : contract.finishDate
                                                    }))
                                                }}
                                                popupStyle={{ zIndex: 10000}}
                                                style={{ height: 40, width: '190px'}}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex gap-5">
                                        <div>
                                            <FormLabel fontSize={12}>Quantidade de Horas: *</FormLabel>
                                            <InputDefault 
                                                onChange={(e) => setContract((prev) => ({ ...prev, amountSeconds: Number(e.target.value) }))} 
                                                width={'180px'} value={contract?.amountSeconds} 
                                                type="text" id="value"
                                            />
                                        </div>
                                        <div>
                                            <FormLabel fontSize={12}>Valor:</FormLabel>
                                            <InputDefault 
                                                onChange={(e) => setContract((prev) => ({ ...prev, value: Number(e.target.value) }))} 
                                                width={'180px'} value={contract?.value} 
                                                type="text" id="value"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-10 flex gap-7">
                                    <ButtonDefault onClick={() => navigate(-1)} success={false}>Cancelar</ButtonDefault>
                                    <ButtonDefault onClick={() => handleSubmit()}>Cadastrar</ButtonDefault>
                                </div>
                            </div>
                            <hr className="w-full my-8"/>
                            <div className="w-full">  {/* Aditivos */}
                                <div className="flex justify-between items-center">
                                    <h2 className='text-lg text-primaryColor mb-4'>Aditivos</h2> 
                                    <span 
                                        className="text-sm text-primaryColor cursor-pointer hover:text-primaryColor/70" 
                                        onClick={() => setDetectModal('addAmendment')}
                                    >
                                        + Adicionar
                                    </span>
                                </div>
                                <div className="w-full border">  
                                    <NewTableDefaultWithoutAPI
                                        columns={[
                                            {
                                                description: 'Descrição',
                                                type: 'string',
                                                field: 'description',
                                            },
                                            {
                                                description: 'Data',
                                                type: 'date',
                                                field: 'referenceDate'
                                            },
                                            {
                                                description: 'Horas',
                                                type: 'secondsToHours',
                                                field: 'amountSeconds'
                                            },
                                            {
                                                description: 'Valor',
                                                type: 'money',
                                                field: 'value'
                                            },
                                            {
                                                description: 'Ações',
                                                type: 'string',
                                                newFieldValue: (e) => {
                                                    return <div className="w-full flex items-center justify-center">
                                                        <Trash className="cursor-pointer text-red-800" size={17} onClick={() => {
                                                            setAmendment(e)
                                                            setDetectModal('removeAmendment')
                                                        }}/>
                                                    </div>
                                                }
                                            }
                                        ]}
                                        tableData={contract?.amendments ? contract.amendments : []}
                                        headerColor={true}
                                    />
                                </div>
                            </div>
                            <hr className="w-full my-8"/>
                            <div className="w-full"> {/* Vinculos e Histórico */}
                                <div className="flex justify-between items-center">
                                    <h2 className='text-lg text-primaryColor mb-4'>Vinculos e Histórico</h2> 
                                    <span onClick={() => setDetectModal('addLink')} className="text-sm text-primaryColor cursor-pointer hover:text-primaryColor/70">
                                        + Adicionar
                                    </span>
                                </div>
                                <div className="w-full border">
                                    <NewTableDefaultWithoutAPI
                                        columns={[
                                            {
                                                description: 'Vinculo',
                                                type: 'string',
                                                newFieldValue: (e) => {
                                                    return e.isFor === 'project' ? 'Projeto' : 'Suporte'
                                                }
                                            },
                                            {
                                                description: 'Projeto',
                                                type: 'string',
                                                newFieldValue: (e) => {
                                                    return e?.pro_projects?.name || '-'
                                                }
                                            },
                                            {
                                                description: 'Data início',
                                                type: 'date',
                                                field: 'startDate'
                                            },
                                            {
                                                description: 'Data fim',
                                                type: 'date',
                                                field: 'closingDate'
                                            },
                                            {
                                                description: 'Ações',
                                                type: 'string',
                                                newFieldValue: (e) => {
                                                    return <div className="w-full flex items-center justify-center gap-2">
                                                        <Pencil className="cursor-pointer text-yellow-700" size={17} onClick={() => {
                                                            setLinkUpdate(e)
                                                            setDetectModal('updateLink')
                                                        }}/>
                                                        <Trash className="cursor-pointer text-red-800" size={17} onClick={() => {
                                                            setLinkContractToRemove(e.id)
                                                            setDetectModal('removeLink')
                                                        }}/>
                                                    </div>
                                                }
                                            }
                                        ]}
                                        tableData={contract?.reg_contractsHistoric ? contract.reg_contractsHistoric : []}
                                        headerColor={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </Container>
            }
        </>
    )
}