import { Box, Flex, FormControl, FormLabel, Text, Tooltip, Select } from "@chakra-ui/react";
import { DatePicker, DatePickerProps } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { useEffect, useState } from 'react';
import { FaInfo } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonDefault } from "../../../components/button/buttonDefault";
import { Container } from "../../../components/container/container";
import AutoCompleteInput from "../../../components/input/autocomplete";
import { InputDefault } from "../../../components/input/inputDefault";
import { Loader } from '../../../components/loader/loader';
import { ScreenModal } from '../../../components/modals/screenModal';
import { useAlertContext } from '../../../contexts/alertContext';
import { useAuthContext } from "../../../contexts/authContext";
import { useScreenModalContext } from '../../../contexts/screenModalContext';
import { IProjects } from '../../../services/interfaces/pro/projects';
import { Plus } from "@phosphor-icons/react";
import { NewTableDefault } from "../../../components/tables/newTableDefault";
import { Projects } from "../../../services/api/pro/Projects";
import { ClientsContracts } from "../../../services/api/reg/ClientsContracts";
import { IClientsContracts } from "../../../services/interfaces/reg/clientsContracts";
import { CreateContractsHistoric } from "../../../services/interfaces/reg/contractsHistoric";
import { ContractsHistoric } from "../../../services/api/reg/ContractHistoric";
import { SelectDefault } from "../../../components/selects/selectDefault";

export function EditProject() {

    const navigate = useNavigate()
    const { id } = useParams()
    const { userData } = useAuthContext()
    const { setAlert } = useAlertContext()
    const { detectModal, setDetectModal } = useScreenModalContext()
    const [projectsData, setProjectsData] = useState<IProjects>()
    const [clientsContracts, setClientsContracts] = useState<IClientsContracts[]>([])
    const [contractLink, setContractLink] = useState<CreateContractsHistoric>({} as any)
    const [loading, setLoading] = useState(true)
    const dateFormat = ['DD/MM/YYYY']
    const statusList = [
        { id: 0, description: 'Em Andamento' },
        { id: 1, description: 'Finalizado' },
        { id: 2, description: 'Cancelado' }
    ]
    const projectsService = new Projects(userData?.token)
    const clientsContractsService = new ClientsContracts(userData?.token)
    const contractsHistoricService = new ContractsHistoric(userData?.token)

    async function getData() {
        try {
            const getProject = await projectsService.readById(Number(id))

            setProjectsData({
                ...getProject,
                limitHours: getProject?.limitHours,
                goalHours: getProject?.goalHours
            })

            const getContracts = await clientsContractsService.readByClientsId(getProject.reg_clients_id)
            setClientsContracts(getContracts.filter(c => {
                return Number(moment(c.finishDate).utc(false).toDate()) > Number(new Date()) || !c.finishDate
            }))

        } catch (error) {
            setAlert({  
                status: 'error',
                title: 'Erro',
                description: 'Erro ao buscar projetos'
            })
        } finally {
            setLoading(false)
        }
    }

    async function handleSubmit() {
        setLoading(true)            
        try {
            await projectsService.updateById(Number(id), {
                description: projectsData?.description ? projectsData?.description : undefined,
                dueDate: projectsData?.dueDate ? projectsData?.dueDate : undefined,
                finishDate: projectsData?.finishDate ? projectsData?.finishDate : undefined,
                goalHours: projectsData?.goalHours ? projectsData?.goalHours : undefined,
                limitHours: projectsData?.limitHours ? projectsData?.limitHours : undefined,
                name: projectsData?.name ? projectsData?.name : undefined,
                startDate: projectsData?.startDate ? projectsData?.startDate : undefined,
                status: projectsData?.status || projectsData?.status === 0 ? projectsData?.status : undefined,
                isNotVisible: projectsData?.isNotVisible ? projectsData?.isNotVisible : false
            })
            setAlert({
                status: "success",
                title: "Sucesso",
                description: "Sucesso ao editar projeto"
            })
            navigate(-1)
        } catch (error) {
            setAlert({
                status: "error",
                title: "Erro",
                description: "Erro ao editar projeto"
            })
        } finally {
            setLoading(false)
        }
    }

    async function handleRemove() {
        setLoading(true)
        try {
            await projectsService.removeById(Number(id))
            setAlert({
                status: "success",
                title: "Sucesso",
                description: "Sucesso ao excluir projeto"
            })
            navigate('/projects')
        } catch (error) {
            setAlert({
                status: "error",
                title: "Erro",
                description: "Erro ao excluir projeto"
            })
        } finally {
            setDetectModal('')
            setLoading(false)
        }
    }

    async function handleNewContractLink(){
        try {
            setLoading(true)

            await contractsHistoricService.create({
                isFor: 'project',
                pro_projects_id: Number(id),
                reg_clientsContracts_id: Number(contractLink?.reg_clientsContracts_id),
                startDate: contractLink?.startDate,
                closingDate: contractLink?.closingDate ? contractLink.closingDate : undefined,
                active: true,
            })

            setContractLink({} as any)
            setAlert({
                status: "success",
                title: "Sucesso",
                description: "Sucesso ao criar novo vinculo de contrato ao projeto"
            })
            setDetectModal('')
        } catch (error) {
            setDetectModal('addContractLink')
            setAlert({
                status: "error",
                title: "Erro",
                description: "Erro ao criar novo vinculo de contrato ao projeto"
            })
        } finally {
            setLoading(false)
        }
    }

    function handleChangeDate(type: string): DatePickerProps['onChange'] {
        return (dateString) => {
            const dateFormatted: any = moment(String(dateString)).format("YYYY-MM-DD")
            if(type === 'startDate'){
                setProjectsData((prev: any) => ({
                    ...prev,
                    startDate: dateString ? dateFormatted : projectsData?.startDate,
                })) 
            }else if(type === 'dueDate') {
                setProjectsData((prev: any) => ({
                    ...prev,
                    dueDate:   dateString ? dateFormatted : projectsData?.dueDate, 
                }))
            } else if(type === 'finishDate'){   
                setProjectsData((prev: any) => ({
                    ...prev,
                    finishDate: dateString ? dateFormatted : projectsData?.finishDate,
                    
                }))
            }
        }
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <>
            {
                loading
                    ?
                    <Loader />
                    :
                    <Container noBackground>
                        {
                            detectModal === 'removeProject' ?
                                <ScreenModal height={150} width={250}>
                                    <Box className="h-full flex flex-col justify-center items-center gap-4 mt-6">
                                        <Text className='text-sm' color={'#1E1E1E'}>Deseja excluir este projeto?</Text>
                                        <Flex gap={2} alignItems={'center'}>
                                            <ButtonDefault onClick={() => setDetectModal('')} anotherColor="gray">Cancelar</ButtonDefault>
                                            <ButtonDefault onClick={() => handleRemove()} success={false}>Confirmar</ButtonDefault>
                                        </Flex>
                                    </Box>
                                </ScreenModal>
                                : detectModal === 'addContractLink' ?
                                    <ScreenModal height={430} width={400}>
                                        <Box className="h-full flex flex-col justify-center items-center gap-3 my-4">
                                            <h2 className="text-md text-primaryColor pb-4">Novo vinculo contratual</h2>
                                            <div className="z-[900]">
                                                <FormLabel fontSize={12}>Contrato: * </FormLabel>
                                                <Select 
                                                    placeholder='Selecione um contrato' 
                                                    width={260} 
                                                    value={contractLink.reg_clientsContracts_id}
                                                    onChange={(e) => {
                                                        setContractLink((prev: any) => ({
                                                            ...prev, 
                                                            reg_clientsContracts_id: e?.target?.value
                                                        }))
                                                    }}
                                                >
                                                    {
                                                        clientsContracts.map((e) => {
                                                            return <option value={e.id}>{e.description}</option>
                                                        })
                                                    }
                                                </Select>
                                            </div>
                                            <div>
                                                <FormLabel fontSize={12}>Início: *</FormLabel>
                                                <DatePicker
                                                    id="startDate"
                                                    format={['DD/MM/YYYY']}
                                                    placeholder="dd/mm/aaaa"
                                                    value={ contractLink?.startDate ? dayjs(moment.utc(contractLink?.startDate).format('DD/MM/YYYY'), ['DD/MM/YYYY']) : null } 
                                                    onChange={(dateString) => {
                                                        const dateFormatted: any = moment(String(dateString?.toISOString()))
                                                        setContractLink((prev: any) => ({
                                                            ...prev, 
                                                            startDate: dateString ? dateFormatted : contractLink?.startDate
                                                        }))
                                                    }}
                                                    popupStyle={{ zIndex: 10000}}
                                                    style={{ height: 40, width: '260px'}}
                                                />
                                            </div>
                                            <div>
                                                <FormLabel fontSize={12}>Fim:</FormLabel>
                                                <DatePicker
                                                    id="startDate"
                                                    format={['DD/MM/YYYY']}
                                                    placeholder="dd/mm/aaaa"
                                                    value={ contractLink?.closingDate ? dayjs(moment.utc(contractLink?.closingDate).format('DD/MM/YYYY'), ['DD/MM/YYYY']) : null } 
                                                    onChange={(dateString) => {
                                                        const dateFormatted: any = moment(String(dateString?.toISOString()))
                                                        setContractLink((prev: any) => ({
                                                            ...prev, 
                                                            closingDate: dateString ? dateFormatted : contractLink?.closingDate
                                                        }))
                                                    }}
                                                    popupStyle={{ zIndex: 10000}}
                                                    style={{ height: 40, width: '260px'}}
                                                />
                                            </div>
                                            <Flex gap={2} alignItems={'center'} className="pt-5">
                                                <ButtonDefault onClick={() => setDetectModal('')} success={false}>Cancelar</ButtonDefault>
                                                <ButtonDefault onClick={() => setDetectModal('confirmAddContractLink')} success={true}>Confirmar</ButtonDefault>
                                            </Flex>
                                        </Box>
                                    </ScreenModal>
                                    : detectModal === 'confirmAddContractLink' ?
                                        <ScreenModal height={220} width={400}>
                                            <Box className="h-full flex flex-col justify-center items-center gap-10 mt-8">
                                                <Text className='text-sm text-center' color={'#1E1E1E'}>Ao realizar essa ação você terminará o vinculo desse projeto com o contrato anterior. Deseja prosseguir?</Text>
                                                <Flex gap={2} alignItems={'center'}>
                                                    <ButtonDefault onClick={() => setDetectModal('addContractLink')} success={false}>Cancelar</ButtonDefault>
                                                    <ButtonDefault onClick={() => handleNewContractLink()} success={true}>Confirmar</ButtonDefault>
                                                </Flex>
                                            </Box>
                                        </ScreenModal>
                                        : <></>
                        }
                        <Box className="w-full flex flex-col justify-start gap-10">
                            <Box className="bg-white shadow-md p-10 rounded-md flex flex-col gap-12" >
                                <Text className='text-lg text-primaryColor' fontWeight={'450'}>Edição de projeto</Text>
                                <FormControl className="flex flex-row flex-wrap gap-6 w-full">
                                    <Box className="flex flex-row gap-6 flex-wrap w-full">
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>Nome:</FormLabel>
                                            <InputDefault 
                                                width={"400px"} 
                                                value={projectsData?.name} 
                                                onChange={(e) => setProjectsData(((prev: any) => ({ ...prev, name: e.target.value })))} 
                                                type="text" 
                                                id="name" 
                                            />
                                        </Box>
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>Descrição:</FormLabel>
                                            <InputDefault 
                                                width={"400px"} 
                                                value={projectsData?.description} 
                                                onChange={(e) => setProjectsData(((prev: any) => ({ ...prev, description: e.target.value })))} 
                                                type="text" 
                                                id="description" 
                                            />
                                        </Box>
                                    </Box>
                                    <Box className="flex flex-row gap-6 flex-wrap w-full">
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>Cliente:</FormLabel>
                                            <InputDefault 
                                                width="400px" 
                                                value={projectsData?.reg_clients?.name} 
                                                isDisabled
                                                id="clients_id" 
                                            />
                                        </Box>
                                        <Box className="flex flex-col w-50 md:w-auto">
                                            <FormLabel fontSize={12}>Status:</FormLabel>
                                            <AutoCompleteInput 
                                                width="220px" 
                                                defaultValue={statusList?.filter(item => item?.id === projectsData?.status)[0]?.description} 
                                                id="status" 
                                                data={statusList} 
                                                preSelectedValue={["id", "description"]} 
                                                placeholder={"Selecione o status"} 
                                                onChange={(e: number) => setProjectsData((prev: any) => ({ ...prev, status: e }))} 
                                            />
                                        </Box>
                                        <Box className="flex flex-col w-50 md:w-auto">
                                            <FormLabel fontSize={12}>Visibilidade do projeto: *</FormLabel>
                                            <SelectDefault
                                                value={projectsData?.isNotVisible === true ? 1 : 0}
                                                onChange={(e) => setProjectsData((prev: any) => ({ ...prev, isNotVisible: Number(e.target.value) === 1 ? true : false}))}
                                                placeholder="Selecionar">
                                                <option key={0} value={0}>Todos usuários</option>
                                                <option key={1} value={1}>Apenas membros</option>
                                            </SelectDefault>
                                        </Box>
                                    </Box>
                                    <Box className="flex flex-row gap-6 w-full">
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>Data prazo:</FormLabel>
                                            <DatePicker
                                                id="startDate"
                                                format={dateFormat}
                                                placeholder="dd/mm/aaaa"
                                                value={ projectsData?.dueDate ? dayjs(moment.utc(projectsData?.dueDate).format('DD/MM/YYYY'), dateFormat[0]) : null } 
                                                onChange={handleChangeDate('dueDate')}
                                                popupStyle={{ zIndex: 10000}}
                                                style={{ height: 40, width: 145}}
                                            />
                                        </Box>
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>Data de conclusão:</FormLabel>
                                            <DatePicker
                                                id="startDate"
                                                format={dateFormat}
                                                placeholder="dd/mm/aaaa"
                                                value={ projectsData?.finishDate ? dayjs(moment.utc(projectsData?.finishDate).format('DD/MM/YYYY'), dateFormat[0]) : null } 
                                                onChange={handleChangeDate('finishDate')}
                                                popupStyle={{ zIndex: 10000}}
                                                style={{ height: 40, width: 145}}
                                            />
                                        </Box>
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>Data de início:</FormLabel>
                                            <DatePicker
                                                id="startDate"
                                                format={dateFormat}
                                                placeholder="dd/mm/aaaa"
                                                value={ projectsData?.startDate ? dayjs(moment.utc(projectsData?.startDate).format('DD/MM/YYYY') , dateFormat[0]) : null} 
                                                onChange={handleChangeDate('startDate')}
                                                popupStyle={{ zIndex: 10000}}
                                                style={{ height: 40, width: 145}}
                                            />
                                        </Box>
                                        <Box className="flex flex-col relative w-full md:w-auto">
                                            <Tooltip hasArrow label="São as horas acordadas com o cliente para finalização do projeto" placement={'top'}>
                                                <Box className="absolute right-0 top-0">
                                                    <FaInfo></FaInfo>
                                                </Box>
                                            </Tooltip>
                                            <FormLabel fontSize={12}>Horas limite do projeto:</FormLabel>
                                            <InputDefault 
                                                width={'145px'} 
                                                className="placeholder:text-zinc-800"
                                                value={projectsData?.limitHours ? projectsData?.limitHours : ''} 
                                                onChange={(e) => setProjectsData((prev: any) => ({ ...prev, limitHours: e.target.value }))} 
                                                type="number" 
                                                id="limitHours" 
                                            />
                                        </Box>
                                        <Box className="flex flex-row gap-6 flex-wrap w-full">
                                            <Box className="flex flex-col relative w-full md:w-auto">
                                                <Tooltip hasArrow label="Geralmente são as horas que gostaríamos de entregar este projeto" placement={'top'}>
                                                    <Box className="absolute right-0 top-0">
                                                        <FaInfo></FaInfo>
                                                    </Box>
                                                </Tooltip>
                                                <FormLabel fontSize={12}>Horas meta do projeto:</FormLabel>
                                                <InputDefault 
                                                    width={'145px'} 
                                                    value={projectsData?.goalHours ? projectsData?.goalHours : ''}
                                                    className="placeholder:text-zinc-800"
                                                    onChange={(e) => setProjectsData((prev: any) => ({ ...prev, goalHours: e.target.value }))} 
                                                    type="number" 
                                                    id="goalHours" 
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </FormControl>
                                <Box className="flex items-center justify-between">
                                        <ButtonDefault onClick={() => navigate('/projects')} success={false}>Cancelar</ButtonDefault>
                                    <Flex gap={2}>
                                        <ButtonDefault onClick={() => setDetectModal('removeProject')} success={false}>Excluir</ButtonDefault>
                                        <ButtonDefault success onClick={() => handleSubmit()}>Editar</ButtonDefault>
                                    </Flex>
                                </Box>
                                <hr className="w-full"/>
                                <div className="flex flex-col gap-4">
                                    <div className="flex justify-between">
                                        <h2 className="text-lg text-primaryColor">Histórico - Vinculos de contrato</h2>
                                        <span  
                                            onClick={() => setDetectModal('addContractLink')}
                                            className="flex items-center text-sm gap-1 text-primaryColor hover:opacity-70 cursor-pointer">
                                            <Plus size={14}/> Adicionar
                                        </span>
                                    </div>
                                    <div className="border">
                                        <NewTableDefault
                                            columns={[
                                                {
                                                    description: 'Contrato',
                                                    type: 'string',
                                                    newFieldValue: (e) => {
                                                        return e?.reg_clientsContracts?.description || '-'
                                                    }
                                                },
                                                {
                                                    description: 'Data início',
                                                    type: 'date',
                                                    field: 'startDate'
                                                },
                                                {
                                                    description: 'Data fim',
                                                    type: 'date',
                                                    field: 'closingDate'
                                                },
                                                {
                                                    description: 'Status',
                                                    type: 'status',
                                                    field: 'active'
                                                }
                                            ]}
                                            endpoint={`/api/v1/reg/contractsHistoric/project/${Number(id)}`}
                                            token={String(userData?.token)}
                                            filters={[]}
                                            usePagination={false}
                                            headerColor={true}
                                            onClickInTableLine={(e) => navigate(`/contracts/edit/${e.reg_clientsContracts_id}`)}
                                        />
                                    </div>
                                </div>
                            </Box>
                        </Box>
                    </Container>
            }
        </>
    )
}