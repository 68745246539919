import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from "react";
import { ChildrenProps } from "./alertContext";

interface ISelectTaskTo {
    taksId: string
    stepId: number
}

interface ISelectStepTo {
    id?: number
}

export const TaskMoveContext = createContext<{
    show: boolean | undefined
    setShow: Dispatch<SetStateAction<boolean | undefined>>
    taskMove: ISelectTaskTo[]
    stepMove: ISelectStepTo[]
    setTaskMove: Dispatch<SetStateAction<ISelectTaskTo[]>>
    setStepMove: Dispatch<SetStateAction<ISelectStepTo[]>>

} | undefined>(undefined)

export function TaskMoveContextProvider({ children }: ChildrenProps) {
    const [show, setShow] = useState<boolean>()
    const [taskMove, setTaskMove] = useState<ISelectTaskTo[]>([])
    const [stepMove, setStepMove] = useState<ISelectStepTo[]>([])

    return (
        <TaskMoveContext.Provider value={{
            show, setShow,
            taskMove, setTaskMove,
            stepMove, setStepMove
        }}>
            {children}
        </TaskMoveContext.Provider>
    )
}

export function useTaskMoveContext() {
    const context = useContext(TaskMoveContext);

    if (!context) {
        throw new Error("useTaskMoveContext must be used within an TaskMoveContextProvider");
    }

    return context;
}