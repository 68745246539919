export interface FormValidator {
    name: string
    required: boolean
    type: 'number' | 'string' | 'object' | 'file' | 'datepicker' | 'autocomplete'
    value: any | null | undefined
}

export function formValidator(fields: FormValidator[]) {
    const errors: FormValidator[] = []

    fields.forEach((field) => {
        if (field.required) {
            switch (field.type) {
                case 'string':
                    if (!field.value) {
                        errors.push(field)
                    }
                    break;
                case 'object':
                    if (typeof (field.value) != 'object') {
                        errors.push(field)
                    }
                    break;
                case 'number':
                    if (!field.value && Number(field.value) !== 0) {
                        errors.push(field)
                    }
                    break;
                case 'autocomplete':
                    if (!field.value && Number(field.value) !== 0) {
                        errors.push(field)
                    }
                    break;
                case 'file':
                    if (!field.value || !(field.value instanceof File)) {
                        errors.push(field)
                    }
                    break;
                case 'datepicker':
                    if (!field.value && Number(field.value) !== 0) {
                        errors.push(field)
                    }
                    break;
                default:
                    if (!field.value && (typeof field.value == 'number' && Number(field.value) !== 0)) {
                        errors.push(field)
                    }
                    break;
            }
        }
    })

    fields.forEach(field => {
        const validErrorField = errors.find(e => e.name === field.name)
        const element = document.getElementById(field.name)
        if (validErrorField) {
            if (['datepicker'].includes(validErrorField.type)) {
                const getParentElement = element?.parentElement?.parentElement
                if (getParentElement) return getParentElement!.style.border = '1px solid red'
            }
            if (['autocomplete'].includes(validErrorField.type)) {
                const getParentElement = element?.parentElement?.parentElement
                if (getParentElement) return getParentElement!.style.border = '1px solid red'
            }
            if (['file'].includes(validErrorField.type)) {
                const getParentElement = element?.parentElement?.parentElement
                if (getParentElement) return getParentElement!.style.border = '1px solid red'
            }
            if (element) return element!.style.border = '1px solid red'
        }
        return element!.style.border = ''
    })

    return errors
}
