import { Input, InputProps } from "@chakra-ui/react";

interface InputDefaultProps extends InputProps {}

export function InputDefault(props: InputProps){

    return (
        <Input height={8} fontSize={14} {...props} borderColor={'blackAlpha.300'}></Input>
    )

}
