import { useState, useEffect } from 'react'
import { useScreenSizeContext } from '../../contexts/screenSizeContext';
import { FaAngleLeft, FaAngleRight, FaCog, FaEdit } from 'react-icons/fa';
import { api } from '../../config/api';
import moment from 'moment';
import { Box } from '@chakra-ui/react';
import { secondsToHHMMSS } from '../../utils/mask/secondsToHours';
import { priorityList } from '../../utils/list/priorityList';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { tasksStatusList } from '../../utils/list/tasksStatusList';

interface INewTableDefault{
    endpoint: string
    token: string
    columns: IColumns[]
    mapApiData?: string[]
    filters: IFilters[]
    usePagination: boolean
    mapQuantityOfRegisters?: string[]
    mapTotalPages?: string[]
    onClickInTableLine?: (e: any) => void 
    headerColor?: boolean
}

interface IColumns{
    type: 'number' | 'date' | 'datetime' | 'string' | 'boolean' | 'money' | 'status' | 'secondsToHours'
    description: string
    field?: string
    newFieldValue?: (e: any) => any
    color?: 'yellow' | 'red' | 'green' | 'blue' | 'gray'
    orderBy?: boolean
}

interface IFilters{
    field: string,
    value: any
}

export function NewTableDefault({ 
    endpoint, 
    token, 
    columns = [], 
    mapApiData = [], 
    filters = [], 
    usePagination,
    mapQuantityOfRegisters = [],
    mapTotalPages = [],
    onClickInTableLine,
    headerColor = false
}: INewTableDefault) {

    const { screenX, screenY } = useScreenSizeContext()
    const [ loading, setLoading ] = useState(true)
    const [ filterHistory, setFilterHistory ] = useState([])
    const [ page, setPage ] = useState(1)
    const [ tableData, setTableData ] = useState([] as any)
    const [ quantityOfRegisters, setQuantityOfRegisters ] = useState(null)
    const [ totalPages, setTotalPages ] = useState(null)
    const [order, setOrder] = useState({
        field: '',
        type: 'desc'
    })

    const dataTypeAccess = [
        { id: 1, value: 'Administrador' },
        { id: 2, value: 'Gestor' },
        { id: 3, value: 'Líder' },
        { id: 4, value: 'Colaborador' }
    ]

    useEffect(() => {
        getData()
    }, [page, filters])

    async function getData(){
        // setLoading(true)
        try {
            const filter = formatFilter()

            if(filterHistory !== filter && filters){
                setFilterHistory(filter as any)
            }

            const api_endpoint = endpoint + '?' + (usePagination ? `page=${page}&` + filter : filter)
            const response = await api.get(api_endpoint, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            mapAndSetTableData(response.data)
            if(usePagination){
                mapAndSetQuantityOfRegisters(response.data)    
                mapAndSetTotalPages(response.data)        
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log('NEW TABLE MOBILE ERROR: ', error)
        }
    }

    function formatFilter(){
        const formatFilter = [] as string[]
        if(filters && filters?.length > 0) {
            filters?.map(filter => {
                if(filter?.field && filter?.value){
                    formatFilter.push(`${filter?.field}=${filter?.value?.toString()?.replace(' ', '%')}`)
                }
            })
        }
        return formatFilter.length > 0 ? formatFilter.join('&') : []
    }

    function mapAndSetTableData(apiResponseData: any){
        if(mapApiData?.length > 0){ // mapeia no objeto da resposta o DATA
            const value = utilsMapObjectFinder(apiResponseData, mapApiData)
            setTableData(value)
        } else { // default se não informar
            setTableData(apiResponseData?.data)
        }
    }

    function mapAndSetQuantityOfRegisters(apiResponseData: any){
        if(mapQuantityOfRegisters.length > 0){
            try {
                const value = utilsMapObjectFinder(apiResponseData, mapQuantityOfRegisters)
                setQuantityOfRegisters(value)
            } catch (error) {
                setQuantityOfRegisters(null)
                console.error('NewTableDefault, property: mapQuantityOfRegisters -', error)
            }
        }
    }

    function mapAndSetTotalPages(apiResponseData: any){
        if(mapTotalPages.length > 0){
            try {
                const value = utilsMapObjectFinder(apiResponseData, mapTotalPages)
                setTotalPages(value)
            } catch (error) {
                setTotalPages(null)
                console.error('NewTableDefault, property: mapTotalPages -', error)
            }
        }
    }

    function utilsMapObjectFinder(object: any, mapSequence: string[]){
        let value = object
        for (const key of mapSequence) {
            if (value.hasOwnProperty(key)) {
                value = value[key];
            } else {
                throw new Error(`Chave '${key}' não encontrada nos dados.`)
            }
        }
        return value
    }

    function formatColumnValue(type: string, value: any){
        switch (type) {
            case 'money':
                return Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value)
            case 'date':
                return moment(value).utc(false).format('DD/MM/YYYY')
            case 'datetime':
                return moment(value).utc(false).format('DD/MM/YYYY hh:mm:ss')
            case 'boolean': 
                return value === true ? 'Sim' : 'Não'
            case 'status': 
                return value === true ? 'Ativo' : 'Inativo'
            case 'secondsToHours': 
                return secondsToHHMMSS(value)
            default:
                return value
        }
    }

    function PaginationTotalPages(){
        const arrayPagesPositions = Array.apply(null, Array(totalPages))
        return (
            <div className='flex justify-center items-center gap-4'>
                <FaAngleLeft 
                    className={`${page === 1 ? 'cursor-not-allowed' : 'cursor-pointer'} text-white`}
                    size={16}
                    onClick={() => setPage((prev) => (prev === 1 ? 1 : prev - 1))}
                />
                {
                    arrayPagesPositions.map((item, index) => {
                        const button = <button 
                            className={`hover:text-gray-400 ${index+1 === page && 'underline'} text-sm`}
                            onClick={() => setPage(index + 1)}
                        >{index + 1}</button>
                        let existsPoints = false
                        let showPointers = false

                        if(totalPages && totalPages < 5){
                            return button
                        }else{
                            existsPoints = true
                            if(index == 0 || index + 1 === totalPages){
                                return button
                            }else if( page + 2 > index + 1 && page - 2 < index + 1){
                                return button
                            }else{
                                if (page + 2 < index + 1 || page - 2 > index + 1){
                                    showPointers = true
                                }
                                if (existsPoints && showPointers === false){
                                    return <span className='flex items-center'>...</span>
                                }
                            }
                        }
                            
                    })
                }
                <FaAngleRight 
                    className={`${arrayPagesPositions.length === page ? 'cursor-not-allowed' : 'cursor-pointer'} text-white`}
                    size={16}
                    onClick={() => setPage((prev) => (arrayPagesPositions.length === page ? prev : prev + 1))}
                />
            </div>
        )
    }
    
    function BasicPagination({ size }: { size?: number }){
        return (
            <div className='flex justify-center items-center gap-4'>
                <FaAngleLeft 
                    className={`${page === 1 ? 'cursor-not-allowed' : 'cursor-pointer'} text-titleGrayTextLight `}
                    size={size ?? 16}
                    onClick={() => setPage((prev) => (prev === 1 ? 1 : prev - 1))}
                />
                <p className='text-sm text-titleGrayTextLight'>Página: {page}</p>
                <FaAngleRight 
                    className={`${tableData.length === 0 ? 'cursor-not-allowed' : 'cursor-pointer'} text-titleGrayTextLight `}
                    size={size ?? 16}
                    onClick={() => setPage((prev) => (tableData.length === 0 ? prev : prev + 1))}
                />
            </div>
        )
    }

    function Table(){
        function Thead(){
            return <thead className='w-full'>
                <tr className={`${headerColor ? ' bg-primaryColor text-white' : 'text-gray-500'} border-b border-gray-200`}>
                    {
                        columns.map((column, index) => {
                            return (
                                <th 
                                    key={'th-'+index} 
                                    className='text-sm py-2' 
                                    onClick={
                                        !column.orderBy 
                                            ? ()=> {} 
                                            : () => setOrder({
                                                field: column.field ? column.field : '',
                                                type: column.field !== order.field ? 'asc' : order.type === 'desc' ? 'asc' : 'desc' 
                                            })
                                        }>
                                            { 
                                                !column.orderBy  ? 
                                                    column.description
                                                :
                                                    <div className='flex justify-center cursor-pointer' key={index}>
                                                        {column.description}
                                                        {/* Renderiza CaretUp ou CaretDown conforme o estado da coluna atual */}
                                                        {order.field === column.field ? (
                                                            order.type === 'asc' ? 
                                                            <CaretUp className="mt-1 ml-1 font-semibold" /> : 
                                                            <CaretDown className="mt-1 ml-1 font-semibold" />
                                                        ) : (
                                                            // CaretDown padrão para colunas que têm orderBy true
                                                            <CaretDown className="mt-1 ml-1 text-gray-400" />
                                                        )}
                                                    </div>
                                            }
                                </th>
                            )
                        })
                    }
                </tr>
            </thead>
        }

        function Tbody(){
            return <tbody>
                {
                    loading ?
                        <tr>
                            <td colSpan={columns.length} className={`text-sm text-center py-2`}>
                                <Box className='w-full flex flex-col  items-center gap-2 justify-center mt-5'>
                                    <Box className='animate-bounce'>
                                        <FaCog className='text-zinc-300 text-6xl animate-spin-slow transition-all duration-500 '></FaCog>
                                    </Box>
                                    <p className='text-sm text-zinc-700'>Carregando...</p>
                                </Box>
                            </td>
                        </tr>
                        : <></>
                }
                {tableData.length > 0 && !loading ? 
                    
                        order.field === '' ?
                            tableData.map((data: any, row: number) => {
                                return (
                                    <tr 
                                        className={`${onClickInTableLine && 'cursor-pointer'} hover:bg-gray-100`}
                                        onClick={() => typeof onClickInTableLine === 'function' && onClickInTableLine(data)}
                                        key={`tr-${row}`} 
                                    >
                                        {columns.map((column, index) => {
                                            const field = typeof column?.newFieldValue === 'function' ? column?.newFieldValue(data)
                                                : column.field ? data[column.field] 
                                                : null 
                                            let formattedValue = '-'
                                            if(![undefined, null].includes(field)){
                                                formattedValue = formatColumnValue(column?.type, field)
                                            }
                                            return (
                                                <td key={`td-${index}`} className={`text-sm text-center py-2`}>{formattedValue}</td>
                                            )
                                        })}
                                    </tr>
                                )
                            })
                        :
                            tableData?.sort((a: any, b: any) => {
                                const field = order.field;

                                if(order.field === 'priority'){
                                    a.priorityDetails = priorityList.find(p => p.id === a.priority)
                                    b.priorityDetails = priorityList.find(p => p.id === b.priority)
                                }

                                if(order.field === 'status'){
                                    a.statusDetails = tasksStatusList.find(t => t.id === a.status)
                                    b.statusDetails = tasksStatusList.find(t => t.id === b.status)
                                }

                                if(order.field === 'auth_typeAccess_id') {
                                    a.accessDetails = dataTypeAccess.find(d => d.id === a.auth_typeAccess_id)
                                    b.accessDetails = dataTypeAccess.find(d => d.id === b.auth_typeAccess_id)
                                }

                                const aValue = 
                                    order.field === 'priority' ? a.priorityDetails.description 
                                    : order.field === 'status' ? a.statusDetails.description.split() 
                                    : order.field === 'auth_typeAccess_id' ? a.accessDetails.value 
                                    : typeof a[field] === 'string' ?  a[field].toLowerCase()
                                    : a[field];
                                const bValue = 
                                    order.field === 'priority' ? b.priorityDetails.description 
                                    : order.field === 'status' ? b.statusDetails.description.split()
                                    : order.field === 'auth_typeAccess_id' ? b.accessDetails.value
                                    : typeof b[field] === 'string' ?  b[field].toLowerCase()
                                    : b[field];
                                
                                    if (aValue == null && bValue == null) {
                                        return 0;
                                    }
                                    if (aValue == null) {
                                        return 1;
                                    }
                                    if (bValue == null) {
                                        return -1;
                                    }
                                
                                    // Comparação de valores não nulos
                                    if (aValue < bValue) {
                                        return order.type === 'asc' ? -1 : 1;
                                    }
                                    if (aValue > bValue) {
                                        return order.type === 'asc' ? 1 : -1;
                                    }
                                return 0;
                            })
                            .map((data: any, row: number) => {
                                return (
                                    <tr 
                                        className={`${onClickInTableLine && 'cursor-pointer'} hover:bg-gray-100`}
                                        onClick={() => typeof onClickInTableLine === 'function' && onClickInTableLine(data)}
                                        key={`tr-${row}`} 
                                    >
                                        {columns.map((column, index) => {
                                            const field = typeof column?.newFieldValue === 'function' ? column?.newFieldValue(data)
                                                : column.field ? data[column.field] 
                                                : null 
                                            let formattedValue = '-'
                                            if(![undefined, null].includes(field)){
                                                formattedValue = formatColumnValue(column?.type, field)
                                            }
                                            return (
                                                <td key={`td-${index}`} className={`text-sm text-center py-2`}>{formattedValue}</td>
                                            )
                                        })}
                                    </tr>
                                )
                            })
                    
                    : <></>
                }
                {tableData.length === 0 && !loading ?
                    <tr>
                        <td colSpan={columns.length} className='text-center py-2'> Nenhum registro foi encontrado.</td>
                    </tr>
                    : <></>
                }   
            </tbody>
        }

        function Tfoot(){
            return <tfoot>
                {usePagination && loading ?
                    <tr className='bg-primarytext-primaryColor'>
                        <td colSpan={columns.length}>
                            <div className='w-full h-6 flex px-2 justify-between items-center'/>
                        </td>
                    </tr>
                    : <></>
                }
                {usePagination && !loading ?
                    <tr className='bg-primarytext-primaryColor text-titleGrayTextLight border-t '>
                        <td colSpan={columns.length}>
                            <div className='w-full h-full flex px-2 justify-between items-center'>
                                {
                                    quantityOfRegisters ? 
                                        <p className='text-sm'>
                                            Total de Registros: {quantityOfRegisters}
                                        </p>
                                        : <></>
                                }
                                {
                                    !!totalPages ? 
                                        <PaginationTotalPages/>
                                        : <></>
                                }
                                {
                                    !totalPages ?
                                        <BasicPagination/>
                                        : <></>
                                }
                            </div>
                        </td>
                    </tr>
                    : <></>
                }
            </tfoot>
        }

        return (
            <div>
                <table className='w-full select-none border-collapse table-auto'>
                    <Thead/>
                    <Tbody/>
                    <Tfoot/>
                </table>
            </div>
        )
    }

    function MobileList(){
        function TableMobile(){
            return tableData.map((data: any) => {
                return (
                    <table className='w-full select-none border-collapse table-auto border border-gray-300 rounded-md'>
                        <tbody>
                            {
                                columns.map(column => {
                                    const field = typeof column?.newFieldValue === 'function' ? column?.newFieldValue(data)
                                        : column.field ? data[column.field] 
                                        : null 
                                    let formattedValue = '-'
                                    if(![undefined, null].includes(field)){
                                        formattedValue = formatColumnValue(column?.type, field)
                                    }
                                    return (
                                        <tr className='bg-slate-100'>
                                            <td className='border-none text-primaryColor px-2 py-1 mt-1'>
                                                {column.description}
                                            </td>
                                            <td className='px-2'>{formattedValue}</td>
                                        </tr>
                                    )
                                })
                            }
                            {
                                onClickInTableLine ?
                                <tr className='bg-primarytext-primaryColor my-0.5 text-white'>
                                    <td className='' colSpan={2} onClick={() => onClickInTableLine(data)}>
                                        <div className='h-1'></div>
                                        <p className='flex gap-2 items-center justify-center py-1'>Editar <FaEdit/></p>
                                    </td>
                                </tr>
                                : <></>
                            }
                        </tbody>
                    </table>
                )
            })
        }

        function Pagination(){
            return (
                <>
                    {
                        usePagination && !loading ?
                            <div className='bg-primarytext-primaryColor text-titleGrayTextLight border-t mt-3 mb-1'>
                                <div className='w-full h-full flex px-2 justify-between items-center py-1'>
                                    {
                                        quantityOfRegisters ? 
                                            <p className='text-base'>
                                                Total de Registros: {quantityOfRegisters}
                                            </p>
                                            : <></>
                                    }
                                    {
                                        !!totalPages ? 
                                            <PaginationTotalPages/>
                                            : <></>
                                    }
                                    {
                                        !totalPages ?
                                            <BasicPagination/>
                                            : <></>
                                    }
                                </div>
                            </div>
                            : <></>
                    }
                </>
            )
        }
        
        return (
            <div className='flex flex-col gap-2 w-full'>
                <TableMobile/>
                <Pagination/>
            </div>
        )
        
    }

    const textColorOptions = {
        'yellow': 'text-yellow-500',
        'red': 'text-red-700',
        'green': 'text-green-700',
        'blue': 'text-sky-700',
        'gray': 'text-zinc-400',
        'default': 'text-zinc-600',
    }

    return (
        <div className="bg-white relative w-full text-titleBlackTextLight overflow-auto">
            {
                screenX <= 750 ? <MobileList/> : <Table/>
            }
        </div>
    )
}