import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react'

const ScreenSizeContext = createContext<{
    screenX: number,
    setScreenX: React.Dispatch<React.SetStateAction<number>>,
    screenY: number,
    setScreenY:  React.Dispatch<React.SetStateAction<number>>
} | undefined>(undefined)

export function ScreenSizeProvider({ children }: { children: ReactNode }) {

    const [screenX, setScreenX] = useState(0)
    const [screenY, setScreenY] = useState(0)

    useEffect(() => {
        
        setScreenX(window.innerWidth)
        setScreenY(window.innerHeight)

        window.addEventListener('resize', () => getSize())

        return () => window.removeEventListener('resize', () => getSize())

        function getSize() {

            setScreenX(window.innerWidth)
            setScreenY(window.innerHeight)
        }

    }, [])

    return <ScreenSizeContext.Provider value={{
        screenX,
        setScreenX,
        screenY,
        setScreenY
    }}>
        {children}
    </ScreenSizeContext.Provider>
}



export function useScreenSizeContext(){
    const context = useContext(ScreenSizeContext)

    if (!context) {
        throw new Error("useScreenModalContext must be used within an ScreenModalProvider");
    }

    return context;
}
