import { Container } from "../../../components/container/container"
import { useAuthContext } from "../../../contexts/authContext"
import { NewTableDefault } from "../../../components/tables/newTableDefault"
import { Text } from "@chakra-ui/react"
import { tasksStatusList } from "../../../utils/list/tasksStatusList"
import { priorityList } from "../../../utils/list/priorityList"
import { Warning } from '@phosphor-icons/react'
import moment from "moment"
import Tippy from "@tippyjs/react"
import { AutocompletePrime } from "../../../components/input/autocompletePrime"
import { useEffect, useState } from "react"
import { IUsers } from "../../../services/interfaces/auth/users"
import { api } from "../../../config/api"
import { Loader } from "../../../components/loader/loader"
import { useScreenModalContext } from "../../../contexts/screenModalContext"

export function MyTasks() {

    const { setTaskModal } = useScreenModalContext()
    const { userData } = useAuthContext()
    const [loading, setLoading] = useState(true)
    const [selectedUser, setSelectedUser] = useState<any>()
    const [allUsers, setAllUsers] = useState<IUsers[]>()

    async function getUsers() {
        try {
            const users = await api.get(`/api/v1/auth/users`, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            const orderedUsers = users.data.data.sort((a: any, b: any) => {
                return a.fullname.localeCompare(b.fullname);
            });

            setAllUsers(orderedUsers)
            setLoading(false)

        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    useEffect(() => {
        getUsers()
    }, [selectedUser])

    return (
        <>
            {
                loading ?
                    <Loader />
                    :
                    <Container noBackground={false} title={{
                        name: 'Minhas Tarefas'
                    }}>
                        {
                            userData?.auth_typeAccess_id === 1 || userData?.auth_typeAccess_id === 2 || userData?.auth_typeAccess_id === 3 ?
                                <div className="flex flex-col">
                                    <p>Analista:</p>
                                    <div className="pb-4 flex flex-row w-full justify-start">
                                        
                                        <AutocompletePrime
                                            items={allUsers as IUsers[]}
                                            placeholder="Selecione um analista"
                                            label="fullname"
                                            value="id"
                                            size={50}
                                            className="mb-1"
                                            preselectedItem={selectedUser?.fullname}
                                            onChange={e => {
                                                setSelectedUser(e?.value)
                                            }}
                                        />
                                    </div>
                                </div>
                                : <></>
                        }
                        <NewTableDefault
                            endpoint={selectedUser?.value ? `/api/v1/pro/tasksAssigned/usersTasks/${selectedUser?.value}` : '/api/v1/pro/tasksAssigned/myTasks'}
                            columns={[
                                {
                                    description: "Projeto",
                                    field: "pro_projects_name",
                                    type: "string",
                                    orderBy: true,
                                },
                                {
                                    description: "Tarefa",
                                    field: "title",
                                    type: "string",
                                    orderBy: true,
                                    newFieldValue: (e) => {
                                        return <Tippy
                                            content={<span>{e?.title}</span>}
                                            arrow={true}
                                            animation='perspective'
                                            placement='top'
                                            delay={80}
                                        >
                                            <p>{e?.title?.length > 55 ? e?.title?.slice(0, 55) + '...' : e?.title}</p>
                                        </Tippy>
                                    }
                                },
                                {
                                    description: "Prioridade",
                                    type: 'string',
                                    field: 'priority',
                                    orderBy: true,
                                    newFieldValue: (e) => {
                                        const priority = priorityList.find(p => p.id === e?.priority ?? 'low')
                                        return <div className="w-full flex items-center justify-center">
                                            <p className={`${priority?.bgColor} p-1 rounded-md  ${priority?.textColor} text-xs font-semibold`}>
                                                {priority?.description}
                                            </p>
                                        </div>
                                    }
                                },
                                {
                                    description: "Status",
                                    type: 'string',
                                    field: 'status',
                                    orderBy: true,
                                    newFieldValue: (e) => {
                                        const status = tasksStatusList.find(s => s.id === e?.status ?? 0)
                                        return <div className="w-full flex items-center justify-center">
                                            <p className={`${status?.bgColor} p-1 rounded-md ${status?.textColor} text-xs font-semibold`}>
                                                {status?.description}
                                            </p>
                                        </div>
                                    }
                                },
                                {
                                    description: "Incio",
                                    type: 'date',
                                    field: 'startDate',
                                    orderBy: true,
                                },
                                {
                                    description: "Prazo",
                                    type: 'string',
                                    field: 'dueDate',
                                    orderBy: true,
                                    newFieldValue: (e) => {
                                        if (e?.dueDate) {
                                            const formatedDueDate = moment(e.dueDate).utc(false).format('DD/MM/YYYY')
                                            const isExpired = Number(new Date(e.dueDate)) <= Number(new Date())

                                            if (isExpired && e?.status !== 1) {
                                                return <div className={`${isExpired && e?.status !== 1 ? 'text-red-500' : ''}  flex items-center justify-center gap-1 w-full`}>
                                                    <p>{formatedDueDate}</p>                                                   
                                                </div>
                                            }
                                            return formatedDueDate
                                        }
                                        return '-'
                                    }
                                },
                                {
                                    description: "Etapa",
                                    type: 'string',
                                    field: 'pro_projectsSteps_name',
                                    orderBy: true,
                                    newFieldValue: (e) => {
                                        return <Text color={e.pro_projectsSteps_color ? e.pro_projectsSteps_color : ''} className="py-1 px-1">
                                            {e.pro_projectsSteps_name}
                                        </Text>
                                    }
                                },
                                {
                                    description: "Cliente",
                                    type: "string",
                                    field: 'reg_clients_name',
                                    orderBy: true,
                                    newFieldValue: (e) => {
                                        return e?.reg_clients_name?.length > 20 ? e?.reg_clients_name?.slice(0, 24) + '...' : e?.reg_clients_name
                                    },
                                },
                            ]}
                            token={userData?.token as string}
                            usePagination={false}
                            filters={[]}
                            onClickInTableLine={(e) =>
                                setTaskModal({
                                    pro_projects_id: e.pro_projects_id,
                                    pro_tasks_id: e.id,
                                    pro_projectsSteps_id: e.pro_projectsSteps_id
                                })
                            }
                        />
                    </Container>
            }
        </>
    )
}