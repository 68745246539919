import { useEffect, useRef, useState } from 'react'
import { useScreenModalContext } from '../../contexts/screenModalContext'
import {
    Avatar,
    AvatarGroup,
    Box,
    Flex,
    FormControl,
    FormLabel,
    InputGroup,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Select,
    Spinner,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Text,
    useDisclosure
} from '@chakra-ui/react'

import { api } from '../../config/api'
import { useAuthContext } from '../../contexts/authContext'
import { ITasks, ITasksFiles } from '../../services/interfaces/pro/tasks'
import { LabelInput } from '../label/label.input'
import { InputHTMLDefault } from '../input/inputHtmlDefault'
import { ButtonDefault } from '../button/buttonDefault'
import { formValidator } from '../../utils/form.validator'
import { useAlertContext } from '../../contexts/alertContext'
import { ChatDots, Check, CheckCircle, Download, Files, ListChecks, Play, Plus, Tag, Trash, UserCirclePlus, UserPlus, X } from '@phosphor-icons/react'
import Tippy from '@tippyjs/react'
import { IChecklist, INewAttachment } from '../../pages/pro/projectsViews/projectsViews'
import { InputDefault } from '../input/inputDefault'
import moment from 'moment'
import { TextAreaDefault } from '../textArea/textAreaHtml'
import { Loader } from '../loader/loader'
import { SelectDefault } from '../selects/selectDefault'
import { IProjectsMembers } from '../../services/interfaces/pro/projectsMembers'
import { useNavigate } from 'react-router-dom'
import { FaCog } from 'react-icons/fa'
import { IProjectsTags } from '../../services/interfaces/pro/projectsTags'
import { useAllUsersContext } from '../../contexts/allUsersContext'
import { IProjectsSteps } from '../../services/interfaces/pro/projectsSteps'

export function TaskModal({ task }: ITaskModal) {

    const navigate = useNavigate()
    const { onClose } = useDisclosure()
    const { userData } = useAuthContext()
    const [userIsExternal, setUserIsExternal] = useState<boolean>(userData?.auth_typeAccess_id && userData?.auth_typeAccess_id > 4 ? true : false)
    const { setAlert } = useAlertContext()
    const { allUsers } = useAllUsersContext()
    const { setTaskModal, setDetectModal } = useScreenModalContext()
    const [loading, setLoading] = useState(task.externalCreateCallback ? false : true)
    const [taskData, setTaskData] = useState<ITasks>()
    const [taskStatus, setTaskStatus] = useState('')
    const [taskPriority, setTaskPriority] = useState('')
    const [thisTask, setThisTask] = useState<any>({
        startDate: null,
        dueDate: null,
        finishDate: null
    })
    const [oldFinishDate, setOldFinishDate] = useState<Date>()
    const [tabIndex, setTabIndex] = useState(0)

    const [projectsTags, setProjectsTags] = useState<IProjectsTags[]>([])
    const [projectsMembers, setProjectsMembers] = useState<IProjectsMembers[]>([])
    const [projectsSteps, setProjectsSteps] = useState<IProjectsSteps[]>([])
    const taskTitleRef = useRef<HTMLInputElement>(null)

    //checklist
    const [inCreationChecklist, setInCreationChecklist] = useState(null as null | IChecklist)
    const newTaskCheckItemTitleRef = useRef<HTMLInputElement>(null)
    const [loadingNewCheck, setLoadingNewCheck] = useState<boolean>(false);

    //comentarios
    const [dataCreateComment, setDataCreateComment] = useState({ description: '' })
    const [addNewComment, setAddNewComment] = useState<boolean>(false)

    //editTask
    const taskEditTitleRef = useRef<HTMLInputElement>(null)

    //files
    const [newAttach, setNewAttach] = useState(null as null | INewAttachment)
    const inputAttachRef = useRef<HTMLInputElement>(null)

    const [addNewTime, setAddNewTime] = useState(null as null | ITime)

    const [isToAddNewTime, setIsToAddNewTime] = useState<boolean>(false)

    useEffect(() => {
        if (task && !task.externalCreateCallback) {
            getData();
        }
        const tabView = ['tasks', 'times', 'comentaries', 'files']
        const selectedView = tabView.findIndex(v => v === task.openTab)
        if (selectedView > -1) {
            setTabIndex(selectedView)
        }
    }, [])

    async function getData() {
        try {
            setLoading(true)
            await Promise.all([
                await validAndGetTaskData(),
                await validAndGetProjectSteps(),
                await validAndGetProjectMembers(),
                await validAndGetProjectTags()
            ])
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    async function validAndGetTaskData() {
        if (
            task?.taskData?.pro_tasksAssigned &&
            task?.taskData?.pro_tasksChecklist &&
            task?.taskData?.pro_tasksComments &&
            task?.taskData?.pro_tasksFiles &&
            task?.taskData?.pro_tasksTags &&
            task?.taskData?.pro_tasksTimes
        ) {
            setTaskData(task?.taskData)
            setThisTask(task?.taskData)
            setOldFinishDate(task?.taskData?.finishDate as Date)
            return
        }

        const taskDataApi = await api.get(`/api/v1/pro/tasks/${task.pro_tasks_id}/project/${task.pro_projects_id}`, {
            headers: {
                Authorization: `Bearer ${userData?.token}`
            }
        })

        setTaskData(taskDataApi?.data?.data)
        setThisTask(taskDataApi?.data?.data)
        setOldFinishDate(taskDataApi?.data?.data?.finishDate)
        return
    }

    async function validAndGetProjectSteps() {
        if (task?.pro_projectsSteps) {
            setProjectsSteps(task.pro_projectsSteps)
            return
        }
        const stepsDataApi = await api.get(`/api/v1/pro/projectsSteps/project/${task.pro_projects_id}`, {
            headers: {
                Authorization: `Bearer ${userData?.token}`
            }
        })
        setProjectsSteps(stepsDataApi?.data?.data)
        return
    }

    async function validAndGetProjectMembers() {
        if (task?.pro_projectsMembers) {
            setProjectsMembers(task.pro_projectsMembers)
            return
        }
        const membersDataApi = await api.get(`/api/v1/pro/projectsMembers/project/${task.pro_projects_id}`, {
            headers: {
                Authorization: `Bearer ${userData?.token}`
            }
        })
        setProjectsMembers(membersDataApi?.data?.data)
        return
    }

    async function validAndGetProjectTags() {
        if (task?.pro_projectsTags) {
            setProjectsTags(task.pro_projectsTags)
            return
        }

        const tagsDataApi = await api.get(`/api/v1/pro/projectsTags/project/${task.pro_projects_id}`, {
            headers: {
                Authorization: `Bearer ${userData?.token}`
            }
        })

        setProjectsTags(tagsDataApi?.data?.data)
        return
    }

    async function handleAddNewTask() {
        try {
            const requiredFields = formValidator([
                { "name": 'taskTitle', "value": taskTitleRef.current?.value, "required": true, "type": 'string' },
            ])

            if (requiredFields.length > 0) {
                return setAlert({
                    status: "error",
                    title: "Campos obrigatórios",
                    description: "Campos obrigatórios não preenchidos"
                })
            }
            setLoading(true)

            const created = await api.post(`/api/v1/pro/tasks/project/${task.pro_projects_id}`, {
                title: taskTitleRef.current?.value,
                description: taskData?.description ? taskData?.description : undefined,
                priority: taskPriority ? taskPriority : undefined,
                dueDate: thisTask?.dueDate ? thisTask?.dueDate : undefined,
                startDate: thisTask?.startDate ? thisTask?.startDate : undefined,
                status: taskStatus ? Number(taskStatus) : undefined,
                pro_projectsSteps_id: task?.pro_projectsSteps_id
            }, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })
            setLoading(false)
            setAlert({
                title: "Sucesso",
                description: "Sucesso ao criar tarefa",
                status: "success"
            })
            if (task.externalCreateCallback) {
                task.externalCreateCallback(created.data.data)
            }
            setTaskModal(null)

        } catch (error) {
            console.log('Error on try to create a new task:', error)
            setAlert({
                status: "error",
                title: "Erro",
                description: (error as any)?.response?.data?.message || "Erro ao criar tarefa"
            })
        }
    }

    async function handleTaskEdit(toClose?: boolean) {
        //fecha drawer

        thisTask.title = taskEditTitleRef.current?.value ? taskEditTitleRef.current?.value : thisTask.title

        setThisTask({ ...thisTask })
        try {
            const updated = await api.put(`/api/v1/pro/tasks/${thisTask.id}/project/${task.pro_projects_id}`, {
                title: thisTask?.title,
                description: thisTask?.description ? thisTask.description : undefined,
                priority: thisTask?.priority ? thisTask?.priority : undefined,
                dueDate: thisTask?.dueDate ? thisTask.dueDate : undefined,
                startDate: thisTask?.startDate ? thisTask.startDate : undefined,
                finishDate: thisTask?.finishDate ? thisTask.finishDate : undefined,
                isRecurrency: thisTask?.isRecurrency ? thisTask?.isRecurrency : false,
                isRecurrencyType: thisTask?.isRecurrency && thisTask?.isRecurrencyType ? thisTask?.isRecurrencyType : "0",
                removed: thisTask?.removed,
                status: (thisTask?.status || thisTask?.status === 0) ? thisTask?.status : undefined,
            }, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            setThisTask({
                ...thisTask,
                dueDate: updated?.data?.data?.dueDate ? updated?.data?.data?.dueDate : '',
                finishDate: updated?.data?.data?.finishDate ? updated?.data?.data?.finishDate : '',
                status: updated?.data?.data?.status
            })
            setOldFinishDate(updated?.data?.data?.finishDate)
            if (task?.externalUpdateCallback) { //callback da task que foi passada de forma externa
                task?.externalUpdateCallback({
                    ...thisTask,
                    dueDate: updated?.data?.data?.dueDate ? updated?.data?.data?.dueDate : '',
                    finishDate: updated?.data?.data?.finishDate ? updated?.data?.data?.finishDate : '',
                    status: updated?.data?.data?.status
                })
            }
            setAlert({
                title: "Sucesso",
                description: "Sucesso ao editar dados da tarefa",
                status: "success"
            })
            if (toClose) {
                setTaskModal(null)
            }
        } catch (error) {
            console.log('Error on try to edit task:', error)
            setAlert({
                status: 'error',
                title: 'Erro',
                description: (error as any)?.response?.data?.message || 'Erro ao editar tarefa'
            })
        }
    }

    async function handleRemoveAssigned(auth_users_id: number) {
        const originalTask = structuredClone(thisTask)
        try {
            const assignedId = thisTask.pro_tasksAssigned.filter((assigned: any) => assigned.auth_users_id === auth_users_id)[0]
            thisTask.pro_tasksAssigned = thisTask.pro_tasksAssigned.filter((assigned: any) => assigned.auth_users_id !== auth_users_id)
            setThisTask({ ...thisTask })
            if (task?.externalUpdateCallback) { //callback da task que foi passada de forma externa
                task?.externalUpdateCallback({ ...thisTask })
            }

            await api.delete(`/api/v1/pro/tasksAssigned/${assignedId.id}/project/${task.pro_projects_id}`, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

        } catch (error) {
            setThisTask({ ...originalTask })
            if (task?.externalUpdateCallback) { //callback da task que foi passada de forma externa
                task?.externalUpdateCallback({ ...originalTask })
            }
            console.log('Error on try to create new item on checklist:', error)
        }
    }

    async function handleNewTaskAssigned(step_id: number, pro_tasks_id: string, auth_users_id: number, fullname: string) {
        const originalTask = structuredClone(thisTask)
        try {
            thisTask.pro_tasksAssigned.push({
                auth_users_id,
                auth_users: { fullname },
                pro_tasks_id: thisTask.id,
                id: ''
            })
            setThisTask({ ...thisTask })
            if (task?.externalUpdateCallback) { //callback da task que foi passada de forma externa
                task?.externalUpdateCallback({ ...thisTask })
            }

            const data = await api.post(`/api/v1/pro/tasksAssigned/project/${task?.pro_projects_id}`, {
                pro_tasks_id,
                auth_users_id
            }, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })
            originalTask.pro_tasksAssigned.push({ ...data.data.data, auth_users: { fullname: allUsers.find(user => user.id === data.data.data.auth_users_id)?.fullname } })
            setThisTask({ ...originalTask })
            if (task?.externalUpdateCallback) { //callback da task que foi passada de forma externa
                task?.externalUpdateCallback({ ...originalTask })
            }
        } catch (error) {
            setThisTask({ ...originalTask })
            if (task?.externalUpdateCallback) { //callback da task que foi passada de forma externa
                task?.externalUpdateCallback({ ...originalTask })
            }
            console.log('Error on try to create new item on checklist:', error)
        }
    }

    async function handleAddTaskTag(tag: any) {
        const originalTask = structuredClone(thisTask)
        try {
            const tagData = await api.post(`/api/v1/pro/tasksTags/project/${task.pro_projects_id}`, {
                pro_projectsTags_id: tag.id,
                pro_tasks_id: thisTask.id
            }, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            thisTask.pro_tasksTags.push(tagData?.data?.data)
            setThisTask({ ...thisTask })
            if (task?.externalUpdateCallback) { //callback da task que foi passada de forma externa
                task?.externalUpdateCallback({ ...thisTask })
            }
        } catch (error) {
            setThisTask({ ...originalTask })
            if (task?.externalUpdateCallback) { //callback da task que foi passada de forma externa
                task?.externalUpdateCallback({ ...originalTask })
            }
            console.log('Error on try to delete an attached file:', error)
            setAlert({
                status: 'error',
                title: 'Erro',
                description: (error as any)?.response?.data?.message || 'Erro ao adicionar tag'
            })
        }
    }

    async function handleRemoveTaskTag(tag: any) {
        let historicTags = structuredClone(thisTask.pro_tasksTags)
        try {
            thisTask.pro_tasksTags = thisTask.pro_tasksTags.filter((projectsTag: any) => projectsTag.pro_projectsTags_id !== tag.id)
            setThisTask({ ...thisTask })
            if (task?.externalUpdateCallback) { //callback da task que foi passada de forma externa
                task?.externalUpdateCallback({ ...thisTask })
            }

            await api.delete(`/api/v1/pro/tasksTags/task/${thisTask.id}/projectTag/${tag.id}/project/${task.pro_projects_id}`, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

        } catch (error) {
            thisTask.pro_tasksTags = historicTags
            setThisTask({ ...thisTask })
            if (task?.externalUpdateCallback) { //callback da task que foi passada de forma externa
                task?.externalUpdateCallback({ ...thisTask })
            }
            setAlert({
                status: 'error',
                title: 'Erro',
                description: (error as any)?.response?.data?.message || 'Erro ao remover tag'
            })
        }
    }

    async function handleSubmitNewChecklist() {
        try {
            setLoadingNewCheck(true)
            const newChecklist = await api.post(`/api/v1/pro/tasksChecklist/project/${task.pro_projects_id}`, {
                pro_tasks_id: thisTask?.id,
                description: newTaskCheckItemTitleRef.current?.value ? newTaskCheckItemTitleRef.current?.value : undefined
            }, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            thisTask.pro_tasksChecklist.push(newChecklist.data.data)
            setThisTask({ ...thisTask })
            if (task?.externalUpdateCallback) { //callback da task que foi passada de forma externa
                task?.externalUpdateCallback({ ...thisTask })
            }

            setInCreationChecklist(null)
            setLoadingNewCheck(false)
        } catch (error) {
            setLoadingNewCheck(false)
            console.log('Error on try to create new item on checklist:', error)
        }
    }

    async function handleUpdateCheckItem(step_id: number, check_id: string, pro_tasks_id: string, checkStatus: boolean) {
        try {
            // const findChecklist = projectsSteps.filter(step => step.id === step_id)[0].pro_tasks?.find((i: ITasks) => i.id === pro_tasks_id)
            const findItemCheck = thisTask?.pro_tasksChecklist?.find((i: any) => i.id === check_id)
            if (findItemCheck) {
                findItemCheck.check = checkStatus
            }
            setThisTask({ ...thisTask })
            if (task?.externalUpdateCallback) { //callback da task que foi passada de forma externa
                task?.externalUpdateCallback({ ...thisTask })
            }

            await api.put(`/api/v1/pro/tasksChecklist/${check_id}/project/${task.pro_projects_id}`, {
                check: checkStatus
            }, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })
        } catch (error) {
            const findItemCheck = thisTask?.pro_tasksChecklist?.find((i: any) => i.id === check_id)
            if (findItemCheck) {
                findItemCheck.check = !checkStatus
            }
            setThisTask({ ...thisTask })
            if (task?.externalUpdateCallback) { //callback da task que foi passada de forma externa
                task?.externalUpdateCallback({ ...thisTask })
            }
            console.log('Error on try to update an item on checklist:', error)
        }
    }

    async function handleDeleteItemChecklist(step_id: number, check_id: string, pro_tasks_id: string, indexToRemove: number) {
        const originalTask = structuredClone(thisTask)
        try {

            thisTask?.pro_tasksChecklist?.splice(indexToRemove, 1)
            setThisTask({ ...thisTask })
            if (task?.externalUpdateCallback) { //callback da task que foi passada de forma externa
                task?.externalUpdateCallback({ ...thisTask })
            }

            await api.delete(`/api/v1/pro/tasksChecklist/${check_id}/project/${task.pro_projects_id}`, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

        } catch (error) {
            setThisTask({ ...originalTask })
            if (task?.externalUpdateCallback) { //callback da task que foi passada de forma externa
                task?.externalUpdateCallback({ ...originalTask })
            }
            console.log('Error on try to create new item on checklist:', error)
        } finally {
        }
    }

    async function handleSubmitNewFile() {
        try {
            setLoading(true)
            const formData = new FormData()
            if (!newAttach || !newAttach.file) {
                setLoading(false)
                return setAlert({
                    status: "error",

                    title: "Ação proibida",
                    description: "É necessario anexar um arquivo para adicionar a tarefa"
                })
            }

            formData.append('data', JSON.stringify({
                pro_tasks_id: thisTask?.id,
                description: newAttach?.description
            }))
            formData.append('file', newAttach.file)

            const uploadFile = await api.post(`/api/v1/pro/tasksFiles/project/${task.pro_projects_id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${userData?.token}`
                }
            })


            thisTask?.pro_tasksFiles?.unshift(uploadFile?.data?.data)
            setThisTask({ ...thisTask })
            if (task?.externalUpdateCallback) { //callback da task que foi passada de forma externa
                task?.externalUpdateCallback({ ...thisTask })
            }
            setLoading(false)
            setNewAttach(null)

        } catch (error) {
            setLoading(false)
            console.log('Error on try to upload new file to attachments:', error)
        }
    }

    async function handleDownloadFile(file: ITasksFiles) {
        try {
            setLoading(true)

            const data = await api.get(`/api/v1/pro/tasksFiles/${file.id}/project/${task.pro_projects_id}`, {
                headers: {
                    'Authorization': `Bearer ${userData?.token}`,
                },
                responseType: 'blob'
            })

            const downloadableUrl = window.URL.createObjectURL(data.data);
            const link = document.createElement('a');
            link.href = downloadableUrl;
            link.download = file.originalname;
            link.click();

            window.URL.revokeObjectURL(downloadableUrl);

            setLoading(false)
        } catch (error) {
            setLoading(false)
            setAlert({
                status: 'error',
                title: 'Erro',
                description: (error as any)?.response?.data?.message || 'Erro ao realizar download do arquivo'
            })
        }
    }

    async function handleDeleteFile(file_id: string, index: number) {
        try {
            setLoading(true)

            await api.delete(`/api/v1/pro/tasksFiles/${file_id}/project/${task.pro_projects_id}`, {
                headers: {
                    'Authorization': `Bearer ${userData?.token}`
                }
            })

            thisTask?.pro_tasksFiles.splice(index, 1)
            setThisTask({ ...thisTask })
            if (task?.externalUpdateCallback) { //callback da task que foi passada de forma externa
                task?.externalUpdateCallback({ ...thisTask })
            }
            setNewAttach(null)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log('Error on try to delete an attached file:', error)
            setAlert({
                status: 'error',
                title: 'Erro',
                description: (error as any)?.response?.data?.message || 'Erro ao realizar exclusão do arquivo'
            })
        }
    }

    async function handleAddTime() {
        try {
            const requiredFields = formValidator([
                { "name": 'time', "value": addNewTime?.ammountSeconds, "required": true, "type": 'number' },
                { "name": 'description', "value": addNewTime?.description, "required": false, "type": 'string' },
                { "name": 'startDate', "value": addNewTime?.startDate, "required": true, "type": 'string' },
            ])
            if (requiredFields.length > 0) return setAlert({
                status: "error",

                title: "Campos obrigatórios",
                description: "Campos obrigatórios não preenchidos"
            })
            let total = Number(addNewTime?.ammountSeconds) * 3600
            let ammountSeconds = addNewTime?.ammountSeconds?.toString() as string
            if (ammountSeconds?.indexOf(":") > -1) {
                const hours = ammountSeconds.split(":")[0]
                const minutes = ammountSeconds.split(":")[1]
                let hoursInSeconds = Number(hours) * 3600
                let minutesInSeconds = Number(minutes) * 60

                total = hoursInSeconds + minutesInSeconds

            }

            setIsToAddNewTime(false)
            const data = {
                description: addNewTime?.description ? addNewTime?.description : undefined,
                isCounting: addNewTime?.isCounting,
                ammountSeconds: total,
                startDate: addNewTime?.startDate,
                pro_tasks_id: thisTask.id,
                billable: addNewTime?.billable
            } as any

            if (userData?.auth_typeAccess_id !== 4) data.auth_users_id = addNewTime?.user_id ? addNewTime.user_id : userData?.id

            const response = await api.post(`/api/v1/pro/tasksTimes/project/${task.pro_projects_id}`, data, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            thisTask?.pro_tasksTimes?.unshift(response?.data?.data)
            setThisTask({ ...thisTask })
            if (task?.externalUpdateCallback) { //callback da task que foi passada de forma externa
                task?.externalUpdateCallback({ ...thisTask })
            }
        } catch (error) {
            console.log('Erro on try to send new taskTime:', error)
            setAlert({
                status: "error",
                title: "Erro",
                description: (error as any)?.response?.data?.message || "Erro ao adicionar apontamento de horas"
            })
        }
    }

    async function handleRemoveTime(timeId: any, index: number) {
        try {

            await api.delete(`/api/v1/pro/tasksTimes/${timeId}/project/${task.pro_projects_id}`, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            thisTask?.pro_tasksTimes.splice(index, 1)
            setThisTask({ ...thisTask })
            if (task?.externalUpdateCallback) { //callback da task que foi passada de forma externa
                task?.externalUpdateCallback({ ...thisTask })
            }
        } catch (error) {
            console.log('Error on try to delete time:', error)
            setAlert({
                status: 'error',
                title: 'Erro',
                description: (error as any)?.response?.data?.message || 'Erro ao tentar deletar apontamento de tempo'
            })
        }
    }

    async function handleAddComment() {
        try {
            setAddNewComment(false)
            const data = await api.post(`/api/v1/pro/tasksComments/project/${task.pro_projects_id}`, {
                description: dataCreateComment.description,
                pro_tasks_id: thisTask.id
            }, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            thisTask?.pro_tasksComments.unshift(data?.data?.data)
            setDataCreateComment({ description: '' })
            setThisTask({ ...thisTask })
            if (task?.externalUpdateCallback) { //callback da task que foi passada de forma externa
                task?.externalUpdateCallback({ ...thisTask })
            }

        } catch (error) {
            console.log('Erro on try to create a new comment:', error)
            setAlert({
                status: "error",
                title: "Erro",
                description: (error as any)?.response?.data?.message || "Erro ao adicionar comentário"
            })
        }
    }

    async function handleRemoveComment(data: any, index: number) {
        try {
            await api.delete(`/api/v1/pro/tasksComments/${data?.id}/project/${task.pro_projects_id}`, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            thisTask?.pro_tasksComments.splice(index, 1)
            setThisTask({ ...thisTask })
            if (task?.externalUpdateCallback) { //callback da task que foi passada de forma externa
                task?.externalUpdateCallback({ ...thisTask })
            }
        } catch (error) {
            console.log(error)
        }
    }

    async function handleUpdateDates(type: string, date: string) {
        if (type === 'startDate') {
            thisTask.startDate = moment(date).utc(false).toDate()
        }
        if (type === 'dueDate') {
            thisTask.dueDate = moment(date).utc(false).toDate()
        }
        if (type === 'finishDate') {
            thisTask.finishDate = moment(date).utc(false).toDate()
        }

        setThisTask({ ...thisTask })
        if (task?.externalUpdateCallback) { //callback da task que foi passada de forma externa
            task?.externalUpdateCallback({ ...thisTask })
        }
        if (type !== 'finishDate' && !task.externalCreateCallback) {
            handleTaskEdit()
        }
    }

    function alterTaskPriority(thisPriority: string) {
        thisTask.priority = thisPriority
        setThisTask({ ...thisTask })
        if (task?.externalUpdateCallback) { //callback da task que foi passada de forma externa
            task?.externalUpdateCallback({ ...thisTask })
        }

        handleTaskEdit()
        onClose()
    }

    function alterTaskStatus(status: number) {
        if (thisTask?.pro_tasksTimes?.length === 0 && status === 1) {
            return setAlert({
                status: 'error',
                title: 'Erro',
                description: 'Não é possível concluir uma tarefa que não possui apontamento de tempo.'
            })
        }

        thisTask.status = status
        setThisTask({ ...thisTask })
        if (task?.externalUpdateCallback) { //callback da task que foi passada de forma externa
            task?.externalUpdateCallback({ ...thisTask })
        }

        handleTaskEdit()
    }

    function closeTask() {
        setTaskModal(null)
        setDetectModal('')
    }

    function handleTotalCount(item: any) {
        if (item) {
            const sec = item.reduce((acumulator: any, current: any) => Number(acumulator) + Number(current.ammountSeconds), 0)
            return `${Math.round(sec / 3600) < 10 ? '0' + Math.floor(sec / 3600) : Math.round(sec / 3600)}:${Math.round(sec % 3600 / 60) < 10 ? '0' + Math.round(sec % 3600 / 60) : Math.round(sec % 3600 / 60)}`
        }
    }

    return (
        <Modal trapFocus={false} closeOnOverlayClick={false} isOpen={true} onClose={() => closeTask()} isCentered>
            <ModalOverlay backgroundColor={``} />
            <ModalContent height={task?.pro_tasks_id === '' ? 550 : '95%'} width={750} maxH={720} maxW={750} >
                <ModalCloseButton />
                <ModalHeader className='flex flex-row justify-between mr-12'>
                    <Text className="text-lg" color={'#1E1E1E'}>{task?.pro_tasks_id === '' ? 'Nova tarefa' : 'Editar Tarefa'}</Text>
                    <Text className='underline cursor-pointer text-sm hover:text-blue-600' color={'#727171'} onClick={() => { navigate(`/viewProject/${task.pro_projects_id}`); closeTask() }} hidden={task.inProjectView === true ? true : false}>
                        Ir para projeto
                    </Text>
                </ModalHeader>
                <ModalBody className='w-full overflow-auto'>
                    {
                        loading ?
                            <Flex className='justify-center items-center' height="35vh">
                                <Spinner size="xl" />
                            </Flex>
                            : !task?.pro_tasks_id ?
                                // Formulário para criação de Task
                                <div className='w-full mb-4'>
                                    <InputGroup className="flex flex-col items-start mb-2">
                                        <LabelInput>Nome *</LabelInput>
                                        <InputHTMLDefault
                                            disabled={userIsExternal}
                                            id="taskTitle"
                                            defaultValue={taskData?.title
                                                ? taskData?.title
                                                : ''
                                            }
                                            ref={taskTitleRef}
                                        >
                                        </InputHTMLDefault>
                                    </InputGroup>
                                    <InputGroup className="flex flex-col items-start mb-2">
                                        <LabelInput>Descrição</LabelInput>
                                        <TextAreaDefault
                                            value={taskData?.description}
                                            onChange={(e) => setTaskData((prev: any) => ({ ...prev, description: e.target.value }))}
                                        >
                                            {taskData?.description ? taskData?.description : ''}
                                        </TextAreaDefault>
                                    </InputGroup>
                                    <Flex className='gap-4 mb-2'>
                                        <div className='w-full'>
                                            <LabelInput>Data inicial</LabelInput>
                                            <div className="flex flex-col">
                                                <InputDefault
                                                    value={thisTask?.startDate ? moment(thisTask.startDate).utc(false).format("YYYY-MM-DD") : ''}
                                                    onChange={(e) => handleUpdateDates('startDate', e.target.value)}
                                                    id="startDate"
                                                    type="date"
                                                />
                                            </div>
                                        </div>
                                        <div className='w-full'>
                                            <LabelInput>Data vencimento</LabelInput>
                                            <div className="flex flex-col">
                                                <InputDefault
                                                    value={thisTask?.dueDate ? moment(thisTask.dueDate).utc(false).format("YYYY-MM-DD") : ''}
                                                    onChange={(e) => handleUpdateDates('dueDate', e.target.value)}
                                                    id="dueDate"
                                                    type="date"
                                                />
                                            </div>
                                        </div>
                                    </Flex>
                                    <Flex>
                                        <InputGroup className="flex flex-col items-start pr-2">
                                            <LabelInput>Prioridade *</LabelInput>
                                            <Select disabled={userIsExternal} size="sm" value={task.pro_projects_id ? taskData?.priority : taskPriority ? taskPriority : 'low'} onChange={e => setTaskPriority(e.target.value)} height={8}>
                                                <option value={"low"}>Baixa</option>
                                                <option value={"medium"}>Moderada</option>
                                                <option value={"high"}>Urgente</option>
                                            </Select>
                                        </InputGroup>
                                        <InputGroup className="flex flex-col items-start pr-2">
                                            <LabelInput>Status *</LabelInput>
                                            <Select size="sm" value={task.pro_projects_id ? taskData?.status : taskStatus ? taskStatus : '0'} onChange={e => setTaskStatus(e.target.value)} height={8}>
                                                <option value={"0"}>Pendente</option>
                                                <option value={"2"}>Em andamento</option>
                                                <option value={"4"}>Aguardando retorno</option>
                                                <option value={"3"}>Parado</option>
                                                <option value={"1"}>Concluído</option>
                                            </Select>
                                        </InputGroup>
                                    </Flex>
                                </div>
                                :
                                <Tabs variant='enclosed' index={tabIndex}>
                                    <TabList>
                                        <Tab className='gap-1' onClick={() => setTabIndex(0)}>
                                            <CheckCircle size={16} />
                                            Tarefa
                                        </Tab>
                                        <Tab className='gap-1' onClick={() => setTabIndex(1)}>
                                            <Play size={16} />
                                            <div className="px-1 bg-zinc-200 rounded-full ">
                                                <Text fontSize={10} className="">
                                                    {handleTotalCount(thisTask?.pro_tasksTimes)}
                                                </Text>
                                            </div>
                                            Apontamentos
                                        </Tab>
                                        <Tab className='gap-1' onClick={() => setTabIndex(2)}>
                                            <ChatDots size={16} />
                                            <div className="px-1 bg-zinc-200 rounded-full ">
                                                <Text fontSize={10}>
                                                    {thisTask?.pro_tasksComments?.length}
                                                </Text>
                                            </div>
                                            Comentários
                                        </Tab>
                                        <Tab className='gap-1' onClick={() => setTabIndex(3)}>
                                            <Files size={16} />
                                            <div className="px-1 bg-zinc-200 rounded-full ">
                                                <Text fontSize={10} className="">
                                                    {thisTask?.pro_tasksFiles?.length}
                                                </Text>
                                            </div>
                                            Arquivos
                                        </Tab>
                                    </TabList>
                                    <TabPanels>
                                        <TabPanel>
                                            {/* // Formulário para Edição de Task */}
                                            <>
                                                <div className='w-full mb-4'>
                                                    {/* Nome da task */}
                                                    <InputGroup className="flex flex-col items-start mb-4">
                                                        <LabelInput>Nome *</LabelInput>
                                                        <InputHTMLDefault
                                                            disabled={userIsExternal}
                                                            id="taskTitle"
                                                            defaultValue={taskData?.title
                                                                ? taskData?.title
                                                                : ''
                                                            }
                                                            ref={taskEditTitleRef}
                                                        >
                                                        </InputHTMLDefault>
                                                    </InputGroup>

                                                    {/* Vinculo de usuario a task */}
                                                    <Box className='flex gap-4 mb-4' as='span' flex='1' textAlign='left'>
                                                        <UserPlus className='mt-1' size={20} />

                                                        <Popover onClose={onClose} variant={'responsive'} size={'sm'} >
                                                            <PopoverTrigger >
                                                                <div>
                                                                    {
                                                                        thisTask?.pro_tasksAssigned && thisTask?.pro_tasksAssigned?.length > 0 ?

                                                                            <AvatarGroup size='sm' max={5}>
                                                                                {
                                                                                    thisTask?.pro_tasksAssigned?.map((taskAssigned: any) => {
                                                                                        let user = allUsers.find(u => u.id === taskAssigned.auth_users_id)
                                                                                        return (

                                                                                            thisTask?.pro_tasksAssigned?.length === 1 ?
                                                                                                <div className="cursor-pointer">
                                                                                                    <Avatar className="mr-2" size={'sm'} name={user?.fullname} src={`${user?.picture}`} />
                                                                                                    {user?.fullname}
                                                                                                </div>
                                                                                                :
                                                                                                <Avatar className="cursor-pointer" name={user?.fullname} src={`${user?.picture}`} />

                                                                                        )

                                                                                    })
                                                                                }
                                                                            </AvatarGroup>
                                                                            :
                                                                            <Tippy
                                                                                content={<span>Vazio - Atribuir</span>}
                                                                                arrow={true}
                                                                                animation='perspective'
                                                                                placement='top-start'
                                                                                delay={80}>
                                                                                <div className='flex'>
                                                                                    <UserCirclePlus size={28} className="cursor-pointer text-primaryColor" />
                                                                                    Atribuir
                                                                                </div>
                                                                            </Tippy>
                                                                    }
                                                                </div>
                                                            </PopoverTrigger>
                                                            <PopoverContent>
                                                                <PopoverArrow />
                                                                <PopoverBody>
                                                                    <div className="flex flex-col w-56 gap-3">
                                                                        <p className="text-sm font-semibold">Atribuído</p>
                                                                        {

                                                                            thisTask?.pro_tasksAssigned && thisTask?.pro_tasksAssigned.length > 0 ?
                                                                                thisTask?.pro_tasksAssigned?.map((taskAssigned: any) => {
                                                                                    let user = allUsers.find(u => u.id === taskAssigned.auth_users_id)
                                                                                    return <div className="flex gap-2 w-full items-center justify-between">
                                                                                        <div className="flex gap-2 items-center justify-start">
                                                                                            <Avatar className="" size={'sm'} name={user?.fullname} src={`${user?.picture}`} />
                                                                                            <p className="font-semibold text-xs">
                                                                                                {user?.fullname && user?.fullname?.length > 25 ? user?.fullname.slice(0, 25) + '...' : user?.fullname}
                                                                                            </p>
                                                                                        </div>
                                                                                        <X onClick={() => !userIsExternal && handleRemoveAssigned(Number(taskAssigned.auth_users_id))} className="color-red-600 cursor-pointer" />
                                                                                    </div>
                                                                                })
                                                                                :
                                                                                <></>
                                                                        }
                                                                        <div className="border-t flex flex-col gap-2 pt-3 border-zinc-200">
                                                                            <p className="font-semibold text-sm">Não atribuídos</p>
                                                                            {
                                                                                projectsMembers?.length > 0 ?
                                                                                    projectsMembers?.map((member: any) => {
                                                                                        if (!thisTask?.pro_tasksAssigned?.map((userAssigned: any) => { return userAssigned.auth_users_id }).includes(member.auth_users_id)) {
                                                                                            let user = allUsers.find(u => u.id === member.auth_users_id)
                                                                                            return <p onClick={() => !userIsExternal && handleNewTaskAssigned(taskData?.pro_projectsSteps.id ? taskData?.pro_projectsSteps.id : 0, taskData?.id ? taskData?.id : '', Number(user?.id), user?.fullname as string)} className="hover:font-semibold text-sm cursor-pointer">{user?.fullname}</p>
                                                                                        }
                                                                                    })
                                                                                    :
                                                                                    <></>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </PopoverBody>
                                                            </PopoverContent>
                                                        </Popover>
                                                    </Box>

                                                    {/* Tags de task */}
                                                    <Box className='flex gap-4 mb-4' as='span' flex='1' textAlign='left'>
                                                        <Tag className='mt-1' size={20} />
                                                        <Popover onClose={onClose} variant={'responsive'} size={'sm'} >
                                                            <PopoverTrigger>
                                                                {
                                                                    thisTask?.pro_tasksTags?.length > 0 ?
                                                                        <div className="flex flex-row overflow-hidden">
                                                                            {
                                                                                thisTask?.pro_tasksTags?.map((tag: any, index: number) => {
                                                                                    let thisTag = projectsTags?.filter((projectTag: any) => tag.pro_projectsTags_id === projectTag.id)[0]
                                                                                    return <Tippy
                                                                                        content={<span>{thisTag?.description}</span>}
                                                                                        arrow={true}
                                                                                        animation='perspective'
                                                                                        placement='top-start'
                                                                                        delay={80}>
                                                                                        <div className="px-2 py-1  mr-2 rounded-md cursor-pointer" style={{ background: `${thisTag.color}`, translate: `-${5 * index}px 0px` }} >
                                                                                            <p className="text-white font-semibold w-full h-full text-sm">{thisTag?.description}</p>
                                                                                        </div>
                                                                                    </Tippy>

                                                                                })
                                                                            }
                                                                        </div>
                                                                        :
                                                                        <p className="cursor-pointer w-36 text-sm">Adicione uma tag</p>
                                                                }

                                                            </PopoverTrigger>
                                                            <PopoverContent>
                                                                <PopoverArrow />
                                                                <PopoverBody>
                                                                    <div className="flex flex-col gap-2 items-center justify-center">
                                                                        {
                                                                            projectsTags?.length > 0 ?
                                                                                projectsTags?.map((tag: any) => {
                                                                                    let hasThisTag = thisTask?.pro_tasksTags?.filter((taskTag: any) => taskTag.pro_projectsTags_id === tag?.id)[0]
                                                                                    return hasThisTag ?
                                                                                        <div style={{ background: `${tag.color}` }} className="flex gap-2 items-center rounded-md cursor-pointer">
                                                                                            <p onClick={() => handleAddTaskTag(tag)} className="px-2 py-1 rounded-md hover:cursor-pointer hover:brightness-90 text-white text-sm font-semibold">{tag?.description}</p>
                                                                                            <div className="text-white font-semibold cursor-pointer border-l px-1">
                                                                                                <X onClick={() => handleRemoveTaskTag(tag)} size={12} />
                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                        <p onClick={() => handleAddTaskTag(tag)} style={{ background: `${tag.color}` }} className="px-2 py-1 rounded-md hover:cursor-pointer hover:brightness-90 text-white text-sm font-semibold">{tag?.description}</p>
                                                                                })
                                                                                :
                                                                                <p className="text-sm cursor-pointer">Sem tags cadastradas</p>
                                                                        }
                                                                    </div>
                                                                </PopoverBody>
                                                            </PopoverContent>
                                                        </Popover>
                                                    </Box>
                                                    {/* Seleção de prioridade e status da task */}
                                                    <Flex className='gap-4 mb-4'>
                                                        <InputGroup className="flex flex-col items-start pr-2">
                                                            <LabelInput>Etapa </LabelInput>
                                                            {projectsSteps.find(s => s.id === taskData?.pro_projectsSteps_id)?.name}
                                                            <InputHTMLDefault
                                                                defaultValue={projectsSteps.find(s => s.id === taskData?.pro_projectsSteps_id)?.name ?? ''}
                                                                disabled
                                                                width={48}
                                                                type="string"
                                                            >
                                                            </InputHTMLDefault>
                                                        </InputGroup>
                                                        <InputGroup className="flex flex-col items-start pr-2">
                                                            <LabelInput>Prioridade *</LabelInput>
                                                            <Select disabled={userIsExternal} size="sm" className='cursor-pointer' value={task.pro_projects_id ? thisTask?.priority : taskPriority ? taskPriority : 'low'} onChange={e => alterTaskPriority(e.target.value)} height={8}>
                                                                <option value={"low"}>Baixa</option>
                                                                <option value={"medium"}>Moderada</option>
                                                                <option value={"high"}>Urgente</option>
                                                            </Select>
                                                        </InputGroup>
                                                        <InputGroup className="flex flex-col items-start pr-2">
                                                            <LabelInput>Status *</LabelInput>
                                                            <Select disabled={userIsExternal} size={'sm'} className='cursor-pointer' value={task.pro_projects_id ? thisTask?.status : taskStatus ? taskStatus : '0'} onChange={e => alterTaskStatus(Number(e.target.value))} height={8}>
                                                                <option value={"0"}>Pendente</option>
                                                                <option value={"2"}>Em andamento</option>
                                                                <option value={"4"}>Aguardando retorno</option>
                                                                <option value={"3"}>Parado</option>
                                                                <option value={"1"}>Concluído</option>
                                                            </Select>
                                                        </InputGroup>
                                                    </Flex>

                                                    {/* Seleção de data inicial data final e data de finalização */}
                                                    <Flex className='gap-4 mb-4'>
                                                        <div className='w-full'>
                                                            <LabelInput>Data inicial</LabelInput>
                                                            <div className="flex flex-col">
                                                                <InputDefault
                                                                    isDisabled={userIsExternal}
                                                                    value={thisTask?.startDate ? moment(thisTask?.startDate).utc(false).format("YYYY-MM-DD") : ''}
                                                                    onChange={(e) => handleUpdateDates('startDate', e.target.value)}
                                                                    id="startDate"
                                                                    type="date"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='w-full'>
                                                            <LabelInput>Data vencimento</LabelInput>
                                                            <div className="flex flex-col">
                                                                <InputDefault
                                                                    isDisabled={userIsExternal}
                                                                    value={thisTask?.dueDate ? moment(thisTask.dueDate).utc(false).format("YYYY-MM-DD") : ''}
                                                                    onChange={(e) => handleUpdateDates('dueDate', e.target.value)}
                                                                    id="dueDate"
                                                                    type="date"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='w-full'>
                                                            <LabelInput>Data conclusão</LabelInput>
                                                            <div className="flex flex-col">
                                                                <InputDefault
                                                                    isDisabled={userIsExternal}
                                                                    value={thisTask?.finishDate ? moment(thisTask.finishDate).utc(false).format("YYYY-MM-DD") : ''}
                                                                    onChange={(e) => handleUpdateDates('finishDate', e.target.value)}
                                                                    id="finishDate"
                                                                    type="date"
                                                                    onBlur={() => {
                                                                        if (thisTask.pro_tasksTimes.length === 0 && thisTask?.finishDate) {
                                                                            setAlert({
                                                                                status: 'error',
                                                                                title: 'Erro',
                                                                                description: 'Você precisa apontar ao menos 1 lançamento de tempo'
                                                                            })
                                                                            setThisTask({ ...thisTask, finishDate: '' })
                                                                            return
                                                                        } else if (thisTask.status !== 1 && thisTask?.finishDate) {

                                                                            setAlert({
                                                                                status: 'error',
                                                                                title: 'Erro',
                                                                                description: 'Você deve concluir a tarefa para ter a data de conclusão'
                                                                            })
                                                                            setThisTask({ ...thisTask, finishDate: '' })
                                                                            return
                                                                        } else if (thisTask?.finishDate === oldFinishDate) {
                                                                            return
                                                                        } else {
                                                                            handleTaskEdit()
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Flex>

                                                    <InputGroup className="flex flex-col items-start mb-4">
                                                        <LabelInput>Descrição</LabelInput>
                                                        <TextAreaDefault
                                                            disabled={userIsExternal}
                                                            value={thisTask?.description}
                                                            onChange={(e) => setThisTask((prev: any) => ({ ...prev, description: e.target.value }))}
                                                        >
                                                            {thisTask?.description ? thisTask?.description : ''}
                                                        </TextAreaDefault>

                                                    </InputGroup>
                                                    {/* Checklist */}
                                                    <Box className='flex flex-col mb-4' as='span' flex='1' textAlign='left'>
                                                        <div className='flex flex-row gap-1 w-full items-center'>
                                                            <ListChecks size={20} /> <a className="text-sm">Checklist</a>
                                                        </div>
                                                        {
                                                            <>
                                                                <div className="w-full flex gap-1 items-center ml-4">
                                                                    {
                                                                        loadingNewCheck ?
                                                                            <div className='w-full flex flex-col  items-center gap-2 justify-center mt-5'>
                                                                                <div className='animate-bounce'>
                                                                                    <FaCog className='text-zinc-300 text-6xl animate-spin-slow transition-all duration-500 '></FaCog>
                                                                                </div>
                                                                                <p className='text-sm text-zinc-700'>Carregando...</p>
                                                                            </div>
                                                                            :
                                                                            inCreationChecklist ?

                                                                                <form onSubmit={() => handleSubmitNewChecklist()} className="w-full mb-2 flex gap-2 items-center">
                                                                                    <InputHTMLDefault defaultValue={inCreationChecklist?.description ? inCreationChecklist.description : ''} id="newTaskCheckItem" ref={newTaskCheckItemTitleRef} />
                                                                                    <button onClick={() => setInCreationChecklist(null)} type="button" className="border-none bg-none">
                                                                                        <X className="text-red-800" size={18} />
                                                                                    </button>
                                                                                    <button type='submit' className="border-none bg-none">
                                                                                        <Check className="text-primaryColor" size={18} />
                                                                                    </button>
                                                                                </form>
                                                                                :
                                                                                <div onClick={() => !userIsExternal && setInCreationChecklist({ pro_tasks_id: thisTask?.id as string })} className="flex mb-2 items-center gap-1 text-black hover:text-primaryColor cursor-pointer">
                                                                                    <Plus /><a className="text-sm">Adicionar item</a>
                                                                                </div>
                                                                    }
                                                                </div>
                                                                <div className="w-full flex flex-col gap-2">
                                                                    {
                                                                        thisTask?.pro_tasksChecklist?.length > 0 &&
                                                                        thisTask?.pro_tasksChecklist?.map((check: any, i: any) => {
                                                                            return <div className="flex gap-2 w-full bg-zinc-100 p-2 rounded-sm text-sm justify-between items-center">
                                                                                <div className="flex gap-2 flex-row">
                                                                                    <input onClick={(e) => handleUpdateCheckItem(thisTask.id, check.id, check.pro_tasks_id, !check.check)} checked={check.check} type="checkbox"></input>
                                                                                    <a>{check.description}</a>
                                                                                </div>
                                                                                <Trash onClick={() => handleDeleteItemChecklist(thisTask.id, check.id, check.pro_tasks_id, i)} className="cursor-pointer" color="red" />
                                                                            </div>
                                                                        })
                                                                    }
                                                                </div>
                                                            </>
                                                        }
                                                    </Box>

                                                </div>
                                            </>
                                        </TabPanel>
                                        {/* Apontamento de tempo */}
                                        <TabPanel>
                                            <div>
                                                <div >
                                                    {
                                                        isToAddNewTime ?
                                                            <form className="flex flex-col items-start gap-5 w-full">
                                                                <div className="flex flex-col w-full gap-2 px-4" >
                                                                    {
                                                                        userData?.auth_typeAccess_id !== 4 ?
                                                                            <div className="w-full">
                                                                                <FormLabel fontSize={12}>Usuário responsável *</FormLabel>

                                                                                <SelectDefault
                                                                                    onChange={(e) => setAddNewTime((prev: any) => ({ ...prev, user_id: Number(e.target.value) }))}
                                                                                    placeholder="Selecionar">
                                                                                    {
                                                                                        allUsers
                                                                                            .filter(u => projectsMembers.find(m => m.auth_users_id === u.id))
                                                                                            .map((u) => {
                                                                                                return <option key={u?.id} value={u?.id} selected={u.id === addNewTime?.user_id}>{u?.fullname}</option>
                                                                                            })
                                                                                    }
                                                                                </SelectDefault>
                                                                            </div>
                                                                            : <></>
                                                                    }
                                                                    <div>
                                                                        <FormLabel fontSize={12}>Data da tarefa *</FormLabel>
                                                                        <div className="flex flex-col">
                                                                            <InputDefault value={addNewTime?.startDate} onChange={(e) => setAddNewTime((prev: any) => ({ ...prev, startDate: e.target.value }))} id="startDate" type="date" />
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <FormLabel fontSize={12}>Tempo da tarefa HH:MM *</FormLabel>
                                                                        <InputDefault autoFocus id="time" size='sm'
                                                                            fontSize={16}
                                                                            onChange={(e) => setAddNewTime((prev: any) => ({ ...prev, ammountSeconds: e.target.value }))}
                                                                            min={0} aria-label={'Horas'}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <FormLabel fontSize={12}>Descrição</FormLabel>
                                                                        <TextAreaDefault wrap="hard" id='description' value={addNewTime?.description} onChange={(e) => setAddNewTime((prev: any) => ({ ...prev, description: e.target.value }))} />
                                                                    </div>
                                                                    <div className="flex items-center gap-2 mt-2 text-sm">
                                                                        <input className="pt-3 cursor-pointer" type='checkbox' checked={addNewTime?.billable ?? false} onChange={(e) => setAddNewTime((prev: any) => ({ ...prev, billable: e.target.checked }))} />
                                                                        <p>Faturável</p>
                                                                    </div>
                                                                </div>
                                                                <div className="flex justify-between gap-2" >
                                                                    <ButtonDefault onClick={() => setIsToAddNewTime(false)} success={false}>Cancelar</ButtonDefault>
                                                                    <ButtonDefault success onClick={() => handleAddTime()}>Adicionar</ButtonDefault>
                                                                </div>
                                                                <hr className="w-full" />
                                                            </form>
                                                            :
                                                            loading ?
                                                                <div className="flex flex-col w-full gap-2 px-4 items-center justify-center" >
                                                                    <Loader />
                                                                </div>
                                                                :
                                                                <div className="flex flex-col items-center w-full gap-5">
                                                                    {
                                                                        !userIsExternal &&
                                                                        <ButtonDefault
                                                                            success
                                                                            onClick={() => {
                                                                                setIsToAddNewTime(true);
                                                                                setAddNewTime({
                                                                                    ammountSeconds: 0,
                                                                                    billable: true,
                                                                                    isCounting: false,
                                                                                    pro_tasks_id: thisTask.id,
                                                                                    startDate: moment().format("YYYY-MM-DD"),
                                                                                    description: '',
                                                                                    user_id: userData?.id
                                                                                })

                                                                            }}
                                                                        >
                                                                            Adicionar Apontamento
                                                                        </ButtonDefault>
                                                                    }
                                                                    <hr className="w-full" />

                                                                    { //lista timers/apontamentos + loader

                                                                        thisTask?.pro_tasksTimes?.map((time: any, index: any) => (
                                                                            <div key={time.id} className="flex flex-col w-full mt-2 mb-2 text-lg">

                                                                                <div className="flex flex-row w-full items-center font-semibold">
                                                                                    <p className="pl-3">{time.ammountSeconds / 3600 >= 1 ? Math.floor(time.ammountSeconds / 3600) : '00'} </p><p>h</p>
                                                                                    <p className="pl-5">{time.ammountSeconds % 3600 / 60 ? Math.round(time.ammountSeconds % 3600 / 60) : '00'}</p><p>m</p>
                                                                                    <p className="pl-5">{time.ammountSeconds % 3600 % 60 ? Math.round((time.ammountSeconds % 3600) % 60) : '00'}</p><p>s</p>
                                                                                </div>
                                                                                {
                                                                                    Number(userData?.auth_typeAccess_id) < 4 || userData?.id === time.auth_users_id ?
                                                                                        <div className="flex flex-row w-full pl-3 text-xs">
                                                                                            <p color={`${thisTask?.pro_projectsSteps?.color}`} className="underline hover:cursor-pointer" onClick={() => handleRemoveTime(time.id, index)}>Deletar</p>
                                                                                        </div>
                                                                                        : <></>
                                                                                }
                                                                                <div className="flex gap-2">
                                                                                    {
                                                                                        allUsers.filter((u) => u.id === time.auth_users_id).map(u => (
                                                                                            <div key={u.id} className="text-sm flex flex-row pl-3 mt-1">
                                                                                                <Avatar style={{ borderRadius: 5 }} size="sm" name={u.fullname}></Avatar>
                                                                                            </div>
                                                                                        ))
                                                                                    }
                                                                                    <div className="flex flex-col text-xs items-start justify-start">
                                                                                        {
                                                                                            allUsers.filter((u) => u.id === time.auth_users_id).map(u => (
                                                                                                <p className="text-sm">{u.fullname}</p>
                                                                                            ))
                                                                                        }
                                                                                        <p className="" >em {time.startDate ? moment(time.startDate).utc(false).format('DD/MM/YYYY') : ''}</p>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="m-2 text-sm" style={{ borderRadius: '0.3rem' }} >
                                                                                    <TextAreaDefault wrap="hard" disabled className="text-zinc-700 bg-zinc-100 border-none text-sm" >{time.description ? time.description : '...'}</TextAreaDefault>
                                                                                </div>
                                                                                <hr />
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                    }
                                                </div>
                                            </div>
                                        </TabPanel>
                                        {/* Comentários */}
                                        <TabPanel>
                                            <div className="flex flex-col items-center justify-center gap-2 w-full">
                                                {
                                                    addNewComment ?

                                                        <div className="flex flex-col items-center justify-between w-full px-4">
                                                            <div className="flex flex-col items-start gap-5 w-full">
                                                                <div className="flex flex-col w-full">
                                                                    <FormLabel fontSize={12}>Comentário</FormLabel>
                                                                    <TextAreaDefault wrap="hard" autoFocus value={dataCreateComment?.description}
                                                                        onChange={(e) => setDataCreateComment(((prev: any) => ({ ...prev, description: e.target.value })))}
                                                                        id="descriptionComment" maxLength={225}
                                                                    />
                                                                    <a className="text-xs">Limite 2000</a>
                                                                </div>
                                                                <div className="flex items-center gap-2 pb-4">
                                                                    <ButtonDefault
                                                                        onClick={() => {
                                                                            setAddNewComment(false)
                                                                            setDataCreateComment({ description: '' })
                                                                        }}
                                                                        success={false}
                                                                    >Cancelar</ButtonDefault>
                                                                    <ButtonDefault success onClick={() => handleAddComment()}>Adicionar</ButtonDefault>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="w-full flex items-center justify-center">
                                                            {
                                                                !userIsExternal &&
                                                                <ButtonDefault success onClick={() => setAddNewComment(true)}>Adicionar comentário</ButtonDefault>
                                                            }
                                                        </div>

                                                }
                                                <hr className="w-full" />
                                                {

                                                    thisTask?.pro_tasksComments?.map((comment: any, index: any) => ( //lista de comentarios
                                                        <div key={comment.id} className="flex flex-col w-full mt-2 mb-2">
                                                            {
                                                                allUsers.filter((u) => u.id === comment.auth_users_id).map(u => (
                                                                    <div key={u.id} className="flex flex-row w-full mt-2 pl-3">
                                                                        <Avatar style={{ borderRadius: 5 }} size="sm" name={u.fullname} ></Avatar>
                                                                        <Text autoFocus className="pt-1 pl-1" fontSize={15}>{u.fullname}</Text>
                                                                    </div>
                                                                ))
                                                            }
                                                            <Text className="pl-3" fontSize={14}>em {comment.created_at ? moment(comment.created_at).utc(true).format('DD/MM/YYYY HH:mm') : ''}</Text>
                                                            <div className="p-3" style={{ borderRadius: '0.3rem' }}>
                                                                <TextAreaDefault disabled wrap="hard" className="text-sm">{comment.description}</TextAreaDefault>
                                                            </div>
                                                            {
                                                                Number(userData?.auth_typeAccess_id) < 4 || userData?.id === comment.auth_users_id ?
                                                                    <div className="flex flex-row w-full pl-3 pb-3">
                                                                        <Text color={`${thisTask?.pro_projectsSteps?.color}`} className="underline hover:cursor-pointer" fontSize={12} onClick={() => handleRemoveComment(comment, index)}>Deletar</Text>
                                                                    </div>
                                                                    : <></>
                                                            }
                                                            <hr />
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </TabPanel>
                                        {/* Arquivos */}
                                        <TabPanel>
                                            <div>
                                                <div>
                                                    <div className="flex flex-col justify-between w-full ml-4">
                                                        {
                                                            // newAttach ?
                                                            <>
                                                                <FormControl style={newAttach?.file ? { display: 'flex' } : { display: 'none' }} className="flex flex-col items-start gap-5 w-full">
                                                                    <div className="w-full">
                                                                        <FormLabel fontSize={12}>Descrição: *</FormLabel>
                                                                        <InputDefault
                                                                            autoFocus id="descriptionAttachment" size='sm' type="text" value={newAttach?.description} aria-label={'Descrição *'}
                                                                            onChange={(e) => setNewAttach((prev: any) => ({ ...prev, description: e.target.value }))}
                                                                        ></InputDefault>
                                                                    </div>
                                                                    <div className="w-full">
                                                                        <p className="text-xs">{newAttach?.file ? newAttach?.file.name : 'Nenhum arquivo selecionado'}</p>
                                                                        <input
                                                                            ref={inputAttachRef}
                                                                            id="fileAttachment" type="file" aria-label={'Descrição *'}
                                                                            onChange={(e) => setNewAttach((prev: any) => ({ ...prev, file: e?.target?.files ? e?.target?.files[0] : null }))}
                                                                        />
                                                                    </div>
                                                                    <div className="w-full flex justify-center items-center gap-4" >
                                                                        <ButtonDefault onClick={() => setNewAttach(null as null | INewAttachment)} success={false}>
                                                                            Cancelar
                                                                        </ButtonDefault>
                                                                        <ButtonDefault success onClick={() => handleSubmitNewFile()}>
                                                                            Adicionar
                                                                        </ButtonDefault>
                                                                    </div>
                                                                </FormControl>
                                                                <div className="flex flex-col items-center w-full gap-5">
                                                                    {
                                                                        !userIsExternal &&
                                                                        <ButtonDefault success hidden={!newAttach?.file ? false : true} onClick={() => inputAttachRef?.current?.click()}>
                                                                            Adicionar novo arquivo
                                                                        </ButtonDefault>
                                                                    }
                                                                </div>
                                                            </>
                                                        }
                                                        <hr className="w-full my-5" />
                                                        <div className="flex flex-col gap-5 h-full w-full">
                                                            {
                                                                thisTask?.pro_tasksFiles?.map((file: any, index: any) => {
                                                                    return (
                                                                        <div key={file.id} className="flex flex-col w-full">
                                                                            <span className="flex items-center justify-between">
                                                                                <p className="text-xs">{moment(file.created_at).format('DD/MM/YYYY HH:mm')}</p>
                                                                                <Trash className="cursor-pointer hover:text-red-700" onClick={() => handleDeleteFile(file.id, index)} />
                                                                            </span>
                                                                            <div className='flex flex-row justify-between bg-slate-200 rounded-md p-2 items-center'>
                                                                                <p className="text-sm text-center">{file.description}</p>
                                                                                <div className="flex flex-col items-center justify-center mt-2" >
                                                                                    <div onClick={() => { handleDownloadFile(file) }} className="cursor-pointer hover:text-primaryColor">
                                                                                        <p className="flex gap-2 items-center"><Download />Baixar</p>
                                                                                        <p className="text-xs">{file.originalname}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                thisTask?.pro_tasksFiles && <hr className="mt-5" />
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPanel>
                                    </TabPanels>
                                </Tabs>
                    }
                </ModalBody >
                <ModalFooter>
                    <Flex className='gap-3'>
                        <ButtonDefault onClick={() => closeTask()}>Cancelar</ButtonDefault>
                        {
                            !userIsExternal &&
                            <ButtonDefault onClick={() => { task?.pro_tasks_id === '' ? handleAddNewTask() : handleTaskEdit(true) }} success>Salvar</ButtonDefault>
                        }
                    </Flex>
                </ModalFooter>
            </ModalContent >
        </Modal >
    )

}

interface ITaskModal {
    task: {
        pro_tasks_id: string
        pro_projects_id: number
        pro_projectsSteps_id: number
        pro_projectsSteps?: IProjectsSteps[]
        pro_projectsMembers?: IProjectsMembers[]
        pro_projectsTags?: IProjectsTags[]
        inProjectView?: boolean
        taskData?: ITasks
        openTab?: 'task' | 'times' | 'comentaries' | 'files'
        externalCreateCallback?: (e: ITasks) => any
        externalUpdateCallback?: (e: ITasks) => any
    }
}

interface ITime {
    description?: string,
    isCounting: boolean,
    ammountSeconds: number | string,
    startDate: any,
    pro_tasks_id: string,
    user_id?: number,
    billable: boolean,
}
