import { ReactNode } from "react";

interface IButtonProps {
    children?: ReactNode
    success?: boolean
    onClick: ()=> void
    anotherColor?: 'gray' | 'orange' | 'green' | 'red' | 'blue' | 'black'
    hidden?: boolean
    type?: 'button' | 'submit' | 'reset'
}

export function ButtonDefault({ children, success, onClick, anotherColor, hidden, type}: IButtonProps) {

    const colorOptions = {
        'gray': 'bg-gray-500',
        'orange': 'bg-[#fab13c]',
        'green': 'bg-primaryColor',
        'red': 'bg-red-700',
        'blue': 'bg-blue-800',
        'black': 'bg-black',
    }

    return <button
        onClick={onClick}
        hidden={hidden}
        type={type ? type : 'button'}
        className={`
            text-white shadow-lg px-4 py-2 rounded-md text-sm flex items-center gap-1 hover:brightness-110
            ${anotherColor ? colorOptions[anotherColor] : !success ? 'bg-red-700' : 'bg-primaryColor'}                        
        `}
    >{children}</button>
}