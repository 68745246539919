import { Box, FormControl, FormLabel, Text, Tooltip } from "@chakra-ui/react";
import { DatePicker, DatePickerProps } from "antd";
import moment from "moment";
import { useEffect, useState } from 'react';
import { FaCog, FaInfo } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ButtonDefault } from "../../../components/button/buttonDefault";
import { Container } from "../../../components/container/container";
import AutoCompleteInput from "../../../components/input/autocomplete";
import { InputDefault } from "../../../components/input/inputDefault";
import { Loader } from '../../../components/loader/loader';
import { useAlertContext } from '../../../contexts/alertContext';
import { useAuthContext } from "../../../contexts/authContext";
import { IClients } from '../../../services/interfaces/reg/clients';
import { CreateProject } from '../../../services/interfaces/pro/projects';
import { api } from "../../../config/api";
import { formValidator } from '../../../utils/form.validator';
import { IClientsContracts } from "../../../services/interfaces/reg/clientsContracts";
import { Clients } from "../../../services/api/reg/Clients";
import { ClientsContracts } from "../../../services/api/reg/ClientsContracts";
import dayjs from "dayjs";
import { Projects } from "../../../services/api/pro/Projects";
import { SelectDefault } from "../../../components/selects/selectDefault";

export function CreateProjects() {

    const navigate = useNavigate()
    const { userData } = useAuthContext()
    const { setAlert } = useAlertContext()
    const [clientsData, setClientsData] = useState<IClients[]>([])
    const [clientsContractsData, setClientsContractsData] = useState<IClientsContracts[]>([])
    const [projectsData, setProjectsData] = useState<CreateProject>({} as any)
    const [loading, setLoading] = useState(true)
    const dateFormat = ['DD/MM/YYYY']
    const statusList = [
        {
            id: 0,
            description: 'Em Andamento'
        },
        {
            id: 1,
            description: 'Finalizado'
        },
        {
            id: 2,
            description: 'Cancelado'
        }
    ]
    const clientsService = new Clients(userData?.token)
    const contractsService = new ClientsContracts(userData?.token)
    const projectsService = new Projects(userData?.token)
    const [searchingContract, setSearchingContract] = useState<boolean>(false)

    useEffect(() => {
        getData()
    }, [])

    async function getData() {
        setLoading(true)
        try {
            const clients = await clientsService.readAll()
            setClientsData(clients)
        } catch (error) {
            setAlert({
                description: 'Erro ao buscar relação de clientes',
                title: 'Erro',
                status: 'error',
            })
        } finally {
            setLoading(false)
        }

    }

    async function handleSubmit() {
        try {
            const validFields = formValidator([
                { name: 'name', value: projectsData?.name, required: true, type: 'string' },
                { name: 'clients_id', value: projectsData?.reg_clients_id, required: true, type: 'autocomplete' },
                { name: 'status', value: projectsData?.status, required: true, type: 'autocomplete' },
                { name: 'startDate', value: projectsData?.startDate, required: true, type: 'datepicker' },
            ])

            if (validFields.length > 0) {
                return setAlert({
                    status: "error",
                    title: "Campos obrigatórios",
                    description: "Campos obrigatórios não preenchidos"
                })
            }

            setLoading(true)
            await projectsService.create({
                name: projectsData?.name,
                description: projectsData?.description ? projectsData?.description : undefined,
                startDate: projectsData?.startDate,
                dueDate: projectsData?.dueDate ? projectsData?.dueDate : undefined,
                finishDate: projectsData?.finishDate ? projectsData?.finishDate : undefined,
                status: projectsData?.status,
                reg_clients_id: projectsData?.reg_clients_id,
                reg_clientsContracts_id: projectsData?.reg_clientsContracts_id ? projectsData?.reg_clientsContracts_id : undefined,
                limitHours: projectsData?.limitHours ? projectsData?.limitHours * 60 : undefined,
                goalHours: projectsData?.goalHours ? projectsData?.goalHours * 60 : undefined,
                isNotVisible: projectsData?.isNotVisible ? projectsData?.isNotVisible : false
            })

            setLoading(false)
            setAlert({
                status: "success",
                title: "Sucesso",
                description: "Sucesso ao criar projeto"
            })
            navigate(-1)
        } catch (error) {
            console.log(error)
            setLoading(false)
            setAlert({
                status: "error",
                title: "Erro",
                description: "Erro ao criar projeto"
            })
        }
    }

    function handleChangeDate(type: string): DatePickerProps['onChange'] {
        return (dateString) => {
            const dateFormatted: any = moment(String(dateString)).format("YYYY-MM-DD")

            if (type === 'startDate') {
                setProjectsData((prev: any) => ({
                    ...prev,
                    startDate: dateString ? dateFormatted : projectsData?.startDate,
                }))
            } else if (type === 'dueDate') {
                setProjectsData((prev: any) => ({
                    ...prev,
                    dueDate: dateString ? dateFormatted : projectsData?.dueDate,
                }))
            } else if (type === 'finishDate') {
                setProjectsData((prev: any) => ({
                    ...prev,
                    finishDate: dateString ? dateFormatted : projectsData?.finishDate,

                }))
            }
        }
    }

    async function getContractsByClientId(reg_clients_id: number) {
        setSearchingContract(true)
        try {
            const contracts = await contractsService.readByClientsId(reg_clients_id)
            setClientsContractsData(contracts)
        } catch (error) {
            console.log('> Error on try to get contracts:', error)
        } finally {
            setSearchingContract(false)
        }
    }

    return (
        <>
            {
                loading
                    ?
                    <Loader />
                    :
                    <Container noBackground>
                        <Box className="w-full relative flex flex-col justify-start gap-10">
                            {
                                searchingContract &&
                                <Box className='w-full h-full -translate-y-5 absolute border boreder-red-400 top-0 opacity-95 bg-white z-30 flex flex-col  items-center gap-2 justify-center mt-5'>
                                    <Box className='animate-bounce'>
                                        <FaCog className='text-zinc-300 text-6xl animate-spin-slow transition-all duration-500 '></FaCog>
                                    </Box>
                                    <p className='text-sm text-zinc-700'>Carregando...</p>
                                </Box>
                            }
                            <Box className="bg-white shadow-md p-10 rounded-md flex flex-col gap-12" >
                                <Text className='text-lg text-primaryColor'>Criação de projeto</Text>
                                <FormControl className="flex flex-row flex-wrap gap-6 w-full">
                                    <Box className="flex flex-row gap-6 flex-wrap w-full">
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>Nome: *</FormLabel>
                                            <InputDefault
                                                width={'400px'}
                                                value={projectsData?.name}
                                                onChange={(e) => setProjectsData(((prev: any) => ({ ...prev, name: e.target.value })))}
                                                type="text"
                                                id="name"
                                            />
                                        </Box>
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>Descrição:</FormLabel>
                                            <InputDefault
                                                width={'400px'}
                                                value={projectsData?.description}
                                                onChange={(e) => setProjectsData(((prev: any) => ({ ...prev, description: e.target.value })))}
                                                type="text"
                                                id="description"
                                            />
                                        </Box>
                                    </Box>
                                    <Box className="flex flex-row gap-6 flex-wrap w-full">
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>Cliente: *</FormLabel>
                                            <AutoCompleteInput
                                                width="400px"
                                                defaultValue={clientsData.filter(client => client.id == projectsData?.reg_clients_id)[0]?.name}
                                                id="clients_id" data={clientsData}
                                                preSelectedValue={["id", "name"]}
                                                placeholder={"Selecione o cliente"}
                                                onChange={(e: number) => {
                                                    setProjectsData((prev: any) => ({ ...prev, reg_clients_id: e }))
                                                }}
                                                onBlur={() => getContractsByClientId(projectsData.reg_clients_id)}
                                            />
                                        </Box>
                                        <Box className="flex flex-col w-50 md:w-auto">
                                            <FormLabel fontSize={12}>Status: *</FormLabel>
                                            <AutoCompleteInput
                                                width="220px"
                                                defaultValue={statusList?.filter(item => item?.id === projectsData?.status)[0]?.description}
                                                id="status" data={statusList}
                                                preSelectedValue={["id", "description"]}
                                                placeholder={"Selecione o status"}
                                                onChange={(e: number) => setProjectsData((prev: any) => ({ ...prev, status: e }))}
                                            />
                                        </Box>
                                        <Box className="flex flex-col w-50 md:w-auto">
                                            <FormLabel fontSize={12}>Visibilidade do projeto: *</FormLabel>
                                            <SelectDefault
                                                onChange={(e) => setProjectsData((prev: any) => ({ ...prev, isNotVisible: Number(e.target.value) === 0 ? false  : true}))}
                                                placeholder="Selecionar">
                                                <option key={0} value={0} selected>Todos usuários</option>
                                                <option key={1} value={1}>Apenas membros</option>
                                            </SelectDefault>
                                        </Box>
                                    </Box>
                                    <Box className="flex flex-row gap-6 flex-wrap w-full">
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>Data de início: *</FormLabel>
                                            <DatePicker
                                                id="startDate"
                                                format={dateFormat}
                                                placeholder="dd/mm/aaaa"
                                                value={projectsData?.startDate ? dayjs(moment.utc(projectsData.startDate).format('DD/MM/YYYY'), ['DD/MM/YYYY']) : null}
                                                onChange={handleChangeDate('startDate')}
                                                popupStyle={{ zIndex: 10000 }}
                                                style={{ height: 40, width: 145 }}
                                            />
                                        </Box>
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>Data prazo:</FormLabel>
                                            <DatePicker
                                                id="startDate"
                                                format={dateFormat}
                                                placeholder="dd/mm/aaaa"
                                                value={projectsData?.dueDate ? dayjs(moment.utc(projectsData.dueDate).format('DD/MM/YYYY'), ['DD/MM/YYYY']) : null}
                                                onChange={handleChangeDate('dueDate')}
                                                popupStyle={{ zIndex: 10000 }}
                                                style={{ height: 40, width: 145 }}
                                            />
                                        </Box>
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>Data de conclusão:</FormLabel>
                                            <DatePicker
                                                id="startDate"
                                                format={dateFormat}
                                                placeholder="dd/mm/aaaa"
                                                value={projectsData?.finishDate ? dayjs(moment.utc(projectsData.finishDate).format('DD/MM/YYYY'), ['DD/MM/YYYY']) : null}
                                                onChange={handleChangeDate('finishDate')}
                                                popupStyle={{ zIndex: 10000 }}
                                                style={{ height: 40, width: 145 }}
                                            />
                                        </Box>
                                        <Box className="flex flex-col relative w-full md:w-auto">
                                            <Tooltip hasArrow label="São as horas acordadas com o cliente para finalização do projeto" placement={'top'}>
                                                <Box className="absolute right-0 top-0">
                                                    <FaInfo></FaInfo>
                                                </Box>
                                            </Tooltip>
                                            <FormLabel fontSize={12}>Horas limite do projeto:</FormLabel>
                                            <InputDefault
                                                width={'145px'}
                                                value={projectsData?.limitHours}
                                                onChange={(e) => setProjectsData((prev: any) => ({ ...prev, limitHours: e.target.value }))}
                                                type="number"
                                                id="limitHours"
                                            />
                                        </Box>
                                        <Box className="flex flex-col relative w-full md:w-auto">
                                            <Tooltip hasArrow label="Geralmente são as horas que gostaríamos de entregar este projeto" placement={'top'}>
                                                <Box className="absolute right-0 top-0">
                                                    <FaInfo></FaInfo>
                                                </Box>
                                            </Tooltip>
                                            <FormLabel fontSize={12}>Horas meta do projeto:</FormLabel>
                                            <InputDefault
                                                width={'145px'}
                                                value={projectsData?.goalHours}
                                                onChange={(e) => setProjectsData((prev: any) => ({ ...prev, goalHours: e.target.value }))}
                                                type="number"
                                                id="goalHours"
                                            />
                                        </Box>
                                    </Box>
                                    <Box className="flex flex-row gap-6 flex-wrap w-full">
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>Vincular a contrato:</FormLabel>
                                            <AutoCompleteInput
                                                width="400px"
                                                defaultValue={clientsContractsData?.find(item => item?.id === projectsData?.reg_clientsContracts_id)?.description}
                                                id="reg_clientsContracts_id" data={clientsContractsData}
                                                preSelectedValue={["id", "description"]}
                                                placeholder={"Selecione um contrato"}
                                                onChange={(e: number) => setProjectsData((prev: any) => ({ ...prev, reg_clientsContracts_id: e }))}
                                            />
                                        </Box>
                                    </Box>
                                </FormControl>
                                <Box className="w-full flex justify-between items-center gap-2">
                                    <ButtonDefault onClick={() => navigate('/projects')} success={false}>Cancelar</ButtonDefault>
                                    <ButtonDefault onClick={() => handleSubmit()} success>Cadastrar</ButtonDefault>
                                </Box>
                            </Box>
                        </Box>
                    </Container>
            }
        </>
    )
}