import { ReactNode, createContext, useContext, useEffect, useState } from 'react'
import { AlertMessage } from '../components/alert/alertMessage'

export interface ChildrenProps {
    children: ReactNode
}

interface IAlert {
    status: "success" | "error"
    title: string
    timeout?: number
    description: string
}

export const AlertContext = createContext
    <{
        alert: IAlert | null
        setAlert: React.Dispatch<React.SetStateAction<IAlert | null>>
    } | undefined>(undefined)


export function AlertContextProvider({ children }: ChildrenProps) {
    const [alert, setAlert] = useState<IAlert | null>(null)  

    useEffect(() => {
        if(alert){
            alertTimeout()
        }
    }, [alert])
    
    async function alertTimeout(){
        const timeout = alert?.timeout
        await new Promise(resolve => setTimeout(resolve, timeout ? timeout : 5000))
        setAlert(null)
    }

    return (
        <AlertContext.Provider value={{ setAlert, alert }}>
            { alert ? <AlertMessage /> : <></> }
            { children }
        </AlertContext.Provider>
    )
}

export function useAlertContext(){
    const context = useContext(AlertContext);

    if (!context) {
        throw new Error("useAlertContext must be used within an AlertContextProvider");
    }

    return context;
}