import * as XLSX from 'xlsx';

export function generateXlsx(data: any, nameDocument: string, dataEnd?: any[]) {
  // Cria uma nova planilha
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

// Converte a planilha para um array de objetos
  const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

  // Adiciona a nova linha ao final
  sheetData.push(dataEnd);

  // Atualiza a planilha com os dados incluindo a nova linha
  const updatedWorksheet = XLSX.utils.aoa_to_sheet(sheetData as any[]);
  workbook.Sheets['Sheet1'] = updatedWorksheet;

  // Converte a planilha para um arquivo binário
  const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

  // Função auxiliar para converter string para ArrayBuffer
  function s2ab(s: any) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }

  // Cria um blob a partir do ArrayBuffer
  const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });

  // Cria um link para download
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${nameDocument}.xlsx`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}