import { api } from "../../../config/api";
import { CreateClient, IClients, UpdateClient } from "../../interfaces/reg/clients";

export class Clients{

    constructor(private token?: string){}

    async readAll(): Promise<IClients[]> {
        const response = await api.get(`/api/v1/reg/clients`, {
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        })
        return response?.data?.data
    }

    async readById(id: number): Promise<IClients> {
        const response = await api.get(`/api/v1/reg/clients/${id}`, {
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        })
        return response.data.data
    }

    async removeById(id: number): Promise<void> {
        await api.delete(`/api/v1/reg/clients/${id}`, {
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        })
    }

    async create(data: CreateClient): Promise<IClients> {
        const response = await api.post(`/api/v1/reg/clients`, data, {
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        })
        return response.data.data
    }

    async updateById(id: number, data: UpdateClient): Promise<IClients> {
        const response = await api.put(`/api/v1/reg/clients/${id}`, data, {
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        })
        return response.data.data
    }

}
