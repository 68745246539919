import { IProjectsMembers } from "../../../services/interfaces/pro/projectsMembers"
import { IProjectsSteps } from "../../../services/interfaces/pro/projectsSteps"
import { IProjectsTags } from "../../../services/interfaces/pro/projectsTags"

export function ProjectTimelineView({ projectSteps, projectsMembers, projectsTags }: ITimelineView) {
    return (
        <>
            EM DESENVOLVIMENTO
        </>
    )
}

interface ITimelineView {
    projectSteps: IProjectsSteps[] 
    projectsTags: IProjectsTags[]
    projectsMembers: IProjectsMembers[]
    onEditStep?: (e: any) => any 
}