import { Box, FormControl, FormLabel, Text } from "@chakra-ui/react";
import { DatePicker, DatePickerProps } from "antd";
import moment from "moment";
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ButtonDefault } from "../../../components/button/buttonDefault";
import { Container } from "../../../components/container/container";
import AutoCompleteInput from '../../../components/input/autocomplete';
import { InputDefault } from "../../../components/input/inputDefault";
import { Loader } from '../../../components/loader/loader';
import { useAlertContext } from '../../../contexts/alertContext';
import { useAuthContext } from '../../../contexts/authContext';
import { ICreateUsers } from '../../../services/interfaces/auth/users';
import { api } from '../../../config/api';
import { formValidator } from '../../../utils/form.validator';

export function UsersCreate() {

    const navigate = useNavigate()
    const location = useLocation();
    const { userData } = useAuthContext()
    const { setAlert } = useAlertContext()
    const [loading, setLoading] = useState(false)
    const dateFormat = ['DD/MM/YYYY']
    const [createUser, setCreateUser] = useState<ICreateUsers>({ 
        fullname: '',
        email: '', 
        position: '', 
        birthday: undefined, 
        dddPhone: undefined, 
        phone: undefined, 
        auth_typeAccess_id: undefined! 
    })

    const dataTypeAccess = location?.pathname?.includes('external') ? [
        { id: 5, value: 'Externo - Operação' },
        { id: 6, value: 'Externo - Apenas Leitura' },
    ] : [
        { id: 1, value: 'Administrador' },
        { id: 2, value: 'Gestor' },
        { id: 3, value: 'Líder' },
        { id: 4, value: 'Colaborador' }
    ]

    async function handleSubmit() {
        try {
            const requiredFields = formValidator([
                { "name": 'fullname', "value": createUser?.fullname, "required": true, "type": 'string' },
                { "name": 'email', "value": createUser?.email, "required": true, "type": 'string' },
                { "name": 'auth_typeAccess_id', "value": createUser?.auth_typeAccess_id, "required": true, "type": 'autocomplete' }
            ])

            if (requiredFields.length > 0) {
                return setAlert({
                    status: "error",
                    title: "Campos obrigatórios",
                    description: "Campos obrigatórios não preenchidos"
                })
            }

            setLoading(true)
            await api.post(`/api/v1/auth/users`, {
                fullname: createUser?.fullname,
                email: createUser?.email,
                position: createUser?.position ? createUser?.position : undefined,
                birthday: createUser?.birthday ? createUser?.birthday : undefined,
                dddPhone: createUser?.dddPhone ? createUser?.dddPhone : undefined,
                phone: createUser?.phone ? createUser?.phone : undefined,
                auth_typeAccess_id: createUser?.auth_typeAccess_id
            }, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            setLoading(false)
            setAlert({
                status: "success",
                title: "Sucesso",
                description: "Sucesso ao cadastrar usuário"
            })
            navigate(-1)
        } catch (error) {
            console.log(error)
            setLoading(false)
            setAlert({
                status: "error",
                title: "Erro",
                description: "Erro ao cadastrar usuário"
            })
        }
    }

    function handleChangeDate(): DatePickerProps['onChange'] {
        return (dateString) => {
            const dateFormatted: any = moment(String(dateString?.toISOString()))
            setCreateUser((prev: any) => ({
                ...prev, 
                birthday: dateString ? dateFormatted : createUser.birthday
            }))
        }
    }

    return (
        <>
            {
                loading
                    ?
                    <Loader />
                    :
                    <Container noBackground>
                        <Box className="w-full flex flex-col justify-start gap-10">
                            <Box className="bg-white shadow-md p-10 rounded-md flex flex-col gap-12" >
                                <Text className='text-lg text-primaryColor' fontWeight={'450'}>Cadastro de usuário</Text>
                                <FormControl className="flex flex-row flex-wrap gap-6">
                                    <Box className="flex flex-row gap-6 flex-wrap w-full">
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>Nome completo: *</FormLabel>
                                            <InputDefault 
                                                width={['100%', '376px']} 
                                                value={createUser?.fullname} 
                                                onChange={(e) => setCreateUser(prev => ({ ...prev, fullname: e.target.value }))} 
                                                type="text" id="fullname" 
                                            />
                                        </Box>
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>Email: *</FormLabel>
                                            <InputDefault 
                                                width={['100%', '376px']} 
                                                value={createUser?.email} 
                                                onChange={(e) => setCreateUser(prev => ({ ...prev, email: e.target.value }))} 
                                                type="email" id="email" 
                                            />
                                        </Box>
                                    </Box>
                                    <Box className="flex flex-row gap-6 flex-wrap w-full">
                                        <Box className="flex flex-row gap-3">
                                            <Box className="flex flex-col w-full md:w-auto">
                                                <FormLabel fontSize={12}>DDD:</FormLabel>
                                                <InputDefault 
                                                    width={20} 
                                                    value={createUser?.dddPhone ? createUser?.dddPhone : ''} 
                                                    maxLength={2} 
                                                    onChange={(e) => setCreateUser(prev => ({ ...prev, dddPhone: Number(e.target.value) }))} 
                                                    type="text" 
                                                />
                                            </Box>
                                            <Box className="flex flex-col w-full md:w-auto">
                                                <FormLabel fontSize={12}>Telefone:</FormLabel>
                                                <InputDefault 
                                                    width={[36, 72]} 
                                                    value={createUser?.phone ? createUser?.phone : ''} 
                                                    maxLength={9} 
                                                    onChange={(e) => setCreateUser(prev => ({ ...prev, phone: Number(e.target.value) }))} 
                                                    type="tel" 
                                                />
                                            </Box>
                                        </Box>
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>Cargo:</FormLabel>
                                            <InputDefault 
                                                width={['100%', '100%', 44]} 
                                                value={createUser?.position} 
                                                onChange={(e) => setCreateUser(prev => ({ ...prev, position: e.target.value }))} 
                                                type="text" id="position" 
                                            />
                                        </Box>
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>Tipo de acesso: *</FormLabel>
                                            <AutoCompleteInput 
                                                defaultValue={dataTypeAccess.filter((items: any) => items?.id === createUser?.auth_typeAccess_id)[0]?.value} 
                                                id="auth_typeAccess_id" 
                                                data={dataTypeAccess} 
                                                height="10"
                                                placeholder={"Selecione o tipo"} 
                                                preSelectedValue={["id", "value"]} 
                                                onChange={(e: any) => setCreateUser(prev => ({ ...prev, auth_typeAccess_id: e }))} 
                                            />
                                        </Box>
                                    </Box>
                                    <Box className="flex flex-row gap-6 flex-wrap w-full">
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>Data de nascimento:</FormLabel>
                                            <DatePicker
                                                id="startDate"
                                                format={dateFormat}
                                                placeholder="dd/mm/aaaa"
                                                onChange={handleChangeDate()}
                                                popupStyle={{ zIndex: 10000}}
                                                style={{ height: 34, width: 180 }}
                                            />
                                        </Box>
                                    </Box>
                                </FormControl>
                                <Box className="w-full flex justify-between items-center gap-2">
                                    <ButtonDefault onClick={() => navigate(-1)} success={false}>Cancelar</ButtonDefault>
                                    <ButtonDefault success onClick={() => handleSubmit()} >Confirmar</ButtonDefault>
                                </Box>
                            </Box>
                        </Box>
                    </Container>
            }
        </>
    )
}