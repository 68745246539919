import { HashRouter, Route, Routes } from "react-router-dom";
import { Loader } from "../components/loader/loader";
import { useAuthContext } from "../contexts/authContext";
import { Login } from "../pages/auth/login/login";
import { LostPassword } from "../pages/auth/lostPassword/lostPassword";
import { ClientNotFound } from "../pages/clientNotFound";

export function LoginRoutes() {

    const { loadingAuth } = useAuthContext()

    return (
        <HashRouter>
        {
            loadingAuth
            ?
            <Loader />
            :
            <Routes>
                <Route path="/lostPassword" element={<LostPassword />} />
                <Route path="/clientNotFound" element={<ClientNotFound />} />
                <Route path="/*" element={<Login />} />
                <Route path="/" element={<Login />} />
            </Routes>
        }
        </HashRouter>
    )
}