export const tasksStatusList: IStatus[] = [
    {
        id: 0,
        description: "Pendente",
        bgColor: 'bg-yellow-500',
        textColor: 'text-white'
    },
    {
        id: 1,
        description: "Concluído",
        bgColor: 'bg-green-500',
        textColor: 'text-white'
    },
    {
        id: 2,
        description: "Em andamento",
        bgColor: 'bg-blue-500',
        textColor: 'text-white'
    },
    {
        id: 3,
        description: "Parado",
        bgColor: 'bg-red-600',
        textColor: 'text-white'
    },
    {
        id: 4,
        description: "Aguardando retorno",
        bgColor: 'bg-purple-500',
        textColor: 'text-white'
    },
]

interface IStatus {
    id: number
    description: string
    bgColor: string
    textColor: string
}