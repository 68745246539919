import { useNavigate } from "react-router-dom"
import { ButtonDefault } from "./button/buttonDefault"

export function NoContent204() {

    const navigate = useNavigate()

    return (
        <div className="w-full h-full flex flex-col items-center justify-center gap-10">
            <div className="flex items-center justify-center gap-5">
                <div className="flex flex-col items-end">
                    <h1 className="text-7xl text-primaryColor "> 204</h1>
                    <p className="text-base">No Content</p>
                </div>   
                <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" fill="#279d73" viewBox="0 0 256 256"><path d="M240,80H108.94L87.16,36.42A8,8,0,0,0,80,32H48a8,8,0,0,0-8,8V80H24a8,8,0,0,0,0,16H40V208H24a8,8,0,0,0,0,16H128a8,8,0,0,0,0-16H112V96h96v88H192v-8a8,8,0,0,0-16,0v8a16,16,0,0,0,16,16h16a16,16,0,0,0,16-16V96h16a8,8,0,0,0,0-16ZM56,48H75.06l16,32H56Zm0,160V160H96v48Zm40-64H56V96H96Z"></path></svg>
            </div>
            <p className="text-lg">Essa página esta em fase de construção.</p>
            <ButtonDefault
                onClick={() => navigate('/')}
                success={true}
            >Voltar</ButtonDefault>
        </div>
    )
}