import { useAuthContext } from "../contexts/authContext";
import { LoginRoutes } from "./login.routes";
import { PreLoaderRoutes } from "./preLoader.routes";
import { AppRoutes } from "./routes";

export function RedirectRoutes() {

    const { signed, loadingAuth } = useAuthContext()

    return (
        <>
            {
                signed ?
                    <AppRoutes />
                    :
                    loadingAuth ?
                        <PreLoaderRoutes />
                        :
                        <LoginRoutes />
            }
        </>
    )

}