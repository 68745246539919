import { ChangeEvent, useEffect, useState } from 'react';
import { Container } from "../../../components/container/container";
import { useAlertContext } from '../../../contexts/alertContext';
import { useAuthContext } from '../../../contexts/authContext';
import { useToolbarContext } from '../../../contexts/toolbarContext';
import { IUsers, IUsersMyProfile } from '../../../services/interfaces/auth/users';
import { api } from '../../../config/api';
import { DatePicker, DatePickerProps, Flex, Input } from 'antd';
import { Avatar, Box, FormControl, FormLabel, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react';
import { ShieldWarning, ShieldCheck } from '@phosphor-icons/react';
import { InputDefault } from '../../../components/input/inputDefault';
import { Loader } from '../../../components/loader/loader';
import { ButtonDefault } from '../../../components/button/buttonDefault';
import moment from 'moment';
import dayjs from 'dayjs';
import SizeContext from 'antd/es/config-provider/SizeContext';
import { formValidator } from '../../../utils/form.validator';
import { Eye, EyeSlash } from '@phosphor-icons/react';
import {QRCodeSVG} from 'qrcode.react';


export function MyProfile() {

    const dateFormat = ['DD/MM/YYYY']
    const { userData, setUserData, needCofig2FA, setNeedCofig2FA } = useAuthContext()
    const { setAlert } = useAlertContext()
    const { setShow, setChildrenToolbar } = useToolbarContext()
    const [loading, setLoading] = useState<boolean>(true)
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [showNewPassword, setShowNewPassword] = useState<boolean>(false)
    const [showNewPasswordConfirmation, setShowNewPasswordConfirmation] = useState<boolean>(false)
    const [selectShowModal, setSelectShowModal] = useState<string>('')
    const [selectedFile, setSelectedFile] = useState<File | null>(null)
    const [userPicture, setUserPicture] = useState('')
    const [loading2FA, setLoading2FA] = useState(false)
    const [mfaData, setmfaData] = useState({} as {secret: string, qrCode: string, code1: string, code2: string})
    const [dataUser, setDataUser] = useState<IUsersMyProfile>({} as IUsersMyProfile)
    const [dataPassword, setDataPassword] = useState({
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    })

    async function getuserData() {
        try {
            const usersDataApi = await api.get(`/api/v1/auth/myProfile`, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            setDataUser(usersDataApi?.data?.data)

            if (usersDataApi?.data?.data?.picture) {
                try {
                    const resImage = await api.get(`/api/v1/auth/myProfile/picture`,  {
                        headers: {
                            Authorization: `Bearer ${userData?.token}`,
                            
                        },
                        responseType: 'arraybuffer',
                    })
                    const blob = new Blob([resImage.data], { type: resImage.headers['content-type'] })
                    const imageUrl = URL.createObjectURL(blob)
                    setUserPicture(imageUrl)
                    setUserData({
                        ...usersDataApi?.data?.data,
                        picture: imageUrl,
                        token: userData?.token
                    })
                } catch (error) {
                    console.log(error)
                    setLoading(false)
                    setAlert({
                        status: 'error',
                        title: 'Erro',
                        description: 'Erro ao buscar foto de perfil'
                    })
                }
            } 

            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
            setAlert({
                status: 'error',
                title: 'Erro',
                description: 'Erro ao buscar usuários'
            })
        }
    }

    async function getPicture() {
        try {
            if (!!userData?.picture) {
                const resImage = await api.get(`/api/v1/auth/myProfile/picture`,  {
                    headers: {
                        Authorization: `Bearer ${userData?.token}`,
                        
                    },
                    responseType: 'arraybuffer',
                })
                const blob = new Blob([resImage.data], { type: resImage.headers['content-type'] })
                const imageUrl = URL.createObjectURL(blob)
                setUserPicture(imageUrl)
                setUserData({
                    ...userData,
                    picture: imageUrl
                })
            } 
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
            setAlert({
                status: 'error',
                title: 'Erro',
                description: 'Erro ao buscar imagem de perfil'
            })
        }
    }

    async function handleEditProfile() {
        try {
            const requiredFields = formValidator([
                { "name": 'fullname', "value": dataUser?.fullname, "required": true, "type": 'string' },
                { "name": 'email', "value": dataUser.email, "required": true, "type": 'string' }
            ])

            if (requiredFields.length > 0) {
                return setAlert({
                    status: "error",
                    title: "Campos obrigatórios",
                    description: "Campos obrigatórios não preenchidos"
                })
            }

            await api.put(`/api/v1/auth/myProfile`, {
                fullname: dataUser.fullname,
                email: dataUser.email,
                birthday: dataUser?.birthday ? dataUser?.birthday : undefined,
                dddPhone: dataUser?.dddPhone ? dataUser?.dddPhone : undefined,
                phone: dataUser?.phone ? dataUser?.phone : undefined,
            }, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })
            setAlert({
                status: "success",
                title: "Sucesso",
                description: "Sucesso ao editar perfil"
            })
        } catch (error) {
            console.log(error)
            setLoading(false)
            setAlert({
                status: "error",
                title: "Erro",
                description: "Erro ao editar perfil"
            })
        }
    }

    function handleChangeDate(): DatePickerProps['onChange'] {
        return (dateString) => {
            const dateFormatted: any = moment(String(dateString?.toISOString()))
            setDataUser((prev: any) => ({
                ...prev,
                birthday: dateString ? dateFormatted : dataUser?.birthday
            }))
        }
    }

    function handleOpenSelectPicture() {
        const fileInput = document.getElementById('inputSetPicture')
        if (fileInput) {
            fileInput.click();
        }
    }

    function handleChangeImage(event: ChangeEvent<HTMLInputElement>) {
        const file = event.target.files?.[0];
        if (file) {
            setSelectedFile(file);
        }
    };

    function handleSubmit() {
        if (selectedFile) {
            const formData = new FormData();
            formData.append('file', selectedFile);
            handleSaveProfileImage(formData)
            setSelectShowModal(''); 
            setSelectedFile(null)
        } else {
            setAlert({
                status: "error",
                title: "Erro",
                description: "Nenhum arquivo selecionado"
            })
        }
    }

    async function handleSaveProfileImage(formData: FormData) {
        try {
            const requiredFields = formValidator([
                { "name": 'inputSetPicture', "value": selectedFile, "required": true, "type": "file" },
            ])

            if (requiredFields.length > 0) {
                return setAlert({
                    status: "error",
                    title: "Campos obrigatórios",
                    description: "Campos obrigatórios não preenchidos"
                })
            }

            await api.post(`/api/v1/auth/myProfile/picture`, formData, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            await getPicture()
            setAlert({
                status: "success",
                title: "Sucesso",
                description: "Sucesso ao editar foto de perfil"
            })
        } catch (error) {
            console.log(error)
            setLoading(false)
            setAlert({
                status: "error",
                title: "Erro",
                description: "Erro ao editar foto de perfil"
            })
        }
    }

    async function handleAlterPassword() {

        if (dataPassword.newPassword !== dataPassword?.confirmNewPassword) {
            setAlert({
                status: "error",
                title: "Erro",
                description: "Nova senha e confirmação de senha diferentes!"
            })
        }
            
        try {
            const requiredFields = formValidator([
                { "name": 'password', "value": dataPassword.oldPassword, "required": true, "type": 'string' },
                { "name": 'newPassword', "value": dataPassword.newPassword, "required": true, "type": 'string' },
                { "name": 'confirmNewPassword', "value": dataPassword.confirmNewPassword, "required": true, "type": 'string' },
            ])

            if (requiredFields.length > 0) {
                return setAlert({
                    status: "error",
                    title: "Campos obrigatórios",
                    description: "Campos obrigatórios não preenchidos"
                })
            }

            await api.put(`/api/v1/auth/myProfile/changePassword`, {
                oldPassword: dataPassword.oldPassword,
                newPassword: dataPassword.newPassword,
                confirmNewPassword: dataPassword.confirmNewPassword,
            }, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })
            setSelectShowModal('')
            handleClearPassword()
            setAlert({
                status: "success",
                title: "Sucesso",
                description: "Sucesso ao alterar senha"
            })
        } catch (error) {
            console.log(error)
            setLoading(false)
            setAlert({
                status: "error",
                title: "Erro",
                description: "Erro ao alterar senha"
            })
        }
    }

    function handleClearPassword() {
        setDataPassword({
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: '',
        })
    }

    useEffect(() => {
        getuserData()
        setShow(false)
        setChildrenToolbar('')
        getPicture()
    }, [])

    useEffect(() => {
        if(selectShowModal === '2FA_config'){
            get2FA()
        }
    }, [selectShowModal])

    async function get2FA(){
        try {
            setLoading2FA(true)
            const mfaDataApi = await api.get('/api/v1/auth/users/2fa/generate', {
                headers: {
                    Authorization: `Bearer ${userData?.token}` 
                }
            })
            setmfaData(mfaDataApi?.data?.data)
        } catch (error) {
            console.log(error)
            setLoading(false)
            setAlert({
                status: 'error',
                title: 'Erro',
                description: 'Erro ao gerar código 2FA'
            })
        }finally{
            setLoading2FA(false)
        }
    }

    async function handleSend2faConfig(){
        try {
            const requiredFields = formValidator([
                { "name": 'code1', "value": mfaData?.code1, "required": true, "type": 'string' },
            ])

            if (requiredFields.length > 0) {
                return setAlert({
                    status: "error",
                    title: "Campos obrigatórios",
                    description: "Campos obrigatórios não preenchidos"
                })
            }

            setLoading2FA(true)
            await api.post('/api/v1/auth/users/2fa/save', {
                secret: mfaData.secret,
                code1: mfaData.code1,
            },{
                headers: {
                    Authorization: `Bearer ${userData?.token}` 
                }
            })
            
            setUserData({...userData, mfa_use: true} as IUsers)
            setNeedCofig2FA(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
            setAlert({
                status: 'error',
                title: 'Erro',
                description: 'Erro ao configurar código 2FA'
            })
        }finally{
            setLoading2FA(false)
        }
    }

    return (
        <Container noBackground>
            {
                loading
                    ?
                    <Loader />
                    :
                    selectShowModal === 'editProfilePicture'
                        ?
                        // Modal de seleção de foto para perfil do usuário
                        <Modal trapFocus={false} isOpen={selectShowModal === 'editProfilePicture'} onClose={() => { setSelectShowModal(''); setSelectedFile(null) }} isCentered>
                            <ModalOverlay />
                            <ModalContent height={450} className="flex flex-col items-center gap-4 mt-3">
                                <ModalCloseButton />
                                <ModalHeader>
                                    <Text className="text-lg" color={'#1E1E1E'}>Selecione uma foto</Text>
                                </ModalHeader>
                                <ModalBody>
                                    <Box className="flex flex-col gap-2">
                                        <Avatar
                                            className='cursor-pointer'
                                            onClick={() => handleOpenSelectPicture()} styleConfig={SizeContext}
                                            style={{ width: 250, height: 250, fontSize: '6rem', color: 'white' }}
                                            bg={"#c4c4c4"}
                                            name={userData?.fullname}
                                            src={selectedFile && URL.createObjectURL(selectedFile) ? URL.createObjectURL(selectedFile) : userPicture}
                                        >
                                        </Avatar>
                                        <Input id='inputSetPicture' onChange={handleChangeImage} type="file" accept="image/*" hidden />
                                    </Box>
                                </ModalBody>
                                <ModalFooter>
                                    <Flex gap={2} align={'center'}>
                                        <ButtonDefault onClick={() => { setSelectShowModal(''); setSelectedFile(null) }}>Cancelar</ButtonDefault>
                                        <ButtonDefault success onClick={() => handleSubmit()}>Confirmar</ButtonDefault>
                                    </Flex>
                                </ModalFooter>
                            </ModalContent>
                        </Modal>
                        :
                        selectShowModal === 'editPassword'
                            ?
                            // Modal de edição de senha do usuario
                            <Modal size={'xl'} trapFocus={false} isOpen={selectShowModal === 'editPassword'} onClose={() => { setSelectShowModal(''); setSelectedFile(null) }} isCentered>
                                <ModalOverlay />
                                <ModalContent height={450} className="flex flex-col items-center gap-4 mt-3">
                                    <ModalCloseButton />
                                    <ModalHeader>
                                        <Text className="text-lg" color={'#1E1E1E'}>Alteração de senha</Text>
                                    </ModalHeader>
                                    <ModalBody>
                                        <Box className="flex flex-col ">
                                            <FormLabel fontSize={14} style={{ marginBottom: 0 }}>Digite a senha atual:</FormLabel>
                                            <Flex>
                                                <InputDefault
                                                    id='password'
                                                    value={dataPassword?.oldPassword}
                                                    onChange={(e) => setDataPassword(prev => ({ ...prev, oldPassword: e.target.value }))}
                                                    type={showPassword ? 'text' : 'password'}
                                                />
                                                {
                                                    showPassword
                                                        ?
                                                        <EyeSlash onClick={() => setShowPassword(false)} className='mt-2 ml-2' />
                                                        :
                                                        <Eye onClick={() => setShowPassword(true)} className='mt-2 ml-2' />
                                                }
                                            </Flex>
                                            <FormLabel fontSize={14} style={{ marginBottom: 0, marginTop: 10 }}>Digite a nova senha:</FormLabel>
                                            <Flex>
                                                <InputDefault
                                                    id='newPassword'
                                                    value={dataPassword?.newPassword}
                                                    onChange={(e) => setDataPassword(prev => ({ ...prev, newPassword: e.target.value }))}
                                                    type={showNewPassword ? 'text' : 'password'}
                                                />
                                                {
                                                    showNewPassword
                                                        ?
                                                        <EyeSlash onClick={() => setShowNewPassword(false)} className='mt-2 ml-2' />
                                                        :
                                                        <Eye onClick={() => setShowNewPassword(true)} className='mt-2 ml-2' />
                                                }
                                            </Flex>
                                            <FormLabel fontSize={14} style={{ marginBottom: 0, marginTop: 10 }}>Confirme a nova senha:</FormLabel>
                                            <Flex>
                                                <InputDefault
                                                    id='confirmNewPassword'
                                                    value={dataPassword?.confirmNewPassword}
                                                    onChange={(e) => setDataPassword(prev => ({ ...prev, confirmNewPassword: e.target.value }))}
                                                    type={showNewPasswordConfirmation ? 'text' : 'password'}
                                                />
                                                {
                                                    showNewPasswordConfirmation
                                                        ?
                                                        <EyeSlash onClick={() => setShowNewPasswordConfirmation(false)} className='mt-2 ml-2' />
                                                        :
                                                        <Eye onClick={() => setShowNewPasswordConfirmation(true)} className='mt-2 ml-2' />
                                                }
                                            </Flex>

                                        </Box>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Flex gap={2} align={'center'}>
                                            <ButtonDefault onClick={() => { setSelectShowModal(''); handleClearPassword() }}>Cancelar</ButtonDefault>
                                            <ButtonDefault success onClick={() => handleAlterPassword()}>Confirmar</ButtonDefault>
                                        </Flex>
                                    </ModalFooter>
                                </ModalContent>
                            </Modal>
                            :
                            selectShowModal === '2FA_config' 
                                ?
                                <Modal size={'xl'} trapFocus={false} isOpen={selectShowModal === '2FA_config'} onClose={() => { setSelectShowModal(''); setSelectedFile(null) }} isCentered>
                                    <ModalOverlay />
                                    <ModalContent height={400} className="flex flex-col items-center gap-4 mt-3">
                                        <ModalCloseButton />
                                        <ModalHeader>
                                            <Text className="text-lg" color={'#1E1E1E'}>Configurar 2FA</Text>
                                        </ModalHeader>
                                        <ModalBody>
                                            {
                                                loading2FA ? <p className='animate-pulse mt-20'>Carregando...</p>
                                                : <div className='flex flex-col items-center justify-center gap-5'>
                                                    <QRCodeSVG value={mfaData?.qrCode} size={150}/>
                                                    <Box className="flex flex-col w-full md:w-auto">
                                                        <FormLabel fontSize={12}>Código 1: *</FormLabel>
                                                        <InputDefault
                                                            width={['100%', '250px']}
                                                            value={mfaData?.code1}
                                                            maxLength={6}
                                                            textAlign={'center'}
                                                            onChange={(e) => setmfaData(prev => ({ ...prev, code1: e.target.value }))}
                                                            type="text"
                                                            id="code1"
                                                        />
                                                    </Box>
                                                </div>
                                            }
                                        </ModalBody>
                                        <ModalFooter>
                                            <Flex gap={80} align={'center'}>
                                                <ButtonDefault onClick={() => { setSelectShowModal(''); handleClearPassword() }}>Cancelar</ButtonDefault>
                                                <ButtonDefault success onClick={() => handleSend2faConfig()}>Confirmar</ButtonDefault>
                                            </Flex>
                                        </ModalFooter>
                                    </ModalContent>
                                </Modal>
                                : 
                                // Formulario de dados do usuário logado  
                                <Box className="w-full flex flex-col justify-start gap-10">
                                    <Box className="bg-white shadow-md p-10 rounded-md flex flex-col gap-7" >
                                        {needCofig2FA && <p className='text-red-600 text-xl animate-pulse'>AÇÃO NECESSÁRIA: Configure o 2FA para prosseguir</p>}
                                        <Text className="text-lg text-primaryColor" fontWeight={'450'}>Edição de Perfil</Text>
                                        <Box className='flex items-center justify-start gap-4'>
                                            <Avatar className='cursor-pointer' onClick={() => setSelectShowModal('editProfilePicture')} size='xl' bg={"#c4c4c4"} name={userData?.fullname} src={userPicture}></Avatar>
                                            {
                                                userData?.mfa_use ? <div className='flex flex-col items-center'>
                                                    <div className='flex gap-2 items-center text-primaryColor'>
                                                        <ShieldCheck size={25}/>
                                                        <p>Conta segura</p>
                                                    </div>
                                                    <span 
                                                        className='text-primaryColor underline hover:opacity-70 cursor-pointer text-sm' 
                                                        onClick={() => setSelectShowModal('2FA_config')}
                                                    >Editar 2FA</span>
                                                </div>
                                                : <div className='flex flex-col items-center'>
                                                    <div className='flex gap-2 items-center text-red-600'>
                                                        <ShieldWarning size={25}/>
                                                        <p>Conta não segura</p>
                                                    </div>
                                                    <span 
                                                        className='text-primaryColor underline hover:opacity-70 cursor-pointer text-sm' 
                                                        onClick={() => setSelectShowModal('2FA_config')}
                                                    >Configurar 2FA</span>
                                                </div>
                                            }
                                        </Box>
                                        <FormControl className="flex flex-row flex-wrap gap-6">
                                            <Box className="flex flex-row gap-6 flex-wrap w-full">
                                                <Box className="flex flex-col w-full md:w-auto">
                                                    <FormLabel fontSize={12}>Nome completo: *</FormLabel>
                                                    <InputDefault
                                                        width={['100%', '376px']}
                                                        value={dataUser?.fullname}
                                                        onChange={(e) => setDataUser(prev => ({ ...prev, fullname: e.target.value }))}
                                                        type="text"
                                                        id="fullname"
                                                    />
                                                </Box>
                                                <Box className="flex flex-col w-full md:w-auto">
                                                    <FormLabel fontSize={12}>Email: *</FormLabel>
                                                    <InputDefault
                                                        width={['100%', '376px']}
                                                        value={dataUser?.email}
                                                        onChange={(e) => setDataUser(prev => ({ ...prev, email: e.target.value }))}
                                                        type="email"
                                                        id="email"
                                                    />
                                                </Box>
                                            </Box>
                                            <Box className="flex flex-row gap-6 flex-wrap w-full">
                                                <Box className="flex flex-row gap-3">
                                                    <Box className="flex flex-col w-full md:w-auto">
                                                        <FormLabel fontSize={12}>DDD:</FormLabel>
                                                        <InputDefault
                                                            width={20}
                                                            value={dataUser?.dddPhone ? dataUser?.dddPhone : ''}
                                                            maxLength={2}
                                                            onChange={(e) => setDataUser(prev => ({ ...prev, dddPhone: Number(e.target.value) }))}
                                                            type="text"
                                                        />
                                                    </Box>
                                                    <Box className="flex flex-col w-full md:w-auto">
                                                        <FormLabel fontSize={12}>Telefone:</FormLabel>
                                                        <InputDefault
                                                            width={[36, 72]}
                                                            value={dataUser?.phone ? dataUser?.phone : ''}
                                                            maxLength={12}
                                                            onChange={(e) => setDataUser(prev => ({ ...prev, phone: Number(e.target.value) }))}
                                                            type="tel"
                                                        />
                                                    </Box>
                                                </Box>
                                                <Box className="flex flex-row gap-3">
                                                    <Box className="flex flex-col w-full md:w-auto">
                                                        <FormLabel fontSize={12}>Data de nascimento:</FormLabel>
                                                        <DatePicker
                                                            id="startDate"
                                                            format={dateFormat}
                                                            placeholder="dd/mm/aaaa"
                                                            value={dataUser?.birthday ? dayjs(moment(dataUser?.birthday).utc(false).format('DD/MM/YYYY'), dateFormat[0]) : null}
                                                            onChange={handleChangeDate()}
                                                            popupStyle={{ zIndex: 10000 }}
                                                            style={{ height: 33 }}
                                                        />
                                                    </Box>
                                                    <Box className="flex flex-col w-full md:w-auto">
                                                        <FormLabel fontSize={12}>Acesso</FormLabel>
                                                        <InputDefault
                                                            value={
                                                                dataUser.auth_typeAccess_id === 1 ? 'Administrador' :
                                                                    dataUser.auth_typeAccess_id === 2 ? 'Gestor' :
                                                                        dataUser.auth_typeAccess_id === 3 ? 'Líder' :
                                                                            dataUser.auth_typeAccess_id === 4 ? 'Colaborador' :
                                                                                ''
                                                            }
                                                            isDisabled
                                                            width={[36, 56]}
                                                            type="text" />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </FormControl>
                                        <Box className="flex items-center justify-between mt-5">
                                            <Flex gap={2}>
                                                <ButtonDefault success onClick={() => setSelectShowModal('editPassword')}>Alterar Senha</ButtonDefault>
                                            </Flex>
                                            <Flex gap={2}>
                                                <ButtonDefault success onClick={() => handleEditProfile()}>Salvar</ButtonDefault>
                                            </Flex>
                                        </Box>
                                    </Box>
                                </Box>

            }
        </Container>
    )
}