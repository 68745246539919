import { useEffect, useState } from "react";
import { Container } from "../../../components/container/container";
import { useAuthContext } from "../../../contexts/authContext";
import { Clients } from "../../../services/api/reg/Clients";
import { IClients } from "../../../services/interfaces/reg/clients";
import { ClientsContracts } from "../../../services/api/reg/ClientsContracts";
import { CreateClientContracts } from "../../../services/interfaces/reg/clientsContracts";
import { useNavigate } from "react-router-dom";
import { FormLabel } from "@chakra-ui/react";
import { InputDefault } from "../../../components/input/inputDefault";
import moment from "moment";
import { DatePicker, DatePickerProps } from "antd";
import AutoCompleteInput from "../../../components/input/autocomplete";
import { ButtonDefault } from "../../../components/button/buttonDefault";
import { Minus, Plus } from "@phosphor-icons/react";
import dayjs from "dayjs";
import { Loader } from "../../../components/loader/loader";
import { useAlertContext } from "../../../contexts/alertContext";
import { formValidator } from "../../../utils/form.validator";

export function ContractsCreate() {

    const navigate = useNavigate()
    const { setAlert } = useAlertContext()
    const { userData } = useAuthContext()
    const [ clientsList, setClientsList ] = useState<IClients[]>([])
    const [ loading, setLoading ] = useState<boolean>(true)
    const [ contract, setContract ] = useState<CreateClientContracts>({} as any)

    useEffect(() => {
        getClients()        
    }, [])

    const clientsService = new Clients(userData?.token)
    const clientsContractsService = new ClientsContracts(userData?.token)

    async function getClients(){
        setLoading(true)
        try {
            const getClientsList = await clientsService.readAll()
            setClientsList(getClientsList)
        } catch (error) {
            console.log('> Error on try to get clients:', error)
            setAlert({
                title: 'Erro',
                description: 'Erro ao tentar pegar relação de clientes',
                status: 'error'
            })   
        } finally {
            setLoading(false)
        }
    }

    async function handleSubmit(){

        const requiredFields = 
        
        formValidator( [
            { "name": 'reg_clients_id', "value": contract?.reg_clients_id, "required": true, "type": 'autocomplete' },
            { "name": 'description', "value": contract?.description, "required": true, "type": 'string' },
            { "name": 'startDate', "value": contract?.startDate, "required": true, "type": 'datepicker' },
            { "name": 'amountSeconds', "value": contract?.amountSeconds, "required": true, "type": 'number' },
            
        ])       

        if (requiredFields.length > 0) {
            return setAlert({
                status: "error",
                title: "Campos obrigatórios",
                description: "Campos obrigatórios não preenchidos"
            })
        }
        setLoading(true)
        try {
            await clientsContractsService.create({
                amountSeconds: contract.amountSeconds * 60 * 60,
                description: contract.description,
                reg_clients_id: Number(contract.reg_clients_id),
                startDate: contract.startDate, 
                amendments: contract.amendments ? contract.amendments : [],
                finishDate: contract.finishDate ? contract.finishDate : undefined,
                value: contract.value ? contract.value : undefined
            })
            setAlert({
                title: 'Sucesso',
                status: 'success',
                description: 'Sucesso ao criar contrato'
            })
            navigate(-1)
        } catch (error) {
            console.log('> Error on try to create a new contract:', error)
            setAlert({
                title: 'Erro',
                status: 'error',
                description: 'Erro ao tentar criar contrato'
            })
        }finally{
            setLoading(false)
        }
    }

    return (
        <>
            {
                loading 
                    ? <Loader />
                    : <Container noBackground={true}>
                        <div className="bg-white w-full sm:p-10 p-3 flex flex-col">
                            <h2 className='text-lg text-primaryColor'>Alteração de Contratos</h2>
                            <div className="flex flex-row flex-wrap my-10 gap-5 w-[900px]">
                                <div>
                                    <FormLabel fontSize={12}>Cliente: *</FormLabel>
                                    <AutoCompleteInput 
                                        width="400px" 
                                        defaultValue={clientsList.find(client => client.id === contract?.reg_clients_id)?.name} 
                                        id="reg_clients_id" 
                                        data={clientsList} 
                                        preSelectedValue={["id", "name"]} 
                                        placeholder={"Selecione o cliente"} 
                                        onChange={(e: number) => setContract((prev: any) => ({ ...prev, reg_clients_id: e }))} 
                                    />
                                </div>
                                <div>
                                    <FormLabel fontSize={12}>Descrição: *</FormLabel>
                                    <InputDefault 
                                        onChange={(e) => setContract((prev) => ({ ...prev, description: e.target.value }))} 
                                        width={96} value={contract?.description} 
                                        type="text" id="description"
                                    />
                                </div>
                                <div className="flex gap-5">
                                    <div>
                                        <FormLabel fontSize={12}>Início: *</FormLabel>
                                        <DatePicker
                                            id="startDate"
                                            format={['DD/MM/YYYY']}
                                            placeholder="dd/mm/aaaa"
                                            value={ contract.startDate ? dayjs(moment.utc(contract.startDate).format('DD/MM/YYYY'), ['DD/MM/YYYY']) : null } 
                                            onChange={(dateString) => {
                                                const dateFormatted: any = moment(String(dateString?.toISOString()))
                                                setContract((prev: any) => ({
                                                    ...prev, 
                                                    startDate: dateString ? dateFormatted : contract.startDate
                                                }))
                                            }}
                                            popupStyle={{ zIndex: 10000}}
                                            style={{ height: 40, width: '190px'}}
                                        />
                                    </div>
                                    <div>
                                        <FormLabel fontSize={12}>Fim:</FormLabel>
                                        <DatePicker
                                            id="finishDate"
                                            format={['DD/MM/YYYY']}
                                            placeholder="dd/mm/aaaa"
                                            value={ contract.finishDate ? dayjs(moment.utc(contract.finishDate).format('DD/MM/YYYY'), ['DD/MM/YYYY']) : null } 
                                            onChange={(dateString) => {
                                                const dateFormatted: any = moment(String(dateString?.toISOString()))
                                                setContract((prev: any) => ({
                                                    ...prev, 
                                                    finishDate: dateString ? dateFormatted : contract.finishDate
                                                }))
                                            }}
                                            popupStyle={{ zIndex: 10000}}
                                            style={{ height: 40, width: '190px'}}
                                        />
                                    </div>
                                </div>
                                <div className="flex gap-5">
                                    <div>
                                        <FormLabel fontSize={12}>Quantidade de Horas: *</FormLabel>
                                        <InputDefault 
                                            onChange={(e) => setContract((prev) => ({ ...prev, amountSeconds: Number(e.target.value) }))} 
                                            width={'180px'} value={contract?.amountSeconds} 
                                            type="text" id="amountSeconds"
                                        />
                                    </div>
                                    <div>
                                        <FormLabel fontSize={12}>Valor:</FormLabel>
                                        <InputDefault 
                                            onChange={(e) => setContract((prev) => ({ ...prev, value: Number(e.target.value) }))} 
                                            width={'180px'} value={contract?.value} 
                                            type="text" id="value"
                                        />
                                    </div>
                                </div>
                            </div>
                            <hr className="w-full"/>
                            <h2 className='text-base text-primaryColor mt-8 mb-2'>Aditivos</h2> 
                            {
                                !contract?.amendments || contract?.amendments?.length === 0 
                                    ? <button className={`text-sm p-0 text-primaryColor underline`} onClick={() => {
                                        setContract((prev) => ({
                                            ...prev,
                                            amendments: [
                                                {
                                                    amountSeconds: 0,
                                                    description: '',
                                                    referenceDate: new Date(),
                                                    value: 0
                                                }
                                            ]
                                        }))
                                    }}>+ Adicionar</button>
                                    : <div className="flex flex-col gap-5">
                                        {
                                            contract.amendments.map((amendment, index)=> {
                                                return (
                                                    <div key={index} className="flex gap-5">
                                                        <div>
                                                            <FormLabel fontSize={12}>Data de referência: *</FormLabel>
                                                            <DatePicker
                                                                id="finishDate"
                                                                format={['DD/MM/YYYY']}
                                                                placeholder="dd/mm/aaaa"
                                                                onChange={(dateString) => {
                                                                    const dateFormatted: any = moment(String(dateString?.toISOString()))
                                                                    if(contract.amendments){
                                                                        contract.amendments[index].referenceDate = dateFormatted
                                                                        setContract({...contract})
                                                                    }
                                                                }}
                                                                value={ amendment?.referenceDate ? dayjs(moment.utc(amendment?.referenceDate).format('DD/MM/YYYY'), ['DD/MM/YYYY']) : null } 
                                                                popupStyle={{ zIndex: 10000}}
                                                                style={{ height: 40, width: '140px' }}
                                                            />
                                                        </div>
                                                        <div>
                                                            <FormLabel fontSize={12}>Descrição: *</FormLabel>
                                                            <InputDefault 
                                                                onChange={(e) => {
                                                                    amendment.description = e.target.value
                                                                    setContract({...contract})
                                                                }} 
                                                                width={60} 
                                                                value={amendment.description} 
                                                                type="text" id="description"
                                                            />
                                                        </div>
                                                        <div>
                                                            <FormLabel fontSize={12}>Quantidade de horas: *</FormLabel>
                                                            <InputDefault 
                                                                onChange={(e) => {
                                                                    amendment.amountSeconds = Number(e.target.value)
                                                                    setContract({...contract})
                                                                }}
                                                                width={'180px'} 
                                                                value={amendment?.amountSeconds ? amendment?.amountSeconds : undefined} 
                                                                type="number" id="description"
                                                            />
                                                        </div>
                                                        <div>
                                                            <FormLabel fontSize={12}>Valor: *</FormLabel>
                                                            <InputDefault 
                                                                onChange={(e) => {
                                                                    amendment.value = Number(e.target.value)
                                                                    setContract({...contract})
                                                                }}
                                                                width={'180px'} 
                                                                value={amendment?.value ? amendment?.value : undefined} 
                                                                type="number" id="description"
                                                            />
                                                        </div>
                                                        <div className='flex items-center justify-center gap-2'>
                                                            <Plus 
                                                                className='cursor-pointer' 
                                                                onClick={() => {
                                                                    contract.amendments?.push({
                                                                        amountSeconds: 0,
                                                                        description: '',
                                                                        value: 0,
                                                                        referenceDate: new Date(),
                                                                    })
                                                                    setContract({...contract})
                                                                }}
                                                            />
                                                            <Minus className='cursor-pointer' onClick={() => {
                                                                contract.amendments?.splice(index, 1)
                                                                setContract({...contract})
                                                            }}/>
                                                        </div>
                                                    </div>
                                                )
                                            })   
                                        }
                                    </div>
                            }
                            <div className="mt-10 flex justify-between">
                                <ButtonDefault onClick={() => navigate(-1)}>Cancelar</ButtonDefault>
                                <ButtonDefault onClick={() => handleSubmit()} success>Cadastrar</ButtonDefault>
                            </div>
                        </div>
                    </Container>
            }
        </>
    )
}