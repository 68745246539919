import { Container } from "../../../components/container/container";
import { NewTableDefault } from '../../../components/tables/newTableDefault';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from "../../../contexts/authContext";

export function Contracts() {

    const { userData } = useAuthContext()
    const navigate = useNavigate()

    return (
        <Container
            title={{
                name: 'Contratos',
                hasButtonAction: true,
                buttonName: 'Adicionar Contrato',
                onClickAction: () => navigate('/contracts/create')
            }}
        >
            <NewTableDefault
                endpoint='/api/v1/reg/clientsContracts'
                columns={[ 
                    {
                        description: "Código",
                        field: "id",
                        type: "number"
                    },
                    {
                        description: "Descrição",
                        field: "description",
                        type: "string"
                    },
                    {
                        description: "Cliente",
                        field: "reg_clients_name",
                        type: "string",
                        newFieldValue: (e) => {
                            return e.reg_clients?.name
                        }
                    },
                    {
                        description: "Inicio",
                        field: "startDate",
                        type: "date",
                    },
                    {
                        description: "Vencimento",
                        field: "finishDate",
                        type: "date",
                    },
                    {
                        description: "Horas",
                        field: "totalAmountSeconds",
                        type: "secondsToHours",
                    },
                    {
                        description: "Valor",
                        field: "totalValue",
                        type: "money",
                    },
                ]}
                token={userData?.token as string}
                usePagination={false}
                filters={[]}
                onClickInTableLine={(e: any) => navigate(`/contracts/edit/${e.id}`)}
            />
        </Container>
    )
}