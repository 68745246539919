import { Box, FormControl, FormLabel, Text } from "@chakra-ui/react"
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ButtonDefault } from "../../../components/button/buttonDefault"
import { Container } from "../../../components/container/container"
import { InputDefault } from "../../../components/input/inputDefault"
import { Loader } from '../../../components/loader/loader'
import { useAuthContext } from '../../../contexts/authContext'
import { CreateClient } from '../../../services/interfaces/reg/clients'
import { Select } from "antd"
import { Clients } from "../../../services/api/reg/Clients"
import { useAlertContext } from "../../../contexts/alertContext"
import { formValidator } from "../../../utils/form.validator"

export function ClientsCreate() {

    const navigate = useNavigate()
    const { userData } = useAuthContext()
    const { setAlert } = useAlertContext()
    const [loading, setLoading] = useState(false)
    const [createClient, setCreateClient] = useState<CreateClient>({
        name: '',
        email: '',
        cnpj: '',
        personType: "PJ",
    })
    const [successOnGetAddressOfCNPJ, setSuccessOnGetAddressOfCNPJ] = useState(true)
    const clientsService = new Clients(userData?.token)

    async function handleSubmit() {

        const requiredFields = createClient.personType === "PF" ?

            formValidator([
                { "name": 'cpf', "value": createClient?.cpf, "required": true, "type": 'string' },
                { "name": 'name', "value": createClient?.name, "required": true, "type": 'string' },
                { "name": 'email', "value": createClient?.email, "required": true, "type": 'string' }
            ])
            :
            formValidator([
                { "name": 'cnpj', "value": createClient?.cnpj, "required": true, "type": 'string' },
                { "name": 'name', "value": createClient?.name, "required": true, "type": 'string' },
                { "name": 'email', "value": createClient?.email, "required": true, "type": 'string' }
            ])

        if (requiredFields.length > 0) {
            return setAlert({
                status: "error",
                title: "Campos obrigatórios",
                description: "Campos obrigatórios não preenchidos"
            })
        }
        setLoading(true)

        try {
            await clientsService.create({
                ...createClient,
                phone: createClient.phone && Number(createClient.phone),
                dddPhone: createClient.dddPhone && Number(createClient.dddPhone),
                cnpj: createClient.cnpj && createClient.cnpj.replace(/[^a-zA-Z0-9 ]/g, ''),
                cpf: createClient.cpf && createClient.cpf.replace(/[^a-zA-Z0-9 ]/g, ''),
            })
            cleanStatesClients()
            navigate(-1)
        } catch (error) {
            console.log(error)
            setAlert({
                status: 'error',
                title: 'Erro',
                description: (error as any)?.response?.data?.message || 'Erro ao cadastrar cliente'
            })
        } finally {
            setLoading(false)
        }
    }

    function cleanStatesClients() {
        setCreateClient(prev => ({
            ...prev,
            name: '',
            email: '',
            cnpj: '',
            fantasyName: '',
            dddPhone: 0,
            phone: 0,
            cep: '',
            addressRoad: '',
            addressState: '',
            addressDistrict: '',
            addressCity: '',
            addressNumber: '',
            addressComplement: '',
        }))
    }

    async function getAddressCep(cep: string) {
        if (cep?.length === 9) {
            cep = cep.replace(/^0+/, '')
            setLoading(true)
            const addressCepData = await fetch(`https://brasilapi.com.br/api/cep/v1/${cep}`)

            if (!addressCepData.ok) {
                setLoading(false)
                return console.log('Erro ao buscar cep')
            }

            const response = await addressCepData.json()
            if (response) {
                response?.street?.split(' ')
                setCreateClient(prev => ({
                    ...prev,
                    cep: response?.cep,
                    addressCity: response?.city,
                    addressDistrict: response?.neighborhood,
                    addressState: response?.state,
                    addressRoad: response.street,
                }))
                setLoading(false)
            }
        }
    }

    const formatCNPJ = (value: string) => {
        const numbers = value.replace(/\D/g, '');
        return numbers.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    }

    function handleChangeCnpj(e: any) {
        const formattedValue = formatCNPJ(e.target.value)

        setCreateClient(prev => ({ ...prev, cnpj: formattedValue }))
    }

    const formatCPF = (value: string) => {
        const numbers = value.replace(/\D/g, '')
        return numbers.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
    }

    function handleChangeCpf(e: any) {
        const formattedValue = formatCPF(e.target.value)
        setCreateClient(prev => ({ ...prev, cpf: formattedValue }))
    }

    const formatCep = (value?: string) => {
        const numbers = value?.replace(/\D/g, '')
        return numbers?.replace(/^(\d{5})(\d{3})$/, '$1-$2')
    }

    function handleChangeCep(e: any) {
        const formattedValue = formatCep(e.target.value)
        setCreateClient(prev => ({ ...prev, cep: formattedValue }))
    }

    async function getCnpjData(cnpj: string) {
        setLoading(true)
        const formattedCNPJ = cnpj?.replace(/[.-/\\]/g, '')
        try {
            const receitaws = await fetch(`https://receitaws.com.br/v1/cnpj/${formattedCNPJ}`)
            if (receitaws.ok) {
                const data = await receitaws.json()
                const tel = data?.telefone ? data?.telefone?.split(' ') : []
                setCreateClient((prev) => ({
                    ...prev,
                    name: data?.nome,
                    fantasyName: data?.fantasia,
                    email: data?.email,
                    dddPhone: tel.length > 0 ? Number(tel[0].replace('(', '').replace(')', '')) : undefined,
                    phone: tel.length > 0 ? Number(tel[1].replace('-', '')) : undefined,
                    cep: data?.cep ? data.cep.replace('-', '').replace('.', '') : undefined,
                    addressState: data?.uf,
                    addressCity: data?.municipio,
                    addressComplement: data?.complemento,
                    addressDistrict: data?.bairro,
                    addressNumber: data?.numero,
                    addressRoad: data?.logradouro,
                }))
            } else {
                throw new Error('Erro on try to get cnpj data from receitaws.')
            }

            setLoading(false)
            setSuccessOnGetAddressOfCNPJ(true)
        } catch (error) {
            try {
                const brasilapi = await fetch(`https://brasilapi.com.br/api/cnpj/v1/${formattedCNPJ}`)
                if (!brasilapi.ok) {
                    throw new Error('Erro on try to get cnpj data from brasilapi.')
                }
                const data = await brasilapi.json()
                setCreateClient((prev) => ({
                    ...prev,
                    name: data?.razao_social,
                    fantasyName: data?.nome_fantasia,
                    email: data?.email,
                    dddPhone: data?.ddd_telefone_1 ? data?.ddd_telefone_1.substring(0, 2) : undefined,
                    phone: data?.ddd_telefone_1 ? data?.ddd_telefone_1.substring(2) : undefined,
                    cep: data?.cep ? data.cep.replace('-', '').replace('.', '') : undefined,
                    addressState: data?.uf,
                    addressCity: data?.municipio,
                    addressComplement: data?.complemento,
                    addressDistrict: data?.bairro,
                    addressNumber: data?.numero,
                    addressRoad: data?.logradouro,
                }))
                setLoading(false)
                setSuccessOnGetAddressOfCNPJ(true)
            } catch (error) {
                setLoading(false)
                setAlert({
                    status: 'error',
                    title: 'Erro',
                    description: (error as any)?.response?.data?.message || 'Erro ao buscar dados'
                })
                setSuccessOnGetAddressOfCNPJ(false)
            }
        }
    }

    return (
        <>
            {
                loading
                    ?
                    <Loader />
                    :
                    <Container noBackground>
                        <Box className="w-full flex flex-col justify-start gap-10">
                            <Box className="bg-white shadow-md sm:p-10 p-3 rounded-md flex flex-col gap-12" >
                                <Text className='text-lg text-primaryColor' fontWeight={'450'}>Cadastro de Clientes</Text>
                                <FormControl className="flex flex-col flex-wrap gap-6">
                                    <Box className="flex gap-6">
                                        <Box>
                                            <FormLabel fontSize={12}>Tipo de cliente: *</FormLabel>
                                            <Select
                                                style={{ width: '170px' }}
                                                className="placeholder:text-zinc-800 h-8"
                                                value={createClient?.personType}
                                                onChange={(e) => setCreateClient(prev => ({ ...prev, personType: e }))}
                                                id="personType"
                                            >
                                                <option value="PF">Pessoa Física</option>
                                                <option value="PJ">Pessoa Jurídica</option>
                                            </Select>
                                        </Box>
                                        {
                                            createClient.personType === "PF" ?
                                                <Box className="flex flex-col w-full md:w-auto">
                                                    <FormLabel fontSize={12}>CPF: *</FormLabel>
                                                    <InputDefault width={"180px"} value={createClient?.cpf} onChange={handleChangeCpf} type="text" id="cpf" maxLength={11}></InputDefault>
                                                </Box>
                                                :
                                                <Box className="flex flex-col w-full md:w-auto">
                                                    <FormLabel fontSize={12}>CNPJ: *</FormLabel>
                                                    <InputDefault width={"180px"} value={createClient?.cnpj} onChange={handleChangeCnpj} onBlur={(e) => getCnpjData(e.target.value)} type="text" id="cnpj" maxLength={18}></InputDefault>
                                                </Box>
                                        }
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>{
                                                createClient.personType === "PF" ? 'Nome: *' : 'Razão Social: *'
                                            }</FormLabel>
                                            <InputDefault width={['100%', '100%', '376px']} value={createClient?.name} onChange={(e) => setCreateClient(prev => ({ ...prev, name: e.target.value }))} type="text" id="name" />
                                        </Box>
                                    </Box>
                                    <Box className="flex flex-row gap-6 flex-wrap w-full">
                                        {
                                            createClient.personType === "PJ" ?
                                                <Box className="flex flex-col w-full md:w-auto">
                                                    <FormLabel fontSize={12}>Nome fantasia:</FormLabel>
                                                    <InputDefault width={['100%', '100%', '376px']} value={createClient?.fantasyName} onChange={(e) => setCreateClient(prev => ({ ...prev, fantasyName: e.target.value }))} type="text" />
                                                </Box>
                                                : <></>
                                        }
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>Email: *</FormLabel>
                                            <InputDefault width={['100%', '100%', '376px']} value={createClient?.email} onChange={(e) => setCreateClient(prev => ({ ...prev, email: e.target.value }))} type="email" id="email" />
                                        </Box>
                                    </Box>
                                    <Box className="flex flex-row gap-6 flex-wrap w-full">
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>CEP:</FormLabel>
                                            <InputDefault
                                                width={['100%', '100%', 44]} value={formatCep(createClient?.cep)}
                                                onBlur={(e) => createClient.personType === 'PF' || !successOnGetAddressOfCNPJ ? getAddressCep(e.target.value) : null}
                                                maxLength={8} onChange={handleChangeCep} type="text"
                                            />
                                        </Box>
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>Rua:</FormLabel>
                                            <InputDefault width={['100%', '100%', '376px']} value={createClient?.addressRoad} onChange={(e) => setCreateClient(prev => ({ ...prev, addressRoad: e.target.value }))} type="text" />
                                        </Box>
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>Número:</FormLabel>
                                            <InputDefault width={['100%', '100%', 44]} value={createClient?.addressNumber} onChange={(e) => setCreateClient(prev => ({ ...prev, addressNumber: e.target.value }))} type="text" />
                                        </Box>
                                    </Box>
                                    <Box className="flex flex-row gap-6 flex-wrap w-full">
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>Complemento:</FormLabel>
                                            <InputDefault width={['100%', '100%', 44]} value={createClient?.addressComplement} onChange={(e) => setCreateClient(prev => ({ ...prev, addressComplement: e.target.value }))} type="text" />
                                        </Box>
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>Bairro:</FormLabel>
                                            <InputDefault width={['100%', '100%', 44]} value={createClient?.addressDistrict} onChange={(e) => setCreateClient(prev => ({ ...prev, addressDistrict: e.target.value }))} type="text" />
                                        </Box>
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>Cidade:</FormLabel>
                                            <InputDefault width={['100%', '100%', 44]} value={createClient?.addressCity} onChange={(e) => setCreateClient(prev => ({ ...prev, addressCity: e.target.value }))} type="text" />
                                        </Box>
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>Estado:</FormLabel>
                                            <InputDefault width={['100%', '100%', 44]} value={createClient?.addressState} maxLength={2} onChange={(e) => setCreateClient(prev => ({ ...prev, addressState: e.target.value }))} type="text" />
                                        </Box>
                                    </Box>
                                    <Box className="flex flex-row gap-3">
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>DDD:</FormLabel>
                                            <InputDefault w={20} value={createClient?.dddPhone ? createClient?.dddPhone : ''} maxLength={2} onChange={(e) => setCreateClient(prev => ({ ...prev, dddPhone: Number(e.target.value) }))} type="text" />
                                        </Box>
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>Telefone:</FormLabel>
                                            <InputDefault width={[36, 72]} value={createClient?.phone ? createClient?.phone : ''} maxLength={9} onChange={(e) => setCreateClient(prev => ({ ...prev, phone: Number(e.target.value) }))} type='tel' />
                                        </Box>
                                    </Box>
                                </FormControl>
                                <Box className="w-full flex justify-between items-center gap-2">
                                    <ButtonDefault onClick={() => navigate('/clients')} success={false}>Cancelar</ButtonDefault>
                                    <ButtonDefault success onClick={() => handleSubmit()}>Cadastrar</ButtonDefault>
                                </Box>
                            </Box>
                        </Box>
                    </Container>
            }
        </>
    )
}