import { useEffect, useState } from 'react'

export function LabelInput({ text, required, children, flexRow, error, zIndex }: any) {
    
    const [fieldError, setFieldError] = useState(false)

    useEffect(()=> {
        setFieldError(error)
    },[error])

    return (
        <label className={`flex ${flexRow ? 'flex-row-reverse' : 'flex-col'} text-sm w-full sm:w-auto relative gap-1 justify-start  ${zIndex ? `z-[${zIndex}]` : '' }`}>
            <p className={`tex-sm:text-sm ${fieldError ? 'text-red-700' : 'text-textPrimaryColor font-medium' } `}>{text} {required ? '*' : ''}</p>
            {children}
        </label>
    )
}