import { Avatar, Box, Button, Flex, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Text } from "@chakra-ui/react";
import { SignOut } from "@phosphor-icons/react";
import Tippy from "@tippyjs/react";
import { useState } from "react";
import { RiMenuFoldLine, RiMenuUnfoldLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import smallLogo from '../../assets/wedo-favicon.png';
import Logo from '../../assets/wedo.png';
import { useAlertContext } from "../../contexts/alertContext";
import { useAuthContext } from "../../contexts/authContext";
import { useScreenModalContext } from "../../contexts/screenModalContext";
import { api } from "../../config/api";
import { ButtonDefault } from "../button/buttonDefault";
import { Loader } from "../loader/loader";
import { ScreenModal } from "../modals/screenModal";
import { menuItensList } from "./menuItensList";

export function Sidebar() {
    const navigate = useNavigate()
    const { userData, setSigned } = useAuthContext()
    const { setAlert } = useAlertContext()
    const { detectModal, setDetectModal } = useScreenModalContext()
    const [loading, setLoading] = useState(false)
    const [popoverProfile, setPopoverProfile] = useState(false)

    async function handleLogout() {
        try {
            setLoading(true)
            await api.delete(`/api/auth/logout`, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            setDetectModal('')
            navigate('/')
            setSigned(false)
            setLoading(false)
            return setAlert({
                title: "Sucesso",
                description: "Sucesso ao deslogar do sistema",
                status: "success"
            })
        } catch (error) {
            setLoading(false)
            setDetectModal('')
            return setAlert({
                title: "Erro",
                description: "Ocorreu algum erro ao deslogar do sistema",
                status: "error"
            })
        }
    }

    return (
        <>
            {
                detectModal === 'logout' ?
                    <ScreenModal height={150} width={260}>
                        <Box className="h-full mt-6 flex flex-col justify-center items-center gap-4">
                            <Text className="text-sm" color={'#1E1E1E'}>Deseja sair do sistema?</Text>
                            <Flex gap={2} alignItems={'center'}>
                                <ButtonDefault onClick={() => setDetectModal('')}>Cancelar</ButtonDefault>
                                <ButtonDefault onClick={() => handleLogout()} success >Confirmar</ButtonDefault>
                            </Flex>
                        </Box>
                    </ScreenModal>
                    : <></>
            }
            {
                loading
                    ?
                    <Loader />
                    :
                    <aside className={`h-[100vh] w-14 select-none bg-white shadow-lg left-0 top-0 2xl:z-50`}>
                        <Box className={`flex flex-col justify-center items-center`}>
                            <Box className="hover:cursor-pointer w-fullflex flex-col justify-center items-center mt-10" onClick={() => navigate('/')}>
                                <img src={smallLogo} alt="Logo" className='overflow-hidden transition-all w-8' />
                            </Box>
                            <Box className={`flex flex-col justify-start items-start w-full text-textPrimaryColor mt-10`}>
                                {
                                    menuItensList.map(menuItem => {
                                        return menuItem.permissions.includes(Number(userData?.auth_typeAccess_id)) ? 
                                            <Box className="flex items-center gap-2" key={menuItem.name}>
                                                <Tippy
                                                    content={<span>{menuItem.name}</span>}
                                                    arrow={true}
                                                    animation='perspective'
                                                    placement='auto'
                                                    delay={80}
                                                >
                                                    <Button className={`cursor-pointer`} onClick={() => navigate(menuItem.path)} colorScheme="">{menuItem.icon}</Button>
                                                </Tippy>  
                                            </Box>
                                        :<></>
                                    })
                                }
                            </Box>
                        </Box>
                        <Box className="ml-3 mb-3 flex flex-col justify-end fixed bottom-0 hover:cursor-pointer z-50 outline-none">
                            <Popover placement='right' variant={'responsive'} size={'sm'} isOpen={popoverProfile} onClose={() => setPopoverProfile(false)}>
                                <PopoverTrigger>
                                    <Box onClick={() => setPopoverProfile(true)}>
                                        <Tippy
                                            content={<span>Perfil</span>}
                                            arrow={true}
                                            animation='perspective'
                                            placement='left-end'
                                            delay={80}
                                            className="ml-2"
                                        >
                                            <Avatar size='sm' bg={"#c4c4c4"} name={userData?.fullname} src={userData?.picture}></Avatar>
                                        </Tippy>
                                    </Box>
                                </PopoverTrigger>
                                <PopoverContent className="mb-2 z-50 outline-none">
                                    <PopoverArrow />
                                    <PopoverBody className="outline-none">
                                        <Box gap={2} zIndex={50} className="outline-none">
                                            <Flex alignItems={'center'} gap={2} onClick={() => navigate('/myprofile')}>
                                                <Avatar size='sm' bg={"#c4c4c4"} name={userData?.fullname} src={userData?.picture}></Avatar>
                                                <Flex className="w-full" flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                                    <Text>Editar perfil</Text>
                                                    <Text textColor={"#FF0000"} className="hover:cursor-pointer"></Text>
                                                </Flex>
                                            </Flex>
                                            <Flex className="ml-1 mt-2" alignItems={'center'} gap={2} onClick={() => setDetectModal('logout')} >
                                                <SignOut size={26} color="#FF0000" />
                                                <Text color={'#FF0000'} fontSize={16} fontWeight={'600'} className="text-sm">Sair</Text>
                                            </Flex>
                                        </Box>
                                    </PopoverBody>
                                </PopoverContent>
                            </Popover>
                        </Box>
                    </aside>
            }
        </>
    )
}