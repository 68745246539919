import { House, Bell, ListChecks, UserList, Gear, Files, FolderLock } from "@phosphor-icons/react";

export const menuItensList = [
    {
        name: 'Home',
        icon: <House size={21} className="text-primaryColor" />,
        path: '/',
        permissions: [ 1, 2, 3, 4 ],
    },
    {
        name: 'Avisos',
        icon: <Bell size={21} className="text-primaryColor"/>,
        path: '/notifications',
        permissions: [ 1, 2, 3, 4 ],
    },
    {
        name: 'Projetos',
        icon: <ListChecks size={21} className="text-primaryColor"/>,
        path: '/projectOverview',
        permissions: [ 1, 2, 3, 4, 5, 6 ],
    },
    {
        name: 'Cofre de senhas',
        icon: <FolderLock size={21} className="text-primaryColor"/>,
        path: '/docs/clientsVault',
        permissions: [ 1, 2, 3, 4 ],
    },
    {
        name: 'Minhas Tarefas',
        icon: <UserList size={21} className="text-primaryColor"/>,
        path: '/myTasks',
        permissions: [ 1, 2, 3, 4 ],
    },
    {
        name: 'Relatórios',
        icon: <Files size={21} className="text-primaryColor"/>,
        path: '/reports',
        permissions: [ 1, 2, 3, 4 ],
    },
    {
        name: 'Configurações',
        icon: <Gear size={23} className="text-primaryColor"/>,
        path: '/settings',
        permissions :  [ 1, 2 ],
    },
]

export const menuItensListMobile = [
    {
        name: 'Home',
        icon: <House size={28} className="text-primaryColor" />,
        path: '/',
        permissions: [ 1, 2, 3, 4 ],
    },
    {
        name: 'Avisos',
        icon: <Bell size={28} className="text-primaryColor"/>,
        path: '/notifications',
        permissions: [ 1, 2, 3, 4 ],
    },
    {
        name: 'Projetos',
        icon: <ListChecks size={28} className="text-primaryColor"/>,
        path: '/projectOverview',
        permissions: [ 1, 2, 3, 4, 5, 6 ],
    },
    {
        name: 'Cofre',
        icon: <FolderLock size={28} className="text-primaryColor"/>,
        path: '/docs/clientsVault',
        permissions: [ 1, 2, 3, 4 ],
    },
    {
        name: 'Tarefas',
        icon: <UserList size={28} className="text-primaryColor"/>,
        path: '/myTasks',
        permissions: [ 1, 2, 3, 4 ],
    },
    {
        name: 'Relatórios',
        icon: <Files size={28} className="text-primaryColor"/>,
        path: '/reports',
        permissions: [ 1, 2, 3, 4 ],
    },
    {
        name: 'Configurações',
        icon: <Gear size={25} className="text-primaryColor"/>,
        path: '/settings',
        permissions :  [ 1, 2 ],
    },
]