import { useScreenSizeContext } from '../../contexts/screenSizeContext';
import { FaEdit } from 'react-icons/fa';
import moment from 'moment';
import { secondsToHHMMSS } from '../../utils/mask/secondsToHours';

interface INewTableDefault{
    tableData: any[]
    columns: IColumns[]
    onClickInTableLine?: (e: any) => void 
    headerColor?: boolean
}

interface IColumns{
    type: 'number' | 'date' | 'datetime' | 'string' | 'boolean' | 'money' | 'status' | 'secondsToHours'
    description: string
    field?: string
    newFieldValue?: (e: any) => any
    color?: 'yellow' | 'red' | 'green' | 'blue' | 'gray'
}

export function NewTableDefaultWithoutAPI({ 
    tableData = [],
    columns = [], 
    onClickInTableLine,
    headerColor = false
}: INewTableDefault) {

    const { screenX } = useScreenSizeContext()

    function formatColumnValue(type: string, value: any){
        switch (type) {
            case 'money':
                return Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value)
            case 'date':
                return moment(value).utc(false).format('DD/MM/YYYY')
            case 'datetime':
                return moment(value).utc(false).format('DD/MM/YYYY hh:mm:ss')
            case 'boolean': 
                return value === true ? 'Sim' : 'Não'
            case 'status': 
                return value === true ? 'Ativo' : 'Inativo'
            case 'secondsToHours': 
                return secondsToHHMMSS(value)
            default:
                return value
        }
    }

    function Table(){

        function Thead(){
            return <thead className={`${headerColor ? 'bg-primaryColor text-white' : 'text-gray-500'} w-full`}>
                <tr className='border-b border-gray-200'>
                    {
                        columns.map((column, index) => {
                            return (
                                <th 
                                    key={'th-'+index} 
                                    className='text-sm py-2' 
                                    onClick={()=> console.error('test')}
                                > {column.description} </th>
                            )
                        })
                    }
                </tr>
            </thead>
        }

        function Tbody(){
            return <tbody>
                {tableData.length > 0 ?
                    tableData.map((data: any, row: number) => {
                        return (
                            <tr 
                                className={`border-b border-zinc-200 ${onClickInTableLine && 'cursor-pointer'} hover:bg-gray-100`}
                                onClick={() => typeof onClickInTableLine === 'function' && onClickInTableLine(data)}
                                key={`tr-${row}`} 
                            >
                                {columns.map((column, index) => {
                                    const field = typeof column?.newFieldValue === 'function' ? column?.newFieldValue(data)
                                        : column.field ? data[column.field] 
                                        : null 
                                    let formattedValue = '-'
                                    if(![undefined, null].includes(field)){
                                        formattedValue = formatColumnValue(column?.type, field)
                                    }
                                    return (
                                        <td key={`td-${index}`} className={`text-sm text-center py-2`}>{formattedValue}</td>
                                    )
                                })}
                            </tr>
                        )
                    })
                    : <></>
                }
                {tableData.length === 0 ?
                    <tr>
                        <td colSpan={columns.length} className='text-center py-2'> Nenhum registro foi encontrado.</td>
                    </tr>
                    : <></>
                }   
            </tbody>
        }

        return (
            <table className='w-full select-none border-collapse table-auto '>
                <Thead/>
                <Tbody/>
            </table>
        )
    }

    function MobileList(){

        function TableMobile(){
            return tableData?.map((data: any) => {
                return (
                    <table className='shadow-lg w-full select-none border-collapse table-auto border border-gray-300 rounded-md'>
                        <tbody>
                            {
                                columns.map(column => {
                                    const field = typeof column?.newFieldValue === 'function' ? column?.newFieldValue(data)
                                        : column.field ? data[column.field] 
                                        : null 
                                    let formattedValue = '-'
                                    if(![undefined, null].includes(field)){
                                        formattedValue = formatColumnValue(column?.type, field)
                                    }
                                    return (
                                        <tr className='bg-slate-100'>
                                            <td className='border-none text-primaryColor px-2 py-1 mt-1'>
                                                {column.description}
                                            </td>
                                            <td className='px-2'>{formattedValue}</td>
                                        </tr>
                                    )
                                })
                            }
                            {
                                onClickInTableLine ?
                                <tr className='bg-primarytext-primaryColor my-0.5 text-white'>
                                    <td className='' colSpan={2} onClick={() => onClickInTableLine(data)}>
                                        <div className='h-1'></div>
                                        <p className='flex gap-2 items-center justify-center py-1'>Editar <FaEdit/></p>
                                    </td>
                                </tr>
                                : <></>
                            }
                        </tbody>
                    </table>
                )
            })
        }
        
        return (
            <div className='flex flex-col gap-2 w-full'>
                {/* <TableMobile/> */}
            </div>
        )
    }

    return (
        <div className="bg-white relative w-full text-titleBlackTextLight overflow-auto">
            {
                screenX <= 750 
                    ? <MobileList/> 
                    : <Table/>
            }
        </div>
    )
}
