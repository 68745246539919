import { InputGroup } from "@chakra-ui/react";
import { Container } from "../../../components/container/container";
import { LabelInput } from "../../../components/label/label.input";
import { InputDefault } from "../../../components/input/inputDefault";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { ButtonDefault } from "../../../components/button/buttonDefault";
import { useEffect, useState } from "react";
import { api } from "../../../config/api";
import { useAuthContext } from "../../../contexts/authContext";
import { useAlertContext } from "../../../contexts/alertContext";
import { IClientsPermissionGroups } from "../../../services/interfaces/doc/ClientsPermissionGroups";
import { IUsers } from "../../../services/interfaces/auth/users";
import { useNavigate } from "react-router-dom";

export function PermissionGroupsCreate() {

    const navigate = useNavigate()
    const { userData } = useAuthContext()
    const { setAlert } = useAlertContext()

    const [loading, setLoading] = useState<boolean>(true)
    const [allPermissionGroups, setAllPermissionGroups] = useState<IClientsPermissionGroups[]>([])
    const [allUsers, setAllUsers] = useState<IUsers[]>([])

    const [selectedPermissionGroups, setSelectedPermissionGroups] = useState<IClientsPermissionGroups | null>(null);
    const [selectedUsers, setSelectedUsers] = useState<IUsers[]>([])

    async function getData() {

        try {

            const permissionGroupsData = await api.get(`/api/v1/auth/permissionGroups`, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            setAllPermissionGroups(permissionGroupsData.data.data)

            const usersData = await api.get(`/api/v1/auth/users`, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            setAllUsers(usersData?.data?.data)

        } catch (error) {
            console.log(error)
            setAlert({
                status: 'error',
                title: 'Erro',
                description: (error as any)?.response?.data?.message || 'Erro ao buscar dados'
            })
        }
    }

    useEffect(()=> {
        getData()
    },[])

    async function handleCreatePermissionGroups() {

        setLoading(true)
        try {
            const data = await api.post('/api/v1/auth/permissionGroups', {
                title: selectedPermissionGroups?.title ? selectedPermissionGroups.title : undefined,
                auth_users_id: selectedUsers?.map(user => { return user.id })
            }, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            allPermissionGroups.push(data.data.data)
            let formattedGroups = allPermissionGroups.sort((a, b) => {
                if (a.title < b.title) {
                    return -1;
                }
                if (a.title > b.title) {
                    return 1;
                }
                return 0;
            })

            setAllPermissionGroups(formattedGroups)
            setLoading(false)
            setAlert({
                status: 'success',
                title: "Sucesso!",
                description: 'Novo grupo criado!'
            })

            navigate('/permissionGroups')

        } catch (error) {
            setLoading(false)
            console.log(error)
            setAlert({
                status: 'error',
                title: 'Erro',
                description: (error as any)?.response?.data?.message || 'Erro ao criar grupo'
            })
        }
    }

    return (
        <Container noBackground>
            <div className="w-full bg-white p-4 shadow-lg rounded-lg flex flex-col gap-2 items-start justify-start">
                <a className="mb-6">Editar grupo de permissão</a>
                <div className="w-96 flex flex-col gap-2 mb-6">
                    <InputGroup className="flex flex-col items-start justify-start gap-2">
                        <LabelInput>Título</LabelInput>
                        <InputDefault value={selectedPermissionGroups?.title}
                            onChange={e => setSelectedPermissionGroups({ ...selectedPermissionGroups, title: e.target.value, auth_users_id: [] })}
                        />
                    </InputGroup>
                    <InputGroup className="flex flex-col items-start justify-start gap-2">
                        <LabelInput>Usuários</LabelInput>
                        <MultiSelect
                            value={selectedUsers}
                            onChange={(e: MultiSelectChangeEvent) => { console.log(e.value); setSelectedUsers(e.value) }}
                            options={allUsers}
                            display="chip"
                            filter
                            width="300px"
                            optionLabel="fullname"
                            id="id"
                            className="w-full md:w-20rem"
                        />
                    </InputGroup>

                </div>
                <div className="mt-2 flex w-full justify-between ">
                    <ButtonDefault onClick={() => navigate(-1)}>Voltar</ButtonDefault>
                    <ButtonDefault success onClick={() => handleCreatePermissionGroups()}>Salvar</ButtonDefault>
                </div>
            </div>
        </Container>
    )
}