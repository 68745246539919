import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "../../../components/container/container";
import { Loader } from "../../../components/loader/loader";
import { useAuthContext } from "../../../contexts/authContext";
import { useToolbarContext } from "../../../contexts/toolbarContext";
import { IReadProjects } from "../../../services/interfaces/pro/projects";
import { api } from "../../../config/api";
import { InputDefault } from "../../../components/input/inputDefault";
import { statusList } from "../../../utils/list/projectsStatusList";
import { Lock, LockOpen, Star } from "@phosphor-icons/react";

export function ProjectsOverview() {
    const navigate = useNavigate()
    const { userData } = useAuthContext()
    const { setShow, setChildrenToolbar } = useToolbarContext()
    const [ loading, setLoading ] = useState(true)
    const [ filteredStatus, setFilteredStatus ] = useState<number>(0)
    const [ filteredText, setFilteredText ] = useState<string>('')

    const [allProjects, setAllProjects] = useState<any[]>([])
    const [filteredProjectData, setFilteredProjectData] = useState<any[]>([])

    async function getData() {
        try {
            const projectsData = await api.get(`/api/v1/pro/projects`, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            const favoriteProjectData = await api.get(`/api/v1/pro/favoriteProjects`, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })
            
            const projects = projectsData?.data?.data || [];
            const favoriteProjects = favoriteProjectData?.data?.data || [];
            
            const projectWithMembers = projects.map((project: any) => {
                const memberData = project?.pro_projectsMembers?.find(
                    (member: any) => member?.auth_users_id === userData?.id
                );
            
                return {
                    ...project,
                    hasAccess: memberData ? true : false,
                };
            });

            const enrichedProjects = projectWithMembers.map((project: any) => {
                const favoriteData = favoriteProjects.find(
                    (fav: any) => fav.pro_projects_id === project.id
                );
                return {
                    ...project,
                    pro_favoriteProjects: favoriteData || null,
                };
            });

            // Filtra e ordena os projetos com base na existência de dados favoritos
            const favoriteProjectsSorted = enrichedProjects
                .filter((project: any) => project.pro_favoriteProjects !== null)
                .sort((a: any, b: any) => {
                    return (a.pro_favoriteProjects.order || 0) - (b.pro_favoriteProjects.order || 0);
                });

            // Filtra os projetos que não são favoritos
            const otherProjects = enrichedProjects.filter(
                (project: any) => project.pro_favoriteProjects === null
            );

            const sortedProjects = [...favoriteProjectsSorted, ...otherProjects];

            setAllProjects(sortedProjects)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    async function  handleFavorite(item: IReadProjects) {
        try {
            setLoading(true)
            if(item?.pro_favoriteProjects !== null) {
                const favoriteId = item?.pro_favoriteProjects !== null ? item?.pro_favoriteProjects?.id : null
            
                const projectsData = await api.delete(`/api/v1/pro/favoriteProjects/${favoriteId}`, 
                    {
                    headers: {
                        Authorization: `Bearer ${userData?.token}`
                    }
                })            
                setAllProjects(projectsData?.data?.data)
                setLoading(false)
                getData()
            } else {
                const projectsData = await api.post(`/api/v1/pro/favoriteProjects`, 
                    {
                        projects_id: item.id
                    },
                    {
                    headers: {
                        Authorization: `Bearer ${userData?.token}`
                    }
                })            
                setAllProjects(projectsData?.data?.data)
                setLoading(false)
                getData()
            }
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    useEffect(() => {
        filterByStatus(filteredStatus, filteredText)
    }, [allProjects, filteredStatus, filteredText])

    function filterByStatus(status: number, text?: string){
        const filtered = allProjects?.filter((p) => {
            return p?.status === status
        }).filter((p) => {
            return p.name.toLowerCase()?.includes(text) 
                || p?.reg_clients?.name?.toLowerCase()?.includes(text)
        })
        setFilteredProjectData(filtered)
    }

    useEffect(() => {
        getData()
        setShow(false) 
        setChildrenToolbar('')
    }, [])

    return (
        <>
            {
                loading
                    ?
                    <Loader />
                    :
                    <Container>
                        <div className="py-4 flex flex-row w-full justify-between">
                            <InputDefault type="string" width={'300px'} placeholder="Pesquisar..." onChange={(e) => setFilteredText(e.target.value)}/>
                            <div className="flex gap-2">
                                {statusList.map(status => {
                                    const bgColorClass = status.id === filteredStatus ? status.defaultStyle : status.basicStyle;
                                    const hoverClass = status.id === filteredStatus ? '' : status.hoverStyle;
                                    const classNames = `px-3 py-1 text-sm rounded-lg flex items-center justify-center cursor-pointer ${bgColorClass} ${hoverClass}`;
                                    return <div 
                                        onClick={() => setFilteredStatus(status.id)}
                                        className={classNames}
                                        style={{
                                        }}
                                    >
                                        {status.description}
                                    </div>
                                })}
                            </div>
                        </div>
                        <div className="w-full flex flex-wrap max-w-maxWDefault 2xl:max-w-full justify-start items-start gap-10 py-2 ">
                            {
                                filteredProjectData?.length === 0 ?
                                    <div className="mt-4 w-full text-center">Não foi encontrado nenhum projeto</div>
                                : filteredProjectData?.map((items: IReadProjects) => {
                                    const projectStatus = statusList.find(status => status.id === items?.status)
                                    return <Box key={items?.id} onClick={() => navigate(`/viewProject/${items?.id}`)} className="relative border transition-all ease-in-out duration-200 hover:cursor-pointer hover:border hover:border-primaryColor  min-w-[20rem] w-[25rem] md:h-[12rem] sm:h-[10rem] bg-white rounded-lg flex flex-col justify-between p-5">
                                        <Flex flexDirection={'column'} alignItems={'start'} gap={5} className="text-gray-700">
                                            <Box>
                                                <Box className="flex flex-row justify-between">
                                                    <Text className="font-semibold text-lg text-primaryColor">{items?.name}</Text>
                                                    <div  onClick={(e) => { e.stopPropagation(); handleFavorite(items)}}  className="absolute top-5 right-5">
                                                    <Tooltip hasArrow label={items?.pro_favoriteProjects !== null ? "Desafixar": "Fixar"} placement={'top'}>
                                                        <Star 
                                                            className="hover:text-yellow-600 mt-1 ml-2 " 
                                                            weight={items?.pro_favoriteProjects !== null ? "fill": "regular"} 
                                                            size={18}
                                                        />
                                                    </Tooltip>
                                                    </div>
                                                </Box>
                                                
                                                    <Text className="text-sm">{items?.reg_clients?.name && items?.reg_clients?.name?.length > 41 ? items?.reg_clients?.name.slice(0, 25) + '...' : items?.reg_clients?.name}</Text>
                                                    
                                            </Box>
                                            <Box>
                                                <Box className="flex flex-row justify-between">
                                                    <Text className={`text-xs font-semibold p-1 rounded-md ${projectStatus?.defaultStyle}`}>
                                                        {projectStatus?.description}
                                                    </Text>
                                                    <div className="absolute top-25 right-5">
                                                        {
                                                            userData?.auth_typeAccess_id !== 1 && userData?.auth_typeAccess_id !== 2 
                                                                ? items.hasAccess === true 
                                                                    ? <Tooltip hasArrow label="Com acesso" placement={'top'}>
                                                                        <LockOpen size={18} weight="thin" />
                                                                    </Tooltip>
                                                                    : <Tooltip hasArrow label="Sem acesso" placement={'top'}>
                                                                        <Lock size={18} weight="thin" />
                                                                    </Tooltip>
                                                                :
                                                                <></>
                                                        }
                                                    </div>
                                                </Box>
                                            </Box>
                                            <Box className="flex flex-row gap-3">
                                                <Box className="flex flex-col">
                                                    <Text className="font-semibold">Limite</Text>
                                                    <Text className="text-sm">{(items.limitHours / 60).toFixed(0).concat('H')}</Text>
                                                </Box>
                                                <Box className="flex flex-col">
                                                    <Text className="font-semibold">Meta</Text>
                                                    <Text className="text-sm">{(items.goalHours / 60).toFixed(0).concat('H')}</Text>
                                                </Box>
                                                <Box className="flex flex-col">
                                                    <Text className="font-semibold">Consumido</Text>
                                                    <Text className="text-sm">{(items?.hoursPerProject ? items?.hoursPerProject / 3600 : 0).toFixed(0).concat('H')}</Text>
                                                </Box>
                                            </Box>
                                        </Flex>
                                        <Text className="font-semibold text-md text-secondaryColor">{items?.clients_description}</Text>
                                    </Box>
                                })
                            }
                        </div>
                    </Container>
            }
        </>
    )
}