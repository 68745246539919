import { AutoComplete, AutoCompleteProps, AutoCompleteCompleteEvent, AutoCompleteChangeEvent } from "primereact/autocomplete";
import { useEffect, useState } from "react";

interface IAutocomplete extends AutoCompleteProps {
    label: string;
    value: string;
    items: any[];
    preselectedItem?: string;
    onChange: (e: AutoCompleteChangeEvent) => void
}

export function AutocompletePrime({label, value, items, preselectedItem, onChange, ...props}: IAutocomplete) {

    const [selectedItem, setSelectedItem] = useState<any>(preselectedItem ? preselectedItem : null);
    const [filteredItems, setFilteredItems] = useState<any[]>([]);
    const [thisItems, setThisItems] = useState<any[]>(items?.map((_, i) => ({ label: `${_[label]}`, value: _[value] })))

    const searchItems = (event: AutoCompleteCompleteEvent) => {
        let query = event.query;
        let _filteredItems = [];

        for (let i = 0; i < thisItems.length; i++) {
            let item = thisItems[i];
            if (item.label?.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                _filteredItems.push(item);
            }
        }

        setFilteredItems(_filteredItems);
    }

    useEffect(()=> {
        setThisItems(items.map((_, i) => ({ label: `${_[label]}`, value: _[value] })))
    }, [items])

    return (
        <AutoComplete className="h-8 text-sm rounded-none"
            value={selectedItem}
            {...props}
            onChange={(e: AutoCompleteChangeEvent) => {onChange(e); setSelectedItem(e.value)}}      
            suggestions={filteredItems}
            completeMethod={searchItems}
            field={'label'} dropdown variant="filled" />
    )
}