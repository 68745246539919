import { Box, Flex, FormControl, FormLabel, Text } from "@chakra-ui/react"
import { DatePicker, DatePickerProps } from "antd"
import dayjs from "dayjs"
import moment from "moment"
import { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { ButtonDefault } from "../../../components/button/buttonDefault"
import { Container } from "../../../components/container/container"
import AutoCompleteInput from "../../../components/input/autocomplete"
import { InputDefault } from "../../../components/input/inputDefault"
import { Loader } from "../../../components/loader/loader"
import { ScreenModal } from "../../../components/modals/screenModal"
import { useAlertContext } from "../../../contexts/alertContext"
import { useAuthContext } from "../../../contexts/authContext"
import { useScreenModalContext } from "../../../contexts/screenModalContext"
import { IUpdateUsers } from "../../../services/interfaces/auth/users"
import { api } from "../../../config/api"
import { formValidator } from "../../../utils/form.validator"

export function UsersEdit() {
    const navigate = useNavigate()
    const location = useLocation();
    const { id } = useParams()
    const { setAlert } = useAlertContext()
    const { userData } = useAuthContext()
    const { detectModal, setDetectModal } = useScreenModalContext()
    const [loading, setLoading] = useState<boolean>(true)
    const dateFormat = ['DD/MM/YYYY']

    const dataTypeAccess = location?.pathname?.includes('external') ? [
        { id: 5, value: 'Externo - Operação' },
        { id: 6, value: 'Externo - Apenas Leitura' },
    ] : [
        { id: 1, value: 'Administrador' },
        { id: 2, value: 'Gestor' },
        { id: 3, value: 'Líder' },
        { id: 4, value: 'Colaborador' }
    ]
    const [dataUser, setDataUser] = useState<IUpdateUsers>({ 
        fullname: '', 
        email: '', 
        position: '', 
        birthday: new Date(), 
        dddPhone: 0, 
        phone: 0, 
        auth_typeAccess_id: undefined || 0,
        id: Number(id),
    })

    async function getUserById() {
        try {
            const dataUserById = await api.get(`/api/v1/auth/users/${id}`, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })
            setDataUser(dataUserById?.data?.data)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setAlert({

                status: 'error',
                title: 'Erro',
                description: 'Erro ao buscar usuário'
            })
        }
    }

    async function handleSubmit() {

        try {
            const requiredFields = formValidator([
                { "name": 'fullname', "value": dataUser?.fullname, "required": true, "type": 'string' },
                { "name": 'email', "value": dataUser?.email, "required": true, "type": 'string' },
                { "name": 'auth_typeAccess_id', "value": dataUser?.auth_typeAccess_id, "required": true, "type": 'number' }
            ])

            if (requiredFields.length > 0) {
                return setAlert({
                    status: "error",
                    title: "Campos obrigatórios",
                    description: "Campos obrigatórios não preenchidos"
                })
            }
            setLoading(true)
            await api.put(`/api/v1/auth/users/${id}`, {
                fullname: dataUser?.fullname,
                email: dataUser?.email,
                position: dataUser?.position ? dataUser?.position : undefined,
                birthday: dataUser?.birthday ? dataUser?.birthday : undefined,
                dddPhone: dataUser?.dddPhone ? dataUser?.dddPhone : undefined,
                phone: dataUser?.phone ? dataUser?.phone : undefined,
                auth_typeAccess_id: dataUser?.auth_typeAccess_id
            }, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            setLoading(false)
            setAlert({
                status: "success",
                title: "Sucesso",
                description: "Sucesso ao editar usuário"
            })
            navigate(-1)
        } catch (error) {
            console.log(error)
            setLoading(false)
            setAlert({
                status: "error",
                title: "Erro",
                description: "Erro ao editar usuário"
            })
        }
    }

    async function handleRemove() {
        try {
            setLoading(true)
            await api.delete(`/api/v1/auth/users/${id}`, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            setLoading(false)
            setAlert({
                status: "success",
                title: "Sucesso",
                description: "Sucesso ao excluir usuário"
            })
            setDetectModal('')
            navigate('/users')
        } catch (error) {
            console.log(error)
            setLoading(false)
            setDetectModal('')
            setAlert({
                status: "error",
                title: "Erro",
                description: "Erro ao excluir usuário"
            })
        }
    }

    function handleChangeDate(): DatePickerProps['onChange'] {
        return (dateString) => {
            const dateFormatted: any = moment(String(dateString?.toISOString()))
            setDataUser((prev: any) => ({
                ...prev,
                birthday: dateString ? dateFormatted : dataUser.birthday
            }))
        }
    }

    async function handleResetPassword() {
        try {
            setLoading(true)
            await api.patch(`/api/v1/auth/users/${id}/resetPassword`, {}, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            setLoading(false)
            setAlert({
                status: "success",
                title: "Sucesso",
                description: "Sucesso ao redefinir senha do usuário"
            })
            setDetectModal('')
            navigate('/users')
        } catch (error) {
            console.log(error)
            setLoading(false)
            setDetectModal('')
            setAlert({
                status: "error",
                title: "Erro",
                description: "Erro ao redefinir senha do usuário"
            })
        }
    }

    useEffect(() => {
        getUserById()
    }, [])

    return (
        <>
            {
                loading
                    ?
                    <Loader />
                    :
                    <Container noBackground>
                        {
                            detectModal === 'removeUser' ?
                                <ScreenModal height={150} width={250}>
                                    <Box className="h-full flex flex-col justify-center items-center gap-4 mt-6">
                                        <Text className='text-sm' color={'#1E1E1E'}>Deseja excluir este usuário?</Text>
                                        <Flex gap={2} alignItems={'center'}>
                                            <ButtonDefault onClick={() => setDetectModal('')} anotherColor="gray">Cancelar</ButtonDefault>
                                            <ButtonDefault onClick={() => handleRemove()} success={false} >Confirmar</ButtonDefault>
                                        </Flex>
                                    </Box>
                                </ScreenModal>
                                : <></>
                        }
                        {
                            detectModal === 'resetPassword' ?
                                <ScreenModal height={150} width={260}>
                                    <Box className="h-full mt-6 flex flex-col justify-center items-center gap-4">
                                        <Text className="text-sm" color={'#1E1E1E'}>Deseja resetar a senha?</Text>
                                        <Flex gap={2} alignItems={'center'}>
                                            <ButtonDefault onClick={() => setDetectModal('')}>Cancelar</ButtonDefault>
                                            <ButtonDefault onClick={() => handleResetPassword()} success >Confirmar</ButtonDefault>
                                        </Flex>
                                    </Box>
                                </ScreenModal>
                                : <></>
                        }
                        <Box className="w-full flex flex-col justify-start gap-10">
                            <Box className="bg-white shadow-md p-10 rounded-md flex flex-col gap-12" >
                                <Box className="flex items-center justify-between gap-10">
                                    <Text className="text-lg text-primaryColor" fontWeight={'450'}>Edição de usuário</Text>
                                    {
                                        userData?.auth_typeAccess_id === 1 &&
                                        <Box>
                                            <ButtonDefault success onClick={() => setDetectModal('resetPassword')} >
                                                Resetar senha
                                            </ButtonDefault>
                                        </Box>
                                    }
                                </Box>
                                <FormControl className="flex flex-row flex-wrap gap-6">
                                    <Box className="flex flex-row gap-6 flex-wrap w-full">
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>Nome completo: *</FormLabel>
                                            <InputDefault
                                                width={['100%', '376px']}
                                                value={dataUser?.fullname}
                                                onChange={(e) =>
                                                    setDataUser(prev => ({ ...prev, fullname: e.target.value }))}
                                                type="text" id="fullname"
                                            />
                                        </Box>
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>Email: *</FormLabel>
                                            <InputDefault
                                                width={['100%', '376px']}
                                                value={dataUser?.email}
                                                onChange={(e) => setDataUser(prev => ({ ...prev, email: e.target.value }))}
                                                type="email" id="email"
                                            />
                                        </Box>
                                    </Box>
                                    <Box className="flex flex-row gap-6 flex-wrap w-full">
                                        <Box className="flex flex-row gap-3">
                                            <Box className="flex flex-col w-full md:w-auto">
                                                <FormLabel fontSize={12}>DDD:</FormLabel>
                                                <InputDefault
                                                    width={20}
                                                    value={dataUser?.dddPhone ? dataUser?.dddPhone : ''}
                                                    maxLength={2}
                                                    onChange={(e) => setDataUser(prev => ({ ...prev, dddPhone: Number(e.target.value) }))}
                                                    type="text"
                                                />
                                            </Box>
                                            <Box className="flex flex-col w-full md:w-auto">
                                                <FormLabel fontSize={12}>Telefone:</FormLabel>
                                                <InputDefault
                                                    width={[36, 72]}
                                                    value={dataUser?.phone ? dataUser?.phone : ''}
                                                    maxLength={9}
                                                    onChange={(e) => setDataUser(prev => ({ ...prev, phone: Number(e.target.value) }))}
                                                    type="tel"
                                                />
                                            </Box>
                                        </Box>
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>Cargo:</FormLabel>
                                            <InputDefault
                                                width={['100%', '100%', 44]}
                                                value={dataUser?.position}
                                                onChange={(e) => setDataUser(prev => ({ ...prev, position: e.target.value }))}
                                                type="text" id="position"
                                            />
                                        </Box>
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>Tipo de acesso: *</FormLabel>
                                            <AutoCompleteInput
                                                defaultValue={dataTypeAccess.filter((items: any) => items?.id === dataUser?.auth_typeAccess_id)[0]?.value}
                                                id="auth_typeAccess_id" data={dataTypeAccess}
                                                placeholder={"Selecione o tipo"}
                                                preSelectedValue={["id", "value"]}
                                                height="10"
                                                onChange={(e: any) => setDataUser(prev => ({ ...prev, auth_typeAccess_id: e }))}
                                            />
                                        </Box>
                                    </Box>
                                    <Box className="flex flex-row gap-6 flex-wrap w-full">
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>Data de nascimento:</FormLabel>
                                            <DatePicker
                                                id="startDate"
                                                format={dateFormat}
                                                placeholder="dd/mm/aaaa"
                                                value={dataUser?.birthday ? dayjs(moment(dataUser?.birthday).utc(false).format('DD/MM/YYYY'), dateFormat[0]) : null}
                                                onChange={handleChangeDate()}
                                                popupStyle={{ zIndex: 10000 }}
                                                style={{ height: 34, width: 180 }}
                                            />
                                        </Box>
                                        <Box className="flex flex-col w-full md:w-auto">
                                            <FormLabel fontSize={12}>MFA | 2FA:</FormLabel>
                                            <AutoCompleteInput
                                                defaultValue={dataUser?.mfa_use ? 'Configurado' : 'Não configurado'}
                                                id="auth_typeAccess_id" data={dataTypeAccess}
                                                disabled={true}
                                                placeholder={"Selecione o tipo"}
                                                preSelectedValue={["id", "value"]}
                                                height="10"
                                            />
                                        </Box>
                                    </Box>
                                </FormControl>
                                <Box className="flex items-center justify-between">
                                    <ButtonDefault onClick={() => navigate(-1)} success={false}>Cancelar</ButtonDefault>
                                    <Flex gap={2}>
                                        <ButtonDefault onClick={() => setDetectModal('removeUser')} success={false}>Excluir</ButtonDefault>
                                        <ButtonDefault success onClick={() => handleSubmit()}>Editar</ButtonDefault>
                                    </Flex>
                                </Box>
                            </Box>
                        </Box>
                    </Container>
            }
        </>
    )
}