import { Input, InputProps } from "@chakra-ui/react";
import React, { InputHTMLAttributes, RefObject, forwardRef } from "react";

interface InputDefaultProps extends InputHTMLAttributes<HTMLInputElement> {}


export const InputHTMLDefault = forwardRef<HTMLInputElement, InputDefaultProps>((props, ref) => {
    return (
        <input {...props} ref={ref} className="w-full h-8 border-2 text-sm flex items-center justify-start  border-zinc-100 p-1 outline-none transition-all duration-200 focus:border-[#3b82f680] rounded-md" />
    );
});

