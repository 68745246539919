import ReactApexChart from 'react-apexcharts'
import * as ApexCharts from 'apexcharts'

interface Data {
  name: string
  value: number
}

export function ColumnChart({ data } : { data: Data[] }) {


  const options: ApexCharts.ApexOptions = {
    chart: {
      id: 'apexcharts-example',
      type: 'bar',
      toolbar: {
        show: true, // Enable chart toolbar for interactivity
      },
      zoom: {
        enabled: true, // Allow zooming for detailed exploration
      },
    },
    xaxis: {
      categories: data.map((item) => item.name), // Extract names for labels
      labels: {
        style: {
          fontSize: '10px', // Adjust font size for readability
          fontWeight: 400, // Set normal font weight
        },
      },
    },
    yaxis: {
      title: {
        style: {
          fontSize: '12px', // Adjust font size for readability
          fontWeight: 400, // Set normal font weight
        },
      },
      labels: {
        style: {
          fontSize: '12px', // Adjust font size for readability
        },
      },
    },
    title: {
      align: 'center', // Center chart title for better presentation
      style: {
        fontSize: '16px', // Adjust font size for readability
        fontWeight: 500, // Set slightly bolder font weight
      },
    },
    dataLabels: {
      enabled: false, // Remove data labels for cleaner presentation (optional)
    },
    plotOptions: {
      bar: {
        columnWidth: '50%', // Adjust column width for better spacing (optional)
      },
    },
  }

  const series = [
    {
      name: '',
      data: data.map((item) => item.value),
    }
  ]

  return (
    <ReactApexChart options={options} series={series} type="bar" height={230} className="sm:w-72 md:max-w-lg" style={{ width: '500px' }}/>
  )
}

