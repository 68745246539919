import { AutoComplete, AutoCompleteProps } from 'antd';
import { useEffect, useState } from 'react'

interface IAutoCompleteInput extends AutoCompleteProps {
  data: any[]
  placeholder?: string
  onChange?: (e: any) => any
  id?: string
  defaultValue?: any
  preSelectedValue?: any
  width?: string
  height?: string
}

export function AutoCompleteInput(props: IAutoCompleteInput) {

  const [optionsData, setOptionsData] = useState([] as any[])
  const [widthAutomcomplete, setWidthAutoComplete] = useState(props.width)
  const [thisValue, setThisValue] = useState(props.defaultValue)

  useEffect(() => {
    let widthToInsert = ''
    try {
      widthToInsert = `${props.width ? props.width?.includes('px') ? `${props.width}` : `${props.width}`.concat('px') : '176px'}`
      setWidthAutoComplete(props.width ? widthToInsert : '176px')
    } catch (error) {
      setWidthAutoComplete('176px')
    }
    
    if(props.preSelectedValue) {
      const selectedOptions = props.data.map((item: any) => {
        const value = item[props.preSelectedValue[0]]
        const label = item[props.preSelectedValue[1]]
        return {
          ...item,
          value,
          label
        }
      })

      setOptionsData(selectedOptions)
    }
  }, [])

  useEffect(()=> {
    setThisValue(props.defaultValue)

  },[props.defaultValue])

  return (
    <AutoComplete
      {...props}
      className={`w-full`}
      style={{ height: props.height ? props.height : 40, width: widthAutomcomplete }}
      options={optionsData}
      
      // filterOption={(inputValue, data: any) =>
      //   data!?.label?.toUpperCase()?.indexOf(inputValue?.toUpperCase()) !== -1
      // }
      onChange={props.onChange}
      value={thisValue}
    />
  )
}

export default AutoCompleteInput;