import { Box, Flex, FormControl, FormLabel, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { ButtonDefault } from "../../../components/button/buttonDefault"
import { Container } from "../../../components/container/container"
import { InputDefault } from "../../../components/input/inputDefault"
import { Loader } from "../../../components/loader/loader"
import { ScreenModal } from "../../../components/modals/screenModal"
import { useAlertContext } from "../../../contexts/alertContext"
import { useAuthContext } from "../../../contexts/authContext"
import { useScreenModalContext } from "../../../contexts/screenModalContext"
import { IClients } from "../../../services/interfaces/reg/clients"
import { formValidator } from "../../../utils/form.validator"
import { IClientsContracts } from "../../../services/interfaces/reg/clientsContracts"
import { NewTableDefaultWithoutAPI } from "../../../components/tables/newTableDefaultWithoutAPI"
import { maskCnpj } from "../../../utils/mask/cnpj"
import { CreateClientContacts, IClientsContacts, UpdateClientContacts } from "../../../services/interfaces/reg/clientsContacts"
import { Clients } from "../../../services/api/reg/Clients"
import { ClientsContacts } from "../../../services/api/reg/ClientsContacts"
import { Pencil, Trash } from "@phosphor-icons/react"

export function ClientsEdit() {

    const navigate = useNavigate()
    const { id } = useParams()
    const { setAlert } = useAlertContext()
    const { userData } = useAuthContext()
    const { detectModal, setDetectModal } = useScreenModalContext()
    const [loading, setLoading] = useState<boolean>(true)
    const [selectedTab, setSelectedTab] = useState<string>('register')
    const [dataClient, setDataClient] = useState<IClients>({
        id: Number(id),
        name: '', 
        email: '', 
        cnpj: '', 
        fantasyName: '', 
        dddPhone: undefined, 
        phone: undefined, 
        cep: '', 
        addressRoad: '', 
        addressState: '', 
        addressDistrict: '', 
        addressCity: '', 
        addressNumber: '', 
        addressComplement: '', 
        personType: "PJ",
        cpf: undefined,
        auth_customers_id: Number(userData?.auth_customers_id),
    })
    const [newContact, setNewContact] = useState<CreateClientContacts>({} as any)
    const [updateContact, setUpdateContact] = useState<UpdateClientContacts & {id: number}>({} as any)
    const [contractsList, setContractList] = useState<IClientsContracts[]>([])
    const [contactsList, setContactList] = useState<IClientsContacts[]>([])
    const [contactIdToRemove, setContactIdToRemove] = useState<number | null>(null)
    const clientsService = new Clients(userData?.token)
    const clientsContactsService = new ClientsContacts(userData?.token)

    async function getClientById() {
        setLoading(true)
        try {
            const dataClientById = await clientsService.readById(Number(id))
            setDataClient(dataClientById as IClients)
            setContractList(dataClientById?.reg_clientsContracts as IClientsContracts[])
            setContactList(dataClientById?.reg_clientsContacts as IClientsContacts[])
            setLoading(false)
        } catch (error) {
            setAlert({
                status: 'error',
                title: 'Erro',
                description: 'Erro ao buscar cliente'
            })
        }finally{
            setLoading(false)
        }
    }

    async function handleSubmit() {
        try {
            const requiredFields =  dataClient.personType === "PF" ?
        
            formValidator( [
                { "name": 'cpf', "value": dataClient?.cpf, "required": true, "type": 'string' },
                { "name": 'name', "value": dataClient?.name, "required": true, "type": 'string' },
                { "name": 'email', "value": dataClient?.email, "required": true, "type": 'string' }
            ]) 
            :
            formValidator( [
                { "name": 'cnpj', "value": dataClient?.cnpj, "required": true, "type": 'string' },
                { "name": 'name', "value": dataClient?.name, "required": true, "type": 'string' },
                { "name": 'email', "value": dataClient?.email, "required": true, "type": 'string' }
            ])
    
            if (requiredFields.length > 0) {
                return setAlert({
                    status: "error",
                    title: "Campos obrigatórios",
                    description: "Campos obrigatórios não preenchidos"
                })
            }
            setLoading(true)

            await clientsService.updateById(Number(id), {
                name: dataClient?.name,
                email: dataClient?.email,
                fantasyName: dataClient?.fantasyName ? dataClient?.fantasyName : undefined,
                dddPhone: dataClient?.dddPhone ? dataClient?.dddPhone : undefined,
                phone: dataClient?.phone ? dataClient?.phone : undefined,
                cep: dataClient?.cep ? dataClient?.cep : undefined,
                addressRoad: dataClient?.addressRoad ? dataClient?.addressRoad : undefined,
                addressState: dataClient?.addressState ? dataClient?.addressState : undefined,
                addressDistrict: dataClient?.addressDistrict ? dataClient?.addressDistrict : undefined,
                addressCity: dataClient?.addressCity ? dataClient?.addressCity : undefined,
                addressNumber: dataClient?.addressNumber ? dataClient?.addressNumber : undefined,
                addressComplement: dataClient?.addressComplement ? dataClient?.addressComplement : undefined
            })
            setAlert({
                status: "success",
                title: "Sucesso",
                description: "Sucesso ao editar cliente"
            })
            navigate('/clients')
        } catch (error) {
            console.log(error)
            setAlert({
                status: "error",
                title: "Erro",
                description: "Erro ao editar cliente"
            })
        }finally{
            setLoading(false)
        }
    }

    async function handleRemove() {
        setLoading(true)
        try {
            await clientsService.removeById(Number(id))
            setAlert({
                status: "success",
                title: "Sucesso",
                description: "Sucesso ao excluir cliente"
            })
            navigate('/clients')
        } catch (error) {
            console.log(error)
            setAlert({
                status: "error",
                title: "Erro",
                description: "Erro ao excluir cliente"
            })
        }finally{
            setDetectModal('')
            setLoading(false)
        }
    }

    async function getAddressCep(cep: string) {
        if (cep?.length === 8) {
            setLoading(true)
            const addressCepData = await fetch(`https://brasilapi.com.br/api/cep/v1/${cep}`).then(function (response) {
                return response.json()
            })
                .catch(function (error) {
                    console.log(error)
                    setLoading(false)
                })
            if (addressCepData) {
                setDataClient(prev => ({
                    ...prev,
                    cep: addressCepData?.cep,
                    addressCity: addressCepData?.city,
                    addressDistrict: addressCepData?.neighborhood,
                    addressState: addressCepData?.state,
                    addressRoad: addressCepData.street,
                }))
                setLoading(false)
            }
        }
    }

    useEffect(() => {
        getClientById()
    }, [])

    async function handleCreateContact(){

        const requiredFields =
        
        formValidator( [
            { "name": 'contact-name', "value": newContact?.name, "required": true, "type": 'string' },
            { "name": 'contact-position', "value": newContact?.position, "required": true, "type": 'string' }
        ]) 
     
        if (requiredFields.length > 0) {
            return setAlert({
                status: "error",
                title: "Campos obrigatórios",
                description: "Campos obrigatórios não preenchidos"
            })
        }

        setLoading(true)
        try {
            const contact = await clientsContactsService.create({
                reg_clients_id: Number(id),
                name: newContact?.name,
                position: newContact?.position,
                dddPhone: newContact?.dddPhone ? Number(newContact.dddPhone) : undefined,
                email: newContact?.email ? newContact.email : undefined,
                phone: newContact?.phone ? Number(newContact.phone) : undefined
            })
            contactsList.push(contact)
            setContactList([...contactsList])
            setNewContact({} as any)
            setDetectModal('')
        } catch (error) {
            setAlert({
                description: 'Erro ao tentar criar contato',
                status: 'error',
                title: 'Erro'
            })
            console.log('> Error on try to create contact:', error)
        } finally {
            setLoading(false)
        }
    }

    async function handleUpdateContact(){
        setLoading(true)
        try {
            const contact = await clientsContactsService.updateById(updateContact.id, {
                name: updateContact?.name ? updateContact.name : undefined,
                position: updateContact?.position ? updateContact.position : undefined,
                dddPhone: updateContact?.dddPhone ? Number(updateContact.dddPhone) : undefined,
                email: updateContact?.email ? updateContact.email : undefined,
                phone: updateContact?.phone ? Number(updateContact.phone) : undefined
            })
            const index = contactsList?.findIndex(contact => contact.id === updateContact.id)
            if(index >= 0){
                contactsList[index] = contact
                setContactList([...contactsList])
                setNewContact({} as any)
                setDetectModal('')
            }
        } catch (error) {
            setAlert({
                description: 'Erro ao tentar alterar contato',
                status: 'error',
                title: 'Erro'
            })
            console.log('Error on try to update contact:', error)
        }finally{
            setLoading(false)
        }
    }

    async function handleRemoveContact(){
        setLoading(true)
        try {
            await clientsContactsService.removeById(Number(contactIdToRemove))
            const index = contactsList?.findIndex(contact => contact.id === contactIdToRemove)
            if(index >= 0){
                contactsList?.splice(index, 1)
                setContactList([...contactsList])
                setDetectModal('')
                setContactIdToRemove(null)
                
            }
        } catch (error) {
            setAlert({
                description: 'Erro ao tentar remover contato',
                status: 'error',
                title: 'Erro'
            })
            console.log('> Erro on try to remove contact:', error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            {
                loading
                    ?
                    <Loader />
                    :
                    <Container noBackground>
                        {
                            detectModal === 'removeClient' ?
                                <ScreenModal height={150} width={250}>
                                    <div className="h-full flex flex-col justify-center items-center gap-4 mt-6">
                                        <p className='text-sm' color={'#1E1E1E'}>Deseja excluir este cliente?</p>
                                        <Flex gap={2} alignItems={'center'}>
                                            <ButtonDefault onClick={() => setDetectModal('')} anotherColor="gray">Cancelar</ButtonDefault>
                                            <ButtonDefault onClick={() => handleRemove()} success={false}>Confirmar</ButtonDefault>
                                        </Flex>
                                    </div>
                                </ScreenModal>
                                : detectModal === 'createContact' ? 
                                    <ScreenModal height={470} width={500}>
                                        <div className="h-full flex flex-col justify-center items-center gap-4 mt-6">
                                            <h2 className='text-md' color={'#1E1E1E'}>Cadastro de contato</h2>
                                            <div className="flex flex-col py-4 gap-1">
                                                <div className="flex flex-col w-full md:w-auto gap-0">
                                                    <FormLabel fontSize={12}>Nome: *</FormLabel>
                                                    <InputDefault onChange={(e) => setNewContact((prev) => ({ ...prev, name: e.target.value }))} width={60} value={newContact.name} type="text" id="contact-name"/>
                                                </div>
                                                <div className="flex flex-col w-full md:w-auto gap-0">
                                                    <FormLabel fontSize={12}>Cargo: *</FormLabel>
                                                    <InputDefault onChange={(e) => setNewContact((prev) => ({ ...prev, position: e.target.value }))} width={60} value={newContact.position} type="text" id="contact-position"/>
                                                </div>
                                                <div className="flex flex-col w-full md:w-auto gap-0">
                                                    <FormLabel fontSize={12}>Telefone: </FormLabel>
                                                    <div className="flex gap-4">
                                                        <InputDefault onChange={(e) => setNewContact((prev) => ({ ...prev, dddPhone: Number(e.target.value) }))} width={12} value={newContact.dddPhone} type="text" id="contact-dddPhone"/>
                                                        <InputDefault onChange={(e) => setNewContact((prev) => ({ ...prev, phone: Number(e.target.value) }))} width={44} value={newContact.phone} type="text" id="contact-phone"/>
                                                    </div>
                                                </div>
                                                <div className="flex flex-col w-full md:w-auto gap-0">
                                                    <FormLabel fontSize={12}>E-mail: </FormLabel>
                                                    <InputDefault onChange={(e) => setNewContact((prev) => ({ ...prev, email: e.target.value }))} width={60} value={newContact.email} type="text" id="contact-email"/>
                                                </div>
                                            </div>
                                            <Flex gap={2} alignItems={'center'}>
                                                <ButtonDefault onClick={() => setDetectModal('')}>Cancelar</ButtonDefault>
                                                <ButtonDefault onClick={() => handleCreateContact()} success={true}>Confirmar</ButtonDefault>
                                            </Flex>
                                        </div>
                                    </ScreenModal>
                                    : detectModal === 'removeContact' ?
                                        <ScreenModal height={150} width={250}>
                                            <div className="h-full flex flex-col justify-center items-center gap-4 mt-6">
                                                <p className='text-sm' color={'#1E1E1E'}>Deseja excluir este contato?</p>
                                                <Flex gap={2} alignItems={'center'}>
                                                    <ButtonDefault onClick={() => setDetectModal('')} anotherColor="gray">Cancelar</ButtonDefault>
                                                    <ButtonDefault onClick={() => handleRemoveContact()} success={false}>Confirmar</ButtonDefault>
                                                </Flex>
                                            </div>
                                        </ScreenModal>
                                        : detectModal === 'updateContact' ? 
                                            <ScreenModal height={470} width={500}>
                                                <div className="h-full flex flex-col justify-center items-center gap-4 mt-6">
                                                    <h2 className='text-md' color={'#1E1E1E'}>Alterar contato</h2>
                                                    <div className="flex flex-col py-4 gap-1">
                                                        <div className="flex flex-col w-full md:w-auto gap-0">
                                                            <FormLabel fontSize={12}>Nome: *</FormLabel>
                                                            <InputDefault onChange={(e) => setUpdateContact((prev) => ({ ...prev, name: e.target.value }))} width={60} value={updateContact.name} type="text" id="contact-name"/>
                                                        </div>
                                                        <div className="flex flex-col w-full md:w-auto gap-0">
                                                            <FormLabel fontSize={12}>Cargo: *</FormLabel>
                                                            <InputDefault onChange={(e) => setUpdateContact((prev) => ({ ...prev, position: e.target.value }))} width={60} value={updateContact.position} type="text" id="contact-position"/>
                                                        </div>
                                                        <div className="flex flex-col w-full md:w-auto gap-0">
                                                            <FormLabel fontSize={12}>Telefone: </FormLabel>
                                                            <div className="flex gap-4">
                                                                <InputDefault onChange={(e) => setUpdateContact((prev) => ({ ...prev, dddPhone: Number(e.target.value) }))} width={12} value={updateContact.dddPhone} type="text" id="contact-dddPhone"/>
                                                                <InputDefault onChange={(e) => setUpdateContact((prev) => ({ ...prev, phone: Number(e.target.value) }))} width={44} value={updateContact.phone} type="text" id="contact-phone"/>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-col w-full md:w-auto gap-0">
                                                            <FormLabel fontSize={12}>E-mail: </FormLabel>
                                                            <InputDefault onChange={(e) => setUpdateContact((prev) => ({ ...prev, email: e.target.value }))} width={60} value={updateContact.email} type="text" id="contact-email"/>
                                                        </div>
                                                    </div>
                                                    <Flex gap={2} alignItems={'center'}>
                                                        <ButtonDefault onClick={() => setDetectModal('')} anotherColor="gray">Cancelar</ButtonDefault>
                                                        <ButtonDefault onClick={() => handleUpdateContact()} success={true}>Confirmar</ButtonDefault>
                                                    </Flex>
                                                </div>
                                            </ScreenModal>
                                            :<></>
                        }
                        <Box className="w-full flex flex-col justify-start gap-4">
                            <Box className="flex gap-2 text-white text-sm">
                                <div onClick={() => setSelectedTab('register')} className={`px-2 py-1 rounded-md cursor-pointer flex items-center ${selectedTab === 'register' ? 'bg-primaryColor' : 'bg-primaryColor/60 hover:bg-primaryColor/50'}`}>
                                    <p>Cadastro</p>
                                </div>
                                <div onClick={() => setSelectedTab('contacts')} className={`px-2 py-1 rounded-md cursor-pointer flex items-center ${selectedTab === 'contacts' ? 'bg-primaryColor' : 'bg-primaryColor/60 hover:bg-primaryColor/50'}`}>
                                    <p>Contatos</p>
                                </div>
                                <div onClick={() => setSelectedTab('contracts')} className={`px-2 py-1 rounded-md cursor-pointer flex items-center ${selectedTab === 'contracts' ? 'bg-primaryColor' : 'bg-primaryColor/60 hover:bg-primaryColor/50'}`}>
                                    <p>Contratos</p>
                                </div>
                            </Box>
                            <Box className="bg-white p-10 rounded-md shadow-lg">
                                {
                                    selectedTab === 'register' ?
                                        <FormControl className="flex flex-col flex-wrap gap-6">
                                            <Box className="flex flex-row gap-6 flex-wrap w-full">
                                                <Box className="flex flex-col w-full md:w-auto">
                                                    <FormLabel fontSize={12}>CNPJ: *</FormLabel>
                                                    <InputDefault isDisabled width={['100%', '100%', 44]} value={dataClient?.cnpj && maskCnpj(dataClient?.cnpj)} type="text" id="cnpj"></InputDefault>
                                                </Box>
                                                <Box className="flex flex-col w-full md:w-auto">
                                                    <FormLabel fontSize={12}>Razão social: *</FormLabel>
                                                    <InputDefault width={['100%', '100%', '376px']} value={dataClient?.name} onChange={(e) => setDataClient(prev => ({ ...prev, name: e.target.value }))} type="text" id="name" />
                                                </Box>
                                                <Box className="flex flex-col w-full md:w-auto">
                                                    <FormLabel fontSize={12}>Nome fantasia:</FormLabel>
                                                    <InputDefault width={['100%', '100%', '376px']} value={dataClient?.fantasyName} onChange={(e) => setDataClient(prev => ({ ...prev, fantasyName: e.target.value }))} type="text" />
                                                </Box>
                                            </Box>
                                            <Box className="flex flex-row gap-6 flex-wrap w-full">
                                                <Box className="flex flex-col w-full md:w-auto">
                                                    <FormLabel fontSize={12}>CEP:</FormLabel>
                                                    <InputDefault width={['100%', '100%', 44]} value={dataClient?.cep} onBlur={(e) => getAddressCep(e.target.value)} maxLength={8} onChange={(e) => setDataClient(prev => ({ ...prev, cep: e.target.value }))} type="text" />
                                                </Box>
                                                <Box className="flex flex-col w-full md:w-auto">
                                                    <FormLabel fontSize={12}>Rua:</FormLabel>
                                                    <InputDefault width={['100%', '100%', '376px']} value={dataClient?.addressRoad} onChange={(e) => setDataClient(prev => ({ ...prev, addressRoad: e.target.value }))} type="text" />
                                                </Box>
                                                <Box className="flex flex-col w-full md:w-auto">
                                                    <FormLabel fontSize={12}>Número:</FormLabel>
                                                    <InputDefault width={['100%', '100%', 44]} value={dataClient?.addressNumber} onChange={(e) => setDataClient(prev => ({ ...prev, addressNumber: e.target.value }))} type="text" />
                                                </Box>
                                                <Box className="flex flex-col w-full md:w-auto">
                                                    <FormLabel fontSize={12}>Complemento:</FormLabel>
                                                    <InputDefault width={['100%', '100%', 44]} value={dataClient?.addressComplement} onChange={(e) => setDataClient(prev => ({ ...prev, addressComplement: e.target.value }))} type="text" />
                                                </Box>
                                            </Box>
                                            <Box className="flex flex-row gap-6 flex-wrap w-full">
                                                <Box className="flex flex-col w-full md:w-auto">
                                                    <FormLabel fontSize={12}>Bairro:</FormLabel>
                                                    <InputDefault width={['100%', '100%', 44]} value={dataClient?.addressDistrict} onChange={(e) => setDataClient(prev => ({ ...prev, addressDistrict: e.target.value }))} type="text" />
                                                </Box>
                                                <Box className="flex flex-col w-full md:w-auto">
                                                    <FormLabel fontSize={12}>Cidade:</FormLabel>
                                                    <InputDefault width={['100%', '100%', 44]} value={dataClient?.addressCity} onChange={(e) => setDataClient(prev => ({ ...prev, addressCity: e.target.value }))} type="text" />
                                                </Box>
                                                <Box className="flex flex-col w-full md:w-auto">
                                                    <FormLabel fontSize={12}>Estado:</FormLabel>
                                                    <InputDefault width={['100%', '100%', 44]} value={dataClient?.addressState} maxLength={2} onChange={(e) => setDataClient(prev => ({ ...prev, addressState: e.target.value }))} type="text" />
                                                </Box>
                                                <Box className="flex flex-col w-full md:w-auto">
                                                    <FormLabel fontSize={12}>DDD:</FormLabel>
                                                    <InputDefault w={20} value={dataClient?.dddPhone ? dataClient?.dddPhone : ''} maxLength={2} onChange={(e) => setDataClient(prev => ({ ...prev, dddPhone: Number(e.target.value) }))} type="text" />
                                                </Box>
                                                <Box className="flex flex-col w-full md:w-auto">
                                                    <FormLabel fontSize={12}>Telefone:</FormLabel>
                                                    <InputDefault width={'274px'} value={dataClient?.phone ? dataClient?.phone : ''} maxLength={9} onChange={(e) => setDataClient(prev => ({ ...prev, phone: Number(e.target.value) }))} type="tel" />
                                                </Box>
                                            </Box>
                                            <Box className="flex flex-col w-full md:w-auto">
                                                <FormLabel fontSize={12}>Email: *</FormLabel>
                                                <InputDefault width={['100%', '100%', '376px']} value={dataClient?.email} onChange={(e) => setDataClient(prev => ({ ...prev, email: e.target.value }))} type="email" id="email" />
                                            </Box>
                                            <Box className="flex justify-between items-center mt-5">
                                                <ButtonDefault onClick={() => navigate(-1)} success={false}>Cancelar</ButtonDefault>
                                                <ButtonDefault success onClick={() => handleSubmit()}>Cadastrar</ButtonDefault>
                                            </Box>
                                        </FormControl>
                                    :  selectedTab === 'contacts' ?
                                        <Box className="flex w-full flex-col gap-5">
                                            <Box className="flex justify-between items-center">
                                                <h2 className="text-primaryColor font-lg">Contatos</h2>
                                                <ButtonDefault success onClick={() => setDetectModal('createContact')}>Adicionar Contato</ButtonDefault>
                                            </Box>
                                            <NewTableDefaultWithoutAPI
                                                tableData={contactsList ? contactsList : []}
                                                columns={[
                                                    {
                                                        description: 'Código',
                                                        field: 'id',
                                                        type: 'number'
                                                    },
                                                    {
                                                        description: 'Nome',
                                                        field: 'name',
                                                        type: 'string'
                                                    },
                                                    {
                                                        description: 'Cargo',
                                                        field: 'position',
                                                        type: 'string'
                                                    },
                                                    {
                                                        description: 'Email',
                                                        field: 'email',
                                                        type: 'string'
                                                    },
                                                    {
                                                        description: 'Telefone',
                                                        field: 'tel',
                                                        type: 'string',
                                                        newFieldValue: (e) => {
                                                            if(!e.dddPhone && !e.phone){
                                                                return ''
                                                            }
                                                            return `(${e.dddPhone}) ${e.phone}`
                                                        }
                                                    },
                                                    {
                                                        description: 'Status',
                                                        field: 'active',
                                                        type: 'status'
                                                    },
                                                    {
                                                        description: 'Ações',
                                                        type: 'string',
                                                        newFieldValue: (e) => {
                                                            return <div className="w-full flex items-center justify-center gap-3">
                                                                <Pencil className="cursor-pointer text-yellow-500" size={17} onClick={() => {
                                                                    setDetectModal('updateContact')
                                                                    setUpdateContact(e)
                                                                }}/>
                                                                <Trash className="cursor-pointer text-red-800" size={17} onClick={() => {
                                                                    setDetectModal('removeContact')
                                                                    setContactIdToRemove(e.id)
                                                                }}/>
                                                            </div>
                                                        }
                                                    }
                                                ]}
                                            />
                                        </Box>
                                    : selectedTab === 'contracts' ?
                                        <Box className="flex w-full flex-col gap-5">
                                            <Box className="flex justify-between items-center">
                                                <h2 className="text-primaryColor font-lg">Contratos</h2>
                                                <ButtonDefault success onClick={() => navigate('/contracts/create')}>Adicionar Contrato</ButtonDefault>
                                            </Box>
                                            <NewTableDefaultWithoutAPI
                                                tableData={contractsList ? contractsList : []}
                                                columns={[
                                                    {
                                                        description: 'Código',
                                                        field: 'id',
                                                        type: 'number'
                                                    },
                                                    {
                                                        description: 'Descrição',
                                                        field: 'description',
                                                        type: 'string'
                                                    },
                                                    {
                                                        description: 'Data de Inicio',
                                                        field: 'startDate',
                                                        type: 'date'
                                                    },
                                                    {
                                                        description: 'Data de Vencimento',
                                                        field: 'finishDate',
                                                        type: 'date'
                                                    },
                                                    {
                                                        description: 'Total de horas',
                                                        field: 'totalAmountSeconds',
                                                        type: 'secondsToHours'
                                                    },
                                                    {
                                                        description: 'Valor final',
                                                        field: 'totalValue',
                                                        type: 'money'
                                                    }
                                                ]}
                                                onClickInTableLine={(e) => navigate(`/contracts/${e.id}`)}
                                            />
                                        </Box>
                                    : <></>
                                }
                            </Box>
                        </Box>
                    </Container>
            }
        </>
    )
}