import { Box } from "@chakra-ui/react";
import { Sidebar } from "../menuSidebar/menuSidebar";
import { ReactNode } from "react";
import { useToolbarContext } from "../../contexts/toolbarContext";
import { ButtonDefault } from "../button/buttonDefault";
import { MobileBar } from "../menuSidebar/menuMobileBar";

interface IDefautTable {
    children: ReactNode,
    noBackground?: boolean
    title?: {
        name: string
        hasButtonAction?: boolean,
        buttonName?: string,
        onClickAction?: () => void 
    }
}

export function Container({ children, noBackground, title }: IDefautTable) {

    const { show, childrenToolBar } = useToolbarContext()

    return (
        <div className="flex flex-row bg-slate-100 justify-center items-center w-screen">
            <div className="w-fit hidden sm:block">
                <Sidebar />
            </div>
            <div className="md:hidden">
                <MobileBar />
            </div>
            <div className={`relative flex flex-col justify-center items-center w-full h-[100vh] bg-slate-100 max-h-[100vh] sm:p-0 md:p-2 `}>
                <div className={`w-full h-full ${noBackground ? '' : ' bg-white shadow-md'} flex max-w-[1800px] animate-[fromLeft_.5s_ease-in-out] flex-col items-center justify-center`}>
                    <div className={`overflow-auto w-full h-full sm:px-2 sm:py-2 xl:px-6 md:py-3`}>
                        {
                            title ?
                                <div className='flex justify-between items-center mb-8 px-4'>
                                    <h2 className="text-primaryColor text-xl">{title.name}</h2>
                                    {
                                        title?.hasButtonAction && title.onClickAction && title.buttonName ?
                                            <ButtonDefault success onClick={title.onClickAction}>
                                                {title.buttonName}
                                            </ButtonDefault>
                                            : <></>
                                    }
                                </div> 
                                : <></>
                        }
                        {children}
                    </div>
                    <div className={`${show ? 'flex' : 'hidden'} animatedElementBottom absolute bottom-8 left-0 flex justify-center items-center z-10 w-full h-16`} id='tasks-tool'>
                        {childrenToolBar}
                    </div>
                </div>
            </div>
        </div>
    )
}