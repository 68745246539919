import { createContext, useState, Dispatch, SetStateAction, useContext, useEffect } from 'react'

export const ThemeContext = createContext<{
  darkMode: boolean,
  setDarkMode: Dispatch<SetStateAction<boolean>>,
  changeDarkMode: ()=> void
} | undefined>(undefined)

export function ThemeContextProvider({ children }: any) {

  const [darkMode, setDarkMode] = useState<boolean>(false)

  useEffect(()=> {

    const darkModeHistory = localStorage.getItem('@devTaskPro-mode')

    if(darkModeHistory === 'dark'){
      setDarkMode(true)
      document.documentElement.classList.add('dark')
    }else{
      setDarkMode(false)
      document.documentElement.classList.remove('dark')
    }

  },[])

  function changeDarkMode() {

    if (document.documentElement.classList.contains('dark')) {
      document.documentElement.classList.remove('dark')
      localStorage.removeItem('@devTaskPro-mode')
      setDarkMode(false)
    } else {
      document.documentElement.classList.add('dark')
      localStorage.setItem('@devTaskPro-mode', 'dark')
      setDarkMode(true)
    }
  }

  return (
    <ThemeContext.Provider value={{
      darkMode,
      setDarkMode,
      changeDarkMode
    }}>
      {children}
    </ThemeContext.Provider>
  )
}

export function useThemeContext(){

  const context = useContext(ThemeContext);

    if (!context) {
        throw new Error("useThemeContext must be used within an ThemeContextProvider");
    }

    return context;
}