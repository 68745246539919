import { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from 'react'
import { IUsers } from '../services/interfaces/auth/users'
import { api } from '../config/api'
import { useAlertContext } from './alertContext'
import { useAuthContext } from './authContext'

export const AllUsersContext = createContext<{
    allUsers: IUsers[],
    setGetUsers: Dispatch<SetStateAction<boolean>>
} | undefined>(undefined)

export function AllUsersContextProvider({ children }: any){

    const { userData } = useAuthContext()
    const { setAlert } = useAlertContext()
    const [allUsers, setAllUsers] = useState<IUsers[]>([])
    const [getUsers, setGetUsers] = useState<boolean>(true)

    useEffect(() => {
        if(getUsers === true && userData?.token){
            getData()
        }
    }, [getUsers, userData])
    
    async function getData(){
        try {
            const dataApi = await api.get('/api/v1/auth/users', {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })
            const data: IUsers[] = await Promise.all(dataApi?.data?.data.map(async (user: IUsers) => {
                if (!!user?.picture) {
                    try {
                        const resImage = await api.get(`/api/v1/auth/myProfile/picture/${user.id}`, {
                            headers: {
                                Authorization: `Bearer ${userData?.token}`,
                            },
                            responseType: 'arraybuffer',
                        })

                        if (resImage.data) {
                            const blob = new Blob([resImage.data], { type: resImage.headers['content-type'] })
                            const imageUrl = URL.createObjectURL(blob)

                            return {
                                ...user,
                                picture: imageUrl
                            }
                        }
                    } catch (error) {
                        console.log('Erro ao buscar foto de perfil do usuário:', user.fullname)
                    }
                } 

                return { ...user }
            }))

            console.log(data)
            setAllUsers([...data])
        } catch (error) {
            setAlert({
                status: 'error',
                title: 'Erro',
                description: 'Erro ao buscar usuários do sistema'
            })
        } finally {
            setGetUsers(false)
        }
    }

    return (
      <AllUsersContext.Provider value={{
        allUsers,
        setGetUsers
      }}>
        {children}
      </AllUsersContext.Provider>
    )
}

export function useAllUsersContext(){
  const context = useContext(AllUsersContext);

  if (!context) {
      throw new Error("useAllUsersContext must be used within an AllUsersContextProvider");
  }

  return context;
}