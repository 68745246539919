import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RedirectRoutes } from './routes/redirect.routes';
import { AuthContextProvider } from './contexts/authContext';
//chakra
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { ThemeContextProvider } from './contexts/themeContext';
import { AlertContextProvider } from './contexts/alertContext';
import { AllUsersContextProvider } from './contexts/allUsersContext';
import { ScreenModalProvider } from './contexts/screenModalContext';
import { ToolbarContextProvider } from './contexts/toolbarContext';
import { TaskMoveContextProvider } from './contexts/taskMoveContext';

//Prime react
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
// import 'primeicons/primeicons.css';
import { PrimeReactProvider } from 'primereact/api'
const theme = extendTheme({
  fonts: {
    heading: `'Lato', sans-serif`,
    body: `'Lato', sans-serif`,
  },
  components: {
    Popover: {
      variants: {
        responsive: {
          popper: { shadow: "lg", focus: 'none' },
          content: { width: "unset" },
        },
      },
    },
  }
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeContextProvider>
      <PrimeReactProvider>
        <ChakraProvider theme={theme}>
          <AuthContextProvider>
            <TaskMoveContextProvider>
              <ToolbarContextProvider>
                <ScreenModalProvider>
                  <AlertContextProvider>
                    <AllUsersContextProvider>
                      <RedirectRoutes />
                    </AllUsersContextProvider>
                  </AlertContextProvider>
                </ScreenModalProvider>
              </ToolbarContextProvider>
            </TaskMoveContextProvider>
          </AuthContextProvider>
        </ChakraProvider>
      </PrimeReactProvider>
    </ThemeContextProvider>
  </React.StrictMode>
);

