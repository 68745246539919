import { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from 'react'
import { IUsers } from '../services/interfaces/auth/users'
import { api } from '../config/api'
import { ICustomers } from '../services/interfaces/auth/customers'

export const AuthContext = createContext<{
    userData: IUsers | undefined,
    setUserData: Dispatch<SetStateAction<IUsers | undefined>>,
    needCofig2FA: boolean,
    setNeedCofig2FA: Dispatch<SetStateAction<boolean>>,
    customerData: ICustomers | undefined,
    signed: boolean,
    setSigned: Dispatch<SetStateAction<boolean>>
    loadingAuth: boolean
} | undefined>(undefined)

export function AuthContextProvider({ children }: any){

    const [userData, setUserData] = useState<IUsers>()
    const [customerData, setCustomerData] = useState<ICustomers>()
    const [needCofig2FA, setNeedCofig2FA] = useState<boolean>(false)
    const [signed, setSigned] = useState<boolean>(false)
    const [requestAuth, setRequestAuth] = useState<boolean>(false)
    const userToken: string | null = localStorage.getItem('@authToken')
    const [loadingAuth, setLoadingAuth] = useState(true)

    async function isAuth(tokenKey: string) {
      try {
        const isAuthData = await api.get('/api/auth/valid', {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            status: isAuthData.data.status,
            data: isAuthData.data
        }
      } catch (error) {
          console.log(error)
          setLoadingAuth(false)
      }
    }

    async function verifytoken(userToken: string) {
      try {
          setLoadingAuth(true)
          const isAuthUser = await isAuth(userToken)
          const data: any = []

          if (isAuthUser?.status !== 200) {
            localStorage.removeItem('@authToken')
            setLoadingAuth(false)
            setSigned(false)
            return
          }

          let picture = undefined
          if (isAuthUser?.data?.userData?.picture) {
            try {
              const resImage = await api.get(`/api/v1/auth/myProfile/picture`,  {
                  headers: {
                      Authorization: `Bearer ${isAuthUser?.data.token}`,
                      
                  },
                  responseType: 'arraybuffer',
              })
              const blob = new Blob([resImage.data], { type: resImage.headers['content-type'] })
              const imageUrl = URL.createObjectURL(blob)
              
              picture = imageUrl
              
            } catch (error) {
              console.log('FOTO DE PERFIL NÃO ENCONTRADA:', error)
            }
          } 
          
          data.push({
            ...isAuthUser?.data?.userData,
            picture
          })
        
          const apiUserData: IUsers[] = []
          apiUserData.push({...data[0]})
          apiUserData[0].token = isAuthUser?.data.token

          const formattedUserData = {...apiUserData}
          setUserData(formattedUserData[0])
          setSigned(true)
          setLoadingAuth(false)
        
      } catch (error) {
          alert('Erro no login, contate o administrador')
          localStorage.removeItem('@authToken')
          setLoadingAuth(false)
          setSigned(false)
          console.log(error)
      }
    }

    async function getCustomersData(){
      try {
        const customerApiData = await api.get('/api/v1/auth/customers', {
          headers: {
            Authorization: `Bearer ${userData?.token}`
          }
        })
        const customer = customerApiData?.data?.data
        setCustomerData(customer)

        console.log('AQUIIII, CUSTOMER', customer)

        if(
          Number(userData?.auth_typeAccess_id) >= 5 && 
          !userData?.mfa_use && 
          customer?.mfaExternalUsers
        ) {
          setNeedCofig2FA(true)
        }

        if(
          Number(userData?.auth_typeAccess_id) <= 4 && 
          !userData?.mfa_use && 
          customer?.mfaInternalUsers
        ) {
          setNeedCofig2FA(true)
        }
      } catch (error) {
        alert('Erro ao buscar dados do cliente')
        console.log(error)
      }
    }

    useEffect(() => {
      verifytoken(userToken as string)
      if(signed && userData?.token){
        getCustomersData()
      }
    }, [requestAuth, signed])

    return (
      <AuthContext.Provider value={{
        userData,
        setUserData,
        signed,
        setSigned,
        loadingAuth,
        customerData,
        setNeedCofig2FA,
        needCofig2FA, 
      }}>
        {children}
      </AuthContext.Provider>
    )
}

export function useAuthContext(){
  const context = useContext(AuthContext);

  if (!context) {
      throw new Error("useAuthContext must be used within an AuthContextProvider");
  }

  return context;
}