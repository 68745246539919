import { useEffect, useState } from "react";
import { api } from "../../../config/api";
//Components
import AutoCompleteInput from "../../../components/input/autocomplete";
import { Clients } from "../../../services/api/reg/Clients";
import { Container } from "../../../components/container/container";
import { DatePicker } from "antd";
import { IProjects } from "../../../services/interfaces/pro/projects";
import { Loader } from "../../../components/loader/loader";
import { InputDefault } from "../../../components/input/inputDefault";
import { useAuthContext } from "../../../contexts/authContext";
import moment from "moment";
import { Box, Flex, FormLabel, Text } from "@chakra-ui/react";
import { ButtonDefault } from "../../../components/button/buttonDefault";
import { Projects } from "../../../services/api/pro/Projects";
import { IClients } from "../../../services/interfaces/reg/clients";
import { NewTableDefaultWithoutAPI } from "../../../components/tables/newTableDefaultWithoutAPI";

//Utils
import { generateXlsx } from "../../../utils/exportData";
import Tippy from "@tippyjs/react";
import { formValidator } from "../../../utils/form.validator";
import { useAlertContext } from "../../../contexts/alertContext";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import { MultiSelect } from "primereact/multiselect";

interface IReport {
    id: number,
    projectName: string,
    description: string,
    clientName: string,
    auth_customers_id: string,
    title: string,
    status: string,
    ammountSeconds: string,
    fullname: string,
    startDate: string,
    dueDate: string,
    finishDate: string,
    billable: boolean,
}

export function ReportsProjectsTimes() {

    const { userData } = useAuthContext()
    const { setAlert } = useAlertContext()
    const projects = new Projects(userData?.token)
    const clients = new Clients(userData?.token)
    const statusData = [
        { id: "-1", name: "Todos" },
        { id: "0", name: "Pendente" },
        { id: "1", name: "Concluído" },
        { id: "2", name: "Em andamento" },
        { id: "3", name: "Parado" },
        { id: "4", name: "Aguardando retorno" }
    ]
    const dateType = [
        { id: "1", name: "Data inicial" },
        { id: "2", name: "Data Conclusão" },
        { id: "3", name: "Data vencimento" },
        { id: "4", name: "Data do apontamento" },
    ]
    const [loading, setLoading] = useState<boolean>(true)
    const [showDatePicker, setShowDatePicker] = useState<any>(false)
    const [projectsData, setProjectsData] = useState<IProjects[]>([])
    const [clientData, setClientData] = useState<IClients[]>([])
    const [selectedProject, setSelectedProject] = useState<IProjects[] | null>(null)
    const [selectedClient, setSelectedClient] = useState<number>()
    const [timeTotalFormated, setTimeTotalFormated] = useState('')
    const [projectDate, setProjectDate] = useState<any>('')
    const [selectedStatus, setSelectedStatus] = useState<any>(undefined)
    const [selectedDateType, setSelectedDateType] = useState<any>()
    const [reportData, setReportData] = useState<IReport[]>([])
    async function getData() {

        setLoading(true)
        const proData = await projects.readAll()
        const proClient = await clients.readAll()
        setClientData(proClient)
        setProjectsData(proData)
        setLoading(false)
    }

    useEffect(() => {
        getData()
    }, [])

    async function handleGetReport() {
        try {
            const requiredFields = formValidator([
                { "name": 'client_id', "value": selectedClient, "required": true, "type": 'string' },
            ])

            if (!projectDate.startDate || !projectDate.finishDate) {
                return setAlert({
                    status: "error",
                    title: "Campos obrigatórios",
                    description: "Data inicial e final devem ser preenchidas!"
                })
            }

            if (!selectedDateType) {
                return setAlert({
                    status: "error",
                    title: "Campos obrigatórios",
                    description: "Obrigatório selecionar o tipo de data para o filtro!"
                })
            }

            if (requiredFields.length > 0) {
                return setAlert({
                    status: "error",
                    title: "Campos obrigatórios",
                    description: "Campos obrigatórios não preenchidos"
                })
            }
            setLoading(true)
            const selectedProjectIds = selectedProject?.map((s) => s.id)
            const response = await api.post(`/api/v1/pro/reports/reportsHoursPerClient`, {
                client_id: selectedClient,
                projects_id: selectedProjectIds,
                status: selectedStatus,
                dateType: selectedDateType,
                startDate: projectDate.startDate,
                finishDate: projectDate.finishDate,

            }, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })
            setReportData(response.data.data[0])
            // setReportCounter(response.data.data[1][0].quantity)
            setTimeTotalFormated(`${Number(response.data.data[1][0].quantity) / 3600 >= 1 ? Math.round(Number(response.data.data[1][0].quantity) / 3600) < 10 ? '0' + Math.floor(Number(response.data.data[1][0].quantity) / 3600) : Math.round(Number(response.data.data[1][0].quantity) / 3600) : '00'}:${(Number(response.data.data[1][0].quantity)) % 3600 / 60 ? Math.round(Number(response.data.data[1][0].quantity) % 3600 / 60) < 10 ? '0' + Math.round(Number(response.data.data[1][0].quantity) % 3600 / 60) : Math.round(Number(response.data.data[1][0].quantity) % 3600 / 60) : '00'}:${(Number(response.data.data[1][0].quantity)) % 3600 % 60 ? Math.round((Number(response.data.data[1][0].quantity) % 3600) % 60) < 10 ? '0' + Math.round((Number(response.data.data[1][0].quantity) % 3600) % 60) : Math.round((Number(response.data.data[1][0].quantity) % 3600) % 60) : '00'}`)
            setLoading(false)

            if (response.data.data[0]?.length === 0) {
                return setAlert({
                    status: "error",
                    title: "Sem dados",
                    description: "Nenhum registro encontrado com os filtros"
                })
            }
        } catch (error) {
            setLoading(false)
        }
    }

    async function handleReset() {
        try {
            setLoading(true)
            setReportData([])
            // setReportCounter('')
            setSelectedProject(null)
            setSelectedClient(undefined)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }
    async function handleImport() {
        const array: any[] = []
        reportData.map(data => {
            const timeFormated = `${Number(data.ammountSeconds) / 3600 >= 1 ? Math.round(Number(data.ammountSeconds) / 3600) < 10 ? '0' + Math.floor(Number(data.ammountSeconds) / 3600) : Math.round(Number(data.ammountSeconds) / 3600) : '00'}:${(Number(data.ammountSeconds)) % 3600 / 60 ? Math.round(Number(data.ammountSeconds) % 3600 / 60) < 10 ? '0' + Math.round(Number(data.ammountSeconds) % 3600 / 60) : Math.round(Number(data.ammountSeconds) % 3600 / 60) : '00'}:${(Number(data.ammountSeconds)) % 3600 % 60 ? Math.round((Number(data.ammountSeconds) % 3600) % 60) < 10 ? '0' + Math.round((Number(data.ammountSeconds) % 3600) % 60) : Math.round((Number(data.ammountSeconds) % 3600) % 60) : '00'}`
            return array.push({
                'Data inicial': data.startDate ? moment.utc(data.startDate).format('DD/MM/YYYY') : ' - ',
                'Data da conclusão': data.dueDate ? moment.utc(data.dueDate).format('DD/MM/YYYY') : ' - ',
                'Data de vencimento': data.finishDate ? moment.utc(data.finishDate).format('DD/MM/YYYY') : ' - ',
                'Nome do cliente': data.clientName,
                'Nome do projeto': data.projectName,
                'Nome da tarefa': data.title,
                'Faturável': data.billable ? 'Sim' : 'Não',
                'Descrição da tarefa': data.description,
                'Responsável': data.fullname,
                'Horas': timeFormated,
            })
        })

        generateXlsx(array, 'relatorio horas', ['Total de horas lançadas', timeTotalFormated])
    }

    return (
        <Container title={{ name: 'Relatório apontamento de horas' }} >
            {
                loading ?
                    <Loader />
                    :
                    <>
                        {
                            reportData.length === 0 ?
                                <form className="flex gap-4 flex-col">
                                    <div>
                                        <FormLabel fontSize={12}>Cliente *</FormLabel>
                                        <AutoCompleteInput
                                            width="400px"
                                            defaultValue={clientData.filter(client => client.id === selectedClient)[0]?.name}
                                            id="client_id" data={clientData}
                                            preSelectedValue={["id", "name"]}
                                            // placeholder={"Selecione o projeto"}
                                            onChange={(e: number) => {
                                                console.log(e)
                                                setSelectedClient(e)
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <FormLabel fontSize={12}>Projeto</FormLabel>
                                        <div className="w-full sm:w-[400px] flex flex-col">
                                            <MultiSelect
                                                value={selectedProject}
                                                onChange={(e: MultiSelectChangeEvent) => setSelectedProject(e.value)}
                                                options={projectsData.filter(project => project.reg_clients_id === selectedClient)}
                                                display="chip"
                                                filter
                                                width="300px"
                                                optionLabel="name"
                                                id="projects_id"
                                                className="w-full md:w-20rem"
                                            />
                                        </div>
                                        {/* <AutoCompleteInput
                                            width="400px"
                                            defaultValue={projectsData.filter(project => project.id === selectedProject)[0]?.name}
                                            id="projects_id" data={projectsData}
                                            preSelectedValue={["id", "name"]}
                                            // placeholder={"Selecione o projeto"}
                                            onChange={(e: number) => {
                                                console.log(e)
                                                setSelectedProject(e)
                                            }}
                                        /> */}
                                    </div>
                                    <div>
                                        <FormLabel fontSize={12}>Status</FormLabel>
                                        <AutoCompleteInput
                                            width="400px"
                                            defaultValue={statusData.filter(status => status.id === selectedStatus)[0]?.name}
                                            id="status_id" data={statusData}
                                            preSelectedValue={["id", "name"]}
                                            onChange={(e: number) => {
                                                setSelectedStatus(e)
                                            }}

                                        />
                                    </div>
                                    <div>
                                        <FormLabel fontSize={12}>Data para filtrar</FormLabel>
                                        <AutoCompleteInput
                                            width="400px"
                                            defaultValue={dateType.filter(status => status.id === selectedDateType)[0]?.name}
                                            id="status_id" data={dateType}
                                            preSelectedValue={["id", "name"]}
                                            onChange={(e: number) => {
                                                setSelectedDateType(e)
                                            }}

                                        />
                                    </div>
                                    {
                                        selectedDateType < 1
                                            ? <> </>
                                            :
                                            <div className="sm:w-[400px] flex sm:gap-4 flex-wrap">

                                                <div className="sm:w-[190px] w-full">
                                                    <FormLabel fontSize={12}>Data inicio</FormLabel>
                                                    <InputDefault id="startDate" isReadOnly={true} value={projectDate.startDate ? moment.utc(projectDate.startDate).format('LL') : 'dd/mm/aaaa'} onClick={() => setShowDatePicker({ startDate: true })}></InputDefault>
                                                    <DatePicker
                                                        open={showDatePicker.startDate}
                                                        id="startDate"
                                                        style={{ visibility: "hidden", width: 0, height: 0, padding: 0, margin: 0, }}
                                                        placeholder="dd/mm/aaaa"
                                                        format={'DD-MM-YYYY'}
                                                        onChange={(dateString) => {
                                                            const dateFormatted: any = moment(String(dateString?.toISOString())).utc(false).format("YYYY-MM-DD")
                                                            setProjectDate((prev: any) => ({
                                                                ...prev,
                                                                startDate: dateString ? dateFormatted : projectDate.startDate
                                                            }))
                                                        }}
                                                        popupStyle={{ zIndex: 10000 }}
                                                        onOpenChange={showDatePicker => setShowDatePicker({ startDate: showDatePicker })}
                                                    />
                                                </div>
                                                <div className="sm:w-[190px] w-full">
                                                    <FormLabel fontSize={12}>Data fim</FormLabel>
                                                    <InputDefault id="finishDate" isReadOnly={true} value={projectDate.finishDate ? moment.utc(projectDate.finishDate).format('LL') : 'dd/mm/aaaa'} onClick={() => setShowDatePicker({ finishDate: true })}></InputDefault>
                                                    <DatePicker
                                                        open={showDatePicker.finishDate}
                                                        id="finishDate"
                                                        style={{ visibility: "hidden", width: 0, height: 0, padding: 0, margin: 0, }}
                                                        placeholder="dd/mm/aaaa"
                                                        format={'DD-MM-YYYY'}
                                                        onChange={(dateString) => {
                                                            const dateFormatted: any = moment(String(dateString?.toISOString())).utc(false).format("YYYY-MM-DD")

                                                            setProjectDate((prev: any) => ({
                                                                ...prev,
                                                                finishDate: dateString ? dateFormatted : projectDate.finishDate
                                                            }))
                                                        }}
                                                        popupStyle={{ zIndex: 10000 }}
                                                        onOpenChange={showDatePicker => setShowDatePicker({ finishDate: showDatePicker })}
                                                    />
                                                </div>
                                            </div>
                                    }
                                    <div className="">
                                        <ButtonDefault onClick={() => handleGetReport()} success>Gerar</ButtonDefault>
                                    </div>
                                </form>
                                :
                                <>
                                    <Flex gap={2} alignItems={'center'}>
                                        <div className="">
                                            <ButtonDefault onClick={() => handleReset()} success={false}>Voltar</ButtonDefault>
                                        </div>
                                        <div className="">
                                            <ButtonDefault onClick={() => handleImport()} success>Exportar relatório</ButtonDefault>
                                        </div>
                                    </Flex>
                                    <Flex className="justify-center" gap={2} alignItems={'center'}>
                                        <h1 className="text-2xl mt-4 mb-4 text-center font-bold" >Total de horas laçadas no período {timeTotalFormated}</h1>
                                    </Flex>
                                    <div className="shadow-lg mb-20">
                                        <NewTableDefaultWithoutAPI
                                            headerColor={true}
                                            tableData={reportData ? reportData : []}
                                            columns={[
                                                {
                                                    description: 'Início',
                                                    field: 'startDate',
                                                    type: 'date'
                                                },
                                                {
                                                    description: 'Conclusão',
                                                    field: 'finishDate',
                                                    type: 'date'
                                                },
                                                {
                                                    description: 'Vencimento',
                                                    field: 'dueDate',
                                                    type: 'date'
                                                },
                                                {
                                                    description: 'Cliente',
                                                    field: 'clientName',
                                                    type: 'string',
                                                    newFieldValue: (e) =>
                                                    e.clientName ?
                                                        <Tippy
                                                            content={<span>{e.clientName}</span>}
                                                            arrow={true}
                                                            animation='perspective'
                                                            placement='top-start'
                                                            delay={80}>
                                                            <Text>{e.clientName?.length > 20 ? e.clientName?.slice(0, 20).concat('...') : e.clientName}</Text>
                                                        </Tippy>
                                                        : ''
                                                },
                                                {
                                                    description: 'Projeto',
                                                    field: 'projectName',
                                                    type: 'string'
                                                },
                                                {
                                                    description: 'Tarefa',
                                                    field: 'title',
                                                    type: 'string',
                                                    newFieldValue: (e) =>
                                                        e.title ?
                                                            <Tippy
                                                                content={<span>{e.title}</span>}
                                                                arrow={true}
                                                                animation='perspective'
                                                                placement='top-start'
                                                                delay={80}>
                                                                <Text>{e.title?.length > 20 ? e.title?.slice(0, 20).concat('...') : e.title}</Text>
                                                            </Tippy>
                                                            : ''
                                                },
                                                {
                                                    description: 'Faturável',
                                                    field: 'billable',
                                                    type: 'boolean'
                                                },
                                                {
                                                    description: 'Anotação',
                                                    field: 'description',
                                                    type: 'string',
                                                    newFieldValue: (e) =>
                                                        e.description ?
                                                            <Tippy
                                                                content={<span>{e.description}</span>}
                                                                arrow={true}
                                                                animation='perspective'
                                                                placement='top-start'
                                                                delay={80}>
                                                                <Text>{e.description?.length > 20 ? e.description?.slice(0, 20).concat('...') : e.description}</Text>
                                                            </Tippy>
                                                            : ''
                                                },
                                                {
                                                    description: 'Responsável',
                                                    field: 'fullname',
                                                    type: 'string'
                                                },
                                                {
                                                    description: 'Horas',
                                                    field: 'ammountSeconds',
                                                    type: 'string',
                                                    newFieldValue: (e) =>
                                                        e.ammountSeconds ?
                                                            <>
                                                                <Box className="flex flex-row justify-center">
                                                                    <Text className="mr-1" fontSize={15}>
                                                                        {e.ammountSeconds / 3600 >= 1 ? Math.round(e.ammountSeconds / 3600) < 10 ? '0' + Math.floor(e.ammountSeconds / 3600) : Math.round(e.ammountSeconds / 3600) : '00'}:
                                                                        {Number(e.ammountSeconds) % 3600 / 60 ? Math.round(e.ammountSeconds % 3600 / 60) < 10 ? '0' + Math.round(e.ammountSeconds % 3600 / 60) : Math.round(e.ammountSeconds % 3600 / 60) : '00'}:
                                                                        {Number(e.ammountSeconds) % 3600 % 60 ? Math.round((Number(e.ammountSeconds) % 3600) % 60) < 10 ? '0' + Math.round((Number(e.ammountSeconds) % 3600) % 60) : Math.round((Number(e.ammountSeconds) % 3600) % 60) : '00'}
                                                                    </Text>

                                                                </Box>
                                                            </> :
                                                            '00:00:00'
                                                },
                                                {
                                                    description: 'Data apontamento',
                                                    field: 'taskTime_created_at',
                                                    type: 'date'
                                                },
                                            ]}
                                        />
                                    </div>
                                </>
                        }
                    </>
            }
        </Container>
    )
}