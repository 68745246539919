import { User, UsersThree, Tag, Gear } from "@phosphor-icons/react";

export const settingsList = [
    {
        group: 'Gerenciamento',
        icon: <Gear size={22} className="text-primaryColor"/>,
        pathList: [
            {
                name: 'Conta',
                path: '/customerAccount'
            },
            {
                name: 'Grupos de permissão',
                path: '/permissionGroups'
            },
        ]
    },
    {
        group: 'Usuários',
        icon: <UsersThree size={22} className="text-primaryColor"/>,
        pathList: [
            {
                name: 'Internos',
                path: '/users'
            },
            {
                name: 'Externos',
                path: '/users/external'
            },
        ]
    },
    {
        group: 'Clientes',
        icon: <User size={22} className="text-primaryColor"/>,
        pathList: [
            {
                name: 'Cadastros',
                path: '/clients'
            },
            {
                name: 'Contratos',
                path: '/contracts'
            },
        ]
    },
    {
        group: 'Projetos',
        icon: <Tag size={22} className="text-primaryColor"/>,
        pathList: [
            {
                name: 'Configurar',
                path: '/projects'
            }
        ]
    }
]