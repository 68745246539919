import { X } from "@phosphor-icons/react";
import { Box } from "@chakra-ui/react";
import { useAlertContext } from "../../contexts/alertContext";
import '../../styles/globals.css'

export function AlertMessage() {
    const { alert, setAlert } = useAlertContext()

    return (
        <Box 
            color='white' position={'fixed'} right={2} marginTop={'10px'} p={2} zIndex={3000} maxWidth={350}
            className={`${alert?.status === 'error' ? 'bg-red-400' : 'bg-emerald-400'} w-fit rounded-md text-center flex flex-col justify-start items-center text-sm`}
        >
            <div 
                onClick={() => setAlert(null)}
                className="absolute right-2 top-2 cursor-pointer border border-white rounded-full w-4 h-4 flex items-center justify-center"
            >
                <X size={10} color="#fff" />
            </div>
            <h2 className="font-semibold px-8" >{alert?.title}</h2>
            <p className="px-8">{alert?.description}</p>
        </Box>
    )
}