import { useEffect, useState } from "react";
import { IProjectsMembers } from "../../../services/interfaces/pro/projectsMembers"
import { IProjectsSteps } from "../../../services/interfaces/pro/projectsSteps"
import { IProjectsTags } from "../../../services/interfaces/pro/projectsTags"


import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { api } from "../../../config/api";
import { useAuthContext } from "../../../contexts/authContext";
import { useAlertContext } from "../../../contexts/alertContext";
import { useScreenModalContext } from "../../../contexts/screenModalContext";
import { ITasks } from "../../../services/interfaces/pro/tasks";

interface ITask {
    step: any,
    task: ITasks,
    index: number
    projectSteps: IProjectsSteps[]
    projectsTags: IProjectsTags[]
    projectsMembers: IProjectsMembers[]
    onClickCheckTask: (id: string) => void
}



interface Column {
    projectId: number,
    projectSteps: IProjectsSteps[]
    projectsTags: IProjectsTags[]
    projectsMembers: IProjectsMembers[]
    stepId: number,
    color?: string
    moveTask?: (dragIndex: number, hoverIndex: number, sourceColumnId: string) => void;  // Ajuste de tipagem
}

//   interface Column {
//     id: string;
//     title: string;
//     tasks: Task[];
//   }

// Tipos para Drag and Drop
interface DragItem {
    index: number;
    id: string;
    type: string;
    columnId: string;
}

export function ProjectKanbanView({ projectSteps, projectsMembers, projectsTags }: IKanbanView) {

    const [columns, setColumns] = useState<IProjectsSteps[]>(projectSteps);

    const moveTask = (dragIndex: number, hoverIndex: number, sourceColumnId: string) => {
        const sourceColumn = columns.find((column) => column.id.toString() === sourceColumnId);
        if (!sourceColumn) return;

        const draggedTask = sourceColumn?.pro_tasks[dragIndex];
        const updatedTasks = [...sourceColumn?.pro_tasks];
        updatedTasks.splice(dragIndex, 1);
        updatedTasks.splice(hoverIndex, 0, draggedTask);

        const updatedColumns = columns.map((column) =>
            column.id.toString() === sourceColumnId ? { ...column, tasks: updatedTasks } : column
        );
        setColumns(updatedColumns);
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {columns.map((column) => (
                    <Column
                        key={column.id}
                        stepId={column.id}
                        projectId={column.pro_projects_id}
                        projectSteps={projectSteps}
                        projectsTags={projectsTags}
                        projectsMembers={projectsMembers}
                        color={column.color}
                        moveTask={moveTask} />
                ))}
            </div>
        </DndProvider>
    );
}

// Componente de Coluna
const Column: React.FC<Column> = ({ projectId, stepId, color, projectSteps, projectsTags, projectsMembers, moveTask }) => {

    const { userData } = useAuthContext()
    const { setAlert } = useAlertContext()
    const { setTaskModal } = useScreenModalContext()
    const [thisStep, setThisStep] = useState<IProjectsSteps>({} as IProjectsSteps)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getData();
    }, []);

    async function getData() {
        try {
            setLoading(true)
            const stepData = await api.get(`/api/v1/pro/projectsSteps/${stepId}/project/${projectId}`, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            })

            setThisStep(stepData?.data?.data)
        } catch (error) {
            console.log('Error on try to read Step:', error)
            setAlert({
                status: "error",
                title: "Erro",
                description: (error as any)?.response?.data?.message || "Erro ao criar tarefa"
            })
        } finally {
            setLoading(false)
        }
    }


    return (
        <div style={{ margin: '16px', width: '50%', borderRadius: '5px', boxShadow: "10px 10px 10px 10px rgba(90, 86, 86, 0.2)", borderColor: thisStep?.color, borderWidth: '0.2rem'}}>
            <h3 style={{backgroundColor: thisStep?.color, padding: '10px', color: 'white'}}>{thisStep.name}</h3>
            {thisStep.pro_tasks?.map((task, index) => (
                <TaskCard key={task?.id} task={task} index={index} moveTask={moveTask!} columnId={stepId.toString()} />
            ))}
        </div>
    );
};

// Componente de Tarefa
const TaskCard: React.FC<{ task: ITasks, index: number, moveTask: (dragIndex: number, hoverIndex: number, sourceColumnId: string) => void, columnId: string }> = ({ task, index, moveTask, columnId }) => {
    console.log(task)
    const [, ref] = useDrag({
        type: 'TASK',
        item: { id: task?.id, index, columnId },
    });

    const [, drop] = useDrop<DragItem>({
        accept: 'TASK',
        hover(item: DragItem) {
            if (item.index === index && item.columnId === columnId) {
                return;
            }
            moveTask(item.index, index, columnId);
            item.index = index;
            item.columnId = columnId;
        },
    });

    if (!task) return null;

    return (
        <div ref={(node) => ref(drop(node))} style={{ padding: '8px', margin: '4px', backgroundColor: 'white', borderRadius: '5px', boxShadow: '10px 10px 10px rgba(90, 86, 86, 0.2)', marginBottom: '8px'}}>
            {task?.title}
        </div>
    );
};



interface IKanbanView {
    projectSteps: IProjectsSteps[]
    projectsTags: IProjectsTags[]
    projectsMembers: IProjectsMembers[]
    onEditStep?: (e: any) => any
}