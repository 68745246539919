import { useNavigate } from "react-router-dom"
import { ButtonDefault } from "./button/buttonDefault"
import { SmileySad } from "@phosphor-icons/react";

export function NotFound404() {

    const navigate = useNavigate()

    return (
        <div className="w-full h-full flex flex-col items-center justify-center gap-10">
            <div className="flex items-center justify-center gap-5">
                <div className="flex flex-col items-end">
                    <h1 className="text-7xl text-primaryColor "> 404</h1>
                    <p className="text-base">Not Found</p>
                </div>   
                <SmileySad size={100} className="text-primaryColor"/>
            </div>
            <p className="text-lg">Pagina informada não encontrada.</p>
            <ButtonDefault
                onClick={() => navigate('/')}
                success={true}
            >Voltar</ButtonDefault>
        </div>
    )
}