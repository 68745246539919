import { Container } from "../../../components/container/container";
import { useAuthContext } from '../../../contexts/authContext';
import { useNavigate } from 'react-router-dom';
import { NewTableDefault } from '../../../components/tables/newTableDefault';
import { statusList } from "../../../utils/list/projectsStatusList";

export function Projects() {

    const { userData } = useAuthContext()
    const navigate = useNavigate()

    return (
        <Container title={{
            name: 'Projetos',
            hasButtonAction: true,
            buttonName: 'Novo projeto',
            onClickAction: () => navigate('/projects/create')
        }}>
            <NewTableDefault
                endpoint='/api/v1/pro/projects'
                columns={[ 
                    {
                        description: "Código",
                        field: "id",
                        type: "number",
                        orderBy: true
                    },
                    {
                        description: "Projeto",
                        field: "name",
                        type: "string",
                        orderBy: true
                    },
                    {
                        description: "Cliente",
                        newFieldValue: (e) => {
                            return e?.reg_clients?.name
                        },
                        type: "string",
                        orderBy: true
                    },
                    {
                        description: "Status",
                        newFieldValue: (e) => {
                            return statusList.find(status => status.id === e.status)?.description
                        },
                        type: "string",
                        orderBy: true
                    }
                ]}
                token={userData?.token as string}
                usePagination={false}
                filters={[]}
                onClickInTableLine={(e) => navigate(`/projects/edit/${e.id}`)}
            />
        </Container>
    )
}