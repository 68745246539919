import { Link } from "react-router-dom";
import { Container } from "../../../components/container/container";
import { settingsList } from "./settingsList";

export function Settings() {

    return (
        <Container noBackground={false}>
            <div className="flex gap-24 flex-wrap w-full px-10 py-5">
                {
                    settingsList.map((setting) => {
                        return <div className="flex flex-col gap-1">
                            <div className="flex gap-2 items-center">
                                {setting.icon}
                                <h1 className="font-semibold text-base text-primaryColor">{setting.group}</h1>
                            </div>
                            <div className="flex flex-col pl-8 text-sm">
                                {
                                    setting.pathList.map(path => {
                                        return <Link to={path.path} className="hover:opacity-60">{path.name}</Link>
                                    })
                                }
                            </div>
                        </div>
                    })
                }
            </div>
        </Container>
    )
}